import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAuthority from './authority.reducer';
import { BaseUser } from 'src/app/shared/models';

export const selectAuthorityState =
  createFeatureSelector<fromAuthority.AuthorityState>(
    fromAuthority.authorityFeatureKey
  );

export const selectAuthorities = createSelector(
  selectAuthorityState,
  state => state.authorities
);

export const selectAuthorityById = (id: string) =>
  createSelector(selectAuthorityState, state => ({
    ...state,
    authority: state.authorities.find(authority => authority.id === id),
  }));

export const selectAuthorityByIdBaseUser = (id: string) =>
  createSelector<object, fromAuthority.AuthorityState, BaseUser | undefined>(
    selectAuthorityState,
    state => state.authorities.find(authority => authority.id === id)
  );

export const selectSelectedAuthority = createSelector(
  selectAuthorityState,
  state => state.selectedAuthority
);
