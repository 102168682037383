/* eslint-disable */

export const protobufPackage = "";

export enum UserType {
  UNKNOWN_TYPE = 0,
  CONSUMER = 1,
  AGENT = 2,
  EMPLOYEE = 3,
  LEADSELLER = 4,
  AUTHORITY = 5,
  AGENCY = 6,
  CARRIER = 7,
  PUBLISHER = 8,
  UNRECOGNIZED = -1,
}

export function userTypeFromJSON(object: any): UserType {
  switch (object) {
    case 0:
    case "UNKNOWN_TYPE":
      return UserType.UNKNOWN_TYPE;
    case 1:
    case "CONSUMER":
      return UserType.CONSUMER;
    case 2:
    case "AGENT":
      return UserType.AGENT;
    case 3:
    case "EMPLOYEE":
      return UserType.EMPLOYEE;
    case 4:
    case "LEADSELLER":
      return UserType.LEADSELLER;
    case 5:
    case "AUTHORITY":
      return UserType.AUTHORITY;
    case 6:
    case "AGENCY":
      return UserType.AGENCY;
    case 7:
    case "CARRIER":
      return UserType.CARRIER;
    case 8:
    case "PUBLISHER":
      return UserType.PUBLISHER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return UserType.UNRECOGNIZED;
  }
}

export function userTypeToJSON(object: UserType): string {
  switch (object) {
    case UserType.UNKNOWN_TYPE:
      return "UNKNOWN_TYPE";
    case UserType.CONSUMER:
      return "CONSUMER";
    case UserType.AGENT:
      return "AGENT";
    case UserType.EMPLOYEE:
      return "EMPLOYEE";
    case UserType.LEADSELLER:
      return "LEADSELLER";
    case UserType.AUTHORITY:
      return "AUTHORITY";
    case UserType.AGENCY:
      return "AGENCY";
    case UserType.CARRIER:
      return "CARRIER";
    case UserType.PUBLISHER:
      return "PUBLISHER";
    case UserType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum UserRole {
  R_UNKNOWN = 0,
  agent = 1,
  admin = 2,
  dialerAdmin = 3,
  UNRECOGNIZED = -1,
}

export function userRoleFromJSON(object: any): UserRole {
  switch (object) {
    case 0:
    case "R_UNKNOWN":
      return UserRole.R_UNKNOWN;
    case 1:
    case "agent":
      return UserRole.agent;
    case 2:
    case "admin":
      return UserRole.admin;
    case 3:
    case "dialerAdmin":
      return UserRole.dialerAdmin;
    case -1:
    case "UNRECOGNIZED":
    default:
      return UserRole.UNRECOGNIZED;
  }
}

export function userRoleToJSON(object: UserRole): string {
  switch (object) {
    case UserRole.R_UNKNOWN:
      return "R_UNKNOWN";
    case UserRole.agent:
      return "agent";
    case UserRole.admin:
      return "admin";
    case UserRole.dialerAdmin:
      return "dialerAdmin";
    case UserRole.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum DialerRole {
  ROLE_UNKNOWN = 0,
  MARKETING_AGENT = 1,
  SALES_AGENT = 2,
  TEAM_LEAD = 3,
  UNRECOGNIZED = -1,
}

export function dialerRoleFromJSON(object: any): DialerRole {
  switch (object) {
    case 0:
    case "ROLE_UNKNOWN":
      return DialerRole.ROLE_UNKNOWN;
    case 1:
    case "MARKETING_AGENT":
      return DialerRole.MARKETING_AGENT;
    case 2:
    case "SALES_AGENT":
      return DialerRole.SALES_AGENT;
    case 3:
    case "TEAM_LEAD":
      return DialerRole.TEAM_LEAD;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DialerRole.UNRECOGNIZED;
  }
}

export function dialerRoleToJSON(object: DialerRole): string {
  switch (object) {
    case DialerRole.ROLE_UNKNOWN:
      return "ROLE_UNKNOWN";
    case DialerRole.MARKETING_AGENT:
      return "MARKETING_AGENT";
    case DialerRole.SALES_AGENT:
      return "SALES_AGENT";
    case DialerRole.TEAM_LEAD:
      return "TEAM_LEAD";
    case DialerRole.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}
