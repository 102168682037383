import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromConsumer from './consumer.reducer';
import { BaseUser } from 'src/app/shared/models';

export const selectConsumerState =
  createFeatureSelector<fromConsumer.ConsumerState>(
    fromConsumer.consumerFeatureKey
  );

export const selectConsumers = createSelector(
  selectConsumerState,
  state => state.consumers
);

export const selectConsumerById = (id: string) =>
  createSelector(selectConsumerState, state =>
    state.consumers.find(consumer => consumer.id === id)
  );

export const selectConsumerByIdBaseUser = (id: string) =>
  createSelector<object, fromConsumer.ConsumerState, BaseUser | undefined>(
    selectConsumerState,
    state => state.consumers.find(consumer => consumer.id === id)
  );

export const selectSelectedConsumer = createSelector(
  selectConsumerState,
  state => state.selectedConsumer
);
