/* eslint-disable */
import * as _m0 from "protobufjs/minimal";

export const protobufPackage = "";

export enum TranscriptStatusEnum {
  TRANSCRIPT_STATUS_UNKNOWN = 0,
  TRANSCRIPT_STATUS_INITIATED = 1,
  TRANSCRIPT_STATUS_PROCESSING = 2,
  TRANSCRIPT_STATUS_ERROR = 3,
  TRANSCRIPT_STATUS_COMPLETED = 4,
  UNRECOGNIZED = -1,
}

export function transcriptStatusEnumFromJSON(object: any): TranscriptStatusEnum {
  switch (object) {
    case 0:
    case "TRANSCRIPT_STATUS_UNKNOWN":
      return TranscriptStatusEnum.TRANSCRIPT_STATUS_UNKNOWN;
    case 1:
    case "TRANSCRIPT_STATUS_INITIATED":
      return TranscriptStatusEnum.TRANSCRIPT_STATUS_INITIATED;
    case 2:
    case "TRANSCRIPT_STATUS_PROCESSING":
      return TranscriptStatusEnum.TRANSCRIPT_STATUS_PROCESSING;
    case 3:
    case "TRANSCRIPT_STATUS_ERROR":
      return TranscriptStatusEnum.TRANSCRIPT_STATUS_ERROR;
    case 4:
    case "TRANSCRIPT_STATUS_COMPLETED":
      return TranscriptStatusEnum.TRANSCRIPT_STATUS_COMPLETED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return TranscriptStatusEnum.UNRECOGNIZED;
  }
}

export function transcriptStatusEnumToJSON(object: TranscriptStatusEnum): string {
  switch (object) {
    case TranscriptStatusEnum.TRANSCRIPT_STATUS_UNKNOWN:
      return "TRANSCRIPT_STATUS_UNKNOWN";
    case TranscriptStatusEnum.TRANSCRIPT_STATUS_INITIATED:
      return "TRANSCRIPT_STATUS_INITIATED";
    case TranscriptStatusEnum.TRANSCRIPT_STATUS_PROCESSING:
      return "TRANSCRIPT_STATUS_PROCESSING";
    case TranscriptStatusEnum.TRANSCRIPT_STATUS_ERROR:
      return "TRANSCRIPT_STATUS_ERROR";
    case TranscriptStatusEnum.TRANSCRIPT_STATUS_COMPLETED:
      return "TRANSCRIPT_STATUS_COMPLETED";
    case TranscriptStatusEnum.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface TranscriptContainer {
  fields: string[];
  status?: TranscriptStatus | undefined;
  url?: string | undefined;
  rawUrlAssembly?: string | undefined;
}

export interface TranscriptStatus {
  status: TranscriptStatusEnum;
  message: string;
}

export interface TranscriptStatusLegacy {
  status: string;
  message: string;
}

function createBaseTranscriptContainer(): TranscriptContainer {
  return { fields: [], status: undefined, url: undefined, rawUrlAssembly: undefined };
}

export const TranscriptContainer = {
  encode(message: TranscriptContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.fields) {
      writer.uint32(10).string(v!);
    }
    if (message.status !== undefined) {
      TranscriptStatus.encode(message.status, writer.uint32(18).fork()).ldelim();
    }
    if (message.url !== undefined) {
      writer.uint32(26).string(message.url);
    }
    if (message.rawUrlAssembly !== undefined) {
      writer.uint32(34).string(message.rawUrlAssembly);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TranscriptContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTranscriptContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fields.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.status = TranscriptStatus.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.url = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.rawUrlAssembly = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TranscriptContainer {
    return {
      fields: Array.isArray(object?.fields) ? object.fields.map((e: any) => String(e)) : [],
      status: isSet(object.status) ? TranscriptStatus.fromJSON(object.status) : undefined,
      url: isSet(object.url) ? String(object.url) : undefined,
      rawUrlAssembly: isSet(object.rawUrlAssembly) ? String(object.rawUrlAssembly) : undefined,
    };
  },

  toJSON(message: TranscriptContainer): unknown {
    const obj: any = {};
    if (message.fields) {
      obj.fields = message.fields.map((e) => e);
    } else {
      obj.fields = [];
    }
    message.status !== undefined && (obj.status = message.status ? TranscriptStatus.toJSON(message.status) : undefined);
    message.url !== undefined && (obj.url = message.url);
    message.rawUrlAssembly !== undefined && (obj.rawUrlAssembly = message.rawUrlAssembly);
    return obj;
  },

  create<I extends Exact<DeepPartial<TranscriptContainer>, I>>(base?: I): TranscriptContainer {
    return TranscriptContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TranscriptContainer>, I>>(object: I): TranscriptContainer {
    const message = createBaseTranscriptContainer();
    message.fields = object.fields?.map((e) => e) || [];
    message.status = (object.status !== undefined && object.status !== null)
      ? TranscriptStatus.fromPartial(object.status)
      : undefined;
    message.url = object.url ?? undefined;
    message.rawUrlAssembly = object.rawUrlAssembly ?? undefined;
    return message;
  },
};

function createBaseTranscriptStatus(): TranscriptStatus {
  return { status: 0, message: "" };
}

export const TranscriptStatus = {
  encode(message: TranscriptStatus, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== "") {
      writer.uint32(18).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TranscriptStatus {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTranscriptStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TranscriptStatus {
    return {
      status: isSet(object.status) ? transcriptStatusEnumFromJSON(object.status) : 0,
      message: isSet(object.message) ? String(object.message) : "",
    };
  },

  toJSON(message: TranscriptStatus): unknown {
    const obj: any = {};
    message.status !== undefined && (obj.status = transcriptStatusEnumToJSON(message.status));
    message.message !== undefined && (obj.message = message.message);
    return obj;
  },

  create<I extends Exact<DeepPartial<TranscriptStatus>, I>>(base?: I): TranscriptStatus {
    return TranscriptStatus.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TranscriptStatus>, I>>(object: I): TranscriptStatus {
    const message = createBaseTranscriptStatus();
    message.status = object.status ?? 0;
    message.message = object.message ?? "";
    return message;
  },
};

function createBaseTranscriptStatusLegacy(): TranscriptStatusLegacy {
  return { status: "", message: "" };
}

export const TranscriptStatusLegacy = {
  encode(message: TranscriptStatusLegacy, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== "") {
      writer.uint32(10).string(message.status);
    }
    if (message.message !== "") {
      writer.uint32(18).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TranscriptStatusLegacy {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTranscriptStatusLegacy();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.status = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TranscriptStatusLegacy {
    return {
      status: isSet(object.status) ? String(object.status) : "",
      message: isSet(object.message) ? String(object.message) : "",
    };
  },

  toJSON(message: TranscriptStatusLegacy): unknown {
    const obj: any = {};
    message.status !== undefined && (obj.status = message.status);
    message.message !== undefined && (obj.message = message.message);
    return obj;
  },

  create<I extends Exact<DeepPartial<TranscriptStatusLegacy>, I>>(base?: I): TranscriptStatusLegacy {
    return TranscriptStatusLegacy.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TranscriptStatusLegacy>, I>>(object: I): TranscriptStatusLegacy {
    const message = createBaseTranscriptStatusLegacy();
    message.status = object.status ?? "";
    message.message = object.message ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
