import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromPublisher from './publisher.reducer';
import { BaseUser } from 'src/app/shared/models';
import { PublisherState } from './publisher.reducer';

export const selectPublisherState =
  createFeatureSelector<fromPublisher.PublisherState>(
    fromPublisher.publisherFeatureKey
  );

export const selectPublishers = createSelector(
  selectPublisherState,
  state => state.publishers
);

export const selectPublisherById = (id: string) =>
  createSelector(
    selectPublisherState,
    (state): PublisherState => ({
      ...state,
      publisher:
        state.publishers.find(publisher => publisher.id === id) ?? null,
    })
  );

export const selectPublisherByIdBaseUser = (id: string) =>
  createSelector<object, fromPublisher.PublisherState, BaseUser | undefined>(
    selectPublisherState,
    state => state.publishers.find(publisher => publisher.id === id)
  );

export const selectSelectedPublisher = createSelector(
  selectPublisherState,
  state => state.publisher
);
