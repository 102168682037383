import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAgent from './agent.reducer';
import { BaseUser } from 'src/app/shared/models';

export const selectAgentState = createFeatureSelector<fromAgent.AgentState>(
  fromAgent.agentFeatureKey
);

export const selectAgents = createSelector(
  selectAgentState,
  state => state.agents
);

export const selectAgentById = (id: string) =>
  createSelector(selectAgentState, state => ({
    ...state,
    agent: state?.agents?.find(agent => agent.id === id) ?? null,
  }));

export const selectAgentByIdBaseUser = (id: string) =>
  createSelector<object, fromAgent.AgentState, BaseUser | undefined>(
    selectAgentState,
    state => state.agents.find(agent => agent.id === id)
  );

export const selectSelectedAgent = createSelector(
  selectAgentState,
  state => state.agent
);
