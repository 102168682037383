/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { AIPrompt } from "./ai-prompt";

export const protobufPackage = "";

export enum PromptsStatusEnum {
  PROMPTS_STATUS_UNKNOWN = 0,
  PROMPTS_STATUS_INITIATED = 1,
  PROMPTS_STATUS_PROCESSING = 2,
  PROMPTS_STATUS_ERROR = 3,
  PROMPTS_STATUS_COMPLETED = 4,
  UNRECOGNIZED = -1,
}

export function promptsStatusEnumFromJSON(object: any): PromptsStatusEnum {
  switch (object) {
    case 0:
    case "PROMPTS_STATUS_UNKNOWN":
      return PromptsStatusEnum.PROMPTS_STATUS_UNKNOWN;
    case 1:
    case "PROMPTS_STATUS_INITIATED":
      return PromptsStatusEnum.PROMPTS_STATUS_INITIATED;
    case 2:
    case "PROMPTS_STATUS_PROCESSING":
      return PromptsStatusEnum.PROMPTS_STATUS_PROCESSING;
    case 3:
    case "PROMPTS_STATUS_ERROR":
      return PromptsStatusEnum.PROMPTS_STATUS_ERROR;
    case 4:
    case "PROMPTS_STATUS_COMPLETED":
      return PromptsStatusEnum.PROMPTS_STATUS_COMPLETED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PromptsStatusEnum.UNRECOGNIZED;
  }
}

export function promptsStatusEnumToJSON(object: PromptsStatusEnum): string {
  switch (object) {
    case PromptsStatusEnum.PROMPTS_STATUS_UNKNOWN:
      return "PROMPTS_STATUS_UNKNOWN";
    case PromptsStatusEnum.PROMPTS_STATUS_INITIATED:
      return "PROMPTS_STATUS_INITIATED";
    case PromptsStatusEnum.PROMPTS_STATUS_PROCESSING:
      return "PROMPTS_STATUS_PROCESSING";
    case PromptsStatusEnum.PROMPTS_STATUS_ERROR:
      return "PROMPTS_STATUS_ERROR";
    case PromptsStatusEnum.PROMPTS_STATUS_COMPLETED:
      return "PROMPTS_STATUS_COMPLETED";
    case PromptsStatusEnum.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface CustomPromptContainer {
  results: { [key: string]: AIPrompt };
  fields: string[];
  status?: CustomPromptStatus | undefined;
}

export interface CustomPromptContainer_ResultsEntry {
  key: string;
  value: AIPrompt | undefined;
}

export interface CustomPromptStatus {
  status: PromptsStatusEnum;
  message: string;
}

function createBaseCustomPromptContainer(): CustomPromptContainer {
  return { results: {}, fields: [], status: undefined };
}

export const CustomPromptContainer = {
  encode(message: CustomPromptContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.results).forEach(([key, value]) => {
      CustomPromptContainer_ResultsEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    for (const v of message.fields) {
      writer.uint32(18).string(v!);
    }
    if (message.status !== undefined) {
      CustomPromptStatus.encode(message.status, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CustomPromptContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCustomPromptContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = CustomPromptContainer_ResultsEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.results[entry1.key] = entry1.value;
          }
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fields.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.status = CustomPromptStatus.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CustomPromptContainer {
    return {
      results: isObject(object.results)
        ? Object.entries(object.results).reduce<{ [key: string]: AIPrompt }>((acc, [key, value]) => {
          acc[key] = AIPrompt.fromJSON(value);
          return acc;
        }, {})
        : {},
      fields: Array.isArray(object?.fields) ? object.fields.map((e: any) => String(e)) : [],
      status: isSet(object.status) ? CustomPromptStatus.fromJSON(object.status) : undefined,
    };
  },

  toJSON(message: CustomPromptContainer): unknown {
    const obj: any = {};
    obj.results = {};
    if (message.results) {
      Object.entries(message.results).forEach(([k, v]) => {
        obj.results[k] = AIPrompt.toJSON(v);
      });
    }
    if (message.fields) {
      obj.fields = message.fields.map((e) => e);
    } else {
      obj.fields = [];
    }
    message.status !== undefined &&
      (obj.status = message.status ? CustomPromptStatus.toJSON(message.status) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<CustomPromptContainer>, I>>(base?: I): CustomPromptContainer {
    return CustomPromptContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CustomPromptContainer>, I>>(object: I): CustomPromptContainer {
    const message = createBaseCustomPromptContainer();
    message.results = Object.entries(object.results ?? {}).reduce<{ [key: string]: AIPrompt }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = AIPrompt.fromPartial(value);
      }
      return acc;
    }, {});
    message.fields = object.fields?.map((e) => e) || [];
    message.status = (object.status !== undefined && object.status !== null)
      ? CustomPromptStatus.fromPartial(object.status)
      : undefined;
    return message;
  },
};

function createBaseCustomPromptContainer_ResultsEntry(): CustomPromptContainer_ResultsEntry {
  return { key: "", value: undefined };
}

export const CustomPromptContainer_ResultsEntry = {
  encode(message: CustomPromptContainer_ResultsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      AIPrompt.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CustomPromptContainer_ResultsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCustomPromptContainer_ResultsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = AIPrompt.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CustomPromptContainer_ResultsEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? AIPrompt.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: CustomPromptContainer_ResultsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value ? AIPrompt.toJSON(message.value) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<CustomPromptContainer_ResultsEntry>, I>>(
    base?: I,
  ): CustomPromptContainer_ResultsEntry {
    return CustomPromptContainer_ResultsEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CustomPromptContainer_ResultsEntry>, I>>(
    object: I,
  ): CustomPromptContainer_ResultsEntry {
    const message = createBaseCustomPromptContainer_ResultsEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? AIPrompt.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseCustomPromptStatus(): CustomPromptStatus {
  return { status: 0, message: "" };
}

export const CustomPromptStatus = {
  encode(message: CustomPromptStatus, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== "") {
      writer.uint32(18).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CustomPromptStatus {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCustomPromptStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CustomPromptStatus {
    return {
      status: isSet(object.status) ? promptsStatusEnumFromJSON(object.status) : 0,
      message: isSet(object.message) ? String(object.message) : "",
    };
  },

  toJSON(message: CustomPromptStatus): unknown {
    const obj: any = {};
    message.status !== undefined && (obj.status = promptsStatusEnumToJSON(message.status));
    message.message !== undefined && (obj.message = message.message);
    return obj;
  },

  create<I extends Exact<DeepPartial<CustomPromptStatus>, I>>(base?: I): CustomPromptStatus {
    return CustomPromptStatus.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CustomPromptStatus>, I>>(object: I): CustomPromptStatus {
    const message = createBaseCustomPromptStatus();
    message.status = object.status ?? 0;
    message.message = object.message ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
