import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromCarrier from './carrier.reducer';
import { BaseUser } from 'src/app/shared/models';

export const selectCarrierState =
  createFeatureSelector<fromCarrier.CarrierState>(
    fromCarrier.carrierFeatureKey
  );

export const selectCarriers = createSelector(
  selectCarrierState,
  state => state.carriers
);

export const selectCarrierById = (id: string) =>
  createSelector(selectCarrierState, state => ({
    ...state,
    carrier: state.carriers.find(carrier => carrier.id === id),
  }));

export const selectCarrierByIdBaseUser = (id: string) =>
  createSelector<object, fromCarrier.CarrierState, BaseUser | undefined>(
    selectCarrierState,
    state => state.carriers.find(carrier => carrier.id === id)
  );

export const selectSelectedCarrier = createSelector(
  selectCarrierState,
  state => state.selectedCarrier
);
