import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromLeadSeller from './lead-seller.reducer';
import { BaseUser } from 'src/app/shared/models';

export const selectLeadSellerState =
  createFeatureSelector<fromLeadSeller.LeadSellerState>(
    fromLeadSeller.leadsellerFeatureKey
  );

export const selectLeadSellers = createSelector(
  selectLeadSellerState,
  state => state.leadsellers
);

export const selectLeadSellerById = (id: string) =>
  createSelector(selectLeadSellerState, state => ({
    ...state,
    leadseller: state.leadsellers.find(leadseller => leadseller.id === id),
  }));

export const selectLeadSellerByIdBaseUser = (id: string) =>
  createSelector<object, fromLeadSeller.LeadSellerState, BaseUser | undefined>(
    selectLeadSellerState,
    state => state.leadsellers.find(leadseller => leadseller.id === id)
  );

export const selectSelectedLeadSeller = createSelector(
  selectLeadSellerState,
  state => state.selectedLeadSeller
);
