import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAgency from './agency.reducer';
import { BaseUser } from 'src/app/shared/models';

export const selectAgencyState = createFeatureSelector<fromAgency.AgencyState>(
  fromAgency.agencyFeatureKey
);

export const selectAgencies = createSelector(
  selectAgencyState,
  state => state.agencies
);

export const selectAgencyById = (id: string) =>
  createSelector(selectAgencyState, state => ({
    ...state,
    agency: state.agencies.find(agency => agency.id === id),
  }));

export const selectAgencyByIdBaseUser = (id: string) => {
  console.log('get agency', id);
  return createSelector<object, fromAgency.AgencyState, BaseUser | undefined>(
    selectAgencyState,
    state => state.agencies.find(agency => agency.id === id)
  );
};

export const selectSelectedAgency = createSelector(
  selectAgencyState,
  state => state.agency
);
