/* eslint-disable */
// @ts-ignore
import * as Long from "long";
import * as _m0 from "protobufjs/minimal";
import { Agent } from "./agent";
import {
  Consumer,
  Consumer_Dialer_Result,
  Consumer_Dialer_ResultCategory,
  consumer_Dialer_ResultCategoryFromJSON,
  consumer_Dialer_ResultCategoryToJSON,
  consumer_Dialer_ResultFromJSON,
  consumer_Dialer_ResultToJSON,
} from "./consumer";
import { CustomPromptContainer } from "./custom-prompt";
import { Struct } from "./google/protobuf/struct";
import { Timestamp } from "./google/protobuf/timestamp";
import {
  DialerCallCap,
  DialerSettings,
  DialerWebRtc,
  EnrollmentCode,
  EnrollmentSource,
  enrollmentSourceFromJSON,
  enrollmentSourceToJSON,
  Features,
  IdentifierContainer,
  PermanentTagsContainer,
  ReferenceContainer,
  Skill,
  SkillListContainer,
  SortDirection,
  sortDirectionFromJSON,
  sortDirectionToJSON,
  Source,
  sourceFromJSON,
  sourceToJSON,
  StringListContainer,
  TagsContainer,
  UserDisabled,
  UserIdContainer,
} from "./misc";
import { Questionnaire } from "./questionnaire";
import { TranscriptContainer, TranscriptStatusLegacy } from "./transcript-status";
import { BaseUser, User, User_Permission_Roles } from "./user";
import {
  DialerRole,
  dialerRoleFromJSON,
  dialerRoleToJSON,
  UserRole,
  userRoleFromJSON,
  userRoleToJSON,
  UserType,
  userTypeFromJSON,
  userTypeToJSON,
} from "./user-enum";

export const protobufPackage = "";

export enum DialerAgentStatus {
  UNKNOWN_STATUS = 0,
  OFFLINE = 1,
  AVAILABLE = 2,
  CALLING = 3,
  DISPO = 4,
  PAUSED = 5,
  DEAD_CALL = 6,
  RINGING = 7,
  READY = 8,
  RINGING_OUTGOING = 9,
  CUSTOMER_SUPPORT = 10,
  UNRECOGNIZED = -1,
}

export function dialerAgentStatusFromJSON(object: any): DialerAgentStatus {
  switch (object) {
    case 0:
    case "UNKNOWN_STATUS":
      return DialerAgentStatus.UNKNOWN_STATUS;
    case 1:
    case "OFFLINE":
      return DialerAgentStatus.OFFLINE;
    case 2:
    case "AVAILABLE":
      return DialerAgentStatus.AVAILABLE;
    case 3:
    case "CALLING":
      return DialerAgentStatus.CALLING;
    case 4:
    case "DISPO":
      return DialerAgentStatus.DISPO;
    case 5:
    case "PAUSED":
      return DialerAgentStatus.PAUSED;
    case 6:
    case "DEAD_CALL":
      return DialerAgentStatus.DEAD_CALL;
    case 7:
    case "RINGING":
      return DialerAgentStatus.RINGING;
    case 8:
    case "READY":
      return DialerAgentStatus.READY;
    case 9:
    case "RINGING_OUTGOING":
      return DialerAgentStatus.RINGING_OUTGOING;
    case 10:
    case "CUSTOMER_SUPPORT":
      return DialerAgentStatus.CUSTOMER_SUPPORT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DialerAgentStatus.UNRECOGNIZED;
  }
}

export function dialerAgentStatusToJSON(object: DialerAgentStatus): string {
  switch (object) {
    case DialerAgentStatus.UNKNOWN_STATUS:
      return "UNKNOWN_STATUS";
    case DialerAgentStatus.OFFLINE:
      return "OFFLINE";
    case DialerAgentStatus.AVAILABLE:
      return "AVAILABLE";
    case DialerAgentStatus.CALLING:
      return "CALLING";
    case DialerAgentStatus.DISPO:
      return "DISPO";
    case DialerAgentStatus.PAUSED:
      return "PAUSED";
    case DialerAgentStatus.DEAD_CALL:
      return "DEAD_CALL";
    case DialerAgentStatus.RINGING:
      return "RINGING";
    case DialerAgentStatus.READY:
      return "READY";
    case DialerAgentStatus.RINGING_OUTGOING:
      return "RINGING_OUTGOING";
    case DialerAgentStatus.CUSTOMER_SUPPORT:
      return "CUSTOMER_SUPPORT";
    case DialerAgentStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum DialerPauseReason {
  UNKNOWN_PAUSE_REASON = 0,
  BREAK = 1,
  LUNCH = 2,
  TRAINING = 3,
  NOT_READY = 4,
  UNRECOGNIZED = -1,
}

export function dialerPauseReasonFromJSON(object: any): DialerPauseReason {
  switch (object) {
    case 0:
    case "UNKNOWN_PAUSE_REASON":
      return DialerPauseReason.UNKNOWN_PAUSE_REASON;
    case 1:
    case "BREAK":
      return DialerPauseReason.BREAK;
    case 2:
    case "LUNCH":
      return DialerPauseReason.LUNCH;
    case 3:
    case "TRAINING":
      return DialerPauseReason.TRAINING;
    case 4:
    case "NOT_READY":
      return DialerPauseReason.NOT_READY;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DialerPauseReason.UNRECOGNIZED;
  }
}

export function dialerPauseReasonToJSON(object: DialerPauseReason): string {
  switch (object) {
    case DialerPauseReason.UNKNOWN_PAUSE_REASON:
      return "UNKNOWN_PAUSE_REASON";
    case DialerPauseReason.BREAK:
      return "BREAK";
    case DialerPauseReason.LUNCH:
      return "LUNCH";
    case DialerPauseReason.TRAINING:
      return "TRAINING";
    case DialerPauseReason.NOT_READY:
      return "NOT_READY";
    case DialerPauseReason.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum DialerAgentSelectionPriorityType {
  DAPT_UNKNOWN = 0,
  DAPT_WAIT_TIME = 1,
  DAPT_CALL_COUNT = 2,
  UNRECOGNIZED = -1,
}

export function dialerAgentSelectionPriorityTypeFromJSON(object: any): DialerAgentSelectionPriorityType {
  switch (object) {
    case 0:
    case "DAPT_UNKNOWN":
      return DialerAgentSelectionPriorityType.DAPT_UNKNOWN;
    case 1:
    case "DAPT_WAIT_TIME":
      return DialerAgentSelectionPriorityType.DAPT_WAIT_TIME;
    case 2:
    case "DAPT_CALL_COUNT":
      return DialerAgentSelectionPriorityType.DAPT_CALL_COUNT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DialerAgentSelectionPriorityType.UNRECOGNIZED;
  }
}

export function dialerAgentSelectionPriorityTypeToJSON(object: DialerAgentSelectionPriorityType): string {
  switch (object) {
    case DialerAgentSelectionPriorityType.DAPT_UNKNOWN:
      return "DAPT_UNKNOWN";
    case DialerAgentSelectionPriorityType.DAPT_WAIT_TIME:
      return "DAPT_WAIT_TIME";
    case DialerAgentSelectionPriorityType.DAPT_CALL_COUNT:
      return "DAPT_CALL_COUNT";
    case DialerAgentSelectionPriorityType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum DialerTelnyxCallState {
  UNKNOWN_STATE = 0,
  IDLE = 1,
  T_READY = 2,
  T_RINGING = 3,
  ONGOING = 4,
  DISCONNECTED = 5,
  ERROR = 6,
  NO_INIT = 99,
  UNRECOGNIZED = -1,
}

export function dialerTelnyxCallStateFromJSON(object: any): DialerTelnyxCallState {
  switch (object) {
    case 0:
    case "UNKNOWN_STATE":
      return DialerTelnyxCallState.UNKNOWN_STATE;
    case 1:
    case "IDLE":
      return DialerTelnyxCallState.IDLE;
    case 2:
    case "T_READY":
      return DialerTelnyxCallState.T_READY;
    case 3:
    case "T_RINGING":
      return DialerTelnyxCallState.T_RINGING;
    case 4:
    case "ONGOING":
      return DialerTelnyxCallState.ONGOING;
    case 5:
    case "DISCONNECTED":
      return DialerTelnyxCallState.DISCONNECTED;
    case 6:
    case "ERROR":
      return DialerTelnyxCallState.ERROR;
    case 99:
    case "NO_INIT":
      return DialerTelnyxCallState.NO_INIT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DialerTelnyxCallState.UNRECOGNIZED;
  }
}

export function dialerTelnyxCallStateToJSON(object: DialerTelnyxCallState): string {
  switch (object) {
    case DialerTelnyxCallState.UNKNOWN_STATE:
      return "UNKNOWN_STATE";
    case DialerTelnyxCallState.IDLE:
      return "IDLE";
    case DialerTelnyxCallState.T_READY:
      return "T_READY";
    case DialerTelnyxCallState.T_RINGING:
      return "T_RINGING";
    case DialerTelnyxCallState.ONGOING:
      return "ONGOING";
    case DialerTelnyxCallState.DISCONNECTED:
      return "DISCONNECTED";
    case DialerTelnyxCallState.ERROR:
      return "ERROR";
    case DialerTelnyxCallState.NO_INIT:
      return "NO_INIT";
    case DialerTelnyxCallState.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum DialerLeadCallDirection {
  UNKNOWN_CALL_TYPE = 0,
  OUTGOING = 1,
  INCOMING = 2,
  MANUAL = 3,
  UNRECOGNIZED = -1,
}

export function dialerLeadCallDirectionFromJSON(object: any): DialerLeadCallDirection {
  switch (object) {
    case 0:
    case "UNKNOWN_CALL_TYPE":
      return DialerLeadCallDirection.UNKNOWN_CALL_TYPE;
    case 1:
    case "OUTGOING":
      return DialerLeadCallDirection.OUTGOING;
    case 2:
    case "INCOMING":
      return DialerLeadCallDirection.INCOMING;
    case 3:
    case "MANUAL":
      return DialerLeadCallDirection.MANUAL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DialerLeadCallDirection.UNRECOGNIZED;
  }
}

export function dialerLeadCallDirectionToJSON(object: DialerLeadCallDirection): string {
  switch (object) {
    case DialerLeadCallDirection.UNKNOWN_CALL_TYPE:
      return "UNKNOWN_CALL_TYPE";
    case DialerLeadCallDirection.OUTGOING:
      return "OUTGOING";
    case DialerLeadCallDirection.INCOMING:
      return "INCOMING";
    case DialerLeadCallDirection.MANUAL:
      return "MANUAL";
    case DialerLeadCallDirection.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum DialerCallTerminators {
  CT_UNKNOWN = 0,
  CT_AGENT = 1,
  CT_CONSUMER = 2,
  CT_SYSTEM = 3,
  CT_SUPERVISOR = 4,
  CT_AMD = 5,
  UNRECOGNIZED = -1,
}

export function dialerCallTerminatorsFromJSON(object: any): DialerCallTerminators {
  switch (object) {
    case 0:
    case "CT_UNKNOWN":
      return DialerCallTerminators.CT_UNKNOWN;
    case 1:
    case "CT_AGENT":
      return DialerCallTerminators.CT_AGENT;
    case 2:
    case "CT_CONSUMER":
      return DialerCallTerminators.CT_CONSUMER;
    case 3:
    case "CT_SYSTEM":
      return DialerCallTerminators.CT_SYSTEM;
    case 4:
    case "CT_SUPERVISOR":
      return DialerCallTerminators.CT_SUPERVISOR;
    case 5:
    case "CT_AMD":
      return DialerCallTerminators.CT_AMD;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DialerCallTerminators.UNRECOGNIZED;
  }
}

export function dialerCallTerminatorsToJSON(object: DialerCallTerminators): string {
  switch (object) {
    case DialerCallTerminators.CT_UNKNOWN:
      return "CT_UNKNOWN";
    case DialerCallTerminators.CT_AGENT:
      return "CT_AGENT";
    case DialerCallTerminators.CT_CONSUMER:
      return "CT_CONSUMER";
    case DialerCallTerminators.CT_SYSTEM:
      return "CT_SYSTEM";
    case DialerCallTerminators.CT_SUPERVISOR:
      return "CT_SUPERVISOR";
    case DialerCallTerminators.CT_AMD:
      return "CT_AMD";
    case DialerCallTerminators.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum DialerBillableSettingsCountingType {
  BC_UNKNOWN = 0,
  BC_AFTER_CALL_INITIATED = 1,
  BC_AFTER_CALL_ANSWERED = 2,
  BC_AFTER_CALL_CONNECTED = 3,
  UNRECOGNIZED = -1,
}

export function dialerBillableSettingsCountingTypeFromJSON(object: any): DialerBillableSettingsCountingType {
  switch (object) {
    case 0:
    case "BC_UNKNOWN":
      return DialerBillableSettingsCountingType.BC_UNKNOWN;
    case 1:
    case "BC_AFTER_CALL_INITIATED":
      return DialerBillableSettingsCountingType.BC_AFTER_CALL_INITIATED;
    case 2:
    case "BC_AFTER_CALL_ANSWERED":
      return DialerBillableSettingsCountingType.BC_AFTER_CALL_ANSWERED;
    case 3:
    case "BC_AFTER_CALL_CONNECTED":
      return DialerBillableSettingsCountingType.BC_AFTER_CALL_CONNECTED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DialerBillableSettingsCountingType.UNRECOGNIZED;
  }
}

export function dialerBillableSettingsCountingTypeToJSON(object: DialerBillableSettingsCountingType): string {
  switch (object) {
    case DialerBillableSettingsCountingType.BC_UNKNOWN:
      return "BC_UNKNOWN";
    case DialerBillableSettingsCountingType.BC_AFTER_CALL_INITIATED:
      return "BC_AFTER_CALL_INITIATED";
    case DialerBillableSettingsCountingType.BC_AFTER_CALL_ANSWERED:
      return "BC_AFTER_CALL_ANSWERED";
    case DialerBillableSettingsCountingType.BC_AFTER_CALL_CONNECTED:
      return "BC_AFTER_CALL_CONNECTED";
    case DialerBillableSettingsCountingType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum DialerCallDeclineReason {
  DCD_UNKNOWN = 0,
  DCD_NO_AGENT_AVAILABLE = 1,
  DCD_LEAD_CALL_CAP_REACHED = 2,
  DCD_AGENT_CALL_CAP_REACHED = 3,
  DCD_QUEUE_TOTAL_CALL_CAP_REACHED = 4,
  DCD_OUT_OF_SCHEDULE = 5,
  DCD_QUEUE_DISABLED = 6,
  DCD_QUEUE_TOO_MANY_CONCURRENT = 7,
  DCD_AGENT_NUMBER_DISABLED = 8,
  UNRECOGNIZED = -1,
}

export function dialerCallDeclineReasonFromJSON(object: any): DialerCallDeclineReason {
  switch (object) {
    case 0:
    case "DCD_UNKNOWN":
      return DialerCallDeclineReason.DCD_UNKNOWN;
    case 1:
    case "DCD_NO_AGENT_AVAILABLE":
      return DialerCallDeclineReason.DCD_NO_AGENT_AVAILABLE;
    case 2:
    case "DCD_LEAD_CALL_CAP_REACHED":
      return DialerCallDeclineReason.DCD_LEAD_CALL_CAP_REACHED;
    case 3:
    case "DCD_AGENT_CALL_CAP_REACHED":
      return DialerCallDeclineReason.DCD_AGENT_CALL_CAP_REACHED;
    case 4:
    case "DCD_QUEUE_TOTAL_CALL_CAP_REACHED":
      return DialerCallDeclineReason.DCD_QUEUE_TOTAL_CALL_CAP_REACHED;
    case 5:
    case "DCD_OUT_OF_SCHEDULE":
      return DialerCallDeclineReason.DCD_OUT_OF_SCHEDULE;
    case 6:
    case "DCD_QUEUE_DISABLED":
      return DialerCallDeclineReason.DCD_QUEUE_DISABLED;
    case 7:
    case "DCD_QUEUE_TOO_MANY_CONCURRENT":
      return DialerCallDeclineReason.DCD_QUEUE_TOO_MANY_CONCURRENT;
    case 8:
    case "DCD_AGENT_NUMBER_DISABLED":
      return DialerCallDeclineReason.DCD_AGENT_NUMBER_DISABLED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DialerCallDeclineReason.UNRECOGNIZED;
  }
}

export function dialerCallDeclineReasonToJSON(object: DialerCallDeclineReason): string {
  switch (object) {
    case DialerCallDeclineReason.DCD_UNKNOWN:
      return "DCD_UNKNOWN";
    case DialerCallDeclineReason.DCD_NO_AGENT_AVAILABLE:
      return "DCD_NO_AGENT_AVAILABLE";
    case DialerCallDeclineReason.DCD_LEAD_CALL_CAP_REACHED:
      return "DCD_LEAD_CALL_CAP_REACHED";
    case DialerCallDeclineReason.DCD_AGENT_CALL_CAP_REACHED:
      return "DCD_AGENT_CALL_CAP_REACHED";
    case DialerCallDeclineReason.DCD_QUEUE_TOTAL_CALL_CAP_REACHED:
      return "DCD_QUEUE_TOTAL_CALL_CAP_REACHED";
    case DialerCallDeclineReason.DCD_OUT_OF_SCHEDULE:
      return "DCD_OUT_OF_SCHEDULE";
    case DialerCallDeclineReason.DCD_QUEUE_DISABLED:
      return "DCD_QUEUE_DISABLED";
    case DialerCallDeclineReason.DCD_QUEUE_TOO_MANY_CONCURRENT:
      return "DCD_QUEUE_TOO_MANY_CONCURRENT";
    case DialerCallDeclineReason.DCD_AGENT_NUMBER_DISABLED:
      return "DCD_AGENT_NUMBER_DISABLED";
    case DialerCallDeclineReason.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum DialerAgentNumberHandler {
  DANH_UNKNOWN = 0,
  DANH_VOICEMAIL = 1,
  DANH_TRANSFER = 2,
  UNRECOGNIZED = -1,
}

export function dialerAgentNumberHandlerFromJSON(object: any): DialerAgentNumberHandler {
  switch (object) {
    case 0:
    case "DANH_UNKNOWN":
      return DialerAgentNumberHandler.DANH_UNKNOWN;
    case 1:
    case "DANH_VOICEMAIL":
      return DialerAgentNumberHandler.DANH_VOICEMAIL;
    case 2:
    case "DANH_TRANSFER":
      return DialerAgentNumberHandler.DANH_TRANSFER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DialerAgentNumberHandler.UNRECOGNIZED;
  }
}

export function dialerAgentNumberHandlerToJSON(object: DialerAgentNumberHandler): string {
  switch (object) {
    case DialerAgentNumberHandler.DANH_UNKNOWN:
      return "DANH_UNKNOWN";
    case DialerAgentNumberHandler.DANH_VOICEMAIL:
      return "DANH_VOICEMAIL";
    case DialerAgentNumberHandler.DANH_TRANSFER:
      return "DANH_TRANSFER";
    case DialerAgentNumberHandler.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum DialerQueueType {
  DQT_UNKNOWN = 0,
  DQT_RECEIVER_AGENT = 1,
  DQT_RECEIVER_EXTERNAL_NUMBER = 2,
  DQT_ROUTER_PRIORITIZED = 3,
  DQT_ROUTER_UNIVERSAL = 4,
  DQT_INTERNAL_TRANSFER = 5,
  UNRECOGNIZED = -1,
}

export function dialerQueueTypeFromJSON(object: any): DialerQueueType {
  switch (object) {
    case 0:
    case "DQT_UNKNOWN":
      return DialerQueueType.DQT_UNKNOWN;
    case 1:
    case "DQT_RECEIVER_AGENT":
      return DialerQueueType.DQT_RECEIVER_AGENT;
    case 2:
    case "DQT_RECEIVER_EXTERNAL_NUMBER":
      return DialerQueueType.DQT_RECEIVER_EXTERNAL_NUMBER;
    case 3:
    case "DQT_ROUTER_PRIORITIZED":
      return DialerQueueType.DQT_ROUTER_PRIORITIZED;
    case 4:
    case "DQT_ROUTER_UNIVERSAL":
      return DialerQueueType.DQT_ROUTER_UNIVERSAL;
    case 5:
    case "DQT_INTERNAL_TRANSFER":
      return DialerQueueType.DQT_INTERNAL_TRANSFER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DialerQueueType.UNRECOGNIZED;
  }
}

export function dialerQueueTypeToJSON(object: DialerQueueType): string {
  switch (object) {
    case DialerQueueType.DQT_UNKNOWN:
      return "DQT_UNKNOWN";
    case DialerQueueType.DQT_RECEIVER_AGENT:
      return "DQT_RECEIVER_AGENT";
    case DialerQueueType.DQT_RECEIVER_EXTERNAL_NUMBER:
      return "DQT_RECEIVER_EXTERNAL_NUMBER";
    case DialerQueueType.DQT_ROUTER_PRIORITIZED:
      return "DQT_ROUTER_PRIORITIZED";
    case DialerQueueType.DQT_ROUTER_UNIVERSAL:
      return "DQT_ROUTER_UNIVERSAL";
    case DialerQueueType.DQT_INTERNAL_TRANSFER:
      return "DQT_INTERNAL_TRANSFER";
    case DialerQueueType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface DialerAgent {
  id?: string | undefined;
  email?: string | undefined;
  firstName?: string | undefined;
  lastName?: string | undefined;
  npn?: string | undefined;
  ssn?: string | undefined;
  features?: Features | undefined;
  profileImage?: string | undefined;
  currentCall?: DialerCurrentCall | undefined;
  currentCampaign?: DialerCampaign | undefined;
  currentAgency?: DialerAgent_DialerAgency | undefined;
  skills?: { [key: string]: any } | undefined;
  dialerRole?: DialerRole | undefined;
  dialerStats?:
    | DialerAgent_DialerStats
    | undefined;
  /** optional google.protobuf.Struct queuesAssigned = 15; */
  queues?: DialerAgent_Queues | undefined;
  userRole?: UserRole | undefined;
  canImpersonate?: boolean | undefined;
  agencyId?: string | undefined;
  webrtc?: DialerWebRtc | undefined;
  skill?: SkillListContainer | undefined;
  agency?: UserIdContainer | undefined;
  disabled?: UserDisabled | undefined;
  dialerSettings?: DialerSettings | undefined;
  callCapReached?: DialerAgent_DialerCallCapReached | undefined;
  agentNumber?: DialerAgentNumber | undefined;
  userType?: UserType | undefined;
  roles?: User_Permission_Roles | undefined;
  permanentTags?: PermanentTagsContainer | undefined;
}

export interface DialerAgent_Queues {
  assigned: string[];
}

export interface DialerAgent_DialerCallCapReached {
  queueId: string[];
  queueIdEnabled: string[];
  agent?: boolean | undefined;
  agentEnabled?: boolean | undefined;
  agency?: boolean | undefined;
  agencyEnabled?: boolean | undefined;
}

export interface DialerAgent_DialerAgency {
  id?: string | undefined;
  name?: string | undefined;
}

export interface DialerAgent_DialerStats {
  dateToday?: string | undefined;
  totalCalls?: number | undefined;
  sessionStart?: number | undefined;
  total?: DialerAgent_DialerCallStatsContainer | undefined;
  inbound?: DialerAgent_DialerCallStatsContainer | undefined;
  outbound?: DialerAgent_DialerCallStatsContainer | undefined;
  byQueue?: DialerAgent_DialerCallStatsByMap | undefined;
}

export interface DialerAgent_DialerCallStatsByMap {
  id: { [key: string]: DialerAgent_DialerCallStatsContainer };
}

export interface DialerAgent_DialerCallStatsByMap_IdEntry {
  key: string;
  value: DialerAgent_DialerCallStatsContainer | undefined;
}

export interface DialerAgent_DialerCallStatsContainer {
  total?: number | undefined;
  answered?: number | undefined;
  billable?: number | undefined;
}

export interface DialerSession {
  id?: string | undefined;
  updatedAt?: number | undefined;
}

export interface DialerLeadCall {
  id: string;
  agency?: BaseUser | undefined;
  lead?: Consumer | undefined;
  agent?: Agent | undefined;
  createdAt?: number | undefined;
  lastStatus?: DialerLeadCall_CallStatus | undefined;
  duration?: number | undefined;
  direction?: DialerLeadCallDirection | undefined;
  participants: { [key: string]: DialerLeadCall_Participant };
  toNumber?: string | undefined;
  fromNumber?: string | undefined;
  systemResultCategory?: Consumer_Dialer_ResultCategory | undefined;
  systemResult?: Consumer_Dialer_Result | undefined;
  callType?: DialerClientState_CallType | undefined;
  recordingUrl?: string | undefined;
  recordingUrlExternal?: string | undefined;
  cost: DialerLeadCall_Cost[];
  resultCategory?: Consumer_Dialer_ResultCategory | undefined;
  result?: Consumer_Dialer_Result | undefined;
  source?: Source | undefined;
  sourceCustomName?: string | undefined;
  terminatedBy?: DialerCallTerminators | undefined;
  queueStatistics?: DialerQueueStatistics | undefined;
  campaign?: DialerReferenceContainer | undefined;
  list?: DialerReferenceContainer | undefined;
  queue?: DialerReferenceContainer | undefined;
  identifiers?: IdentifierContainer | undefined;
  tags?: TagsContainer | undefined;
  durationInQueue?: number | undefined;
  durationInCall?: number | undefined;
  connectionTime?: number | undefined;
  participantCount?: number | undefined;
  wasConnected?: boolean | undefined;
  billable?: DialerLeadCall_BillableContainer | undefined;
  wasBlocked?: boolean | undefined;
  initialDeclineReason?: DialerCallDeclineReasonContainer | undefined;
  related?: RelatedContainer | undefined;
  wasVoicemail?: boolean | undefined;
  flow?: DialerCallFlow | undefined;
  flowOutgoing?: DialerCallFlow | undefined;
  flowIncoming?: DialerCallFlow | undefined;
  relatedOutgoing?: RelatedContainer | undefined;
  flowStepB1?:
    | DialerFlowStep
    | undefined;
  /** optional DialerFlowStep flowStepB2 = 44; */
  flowOutgoingStep1?: DialerFlowStep | undefined;
  flowOutgoingStep2?: DialerFlowStep | undefined;
  flowIncomingStep1?: DialerFlowStep | undefined;
  sold?: DialerLeadCall_BillableContainer | undefined;
  internalBilling?: DialerInternalBilling | undefined;
  enrollmentCode?: EnrollmentCode | undefined;
  transcriptUrl?: string | undefined;
  transcriptionStatus?: TranscriptStatusLegacy | undefined;
  transcript?: TranscriptContainer | undefined;
  customPrompts?: CustomPromptContainer | undefined;
  status?: string | undefined;
  logs: { [key: string]: any }[];
  incoming?: DialerLeadCall_IncomingContainer | undefined;
}

export enum DialerLeadCall_CallStatus {
  UNKNOWN_STATUS = 0,
  CALL_INITIATED = 1,
  CALL_ANSWERED = 2,
  CALL_BRIDGED = 3,
  CALL_SPEAK_ENDED = 4,
  CALL_DEQUEUED = 5,
  CALL_RECORDING_SAVED = 6,
  CALL_MACHINE_DETECTION_ENDED = 7,
  CALL_HANGUP = 8,
  CONFERENCE_FLOOR_CHANGED = 9,
  CONFERENCE_PARTICIPANT_LEFT = 10,
  CALL_COST = 11,
  CALL_SPEAK_STARTED = 12,
  CALL_PLAYBACK_STARTED = 13,
  CALL_PLAYBACK_ENDED = 14,
  CALL_DTMF_RECEIVED = 15,
  CONFERENCE_CREATED = 16,
  CALL_MACHINE_GREETING_ENDED = 17,
  CONFERENCE_COST = 18,
  UNRECOGNIZED = -1,
}

export function dialerLeadCall_CallStatusFromJSON(object: any): DialerLeadCall_CallStatus {
  switch (object) {
    case 0:
    case "UNKNOWN_STATUS":
      return DialerLeadCall_CallStatus.UNKNOWN_STATUS;
    case 1:
    case "CALL_INITIATED":
      return DialerLeadCall_CallStatus.CALL_INITIATED;
    case 2:
    case "CALL_ANSWERED":
      return DialerLeadCall_CallStatus.CALL_ANSWERED;
    case 3:
    case "CALL_BRIDGED":
      return DialerLeadCall_CallStatus.CALL_BRIDGED;
    case 4:
    case "CALL_SPEAK_ENDED":
      return DialerLeadCall_CallStatus.CALL_SPEAK_ENDED;
    case 5:
    case "CALL_DEQUEUED":
      return DialerLeadCall_CallStatus.CALL_DEQUEUED;
    case 6:
    case "CALL_RECORDING_SAVED":
      return DialerLeadCall_CallStatus.CALL_RECORDING_SAVED;
    case 7:
    case "CALL_MACHINE_DETECTION_ENDED":
      return DialerLeadCall_CallStatus.CALL_MACHINE_DETECTION_ENDED;
    case 8:
    case "CALL_HANGUP":
      return DialerLeadCall_CallStatus.CALL_HANGUP;
    case 9:
    case "CONFERENCE_FLOOR_CHANGED":
      return DialerLeadCall_CallStatus.CONFERENCE_FLOOR_CHANGED;
    case 10:
    case "CONFERENCE_PARTICIPANT_LEFT":
      return DialerLeadCall_CallStatus.CONFERENCE_PARTICIPANT_LEFT;
    case 11:
    case "CALL_COST":
      return DialerLeadCall_CallStatus.CALL_COST;
    case 12:
    case "CALL_SPEAK_STARTED":
      return DialerLeadCall_CallStatus.CALL_SPEAK_STARTED;
    case 13:
    case "CALL_PLAYBACK_STARTED":
      return DialerLeadCall_CallStatus.CALL_PLAYBACK_STARTED;
    case 14:
    case "CALL_PLAYBACK_ENDED":
      return DialerLeadCall_CallStatus.CALL_PLAYBACK_ENDED;
    case 15:
    case "CALL_DTMF_RECEIVED":
      return DialerLeadCall_CallStatus.CALL_DTMF_RECEIVED;
    case 16:
    case "CONFERENCE_CREATED":
      return DialerLeadCall_CallStatus.CONFERENCE_CREATED;
    case 17:
    case "CALL_MACHINE_GREETING_ENDED":
      return DialerLeadCall_CallStatus.CALL_MACHINE_GREETING_ENDED;
    case 18:
    case "CONFERENCE_COST":
      return DialerLeadCall_CallStatus.CONFERENCE_COST;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DialerLeadCall_CallStatus.UNRECOGNIZED;
  }
}

export function dialerLeadCall_CallStatusToJSON(object: DialerLeadCall_CallStatus): string {
  switch (object) {
    case DialerLeadCall_CallStatus.UNKNOWN_STATUS:
      return "UNKNOWN_STATUS";
    case DialerLeadCall_CallStatus.CALL_INITIATED:
      return "CALL_INITIATED";
    case DialerLeadCall_CallStatus.CALL_ANSWERED:
      return "CALL_ANSWERED";
    case DialerLeadCall_CallStatus.CALL_BRIDGED:
      return "CALL_BRIDGED";
    case DialerLeadCall_CallStatus.CALL_SPEAK_ENDED:
      return "CALL_SPEAK_ENDED";
    case DialerLeadCall_CallStatus.CALL_DEQUEUED:
      return "CALL_DEQUEUED";
    case DialerLeadCall_CallStatus.CALL_RECORDING_SAVED:
      return "CALL_RECORDING_SAVED";
    case DialerLeadCall_CallStatus.CALL_MACHINE_DETECTION_ENDED:
      return "CALL_MACHINE_DETECTION_ENDED";
    case DialerLeadCall_CallStatus.CALL_HANGUP:
      return "CALL_HANGUP";
    case DialerLeadCall_CallStatus.CONFERENCE_FLOOR_CHANGED:
      return "CONFERENCE_FLOOR_CHANGED";
    case DialerLeadCall_CallStatus.CONFERENCE_PARTICIPANT_LEFT:
      return "CONFERENCE_PARTICIPANT_LEFT";
    case DialerLeadCall_CallStatus.CALL_COST:
      return "CALL_COST";
    case DialerLeadCall_CallStatus.CALL_SPEAK_STARTED:
      return "CALL_SPEAK_STARTED";
    case DialerLeadCall_CallStatus.CALL_PLAYBACK_STARTED:
      return "CALL_PLAYBACK_STARTED";
    case DialerLeadCall_CallStatus.CALL_PLAYBACK_ENDED:
      return "CALL_PLAYBACK_ENDED";
    case DialerLeadCall_CallStatus.CALL_DTMF_RECEIVED:
      return "CALL_DTMF_RECEIVED";
    case DialerLeadCall_CallStatus.CONFERENCE_CREATED:
      return "CONFERENCE_CREATED";
    case DialerLeadCall_CallStatus.CALL_MACHINE_GREETING_ENDED:
      return "CALL_MACHINE_GREETING_ENDED";
    case DialerLeadCall_CallStatus.CONFERENCE_COST:
      return "CONFERENCE_COST";
    case DialerLeadCall_CallStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface DialerLeadCall_ParticipantsEntry {
  key: string;
  value: DialerLeadCall_Participant | undefined;
}

export interface DialerLeadCall_IncomingContainer {
  participants: { [key: string]: DialerLeadCall_Participant };
  flow?: DialerCallFlow | undefined;
}

export interface DialerLeadCall_IncomingContainer_ParticipantsEntry {
  key: string;
  value: DialerLeadCall_Participant | undefined;
}

export interface DialerLeadCall_BillableContainer {
  hasBillableSetting?: boolean | undefined;
  isBillable?: boolean | undefined;
  billableSeconds?: number | undefined;
  countingType?: DialerBillableSettingsCountingType | undefined;
  cost?: DialerLeadCall_BillableContainerCost | undefined;
}

export interface DialerLeadCall_BillableContainerCost {
  total?: number | undefined;
  base?: number | undefined;
  minutes?: number | undefined;
  billedMinutes?: number | undefined;
}

export interface DialerLeadCall_Cost {
  billedDurationSecs?: number | undefined;
  costParts: DialerLeadCall_Cost_CostPart[];
  total?: number | undefined;
  status?: string | undefined;
  isConferenceCost?: boolean | undefined;
  caller?: DialerClientState_ClientStateTarget | undefined;
}

export interface DialerLeadCall_Cost_CostPart {
  billedDurationSecs?: number | undefined;
  callPart?: string | undefined;
  cost?: number | undefined;
  currency?: string | undefined;
  rate?: number | undefined;
}

export interface DialerLeadCall_Participant {
  role?: string | undefined;
  calledTime?: number | undefined;
  joinedTime?: number | undefined;
  leftTime?: number | undefined;
  declinedTime?: number | undefined;
  firstName?: string | undefined;
  lastName?: string | undefined;
  profileImage?: string | undefined;
  type?: DialerClientState_ClientStateTarget_TargetType | undefined;
  callControlId?: string | undefined;
}

export interface DialerLeadCall_OldParticipant {
  role?: string | undefined;
  active?: boolean | undefined;
  callOrder?: number | undefined;
}

export interface DialerLeadCall_TelnyxLog {
  id?: string | undefined;
  to?: string | undefined;
  eventType?: string | undefined;
  recordType?: string | undefined;
  direction?: string | undefined;
  occurredAt?: string | undefined;
  payload?: { [key: string]: any } | undefined;
}

export interface DialerLeadCall_TelnyxPayload {
  callControlId?: string | undefined;
  callLegId?: string | undefined;
  callSessionId?: string | undefined;
  clientState?: string | undefined;
  connectionId?: string | undefined;
  direction?: string | undefined;
  from?: string | undefined;
  state?: string | undefined;
  to?: string | undefined;
}

export interface DialerCampaign {
  id?: string | undefined;
  name?: string | undefined;
  description?: string | undefined;
  type?: string | undefined;
  callerId?: string | undefined;
  blindTransferCallerId?: string | undefined;
  customCallerId?: string | undefined;
  status?: boolean | undefined;
  dialSettings?: DialerCampaign_DialSettings | undefined;
  advancedDialSettings?: DialerCampaign_AdvancedDialSettings | undefined;
  agentSettings?: DialerCampaign_AgentSettings | undefined;
  holdSettings?: DialerCampaign_HoldSettings | undefined;
  callAnalysis?: DialerCampaign_CallAnalysis | undefined;
  apiLeadCreationSettings?: DialerCampaign_ApiLeadCreationSettings | undefined;
  leadCreationSettings?: DialerCampaign_LeadCreationSettings | undefined;
  leadCallingOrder?: DialerCampaign_LeadCallingOrder | undefined;
  schedule?: DialerSchedule | undefined;
  statistics?: DialerCampaign_Statistics | undefined;
  leadLists?: DialerCampaign_LeadListContainer | undefined;
  allowAllMarketingAgents?: boolean | undefined;
  marketingAgents: { [key: string]: User };
  numbers: { [key: string]: DialerNumber };
  agencyId?: string | undefined;
  scriptCallLaunch?: ScriptCallLaunch | undefined;
  dispoStatusCallLaunch?: DialerDispoStatusCallLaunch | undefined;
}

export enum DialerCampaign_DialMethod {
  POWER_DIAL = 0,
  UNRECOGNIZED = -1,
}

export function dialerCampaign_DialMethodFromJSON(object: any): DialerCampaign_DialMethod {
  switch (object) {
    case 0:
    case "POWER_DIAL":
      return DialerCampaign_DialMethod.POWER_DIAL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DialerCampaign_DialMethod.UNRECOGNIZED;
  }
}

export function dialerCampaign_DialMethodToJSON(object: DialerCampaign_DialMethod): string {
  switch (object) {
    case DialerCampaign_DialMethod.POWER_DIAL:
      return "POWER_DIAL";
    case DialerCampaign_DialMethod.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum DialerCampaign_DialTimeout {
  NO_TIMEOUT = 0,
  FIVE_RINGS = 1,
  EIGHT_RINGS = 2,
  UNRECOGNIZED = -1,
}

export function dialerCampaign_DialTimeoutFromJSON(object: any): DialerCampaign_DialTimeout {
  switch (object) {
    case 0:
    case "NO_TIMEOUT":
      return DialerCampaign_DialTimeout.NO_TIMEOUT;
    case 1:
    case "FIVE_RINGS":
      return DialerCampaign_DialTimeout.FIVE_RINGS;
    case 2:
    case "EIGHT_RINGS":
      return DialerCampaign_DialTimeout.EIGHT_RINGS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DialerCampaign_DialTimeout.UNRECOGNIZED;
  }
}

export function dialerCampaign_DialTimeoutToJSON(object: DialerCampaign_DialTimeout): string {
  switch (object) {
    case DialerCampaign_DialTimeout.NO_TIMEOUT:
      return "NO_TIMEOUT";
    case DialerCampaign_DialTimeout.FIVE_RINGS:
      return "FIVE_RINGS";
    case DialerCampaign_DialTimeout.EIGHT_RINGS:
      return "EIGHT_RINGS";
    case DialerCampaign_DialTimeout.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum DialerCampaign_DialLevel {
  ONE = 0,
  TWO = 1,
  UNRECOGNIZED = -1,
}

export function dialerCampaign_DialLevelFromJSON(object: any): DialerCampaign_DialLevel {
  switch (object) {
    case 0:
    case "ONE":
      return DialerCampaign_DialLevel.ONE;
    case 1:
    case "TWO":
      return DialerCampaign_DialLevel.TWO;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DialerCampaign_DialLevel.UNRECOGNIZED;
  }
}

export function dialerCampaign_DialLevelToJSON(object: DialerCampaign_DialLevel): string {
  switch (object) {
    case DialerCampaign_DialLevel.ONE:
      return "ONE";
    case DialerCampaign_DialLevel.TWO:
      return "TWO";
    case DialerCampaign_DialLevel.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum DialerCampaign_PlaceCallsOptions {
  NEVER = 0,
  UNRECOGNIZED = -1,
}

export function dialerCampaign_PlaceCallsOptionsFromJSON(object: any): DialerCampaign_PlaceCallsOptions {
  switch (object) {
    case 0:
    case "NEVER":
      return DialerCampaign_PlaceCallsOptions.NEVER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DialerCampaign_PlaceCallsOptions.UNRECOGNIZED;
  }
}

export function dialerCampaign_PlaceCallsOptionsToJSON(object: DialerCampaign_PlaceCallsOptions): string {
  switch (object) {
    case DialerCampaign_PlaceCallsOptions.NEVER:
      return "NEVER";
    case DialerCampaign_PlaceCallsOptions.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum DialerCampaign_HoldMusic {
  DEFAULT = 0,
  CUSTOM = 1,
  UNRECOGNIZED = -1,
}

export function dialerCampaign_HoldMusicFromJSON(object: any): DialerCampaign_HoldMusic {
  switch (object) {
    case 0:
    case "DEFAULT":
      return DialerCampaign_HoldMusic.DEFAULT;
    case 1:
    case "CUSTOM":
      return DialerCampaign_HoldMusic.CUSTOM;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DialerCampaign_HoldMusic.UNRECOGNIZED;
  }
}

export function dialerCampaign_HoldMusicToJSON(object: DialerCampaign_HoldMusic): string {
  switch (object) {
    case DialerCampaign_HoldMusic.DEFAULT:
      return "DEFAULT";
    case DialerCampaign_HoldMusic.CUSTOM:
      return "CUSTOM";
    case DialerCampaign_HoldMusic.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum DialerCampaign_MaxHoldTime {
  NO_LIMIT = 0,
  UNRECOGNIZED = -1,
}

export function dialerCampaign_MaxHoldTimeFromJSON(object: any): DialerCampaign_MaxHoldTime {
  switch (object) {
    case 0:
    case "NO_LIMIT":
      return DialerCampaign_MaxHoldTime.NO_LIMIT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DialerCampaign_MaxHoldTime.UNRECOGNIZED;
  }
}

export function dialerCampaign_MaxHoldTimeToJSON(object: DialerCampaign_MaxHoldTime): string {
  switch (object) {
    case DialerCampaign_MaxHoldTime.NO_LIMIT:
      return "NO_LIMIT";
    case DialerCampaign_MaxHoldTime.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum DialerCampaign_AgentAlertSound {
  DEFAULT_ALERT_SOUND = 0,
  UNRECOGNIZED = -1,
}

export function dialerCampaign_AgentAlertSoundFromJSON(object: any): DialerCampaign_AgentAlertSound {
  switch (object) {
    case 0:
    case "DEFAULT_ALERT_SOUND":
      return DialerCampaign_AgentAlertSound.DEFAULT_ALERT_SOUND;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DialerCampaign_AgentAlertSound.UNRECOGNIZED;
  }
}

export function dialerCampaign_AgentAlertSoundToJSON(object: DialerCampaign_AgentAlertSound): string {
  switch (object) {
    case DialerCampaign_AgentAlertSound.DEFAULT_ALERT_SOUND:
      return "DEFAULT_ALERT_SOUND";
    case DialerCampaign_AgentAlertSound.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum DialerCampaign_OverflowDestination {
  NONE = 0,
  HANGUP = 1,
  UNRECOGNIZED = -1,
}

export function dialerCampaign_OverflowDestinationFromJSON(object: any): DialerCampaign_OverflowDestination {
  switch (object) {
    case 0:
    case "NONE":
      return DialerCampaign_OverflowDestination.NONE;
    case 1:
    case "HANGUP":
      return DialerCampaign_OverflowDestination.HANGUP;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DialerCampaign_OverflowDestination.UNRECOGNIZED;
  }
}

export function dialerCampaign_OverflowDestinationToJSON(object: DialerCampaign_OverflowDestination): string {
  switch (object) {
    case DialerCampaign_OverflowDestination.NONE:
      return "NONE";
    case DialerCampaign_OverflowDestination.HANGUP:
      return "HANGUP";
    case DialerCampaign_OverflowDestination.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum DialerCampaign_FasDetection {
  NO_FAS_DETECTION = 0,
  FASD = 1,
  UNRECOGNIZED = -1,
}

export function dialerCampaign_FasDetectionFromJSON(object: any): DialerCampaign_FasDetection {
  switch (object) {
    case 0:
    case "NO_FAS_DETECTION":
      return DialerCampaign_FasDetection.NO_FAS_DETECTION;
    case 1:
    case "FASD":
      return DialerCampaign_FasDetection.FASD;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DialerCampaign_FasDetection.UNRECOGNIZED;
  }
}

export function dialerCampaign_FasDetectionToJSON(object: DialerCampaign_FasDetection): string {
  switch (object) {
    case DialerCampaign_FasDetection.NO_FAS_DETECTION:
      return "NO_FAS_DETECTION";
    case DialerCampaign_FasDetection.FASD:
      return "FASD";
    case DialerCampaign_FasDetection.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum DialerCampaign_DefaultListAccessLevel {
  ALL = 0,
  OWN = 1,
  UNRECOGNIZED = -1,
}

export function dialerCampaign_DefaultListAccessLevelFromJSON(object: any): DialerCampaign_DefaultListAccessLevel {
  switch (object) {
    case 0:
    case "ALL":
      return DialerCampaign_DefaultListAccessLevel.ALL;
    case 1:
    case "OWN":
      return DialerCampaign_DefaultListAccessLevel.OWN;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DialerCampaign_DefaultListAccessLevel.UNRECOGNIZED;
  }
}

export function dialerCampaign_DefaultListAccessLevelToJSON(object: DialerCampaign_DefaultListAccessLevel): string {
  switch (object) {
    case DialerCampaign_DefaultListAccessLevel.ALL:
      return "ALL";
    case DialerCampaign_DefaultListAccessLevel.OWN:
      return "OWN";
    case DialerCampaign_DefaultListAccessLevel.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum DialerCampaign_LeadOrder {
  DEFAULT_LEAD_ORDER = 0,
  FOLLOW_THE_SUN = 1,
  LEADS_LEAST_CALLED = 2,
  UNRECOGNIZED = -1,
}

export function dialerCampaign_LeadOrderFromJSON(object: any): DialerCampaign_LeadOrder {
  switch (object) {
    case 0:
    case "DEFAULT_LEAD_ORDER":
      return DialerCampaign_LeadOrder.DEFAULT_LEAD_ORDER;
    case 1:
    case "FOLLOW_THE_SUN":
      return DialerCampaign_LeadOrder.FOLLOW_THE_SUN;
    case 2:
    case "LEADS_LEAST_CALLED":
      return DialerCampaign_LeadOrder.LEADS_LEAST_CALLED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DialerCampaign_LeadOrder.UNRECOGNIZED;
  }
}

export function dialerCampaign_LeadOrderToJSON(object: DialerCampaign_LeadOrder): string {
  switch (object) {
    case DialerCampaign_LeadOrder.DEFAULT_LEAD_ORDER:
      return "DEFAULT_LEAD_ORDER";
    case DialerCampaign_LeadOrder.FOLLOW_THE_SUN:
      return "FOLLOW_THE_SUN";
    case DialerCampaign_LeadOrder.LEADS_LEAST_CALLED:
      return "LEADS_LEAST_CALLED";
    case DialerCampaign_LeadOrder.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface DialerCampaign_MarketingAgentsEntry {
  key: string;
  value: User | undefined;
}

export interface DialerCampaign_NumbersEntry {
  key: string;
  value: DialerNumber | undefined;
}

export interface DialerCampaign_DialSettings {
  dialMethod?: DialerCampaign_DialMethod | undefined;
  autoDialTimeout?: DialerCampaign_DialTimeout | undefined;
  manualDialTimeout?: DialerCampaign_DialTimeout | undefined;
  dialLevel?: DialerCampaign_DialLevel | undefined;
  placeCallsForAgent?: DialerCampaign_PlaceCallsOptions | undefined;
  dropLockoutTime?: boolean | undefined;
  softLimitTimeBeforeDial?: number | undefined;
  hardLimitTimeBeforeDial?: number | undefined;
  softLimitNumberOfDialsByHour?: number | undefined;
  hardLimitNumberOfDialsByHour?: number | undefined;
  softLimitNumberOfDialsByDay?: number | undefined;
  hardLimitNumberOfDialsByDay?: number | undefined;
  softLimitNumberOfDialsByWeek?: number | undefined;
  hardLimitNumberOfDialsByWeek?: number | undefined;
  softLimitNumberOfDialsByMonth?: number | undefined;
  hardLimitNumberOfDialsByMonth?: number | undefined;
  linesPerAgent?: number | undefined;
  maxAgentsReady?: number | undefined;
  maxNumberOfLines?: number | undefined;
}

export interface DialerCampaign_AdvancedDialSettings {
  dialLevelDifferenceAgentTarget?: boolean | undefined;
  agentTargetValue?: string | undefined;
  dialThreshold?: boolean | undefined;
  stopDialingPastThreshold?: boolean | undefined;
}

export interface DialerCampaign_AgentSettings {
  callRoutingMethod?: string | undefined;
  allowSchedulingCallbacks?: boolean | undefined;
  disableAlteringLeadData?: boolean | undefined;
  disableAlteringPhoneNumbers?: boolean | undefined;
}

export interface DialerCampaign_HoldSettings {
  holdMusic?: DialerCampaign_HoldMusic | undefined;
  maxHoldTime?: DialerCampaign_MaxHoldTime | undefined;
  agentAlertInterval?: string | undefined;
  agentAlertSound?: DialerCampaign_AgentAlertSound | undefined;
}

export interface DialerCampaign_OverflowCallSettings {
  maxWaitTimeSeconds?: number | undefined;
  overflowDestination?: DialerCampaign_OverflowDestination | undefined;
  overflowDestination2?: DialerCampaign_OverflowDestination | undefined;
}

export interface DialerCampaign_CallAnalysis {
  /**  */
  detectAnsweringMachineAndFax?: string | undefined;
  totalAnalysisTimeMs?: number | undefined;
  afterGreetingSilenceMs?: number | undefined;
  betweenWordsSilenceMs?: number | undefined;
  greetingDurationMs?: number | undefined;
  initialSilenceMs?: number | undefined;
  maximumNumberOfWords?: number | undefined;
  maximumWordLengthMs?: number | undefined;
  silenceThreshold?: number | undefined;
  greetingTotalAnalysisTimeMs?: number | undefined;
  greetingSilenceDurationMs?: number | undefined;
  connectCallToAgent?: string | undefined;
}

export interface DialerCampaign_ApiLeadCreationSettings {
  applyFilterWhenInsertingLeadsViaApi?: boolean | undefined;
}

export interface DialerCampaign_LeadCreationSettings {
  allowAgentToChooseList?: boolean | undefined;
  defaultListAccessLevel?: DialerCampaign_DefaultListAccessLevel | undefined;
  defaultListForNewLeads?: string | undefined;
}

export interface DialerCampaign_LeadCallingOrder {
  primaryLeadOrder?: DialerCampaign_LeadOrder | undefined;
  secondaryLeadOrder?: DialerCampaign_LeadOrder | undefined;
  useListMix?: boolean | undefined;
  leadOrderRandomizer?: boolean | undefined;
  hopperPriorityCallOrder?: DialerCampaign_LeadOrder | undefined;
  secondaryHopperPriorityCallOrder?: DialerCampaign_LeadOrder | undefined;
  secondaryHopperPriorityBy?: DialerCampaign_LeadOrder | undefined;
}

export interface DialerCampaign_Statistics {
}

export interface DialerCampaign_OnlineStats {
  enabled?: boolean | undefined;
  available?: { [key: string]: any } | undefined;
  ready?: { [key: string]: any } | undefined;
  calling?: { [key: string]: any } | undefined;
  availableCount?: number | undefined;
  readyCount?: number | undefined;
  callingCount?: number | undefined;
  campaignId?: string | undefined;
  agencyId?: string | undefined;
  linesPerAgent?: number | undefined;
  maxAgentsReady?: number | undefined;
  maxNumberOfLines?: number | undefined;
}

export interface DialerCampaign_LeadListContainer {
  leadLists: LeadList[];
}

export interface DialerCampaignStatisticsToday {
  day?: string | undefined;
  totalCalls?: number | undefined;
  totalConnectedCalls?: number | undefined;
  totalEndedCalls?: number | undefined;
  totalAnsweringMachineCalls?: number | undefined;
}

export interface DialerNumber {
  id?: string | undefined;
  name?: string | undefined;
  number?: string | undefined;
  prefix?: string | undefined;
  zip?: string | undefined;
  state?: string | undefined;
  stats?: DialerNumber_Stats | undefined;
  agencyId?: string | undefined;
  active?: boolean | undefined;
}

export interface DialerNumber_Stats {
  callsScheduled?: DialerNumber_Stats_ByTimeRange | undefined;
  callStarted?: DialerNumber_Stats_ByTimeRange | undefined;
  callConnected?: DialerNumber_Stats_ByTimeRange | undefined;
  callEnded?: DialerNumber_Stats_ByTimeRange | undefined;
}

export interface DialerNumber_Stats_ByTimeRange {
  day?: { [key: string]: any } | undefined;
  week?: { [key: string]: any } | undefined;
  month?: { [key: string]: any } | undefined;
  lastCall?: number | undefined;
}

export interface DialerCurrentCall {
  sip?: string | undefined;
  status?: DialerAgentStatus | undefined;
  validUntil?: number | undefined;
  nextStatus?: DialerAgentStatus | undefined;
  callControlId?: string | undefined;
  callId?: string | undefined;
  pauseReason?: DialerPauseReason | undefined;
  clientState?: DialerClientState | undefined;
  userId?: string | undefined;
  parentUserId?: string | undefined;
  updatedAt?: number | undefined;
  statusSetter?: DialerCurrentCall_StatusSetter | undefined;
  lastConnectionTimeout?: number | undefined;
  wasMailbox?: boolean | undefined;
  wasNotConnected?: boolean | undefined;
}

export enum DialerCurrentCall_StatusSetter {
  UNKNOWN_STATUS_SETTER = 0,
  MANUAL = 1,
  WEBHOOK = 2,
  HEARTBEAT = 3,
  SERVER_DEFAULT = 4,
  UNRECOGNIZED = -1,
}

export function dialerCurrentCall_StatusSetterFromJSON(object: any): DialerCurrentCall_StatusSetter {
  switch (object) {
    case 0:
    case "UNKNOWN_STATUS_SETTER":
      return DialerCurrentCall_StatusSetter.UNKNOWN_STATUS_SETTER;
    case 1:
    case "MANUAL":
      return DialerCurrentCall_StatusSetter.MANUAL;
    case 2:
    case "WEBHOOK":
      return DialerCurrentCall_StatusSetter.WEBHOOK;
    case 3:
    case "HEARTBEAT":
      return DialerCurrentCall_StatusSetter.HEARTBEAT;
    case 4:
    case "SERVER_DEFAULT":
      return DialerCurrentCall_StatusSetter.SERVER_DEFAULT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DialerCurrentCall_StatusSetter.UNRECOGNIZED;
  }
}

export function dialerCurrentCall_StatusSetterToJSON(object: DialerCurrentCall_StatusSetter): string {
  switch (object) {
    case DialerCurrentCall_StatusSetter.UNKNOWN_STATUS_SETTER:
      return "UNKNOWN_STATUS_SETTER";
    case DialerCurrentCall_StatusSetter.MANUAL:
      return "MANUAL";
    case DialerCurrentCall_StatusSetter.WEBHOOK:
      return "WEBHOOK";
    case DialerCurrentCall_StatusSetter.HEARTBEAT:
      return "HEARTBEAT";
    case DialerCurrentCall_StatusSetter.SERVER_DEFAULT:
      return "SERVER_DEFAULT";
    case DialerCurrentCall_StatusSetter.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface DialerClientState {
  uuid?: string | undefined;
  target?: DialerClientState_ClientStateTarget | undefined;
  callType?: DialerClientState_CallType | undefined;
  action?: DialerClientState_ClientStateAction | undefined;
  participants?: DialerClientState_Participants | undefined;
  isConference?: boolean | undefined;
  conferenceId?: string | undefined;
  caller?: DialerClientState_ClientStateTarget | undefined;
  inboundAction?: DialerClientState_ClientStateInboundAction | undefined;
  chatId?: string | undefined;
  invoker?: DialerClientState_Invoker | undefined;
  settings?:
    | DialerClientState_Settings
    | undefined;
  /** optional RelatedContainer related = 16; */
  flow?: DialerCallFlow | undefined;
  hangupRouterHandler?: DialerClientState_HangupRouterHandler | undefined;
  outgoingFlowRelated?: RelatedContainer | undefined;
}

export enum DialerClientState_CallType {
  UNKNOWN_CALL_TYPE = 0,
  OUTGOING_AUTO_DIAL = 1,
  INCOMING_CONSUMER_INITIATED = 2,
  OUTGOING_DIRECT = 3,
  INCOMING_DIRECT_AGENT = 4,
  UNRECOGNIZED = -1,
}

export function dialerClientState_CallTypeFromJSON(object: any): DialerClientState_CallType {
  switch (object) {
    case 0:
    case "UNKNOWN_CALL_TYPE":
      return DialerClientState_CallType.UNKNOWN_CALL_TYPE;
    case 1:
    case "OUTGOING_AUTO_DIAL":
      return DialerClientState_CallType.OUTGOING_AUTO_DIAL;
    case 2:
    case "INCOMING_CONSUMER_INITIATED":
      return DialerClientState_CallType.INCOMING_CONSUMER_INITIATED;
    case 3:
    case "OUTGOING_DIRECT":
      return DialerClientState_CallType.OUTGOING_DIRECT;
    case 4:
    case "INCOMING_DIRECT_AGENT":
      return DialerClientState_CallType.INCOMING_DIRECT_AGENT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DialerClientState_CallType.UNRECOGNIZED;
  }
}

export function dialerClientState_CallTypeToJSON(object: DialerClientState_CallType): string {
  switch (object) {
    case DialerClientState_CallType.UNKNOWN_CALL_TYPE:
      return "UNKNOWN_CALL_TYPE";
    case DialerClientState_CallType.OUTGOING_AUTO_DIAL:
      return "OUTGOING_AUTO_DIAL";
    case DialerClientState_CallType.INCOMING_CONSUMER_INITIATED:
      return "INCOMING_CONSUMER_INITIATED";
    case DialerClientState_CallType.OUTGOING_DIRECT:
      return "OUTGOING_DIRECT";
    case DialerClientState_CallType.INCOMING_DIRECT_AGENT:
      return "INCOMING_DIRECT_AGENT";
    case DialerClientState_CallType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum DialerClientState_InvokerType {
  UNKNOWN_INVOKER_TYPE = 0,
  CAMPAIGN = 1,
  QUEUE = 2,
  DIRECT_CALL = 3,
  INCOMING_DIRECT_TO_AGENT = 4,
  UNRECOGNIZED = -1,
}

export function dialerClientState_InvokerTypeFromJSON(object: any): DialerClientState_InvokerType {
  switch (object) {
    case 0:
    case "UNKNOWN_INVOKER_TYPE":
      return DialerClientState_InvokerType.UNKNOWN_INVOKER_TYPE;
    case 1:
    case "CAMPAIGN":
      return DialerClientState_InvokerType.CAMPAIGN;
    case 2:
    case "QUEUE":
      return DialerClientState_InvokerType.QUEUE;
    case 3:
    case "DIRECT_CALL":
      return DialerClientState_InvokerType.DIRECT_CALL;
    case 4:
    case "INCOMING_DIRECT_TO_AGENT":
      return DialerClientState_InvokerType.INCOMING_DIRECT_TO_AGENT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DialerClientState_InvokerType.UNRECOGNIZED;
  }
}

export function dialerClientState_InvokerTypeToJSON(object: DialerClientState_InvokerType): string {
  switch (object) {
    case DialerClientState_InvokerType.UNKNOWN_INVOKER_TYPE:
      return "UNKNOWN_INVOKER_TYPE";
    case DialerClientState_InvokerType.CAMPAIGN:
      return "CAMPAIGN";
    case DialerClientState_InvokerType.QUEUE:
      return "QUEUE";
    case DialerClientState_InvokerType.DIRECT_CALL:
      return "DIRECT_CALL";
    case DialerClientState_InvokerType.INCOMING_DIRECT_TO_AGENT:
      return "INCOMING_DIRECT_TO_AGENT";
    case DialerClientState_InvokerType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface DialerClientState_HangupRouterHandler {
  queue?: UserIdContainer | undefined;
  nextPrioritizationItems: DialerQueuePrioritizationItem[];
  sourceCallControlId?: string | undefined;
}

export interface DialerClientState_Settings {
  connectCallToAgent?: string | undefined;
}

export interface DialerClientState_ClientStateTarget {
  id?: string | undefined;
  parentUserId?: string | undefined;
  number?: string | undefined;
  type?: DialerClientState_ClientStateTarget_TargetType | undefined;
  callControlId?: string | undefined;
  campaignId?: string | undefined;
  data?: DialerClientState_ClientStateTarget_Data | undefined;
  conferenceId?: string | undefined;
  clientState?: DialerClientState | undefined;
  queueId?: string | undefined;
  queueMessageId?: string | undefined;
  dialerRole?: DialerRole | undefined;
  wasMailbox?: boolean | undefined;
  wasDropped?: boolean | undefined;
  wasNotConnected?: boolean | undefined;
  skill?: SkillListContainer | undefined;
  joinedTime?: number | undefined;
  assignedQueuePath?: StringListContainer | undefined;
}

export enum DialerClientState_ClientStateTarget_TargetType {
  UNKNOWN_TARGET_TYPE = 0,
  LEAD = 1,
  AGENT = 2,
  AGENCY = 3,
  SUPERVISOR = 4,
  EXTERNAL_NUMBER = 5,
  TT_QUEUE = 6,
  EXTERNAL_QUEUE = 7,
  UNRECOGNIZED = -1,
}

export function dialerClientState_ClientStateTarget_TargetTypeFromJSON(
  object: any,
): DialerClientState_ClientStateTarget_TargetType {
  switch (object) {
    case 0:
    case "UNKNOWN_TARGET_TYPE":
      return DialerClientState_ClientStateTarget_TargetType.UNKNOWN_TARGET_TYPE;
    case 1:
    case "LEAD":
      return DialerClientState_ClientStateTarget_TargetType.LEAD;
    case 2:
    case "AGENT":
      return DialerClientState_ClientStateTarget_TargetType.AGENT;
    case 3:
    case "AGENCY":
      return DialerClientState_ClientStateTarget_TargetType.AGENCY;
    case 4:
    case "SUPERVISOR":
      return DialerClientState_ClientStateTarget_TargetType.SUPERVISOR;
    case 5:
    case "EXTERNAL_NUMBER":
      return DialerClientState_ClientStateTarget_TargetType.EXTERNAL_NUMBER;
    case 6:
    case "TT_QUEUE":
      return DialerClientState_ClientStateTarget_TargetType.TT_QUEUE;
    case 7:
    case "EXTERNAL_QUEUE":
      return DialerClientState_ClientStateTarget_TargetType.EXTERNAL_QUEUE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DialerClientState_ClientStateTarget_TargetType.UNRECOGNIZED;
  }
}

export function dialerClientState_ClientStateTarget_TargetTypeToJSON(
  object: DialerClientState_ClientStateTarget_TargetType,
): string {
  switch (object) {
    case DialerClientState_ClientStateTarget_TargetType.UNKNOWN_TARGET_TYPE:
      return "UNKNOWN_TARGET_TYPE";
    case DialerClientState_ClientStateTarget_TargetType.LEAD:
      return "LEAD";
    case DialerClientState_ClientStateTarget_TargetType.AGENT:
      return "AGENT";
    case DialerClientState_ClientStateTarget_TargetType.AGENCY:
      return "AGENCY";
    case DialerClientState_ClientStateTarget_TargetType.SUPERVISOR:
      return "SUPERVISOR";
    case DialerClientState_ClientStateTarget_TargetType.EXTERNAL_NUMBER:
      return "EXTERNAL_NUMBER";
    case DialerClientState_ClientStateTarget_TargetType.TT_QUEUE:
      return "TT_QUEUE";
    case DialerClientState_ClientStateTarget_TargetType.EXTERNAL_QUEUE:
      return "EXTERNAL_QUEUE";
    case DialerClientState_ClientStateTarget_TargetType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface DialerClientState_ClientStateTarget_Data {
  firstName?: string | undefined;
  lastName?: string | undefined;
}

export interface DialerClientState_ClientStateAction {
  type?: DialerClientState_ClientStateAction_ActionType | undefined;
  destination?: string | undefined;
  data?: { [key: string]: any } | undefined;
  hasToAnswer?: boolean | undefined;
  runAfterEvent?:
    | string
    | undefined;
  /** optional NextAction nextAction = 6; */
  settings?: DialerClientState_ClientStateAction_ActionSettings | undefined;
}

export enum DialerClientState_ClientStateAction_ActionType {
  UNKNOWN_ACTION_TYPE = 0,
  CALL_AVAILABLE_AGENT = 1,
  BRIDGE_TO = 2,
  MAKE_AGENT_AVAILABLE = 3,
  JOIN_CONFERENCE = 4,
  CONVERT_TO_CONFERENCE = 5,
  BRIDGE_AGENT_TO_QUEUE = 6,
  CALL_CONSUMER_CONNECT_TO_AGENT = 7,
  CSA_HANGUP = 8,
  CSA_SPEAK = 9,
  CSA_PLAY_MUSIC = 10,
  CSA_BEEP = 11,
  CSA_CALL_AGENT_IN_QUEUE = 12,
  CSA_START_RECORDING = 13,
  UNRECOGNIZED = -1,
}

export function dialerClientState_ClientStateAction_ActionTypeFromJSON(
  object: any,
): DialerClientState_ClientStateAction_ActionType {
  switch (object) {
    case 0:
    case "UNKNOWN_ACTION_TYPE":
      return DialerClientState_ClientStateAction_ActionType.UNKNOWN_ACTION_TYPE;
    case 1:
    case "CALL_AVAILABLE_AGENT":
      return DialerClientState_ClientStateAction_ActionType.CALL_AVAILABLE_AGENT;
    case 2:
    case "BRIDGE_TO":
      return DialerClientState_ClientStateAction_ActionType.BRIDGE_TO;
    case 3:
    case "MAKE_AGENT_AVAILABLE":
      return DialerClientState_ClientStateAction_ActionType.MAKE_AGENT_AVAILABLE;
    case 4:
    case "JOIN_CONFERENCE":
      return DialerClientState_ClientStateAction_ActionType.JOIN_CONFERENCE;
    case 5:
    case "CONVERT_TO_CONFERENCE":
      return DialerClientState_ClientStateAction_ActionType.CONVERT_TO_CONFERENCE;
    case 6:
    case "BRIDGE_AGENT_TO_QUEUE":
      return DialerClientState_ClientStateAction_ActionType.BRIDGE_AGENT_TO_QUEUE;
    case 7:
    case "CALL_CONSUMER_CONNECT_TO_AGENT":
      return DialerClientState_ClientStateAction_ActionType.CALL_CONSUMER_CONNECT_TO_AGENT;
    case 8:
    case "CSA_HANGUP":
      return DialerClientState_ClientStateAction_ActionType.CSA_HANGUP;
    case 9:
    case "CSA_SPEAK":
      return DialerClientState_ClientStateAction_ActionType.CSA_SPEAK;
    case 10:
    case "CSA_PLAY_MUSIC":
      return DialerClientState_ClientStateAction_ActionType.CSA_PLAY_MUSIC;
    case 11:
    case "CSA_BEEP":
      return DialerClientState_ClientStateAction_ActionType.CSA_BEEP;
    case 12:
    case "CSA_CALL_AGENT_IN_QUEUE":
      return DialerClientState_ClientStateAction_ActionType.CSA_CALL_AGENT_IN_QUEUE;
    case 13:
    case "CSA_START_RECORDING":
      return DialerClientState_ClientStateAction_ActionType.CSA_START_RECORDING;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DialerClientState_ClientStateAction_ActionType.UNRECOGNIZED;
  }
}

export function dialerClientState_ClientStateAction_ActionTypeToJSON(
  object: DialerClientState_ClientStateAction_ActionType,
): string {
  switch (object) {
    case DialerClientState_ClientStateAction_ActionType.UNKNOWN_ACTION_TYPE:
      return "UNKNOWN_ACTION_TYPE";
    case DialerClientState_ClientStateAction_ActionType.CALL_AVAILABLE_AGENT:
      return "CALL_AVAILABLE_AGENT";
    case DialerClientState_ClientStateAction_ActionType.BRIDGE_TO:
      return "BRIDGE_TO";
    case DialerClientState_ClientStateAction_ActionType.MAKE_AGENT_AVAILABLE:
      return "MAKE_AGENT_AVAILABLE";
    case DialerClientState_ClientStateAction_ActionType.JOIN_CONFERENCE:
      return "JOIN_CONFERENCE";
    case DialerClientState_ClientStateAction_ActionType.CONVERT_TO_CONFERENCE:
      return "CONVERT_TO_CONFERENCE";
    case DialerClientState_ClientStateAction_ActionType.BRIDGE_AGENT_TO_QUEUE:
      return "BRIDGE_AGENT_TO_QUEUE";
    case DialerClientState_ClientStateAction_ActionType.CALL_CONSUMER_CONNECT_TO_AGENT:
      return "CALL_CONSUMER_CONNECT_TO_AGENT";
    case DialerClientState_ClientStateAction_ActionType.CSA_HANGUP:
      return "CSA_HANGUP";
    case DialerClientState_ClientStateAction_ActionType.CSA_SPEAK:
      return "CSA_SPEAK";
    case DialerClientState_ClientStateAction_ActionType.CSA_PLAY_MUSIC:
      return "CSA_PLAY_MUSIC";
    case DialerClientState_ClientStateAction_ActionType.CSA_BEEP:
      return "CSA_BEEP";
    case DialerClientState_ClientStateAction_ActionType.CSA_CALL_AGENT_IN_QUEUE:
      return "CSA_CALL_AGENT_IN_QUEUE";
    case DialerClientState_ClientStateAction_ActionType.CSA_START_RECORDING:
      return "CSA_START_RECORDING";
    case DialerClientState_ClientStateAction_ActionType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface DialerClientState_ClientStateAction_ActionSettings {
  hangupAfter?: boolean | undefined;
  musicSetAfter?: string | undefined;
  speakAfter?: string | undefined;
  beepAfter?: boolean | undefined;
  wasVoicemail?: boolean | undefined;
  startRecording?: boolean | undefined;
}

export interface DialerClientState_ClientStateAction_NextAction {
  action?: DialerClientState_ClientStateAction | undefined;
}

export interface DialerClientState_ClientStateInboundAction {
  type?: DialerClientState_ClientStateInboundAction_ActionType | undefined;
  destination?: string | undefined;
}

export enum DialerClientState_ClientStateInboundAction_ActionType {
  UNKNOWN_ACTION_TYPE = 0,
  SUPERVISE_BARGE = 1,
  SUPERVISE_LISTEN = 2,
  SUPERVISE_WHISPER = 3,
  UNRECOGNIZED = -1,
}

export function dialerClientState_ClientStateInboundAction_ActionTypeFromJSON(
  object: any,
): DialerClientState_ClientStateInboundAction_ActionType {
  switch (object) {
    case 0:
    case "UNKNOWN_ACTION_TYPE":
      return DialerClientState_ClientStateInboundAction_ActionType.UNKNOWN_ACTION_TYPE;
    case 1:
    case "SUPERVISE_BARGE":
      return DialerClientState_ClientStateInboundAction_ActionType.SUPERVISE_BARGE;
    case 2:
    case "SUPERVISE_LISTEN":
      return DialerClientState_ClientStateInboundAction_ActionType.SUPERVISE_LISTEN;
    case 3:
    case "SUPERVISE_WHISPER":
      return DialerClientState_ClientStateInboundAction_ActionType.SUPERVISE_WHISPER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DialerClientState_ClientStateInboundAction_ActionType.UNRECOGNIZED;
  }
}

export function dialerClientState_ClientStateInboundAction_ActionTypeToJSON(
  object: DialerClientState_ClientStateInboundAction_ActionType,
): string {
  switch (object) {
    case DialerClientState_ClientStateInboundAction_ActionType.UNKNOWN_ACTION_TYPE:
      return "UNKNOWN_ACTION_TYPE";
    case DialerClientState_ClientStateInboundAction_ActionType.SUPERVISE_BARGE:
      return "SUPERVISE_BARGE";
    case DialerClientState_ClientStateInboundAction_ActionType.SUPERVISE_LISTEN:
      return "SUPERVISE_LISTEN";
    case DialerClientState_ClientStateInboundAction_ActionType.SUPERVISE_WHISPER:
      return "SUPERVISE_WHISPER";
    case DialerClientState_ClientStateInboundAction_ActionType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface DialerClientState_Participants {
  agent?: string | undefined;
  consumer?: string | undefined;
}

export interface DialerClientState_Participants_Participant {
  id?: string | undefined;
  callControlId?: string | undefined;
}

export interface DialerClientState_Invoker {
  id?: string | undefined;
  parentId?: string | undefined;
  type?: DialerClientState_InvokerType | undefined;
  scriptId?: string | undefined;
  dispoStatusId?: string | undefined;
  name?: string | undefined;
  enrollmentCodeSettings?: DialerQueueEnrollmentCodeSettings | undefined;
}

export interface DialerQueue {
  id?: string | undefined;
  name?: string | undefined;
  active?: boolean | undefined;
  createdAt?: Date | undefined;
  lastCallAt?: Date | undefined;
  callRouting?: DialerQueue_CallRouting | undefined;
  scriptCallLaunch?: ScriptCallLaunch | undefined;
  schedule?: DialerSchedule | undefined;
  afterHourSettings?: DialerQueue_AfterHourSettings | undefined;
  allowAllMarketingAgents?: boolean | undefined;
  marketingAgents: { [key: string]: DialerQueue_ReceiverSender };
  salesAgents: { [key: string]: DialerQueue_ReceiverSender };
  numbers: { [key: string]: DialerNumber };
  neededSkills?: { [key: string]: any } | undefined;
  callCap?: DialerCallCap | undefined;
  priority?: number | undefined;
  agencyId?: string | undefined;
  agency?: UserIdContainer | undefined;
  marketingAgent?: StringListContainer | undefined;
  salesAgent?: StringListContainer | undefined;
  salesAgentQueueId?: StringListContainer | undefined;
  number?: StringListContainer | undefined;
  dispoStatusCallLaunch?: DialerDispoStatusCallLaunch | undefined;
  billableSettings?: DialerBillableSettings | undefined;
  settings?: DialerQueueSettingsContainer | undefined;
  callCapQueue?: DialerCallCap | undefined;
  callCapReceiverQueue?: DialerCallCap | undefined;
  concurrentCallCap?: DialerConcurrentCallCap | undefined;
  agencySettings?: DialerAgencySettings | undefined;
  soldSettings?: DialerBillableSettings | undefined;
  internalBillableSettings?: DialerBillableSettings | undefined;
  restricted?: boolean | undefined;
  queueType?: DialerQueueType | undefined;
  prioritization?: DialerQueuePrioritizationContainer | undefined;
  enrollmentCodeSettings?: DialerQueueEnrollmentCodeSettings | undefined;
  updatedAt?: number | undefined;
  restrict?: DialerQueue_RestrictContainer | undefined;
}

export interface DialerQueue_MarketingAgentsEntry {
  key: string;
  value: DialerQueue_ReceiverSender | undefined;
}

export interface DialerQueue_SalesAgentsEntry {
  key: string;
  value: DialerQueue_ReceiverSender | undefined;
}

export interface DialerQueue_NumbersEntry {
  key: string;
  value: DialerNumber | undefined;
}

export interface DialerQueue_RestrictContainer {
  billableSettings?: boolean | undefined;
  soldSettings?: boolean | undefined;
}

export interface DialerQueue_ReceiverSender {
  id?: string | undefined;
  name?: string | undefined;
  firstName?: string | undefined;
  lastName?: string | undefined;
  npn?: string | undefined;
  profileImage?: string | undefined;
  agency?: UserIdContainer | undefined;
  type?: DialerClientState_ClientStateTarget_TargetType | undefined;
  soldSettings?: DialerBillableSettings | undefined;
  advanceSettings?: AdvanceSettings | undefined;
}

export interface DialerQueue_CallRouting {
  redirectToNumber?: boolean | undefined;
  number?: string | undefined;
  transferFromAgentPriority?: boolean | undefined;
  autoAccept?: DialerQueue_CallRouting_AutoAccept | undefined;
  hangup?: DialerQueue_CallRouting_Hangup | undefined;
  skills?: DialerQueue_CallRouting_CallRoutingSkills | undefined;
  inverseQueuePriority?: boolean | undefined;
  leadBlock?: DialerQueue_CallRouting_LeadBlock | undefined;
  agentSelection?: DialerAgentSelection | undefined;
  shareLeadData?: boolean | undefined;
  timeoutRedirectInS?: number | undefined;
  ignoreDelayInQueue?: boolean | undefined;
}

export interface DialerQueue_CallRouting_AutoAccept {
  enabled?: boolean | undefined;
  timeoutInMs?: number | undefined;
  textAfterTimeout?: string | undefined;
  playMusic?: boolean | undefined;
  musicSet?: string | undefined;
}

export interface DialerQueue_CallRouting_Hangup {
  enabled?: boolean | undefined;
  timeoutInS?: number | undefined;
  textBeforeHangupTimeout?: string | undefined;
}

export interface DialerQueue_CallRouting_CallRoutingSkills {
  enabled?: boolean | undefined;
  strictMatching?:
    | boolean
    | undefined;
  /** ordered by priority */
  skills: Skill[];
  matchByType?: boolean | undefined;
}

export interface DialerQueue_CallRouting_LeadBlock {
  enabled?: boolean | undefined;
  numberOfCalls?: number | undefined;
}

export interface DialerQueue_AfterHourSettings {
  enableText?: boolean | undefined;
  text?: string | undefined;
}

export interface DialerQueue_Number {
}

export interface DialerQueueSettingsContainer {
  /** bySalesAgent */
  byReceiverAgent: { [key: string]: DialerQueueAgentSettings };
}

export interface DialerQueueSettingsContainer_ByReceiverAgentEntry {
  key: string;
  value: DialerQueueAgentSettings | undefined;
}

export interface DialerQueueAgentSettings {
  callCap?: DialerCallCap | undefined;
}

export interface DialerAgentSelection {
  priority: DialerAgentSelectionPriority[];
  availabilityDelayInS?: number | undefined;
}

export interface DialerAgentSelectionPriority {
  type: DialerAgentSelectionPriorityType;
  /** default ASC */
  direction: SortDirection;
}

export interface DialerCampaignHopper {
  queueId?: string | undefined;
  agencyId?: string | undefined;
  name?: string | undefined;
  state?: string | undefined;
  purgeTime?: string | undefined;
  taskTtl?: string | undefined;
  tombstoneTtl?: string | undefined;
  stackdriverLoggingConfig?: string | undefined;
  type?: string | undefined;
  httpTarget?: string | undefined;
  rateLimits?: DialerCampaignHopper_RateLimits | undefined;
  stats?: DialerCampaignHopper_Stats | undefined;
}

export interface DialerCampaignHopper_RateLimits {
  maxDispatchesPerSecond?: number | undefined;
  maxBurstSize?: number | undefined;
  maxConcurrentDispatches?: number | undefined;
}

export interface DialerCampaignHopper_Stats {
  tasksCount?: number | undefined;
  oldestEstimatedArrivalTime?: DialerCampaignHopper_Stats_FirebaseTimestamp | undefined;
  executedLastMinuteCount?: number | undefined;
  concurrentDispatchesCount?: number | undefined;
  effectiveExecutionRate?: number | undefined;
}

export interface DialerCampaignHopper_Stats_FirebaseTimestamp {
  seconds?: number | undefined;
  nanos?: number | undefined;
}

export interface NextAgent {
  agentId: string;
  callControlId: string;
  agencyId: string;
  campaignId?: string | undefined;
  isConference?: boolean | undefined;
  conferenceId?: string | undefined;
  score?: number | undefined;
  skill?: SkillListContainer | undefined;
}

export interface ScriptCallLaunch {
  useScript?: boolean | undefined;
  scriptId?: string | undefined;
}

export interface DialerDispoStatusCallLaunch {
  useDispoStatus?: boolean | undefined;
  dispoStatusId?: string | undefined;
}

export interface DialerScript {
  id?: string | undefined;
  name?: string | undefined;
  active?: boolean | undefined;
  questionnaire?: Questionnaire | undefined;
  agencyId?: string | undefined;
}

export interface DialerDispoStatus {
  id?: string | undefined;
  name?: string | undefined;
  active?: boolean | undefined;
  agency?: UserIdContainer | undefined;
  resultCategories: DialerDispoStatus_ResultCategoryContainer[];
}

export interface DialerDispoStatus_ResultCategoryContainer {
  resultCategory: Consumer_Dialer_ResultCategory;
  results: DialerDispoStatus_ResultContainer[];
}

export interface DialerDispoStatus_ResultContainer {
  result: Consumer_Dialer_Result;
}

export interface DialerLiveStats {
  id?: string | undefined;
  parentId?: string | undefined;
  abandoned?: number | undefined;
  answered?: number | undefined;
  dropped?: number | undefined;
}

export interface LeadList {
  id?: string | undefined;
  name?: string | undefined;
  agencyId?: string | undefined;
  tags: string[];
  percentage?: number | undefined;
}

export interface DialerSchedule {
  enableSchedule?: boolean | undefined;
  timeZone?: string | undefined;
  weekdays?: DialerSchedule_WeekdayMap | undefined;
}

export interface DialerSchedule_WeekdayMap {
  sunday: DialerSchedule_Weekday | undefined;
  monday: DialerSchedule_Weekday | undefined;
  tuesday: DialerSchedule_Weekday | undefined;
  wednesday: DialerSchedule_Weekday | undefined;
  thursday: DialerSchedule_Weekday | undefined;
  friday: DialerSchedule_Weekday | undefined;
  saturday: DialerSchedule_Weekday | undefined;
}

export interface DialerSchedule_Weekday {
  enabled?: boolean | undefined;
  startTime?: string | undefined;
  endTime?: string | undefined;
}

export interface DialerQueueInbound {
  id?: string | undefined;
  lead?: Consumer | undefined;
  agency?: UserIdContainer | undefined;
  skill?: SkillListContainer | undefined;
  queue?: DialerQueue | undefined;
  uuid?: string | undefined;
  createdAt?: number | undefined;
  priority?: number | undefined;
  bridgedAt?: number | undefined;
  isConference?: boolean | undefined;
  consumerCallControlId?: string | undefined;
  directAgent?: DialerAgent | undefined;
  outgoingFlowRelated?: RelatedContainer | undefined;
}

export interface DialerQueueStatistics {
  position?: number | undefined;
  waitTimeMs?: number | undefined;
}

export interface DialerReferenceContainer {
  id?: string | undefined;
  name?: string | undefined;
  isCustom?: boolean | undefined;
}

export interface DialerAgencySettings {
  defaultDispoStatusId?: string | undefined;
  defaultOutboundNumber?: string | undefined;
  callCap?: DialerCallCap | undefined;
  agentNumber?: DialerAgencyAgentNumberSettings | undefined;
}

export interface DialerAgencyAgentNumberSettings {
  useOwnNumber?: boolean | undefined;
  canClaimNumber?: boolean | undefined;
  autoClaimNewAgent?: boolean | undefined;
  autoClaimRegion?: string | undefined;
}

export interface DialerStatusTimesDaily {
  date?: string | undefined;
  totalWorkTime?: number | undefined;
  statusDurationPercentages?: DialerStatusNumberContainer | undefined;
  statusDurationTotals?: DialerStatusNumberContainer | undefined;
  statusDurations?: DialerStatusNumberContainer | undefined;
  statusTimes?: DialerStatusNumberListContainer | undefined;
  agency?: UserIdContainer | undefined;
  agent?: UserIdContainer | undefined;
  lastStatus?: string | undefined;
  lastStatusSet?: number | undefined;
  timestamp?: number | undefined;
}

export interface DialerStatusNumberContainer {
  AVAILABLE?: number | undefined;
  CALLING?: number | undefined;
  DISPO?: number | undefined;
  OFFLINE?: number | undefined;
  PAUSED?: number | undefined;
  RINGING?: number | undefined;
  ringingOutgoing?: number | undefined;
}

export interface DialerStatusNumberListContainer {
  AVAILABLE: number[];
  CALLING: number[];
  DISPO: number[];
  OFFLINE: number[];
  PAUSED: number[];
  RINGING: number[];
  ringingOutgoing: number[];
}

export interface DialerBillableSettings {
  isBillable?: boolean | undefined;
  billableAfterSeconds?: number | undefined;
  countingType?: DialerBillableSettingsCountingType | undefined;
  billableAfterAnswer?: boolean | undefined;
  costPerCall?: number | undefined;
  costPerMinute?: number | undefined;
}

export interface AdvanceSettings {
  advance?: boolean | undefined;
  advanceInS?: number | undefined;
}

export interface DialerCallDeclineReasonContainer {
  status?: DialerCallDeclineReason | undefined;
  setAt?: number | undefined;
}

export interface DialerConcurrentCallCap {
  enabled?: boolean | undefined;
  maxConcurrentCalls?: number | undefined;
}

export interface RelatedContainer {
  calls: RelatedCall[];
}

export interface RelatedCall {
  uuid?: string | undefined;
  lead?: UserIdContainer | undefined;
  agency?: UserIdContainer | undefined;
  queue?: UserIdContainer | undefined;
  isParent?: boolean | undefined;
  isChild?: boolean | undefined;
}

export interface DialerAgentNumber {
  settings: DialerAgentNumberItem[];
  number?: DialerAgentNumberListContainer | undefined;
  defaultOutbound?: string | undefined;
}

export interface DialerAgentNumberListContainer {
  list: string[];
}

export interface DialerAgentNumberItem {
  enabled?: boolean | undefined;
  number?: string | undefined;
  handler?: DialerAgentNumberHandler | undefined;
  delayInS?: number | undefined;
  voicemailText?: string | undefined;
  voicemailAudio?: string | undefined;
  transferToNumber?: string | undefined;
  boughtAt?: number | undefined;
}

export interface DialerCallFlow {
  isInitial?: boolean | undefined;
  initialId?: string | undefined;
  isPassthrough?: boolean | undefined;
  passthroughId?: string | undefined;
  isFinal?: boolean | undefined;
  finalId?: string | undefined;
  list: DialerFlowStep[];
}

export interface DialerFlowStep {
  queue?: UserIdContainer | undefined;
  agency?: UserIdContainer | undefined;
  type?: DialerClientState_ClientStateTarget_TargetType | undefined;
  crossAgencyUser?: boolean | undefined;
}

export interface DialerQueuePrioritizationContainer {
  list: DialerQueuePrioritizationItem[];
}

export interface DialerQueuePrioritizationItem {
  queue?: ReferenceContainer | undefined;
  agency?: UserIdContainer | undefined;
  delayBeforeNextInS?: number | undefined;
  soldSettings?: DialerBillableSettings | undefined;
}

export interface DialerInternalBilling {
  baseDuration?: number | undefined;
  byParticipantCall: { [key: string]: number };
  countingType?: DialerInternalCountingType | undefined;
  paying?: boolean | undefined;
  payingUserId?: string | undefined;
}

export interface DialerInternalBilling_ByParticipantCallEntry {
  key: string;
  value: number;
}

export interface DialerInternalCountingType {
  byBillableForm: BillableFormList | undefined;
}

export interface BillableFormList {
  /** 60/60 */
  b6060?:
    | number
    | undefined;
  /** 60/6 */
  b606?:
    | number
    | undefined;
  /** 6/6 */
  b66?:
    | number
    | undefined;
  /** 30/30 */
  b3030?:
    | number
    | undefined;
  /** 6/60 */
  b660?: number | undefined;
}

export interface DialerQueueEnrollmentCodeSettings {
  enabled?: boolean | undefined;
  allowedSources: EnrollmentSource[];
  defaultSource?: EnrollmentSource | undefined;
  enforceSource?: boolean | undefined;
}

function createBaseDialerAgent(): DialerAgent {
  return {
    id: undefined,
    email: undefined,
    firstName: undefined,
    lastName: undefined,
    npn: undefined,
    ssn: undefined,
    features: undefined,
    profileImage: undefined,
    currentCall: undefined,
    currentCampaign: undefined,
    currentAgency: undefined,
    skills: undefined,
    dialerRole: undefined,
    dialerStats: undefined,
    queues: undefined,
    userRole: undefined,
    canImpersonate: undefined,
    agencyId: undefined,
    webrtc: undefined,
    skill: undefined,
    agency: undefined,
    disabled: undefined,
    dialerSettings: undefined,
    callCapReached: undefined,
    agentNumber: undefined,
    userType: undefined,
    roles: undefined,
    permanentTags: undefined,
  };
}

export const DialerAgent = {
  encode(message: DialerAgent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.email !== undefined) {
      writer.uint32(18).string(message.email);
    }
    if (message.firstName !== undefined) {
      writer.uint32(26).string(message.firstName);
    }
    if (message.lastName !== undefined) {
      writer.uint32(34).string(message.lastName);
    }
    if (message.npn !== undefined) {
      writer.uint32(42).string(message.npn);
    }
    if (message.ssn !== undefined) {
      writer.uint32(50).string(message.ssn);
    }
    if (message.features !== undefined) {
      Features.encode(message.features, writer.uint32(58).fork()).ldelim();
    }
    if (message.profileImage !== undefined) {
      writer.uint32(66).string(message.profileImage);
    }
    if (message.currentCall !== undefined) {
      DialerCurrentCall.encode(message.currentCall, writer.uint32(74).fork()).ldelim();
    }
    if (message.currentCampaign !== undefined) {
      DialerCampaign.encode(message.currentCampaign, writer.uint32(82).fork()).ldelim();
    }
    if (message.currentAgency !== undefined) {
      DialerAgent_DialerAgency.encode(message.currentAgency, writer.uint32(90).fork()).ldelim();
    }
    if (message.skills !== undefined) {
      Struct.encode(Struct.wrap(message.skills), writer.uint32(98).fork()).ldelim();
    }
    if (message.dialerRole !== undefined) {
      writer.uint32(104).int32(message.dialerRole);
    }
    if (message.dialerStats !== undefined) {
      DialerAgent_DialerStats.encode(message.dialerStats, writer.uint32(114).fork()).ldelim();
    }
    if (message.queues !== undefined) {
      DialerAgent_Queues.encode(message.queues, writer.uint32(130).fork()).ldelim();
    }
    if (message.userRole !== undefined) {
      writer.uint32(136).int32(message.userRole);
    }
    if (message.canImpersonate !== undefined) {
      writer.uint32(144).bool(message.canImpersonate);
    }
    if (message.agencyId !== undefined) {
      writer.uint32(154).string(message.agencyId);
    }
    if (message.webrtc !== undefined) {
      DialerWebRtc.encode(message.webrtc, writer.uint32(162).fork()).ldelim();
    }
    if (message.skill !== undefined) {
      SkillListContainer.encode(message.skill, writer.uint32(170).fork()).ldelim();
    }
    if (message.agency !== undefined) {
      UserIdContainer.encode(message.agency, writer.uint32(178).fork()).ldelim();
    }
    if (message.disabled !== undefined) {
      UserDisabled.encode(message.disabled, writer.uint32(186).fork()).ldelim();
    }
    if (message.dialerSettings !== undefined) {
      DialerSettings.encode(message.dialerSettings, writer.uint32(194).fork()).ldelim();
    }
    if (message.callCapReached !== undefined) {
      DialerAgent_DialerCallCapReached.encode(message.callCapReached, writer.uint32(202).fork()).ldelim();
    }
    if (message.agentNumber !== undefined) {
      DialerAgentNumber.encode(message.agentNumber, writer.uint32(210).fork()).ldelim();
    }
    if (message.userType !== undefined) {
      writer.uint32(216).int32(message.userType);
    }
    if (message.roles !== undefined) {
      User_Permission_Roles.encode(message.roles, writer.uint32(226).fork()).ldelim();
    }
    if (message.permanentTags !== undefined) {
      PermanentTagsContainer.encode(message.permanentTags, writer.uint32(234).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerAgent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerAgent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.email = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.lastName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.npn = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.ssn = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.features = Features.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.profileImage = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.currentCall = DialerCurrentCall.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.currentCampaign = DialerCampaign.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.currentAgency = DialerAgent_DialerAgency.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.skills = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.dialerRole = reader.int32() as any;
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.dialerStats = DialerAgent_DialerStats.decode(reader, reader.uint32());
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.queues = DialerAgent_Queues.decode(reader, reader.uint32());
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.userRole = reader.int32() as any;
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.canImpersonate = reader.bool();
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.agencyId = reader.string();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.webrtc = DialerWebRtc.decode(reader, reader.uint32());
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.skill = SkillListContainer.decode(reader, reader.uint32());
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.agency = UserIdContainer.decode(reader, reader.uint32());
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.disabled = UserDisabled.decode(reader, reader.uint32());
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.dialerSettings = DialerSettings.decode(reader, reader.uint32());
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.callCapReached = DialerAgent_DialerCallCapReached.decode(reader, reader.uint32());
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          message.agentNumber = DialerAgentNumber.decode(reader, reader.uint32());
          continue;
        case 27:
          if (tag !== 216) {
            break;
          }

          message.userType = reader.int32() as any;
          continue;
        case 28:
          if (tag !== 226) {
            break;
          }

          message.roles = User_Permission_Roles.decode(reader, reader.uint32());
          continue;
        case 29:
          if (tag !== 234) {
            break;
          }

          message.permanentTags = PermanentTagsContainer.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerAgent {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      email: isSet(object.email) ? String(object.email) : undefined,
      firstName: isSet(object.firstName) ? String(object.firstName) : undefined,
      lastName: isSet(object.lastName) ? String(object.lastName) : undefined,
      npn: isSet(object.npn) ? String(object.npn) : undefined,
      ssn: isSet(object.ssn) ? String(object.ssn) : undefined,
      features: isSet(object.features) ? Features.fromJSON(object.features) : undefined,
      profileImage: isSet(object.profileImage) ? String(object.profileImage) : undefined,
      currentCall: isSet(object.currentCall) ? DialerCurrentCall.fromJSON(object.currentCall) : undefined,
      currentCampaign: isSet(object.currentCampaign) ? DialerCampaign.fromJSON(object.currentCampaign) : undefined,
      currentAgency: isSet(object.currentAgency) ? DialerAgent_DialerAgency.fromJSON(object.currentAgency) : undefined,
      skills: isObject(object.skills) ? object.skills : undefined,
      dialerRole: isSet(object.dialerRole) ? dialerRoleFromJSON(object.dialerRole) : undefined,
      dialerStats: isSet(object.dialerStats) ? DialerAgent_DialerStats.fromJSON(object.dialerStats) : undefined,
      queues: isSet(object.queues) ? DialerAgent_Queues.fromJSON(object.queues) : undefined,
      userRole: isSet(object.userRole) ? userRoleFromJSON(object.userRole) : undefined,
      canImpersonate: isSet(object.canImpersonate) ? Boolean(object.canImpersonate) : undefined,
      agencyId: isSet(object.agencyId) ? String(object.agencyId) : undefined,
      webrtc: isSet(object.webrtc) ? DialerWebRtc.fromJSON(object.webrtc) : undefined,
      skill: isSet(object.skill) ? SkillListContainer.fromJSON(object.skill) : undefined,
      agency: isSet(object.agency) ? UserIdContainer.fromJSON(object.agency) : undefined,
      disabled: isSet(object.disabled) ? UserDisabled.fromJSON(object.disabled) : undefined,
      dialerSettings: isSet(object.dialerSettings) ? DialerSettings.fromJSON(object.dialerSettings) : undefined,
      callCapReached: isSet(object.callCapReached)
        ? DialerAgent_DialerCallCapReached.fromJSON(object.callCapReached)
        : undefined,
      agentNumber: isSet(object.agentNumber) ? DialerAgentNumber.fromJSON(object.agentNumber) : undefined,
      userType: isSet(object.userType) ? userTypeFromJSON(object.userType) : undefined,
      roles: isSet(object.roles) ? User_Permission_Roles.fromJSON(object.roles) : undefined,
      permanentTags: isSet(object.permanentTags) ? PermanentTagsContainer.fromJSON(object.permanentTags) : undefined,
    };
  },

  toJSON(message: DialerAgent): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.email !== undefined && (obj.email = message.email);
    message.firstName !== undefined && (obj.firstName = message.firstName);
    message.lastName !== undefined && (obj.lastName = message.lastName);
    message.npn !== undefined && (obj.npn = message.npn);
    message.ssn !== undefined && (obj.ssn = message.ssn);
    message.features !== undefined && (obj.features = message.features ? Features.toJSON(message.features) : undefined);
    message.profileImage !== undefined && (obj.profileImage = message.profileImage);
    message.currentCall !== undefined &&
      (obj.currentCall = message.currentCall ? DialerCurrentCall.toJSON(message.currentCall) : undefined);
    message.currentCampaign !== undefined &&
      (obj.currentCampaign = message.currentCampaign ? DialerCampaign.toJSON(message.currentCampaign) : undefined);
    message.currentAgency !== undefined &&
      (obj.currentAgency = message.currentAgency ? DialerAgent_DialerAgency.toJSON(message.currentAgency) : undefined);
    message.skills !== undefined && (obj.skills = message.skills);
    message.dialerRole !== undefined &&
      (obj.dialerRole = message.dialerRole !== undefined ? dialerRoleToJSON(message.dialerRole) : undefined);
    message.dialerStats !== undefined &&
      (obj.dialerStats = message.dialerStats ? DialerAgent_DialerStats.toJSON(message.dialerStats) : undefined);
    message.queues !== undefined &&
      (obj.queues = message.queues ? DialerAgent_Queues.toJSON(message.queues) : undefined);
    message.userRole !== undefined &&
      (obj.userRole = message.userRole !== undefined ? userRoleToJSON(message.userRole) : undefined);
    message.canImpersonate !== undefined && (obj.canImpersonate = message.canImpersonate);
    message.agencyId !== undefined && (obj.agencyId = message.agencyId);
    message.webrtc !== undefined && (obj.webrtc = message.webrtc ? DialerWebRtc.toJSON(message.webrtc) : undefined);
    message.skill !== undefined && (obj.skill = message.skill ? SkillListContainer.toJSON(message.skill) : undefined);
    message.agency !== undefined && (obj.agency = message.agency ? UserIdContainer.toJSON(message.agency) : undefined);
    message.disabled !== undefined &&
      (obj.disabled = message.disabled ? UserDisabled.toJSON(message.disabled) : undefined);
    message.dialerSettings !== undefined &&
      (obj.dialerSettings = message.dialerSettings ? DialerSettings.toJSON(message.dialerSettings) : undefined);
    message.callCapReached !== undefined && (obj.callCapReached = message.callCapReached
      ? DialerAgent_DialerCallCapReached.toJSON(message.callCapReached)
      : undefined);
    message.agentNumber !== undefined &&
      (obj.agentNumber = message.agentNumber ? DialerAgentNumber.toJSON(message.agentNumber) : undefined);
    message.userType !== undefined &&
      (obj.userType = message.userType !== undefined ? userTypeToJSON(message.userType) : undefined);
    message.roles !== undefined &&
      (obj.roles = message.roles ? User_Permission_Roles.toJSON(message.roles) : undefined);
    message.permanentTags !== undefined &&
      (obj.permanentTags = message.permanentTags ? PermanentTagsContainer.toJSON(message.permanentTags) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerAgent>, I>>(base?: I): DialerAgent {
    return DialerAgent.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerAgent>, I>>(object: I): DialerAgent {
    const message = createBaseDialerAgent();
    message.id = object.id ?? undefined;
    message.email = object.email ?? undefined;
    message.firstName = object.firstName ?? undefined;
    message.lastName = object.lastName ?? undefined;
    message.npn = object.npn ?? undefined;
    message.ssn = object.ssn ?? undefined;
    message.features = (object.features !== undefined && object.features !== null)
      ? Features.fromPartial(object.features)
      : undefined;
    message.profileImage = object.profileImage ?? undefined;
    message.currentCall = (object.currentCall !== undefined && object.currentCall !== null)
      ? DialerCurrentCall.fromPartial(object.currentCall)
      : undefined;
    message.currentCampaign = (object.currentCampaign !== undefined && object.currentCampaign !== null)
      ? DialerCampaign.fromPartial(object.currentCampaign)
      : undefined;
    message.currentAgency = (object.currentAgency !== undefined && object.currentAgency !== null)
      ? DialerAgent_DialerAgency.fromPartial(object.currentAgency)
      : undefined;
    message.skills = object.skills ?? undefined;
    message.dialerRole = object.dialerRole ?? undefined;
    message.dialerStats = (object.dialerStats !== undefined && object.dialerStats !== null)
      ? DialerAgent_DialerStats.fromPartial(object.dialerStats)
      : undefined;
    message.queues = (object.queues !== undefined && object.queues !== null)
      ? DialerAgent_Queues.fromPartial(object.queues)
      : undefined;
    message.userRole = object.userRole ?? undefined;
    message.canImpersonate = object.canImpersonate ?? undefined;
    message.agencyId = object.agencyId ?? undefined;
    message.webrtc = (object.webrtc !== undefined && object.webrtc !== null)
      ? DialerWebRtc.fromPartial(object.webrtc)
      : undefined;
    message.skill = (object.skill !== undefined && object.skill !== null)
      ? SkillListContainer.fromPartial(object.skill)
      : undefined;
    message.agency = (object.agency !== undefined && object.agency !== null)
      ? UserIdContainer.fromPartial(object.agency)
      : undefined;
    message.disabled = (object.disabled !== undefined && object.disabled !== null)
      ? UserDisabled.fromPartial(object.disabled)
      : undefined;
    message.dialerSettings = (object.dialerSettings !== undefined && object.dialerSettings !== null)
      ? DialerSettings.fromPartial(object.dialerSettings)
      : undefined;
    message.callCapReached = (object.callCapReached !== undefined && object.callCapReached !== null)
      ? DialerAgent_DialerCallCapReached.fromPartial(object.callCapReached)
      : undefined;
    message.agentNumber = (object.agentNumber !== undefined && object.agentNumber !== null)
      ? DialerAgentNumber.fromPartial(object.agentNumber)
      : undefined;
    message.userType = object.userType ?? undefined;
    message.roles = (object.roles !== undefined && object.roles !== null)
      ? User_Permission_Roles.fromPartial(object.roles)
      : undefined;
    message.permanentTags = (object.permanentTags !== undefined && object.permanentTags !== null)
      ? PermanentTagsContainer.fromPartial(object.permanentTags)
      : undefined;
    return message;
  },
};

function createBaseDialerAgent_Queues(): DialerAgent_Queues {
  return { assigned: [] };
}

export const DialerAgent_Queues = {
  encode(message: DialerAgent_Queues, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.assigned) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerAgent_Queues {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerAgent_Queues();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.assigned.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerAgent_Queues {
    return { assigned: Array.isArray(object?.assigned) ? object.assigned.map((e: any) => String(e)) : [] };
  },

  toJSON(message: DialerAgent_Queues): unknown {
    const obj: any = {};
    if (message.assigned) {
      obj.assigned = message.assigned.map((e) => e);
    } else {
      obj.assigned = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerAgent_Queues>, I>>(base?: I): DialerAgent_Queues {
    return DialerAgent_Queues.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerAgent_Queues>, I>>(object: I): DialerAgent_Queues {
    const message = createBaseDialerAgent_Queues();
    message.assigned = object.assigned?.map((e) => e) || [];
    return message;
  },
};

function createBaseDialerAgent_DialerCallCapReached(): DialerAgent_DialerCallCapReached {
  return {
    queueId: [],
    queueIdEnabled: [],
    agent: undefined,
    agentEnabled: undefined,
    agency: undefined,
    agencyEnabled: undefined,
  };
}

export const DialerAgent_DialerCallCapReached = {
  encode(message: DialerAgent_DialerCallCapReached, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.queueId) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.queueIdEnabled) {
      writer.uint32(18).string(v!);
    }
    if (message.agent !== undefined) {
      writer.uint32(24).bool(message.agent);
    }
    if (message.agentEnabled !== undefined) {
      writer.uint32(32).bool(message.agentEnabled);
    }
    if (message.agency !== undefined) {
      writer.uint32(40).bool(message.agency);
    }
    if (message.agencyEnabled !== undefined) {
      writer.uint32(48).bool(message.agencyEnabled);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerAgent_DialerCallCapReached {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerAgent_DialerCallCapReached();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.queueId.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.queueIdEnabled.push(reader.string());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.agent = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.agentEnabled = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.agency = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.agencyEnabled = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerAgent_DialerCallCapReached {
    return {
      queueId: Array.isArray(object?.queueId) ? object.queueId.map((e: any) => String(e)) : [],
      queueIdEnabled: Array.isArray(object?.queueIdEnabled) ? object.queueIdEnabled.map((e: any) => String(e)) : [],
      agent: isSet(object.agent) ? Boolean(object.agent) : undefined,
      agentEnabled: isSet(object.agentEnabled) ? Boolean(object.agentEnabled) : undefined,
      agency: isSet(object.agency) ? Boolean(object.agency) : undefined,
      agencyEnabled: isSet(object.agencyEnabled) ? Boolean(object.agencyEnabled) : undefined,
    };
  },

  toJSON(message: DialerAgent_DialerCallCapReached): unknown {
    const obj: any = {};
    if (message.queueId) {
      obj.queueId = message.queueId.map((e) => e);
    } else {
      obj.queueId = [];
    }
    if (message.queueIdEnabled) {
      obj.queueIdEnabled = message.queueIdEnabled.map((e) => e);
    } else {
      obj.queueIdEnabled = [];
    }
    message.agent !== undefined && (obj.agent = message.agent);
    message.agentEnabled !== undefined && (obj.agentEnabled = message.agentEnabled);
    message.agency !== undefined && (obj.agency = message.agency);
    message.agencyEnabled !== undefined && (obj.agencyEnabled = message.agencyEnabled);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerAgent_DialerCallCapReached>, I>>(
    base?: I,
  ): DialerAgent_DialerCallCapReached {
    return DialerAgent_DialerCallCapReached.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerAgent_DialerCallCapReached>, I>>(
    object: I,
  ): DialerAgent_DialerCallCapReached {
    const message = createBaseDialerAgent_DialerCallCapReached();
    message.queueId = object.queueId?.map((e) => e) || [];
    message.queueIdEnabled = object.queueIdEnabled?.map((e) => e) || [];
    message.agent = object.agent ?? undefined;
    message.agentEnabled = object.agentEnabled ?? undefined;
    message.agency = object.agency ?? undefined;
    message.agencyEnabled = object.agencyEnabled ?? undefined;
    return message;
  },
};

function createBaseDialerAgent_DialerAgency(): DialerAgent_DialerAgency {
  return { id: undefined, name: undefined };
}

export const DialerAgent_DialerAgency = {
  encode(message: DialerAgent_DialerAgency, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerAgent_DialerAgency {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerAgent_DialerAgency();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerAgent_DialerAgency {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
    };
  },

  toJSON(message: DialerAgent_DialerAgency): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerAgent_DialerAgency>, I>>(base?: I): DialerAgent_DialerAgency {
    return DialerAgent_DialerAgency.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerAgent_DialerAgency>, I>>(object: I): DialerAgent_DialerAgency {
    const message = createBaseDialerAgent_DialerAgency();
    message.id = object.id ?? undefined;
    message.name = object.name ?? undefined;
    return message;
  },
};

function createBaseDialerAgent_DialerStats(): DialerAgent_DialerStats {
  return {
    dateToday: undefined,
    totalCalls: undefined,
    sessionStart: undefined,
    total: undefined,
    inbound: undefined,
    outbound: undefined,
    byQueue: undefined,
  };
}

export const DialerAgent_DialerStats = {
  encode(message: DialerAgent_DialerStats, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.dateToday !== undefined) {
      writer.uint32(10).string(message.dateToday);
    }
    if (message.totalCalls !== undefined) {
      writer.uint32(16).int32(message.totalCalls);
    }
    if (message.sessionStart !== undefined) {
      writer.uint32(24).int64(message.sessionStart);
    }
    if (message.total !== undefined) {
      DialerAgent_DialerCallStatsContainer.encode(message.total, writer.uint32(34).fork()).ldelim();
    }
    if (message.inbound !== undefined) {
      DialerAgent_DialerCallStatsContainer.encode(message.inbound, writer.uint32(42).fork()).ldelim();
    }
    if (message.outbound !== undefined) {
      DialerAgent_DialerCallStatsContainer.encode(message.outbound, writer.uint32(50).fork()).ldelim();
    }
    if (message.byQueue !== undefined) {
      DialerAgent_DialerCallStatsByMap.encode(message.byQueue, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerAgent_DialerStats {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerAgent_DialerStats();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.dateToday = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.totalCalls = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.sessionStart = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.total = DialerAgent_DialerCallStatsContainer.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.inbound = DialerAgent_DialerCallStatsContainer.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.outbound = DialerAgent_DialerCallStatsContainer.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.byQueue = DialerAgent_DialerCallStatsByMap.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerAgent_DialerStats {
    return {
      dateToday: isSet(object.dateToday) ? String(object.dateToday) : undefined,
      totalCalls: isSet(object.totalCalls) ? Number(object.totalCalls) : undefined,
      sessionStart: isSet(object.sessionStart) ? Number(object.sessionStart) : undefined,
      total: isSet(object.total) ? DialerAgent_DialerCallStatsContainer.fromJSON(object.total) : undefined,
      inbound: isSet(object.inbound) ? DialerAgent_DialerCallStatsContainer.fromJSON(object.inbound) : undefined,
      outbound: isSet(object.outbound) ? DialerAgent_DialerCallStatsContainer.fromJSON(object.outbound) : undefined,
      byQueue: isSet(object.byQueue) ? DialerAgent_DialerCallStatsByMap.fromJSON(object.byQueue) : undefined,
    };
  },

  toJSON(message: DialerAgent_DialerStats): unknown {
    const obj: any = {};
    message.dateToday !== undefined && (obj.dateToday = message.dateToday);
    message.totalCalls !== undefined && (obj.totalCalls = Math.round(message.totalCalls));
    message.sessionStart !== undefined && (obj.sessionStart = Math.round(message.sessionStart));
    message.total !== undefined &&
      (obj.total = message.total ? DialerAgent_DialerCallStatsContainer.toJSON(message.total) : undefined);
    message.inbound !== undefined &&
      (obj.inbound = message.inbound ? DialerAgent_DialerCallStatsContainer.toJSON(message.inbound) : undefined);
    message.outbound !== undefined &&
      (obj.outbound = message.outbound ? DialerAgent_DialerCallStatsContainer.toJSON(message.outbound) : undefined);
    message.byQueue !== undefined &&
      (obj.byQueue = message.byQueue ? DialerAgent_DialerCallStatsByMap.toJSON(message.byQueue) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerAgent_DialerStats>, I>>(base?: I): DialerAgent_DialerStats {
    return DialerAgent_DialerStats.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerAgent_DialerStats>, I>>(object: I): DialerAgent_DialerStats {
    const message = createBaseDialerAgent_DialerStats();
    message.dateToday = object.dateToday ?? undefined;
    message.totalCalls = object.totalCalls ?? undefined;
    message.sessionStart = object.sessionStart ?? undefined;
    message.total = (object.total !== undefined && object.total !== null)
      ? DialerAgent_DialerCallStatsContainer.fromPartial(object.total)
      : undefined;
    message.inbound = (object.inbound !== undefined && object.inbound !== null)
      ? DialerAgent_DialerCallStatsContainer.fromPartial(object.inbound)
      : undefined;
    message.outbound = (object.outbound !== undefined && object.outbound !== null)
      ? DialerAgent_DialerCallStatsContainer.fromPartial(object.outbound)
      : undefined;
    message.byQueue = (object.byQueue !== undefined && object.byQueue !== null)
      ? DialerAgent_DialerCallStatsByMap.fromPartial(object.byQueue)
      : undefined;
    return message;
  },
};

function createBaseDialerAgent_DialerCallStatsByMap(): DialerAgent_DialerCallStatsByMap {
  return { id: {} };
}

export const DialerAgent_DialerCallStatsByMap = {
  encode(message: DialerAgent_DialerCallStatsByMap, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.id).forEach(([key, value]) => {
      DialerAgent_DialerCallStatsByMap_IdEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerAgent_DialerCallStatsByMap {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerAgent_DialerCallStatsByMap();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = DialerAgent_DialerCallStatsByMap_IdEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.id[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerAgent_DialerCallStatsByMap {
    return {
      id: isObject(object.id)
        ? Object.entries(object.id).reduce<{ [key: string]: DialerAgent_DialerCallStatsContainer }>(
          (acc, [key, value]) => {
            acc[key] = DialerAgent_DialerCallStatsContainer.fromJSON(value);
            return acc;
          },
          {},
        )
        : {},
    };
  },

  toJSON(message: DialerAgent_DialerCallStatsByMap): unknown {
    const obj: any = {};
    obj.id = {};
    if (message.id) {
      Object.entries(message.id).forEach(([k, v]) => {
        obj.id[k] = DialerAgent_DialerCallStatsContainer.toJSON(v);
      });
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerAgent_DialerCallStatsByMap>, I>>(
    base?: I,
  ): DialerAgent_DialerCallStatsByMap {
    return DialerAgent_DialerCallStatsByMap.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerAgent_DialerCallStatsByMap>, I>>(
    object: I,
  ): DialerAgent_DialerCallStatsByMap {
    const message = createBaseDialerAgent_DialerCallStatsByMap();
    message.id = Object.entries(object.id ?? {}).reduce<{ [key: string]: DialerAgent_DialerCallStatsContainer }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = DialerAgent_DialerCallStatsContainer.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseDialerAgent_DialerCallStatsByMap_IdEntry(): DialerAgent_DialerCallStatsByMap_IdEntry {
  return { key: "", value: undefined };
}

export const DialerAgent_DialerCallStatsByMap_IdEntry = {
  encode(message: DialerAgent_DialerCallStatsByMap_IdEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      DialerAgent_DialerCallStatsContainer.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerAgent_DialerCallStatsByMap_IdEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerAgent_DialerCallStatsByMap_IdEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = DialerAgent_DialerCallStatsContainer.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerAgent_DialerCallStatsByMap_IdEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? DialerAgent_DialerCallStatsContainer.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: DialerAgent_DialerCallStatsByMap_IdEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined &&
      (obj.value = message.value ? DialerAgent_DialerCallStatsContainer.toJSON(message.value) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerAgent_DialerCallStatsByMap_IdEntry>, I>>(
    base?: I,
  ): DialerAgent_DialerCallStatsByMap_IdEntry {
    return DialerAgent_DialerCallStatsByMap_IdEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerAgent_DialerCallStatsByMap_IdEntry>, I>>(
    object: I,
  ): DialerAgent_DialerCallStatsByMap_IdEntry {
    const message = createBaseDialerAgent_DialerCallStatsByMap_IdEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? DialerAgent_DialerCallStatsContainer.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseDialerAgent_DialerCallStatsContainer(): DialerAgent_DialerCallStatsContainer {
  return { total: undefined, answered: undefined, billable: undefined };
}

export const DialerAgent_DialerCallStatsContainer = {
  encode(message: DialerAgent_DialerCallStatsContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.total !== undefined) {
      writer.uint32(8).int32(message.total);
    }
    if (message.answered !== undefined) {
      writer.uint32(16).int32(message.answered);
    }
    if (message.billable !== undefined) {
      writer.uint32(24).int32(message.billable);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerAgent_DialerCallStatsContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerAgent_DialerCallStatsContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.total = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.answered = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.billable = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerAgent_DialerCallStatsContainer {
    return {
      total: isSet(object.total) ? Number(object.total) : undefined,
      answered: isSet(object.answered) ? Number(object.answered) : undefined,
      billable: isSet(object.billable) ? Number(object.billable) : undefined,
    };
  },

  toJSON(message: DialerAgent_DialerCallStatsContainer): unknown {
    const obj: any = {};
    message.total !== undefined && (obj.total = Math.round(message.total));
    message.answered !== undefined && (obj.answered = Math.round(message.answered));
    message.billable !== undefined && (obj.billable = Math.round(message.billable));
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerAgent_DialerCallStatsContainer>, I>>(
    base?: I,
  ): DialerAgent_DialerCallStatsContainer {
    return DialerAgent_DialerCallStatsContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerAgent_DialerCallStatsContainer>, I>>(
    object: I,
  ): DialerAgent_DialerCallStatsContainer {
    const message = createBaseDialerAgent_DialerCallStatsContainer();
    message.total = object.total ?? undefined;
    message.answered = object.answered ?? undefined;
    message.billable = object.billable ?? undefined;
    return message;
  },
};

function createBaseDialerSession(): DialerSession {
  return { id: undefined, updatedAt: undefined };
}

export const DialerSession = {
  encode(message: DialerSession, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.updatedAt !== undefined) {
      writer.uint32(16).int64(message.updatedAt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerSession {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerSession();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.updatedAt = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerSession {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      updatedAt: isSet(object.updatedAt) ? Number(object.updatedAt) : undefined,
    };
  },

  toJSON(message: DialerSession): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.updatedAt !== undefined && (obj.updatedAt = Math.round(message.updatedAt));
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerSession>, I>>(base?: I): DialerSession {
    return DialerSession.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerSession>, I>>(object: I): DialerSession {
    const message = createBaseDialerSession();
    message.id = object.id ?? undefined;
    message.updatedAt = object.updatedAt ?? undefined;
    return message;
  },
};

function createBaseDialerLeadCall(): DialerLeadCall {
  return {
    id: "",
    agency: undefined,
    lead: undefined,
    agent: undefined,
    createdAt: undefined,
    lastStatus: undefined,
    duration: undefined,
    direction: undefined,
    participants: {},
    toNumber: undefined,
    fromNumber: undefined,
    systemResultCategory: undefined,
    systemResult: undefined,
    callType: undefined,
    recordingUrl: undefined,
    recordingUrlExternal: undefined,
    cost: [],
    resultCategory: undefined,
    result: undefined,
    source: undefined,
    sourceCustomName: undefined,
    terminatedBy: undefined,
    queueStatistics: undefined,
    campaign: undefined,
    list: undefined,
    queue: undefined,
    identifiers: undefined,
    tags: undefined,
    durationInQueue: undefined,
    durationInCall: undefined,
    connectionTime: undefined,
    participantCount: undefined,
    wasConnected: undefined,
    billable: undefined,
    wasBlocked: undefined,
    initialDeclineReason: undefined,
    related: undefined,
    wasVoicemail: undefined,
    flow: undefined,
    flowOutgoing: undefined,
    flowIncoming: undefined,
    relatedOutgoing: undefined,
    flowStepB1: undefined,
    flowOutgoingStep1: undefined,
    flowOutgoingStep2: undefined,
    flowIncomingStep1: undefined,
    sold: undefined,
    internalBilling: undefined,
    enrollmentCode: undefined,
    transcriptUrl: undefined,
    transcriptionStatus: undefined,
    transcript: undefined,
    customPrompts: undefined,
    status: undefined,
    logs: [],
    incoming: undefined,
  };
}

export const DialerLeadCall = {
  encode(message: DialerLeadCall, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.agency !== undefined) {
      BaseUser.encode(message.agency, writer.uint32(18).fork()).ldelim();
    }
    if (message.lead !== undefined) {
      Consumer.encode(message.lead, writer.uint32(26).fork()).ldelim();
    }
    if (message.agent !== undefined) {
      Agent.encode(message.agent, writer.uint32(34).fork()).ldelim();
    }
    if (message.createdAt !== undefined) {
      writer.uint32(40).int64(message.createdAt);
    }
    if (message.lastStatus !== undefined) {
      writer.uint32(48).int32(message.lastStatus);
    }
    if (message.duration !== undefined) {
      writer.uint32(56).int32(message.duration);
    }
    if (message.direction !== undefined) {
      writer.uint32(64).int32(message.direction);
    }
    Object.entries(message.participants).forEach(([key, value]) => {
      DialerLeadCall_ParticipantsEntry.encode({ key: key as any, value }, writer.uint32(74).fork()).ldelim();
    });
    if (message.toNumber !== undefined) {
      writer.uint32(82).string(message.toNumber);
    }
    if (message.fromNumber !== undefined) {
      writer.uint32(90).string(message.fromNumber);
    }
    if (message.systemResultCategory !== undefined) {
      writer.uint32(96).int32(message.systemResultCategory);
    }
    if (message.systemResult !== undefined) {
      writer.uint32(104).int32(message.systemResult);
    }
    if (message.callType !== undefined) {
      writer.uint32(112).int32(message.callType);
    }
    if (message.recordingUrl !== undefined) {
      writer.uint32(122).string(message.recordingUrl);
    }
    if (message.recordingUrlExternal !== undefined) {
      writer.uint32(130).string(message.recordingUrlExternal);
    }
    for (const v of message.cost) {
      DialerLeadCall_Cost.encode(v!, writer.uint32(138).fork()).ldelim();
    }
    if (message.resultCategory !== undefined) {
      writer.uint32(144).int32(message.resultCategory);
    }
    if (message.result !== undefined) {
      writer.uint32(152).int32(message.result);
    }
    if (message.source !== undefined) {
      writer.uint32(160).int32(message.source);
    }
    if (message.sourceCustomName !== undefined) {
      writer.uint32(170).string(message.sourceCustomName);
    }
    if (message.terminatedBy !== undefined) {
      writer.uint32(176).int32(message.terminatedBy);
    }
    if (message.queueStatistics !== undefined) {
      DialerQueueStatistics.encode(message.queueStatistics, writer.uint32(186).fork()).ldelim();
    }
    if (message.campaign !== undefined) {
      DialerReferenceContainer.encode(message.campaign, writer.uint32(194).fork()).ldelim();
    }
    if (message.list !== undefined) {
      DialerReferenceContainer.encode(message.list, writer.uint32(202).fork()).ldelim();
    }
    if (message.queue !== undefined) {
      DialerReferenceContainer.encode(message.queue, writer.uint32(210).fork()).ldelim();
    }
    if (message.identifiers !== undefined) {
      IdentifierContainer.encode(message.identifiers, writer.uint32(218).fork()).ldelim();
    }
    if (message.tags !== undefined) {
      TagsContainer.encode(message.tags, writer.uint32(226).fork()).ldelim();
    }
    if (message.durationInQueue !== undefined) {
      writer.uint32(232).int32(message.durationInQueue);
    }
    if (message.durationInCall !== undefined) {
      writer.uint32(240).int32(message.durationInCall);
    }
    if (message.connectionTime !== undefined) {
      writer.uint32(248).int32(message.connectionTime);
    }
    if (message.participantCount !== undefined) {
      writer.uint32(256).int32(message.participantCount);
    }
    if (message.wasConnected !== undefined) {
      writer.uint32(264).bool(message.wasConnected);
    }
    if (message.billable !== undefined) {
      DialerLeadCall_BillableContainer.encode(message.billable, writer.uint32(274).fork()).ldelim();
    }
    if (message.wasBlocked !== undefined) {
      writer.uint32(280).bool(message.wasBlocked);
    }
    if (message.initialDeclineReason !== undefined) {
      DialerCallDeclineReasonContainer.encode(message.initialDeclineReason, writer.uint32(290).fork()).ldelim();
    }
    if (message.related !== undefined) {
      RelatedContainer.encode(message.related, writer.uint32(298).fork()).ldelim();
    }
    if (message.wasVoicemail !== undefined) {
      writer.uint32(304).bool(message.wasVoicemail);
    }
    if (message.flow !== undefined) {
      DialerCallFlow.encode(message.flow, writer.uint32(314).fork()).ldelim();
    }
    if (message.flowOutgoing !== undefined) {
      DialerCallFlow.encode(message.flowOutgoing, writer.uint32(322).fork()).ldelim();
    }
    if (message.flowIncoming !== undefined) {
      DialerCallFlow.encode(message.flowIncoming, writer.uint32(330).fork()).ldelim();
    }
    if (message.relatedOutgoing !== undefined) {
      RelatedContainer.encode(message.relatedOutgoing, writer.uint32(338).fork()).ldelim();
    }
    if (message.flowStepB1 !== undefined) {
      DialerFlowStep.encode(message.flowStepB1, writer.uint32(346).fork()).ldelim();
    }
    if (message.flowOutgoingStep1 !== undefined) {
      DialerFlowStep.encode(message.flowOutgoingStep1, writer.uint32(362).fork()).ldelim();
    }
    if (message.flowOutgoingStep2 !== undefined) {
      DialerFlowStep.encode(message.flowOutgoingStep2, writer.uint32(370).fork()).ldelim();
    }
    if (message.flowIncomingStep1 !== undefined) {
      DialerFlowStep.encode(message.flowIncomingStep1, writer.uint32(378).fork()).ldelim();
    }
    if (message.sold !== undefined) {
      DialerLeadCall_BillableContainer.encode(message.sold, writer.uint32(386).fork()).ldelim();
    }
    if (message.internalBilling !== undefined) {
      DialerInternalBilling.encode(message.internalBilling, writer.uint32(394).fork()).ldelim();
    }
    if (message.enrollmentCode !== undefined) {
      EnrollmentCode.encode(message.enrollmentCode, writer.uint32(402).fork()).ldelim();
    }
    if (message.transcriptUrl !== undefined) {
      writer.uint32(410).string(message.transcriptUrl);
    }
    if (message.transcriptionStatus !== undefined) {
      TranscriptStatusLegacy.encode(message.transcriptionStatus, writer.uint32(418).fork()).ldelim();
    }
    if (message.transcript !== undefined) {
      TranscriptContainer.encode(message.transcript, writer.uint32(426).fork()).ldelim();
    }
    if (message.customPrompts !== undefined) {
      CustomPromptContainer.encode(message.customPrompts, writer.uint32(434).fork()).ldelim();
    }
    if (message.status !== undefined) {
      writer.uint32(442).string(message.status);
    }
    for (const v of message.logs) {
      Struct.encode(Struct.wrap(v!), writer.uint32(450).fork()).ldelim();
    }
    if (message.incoming !== undefined) {
      DialerLeadCall_IncomingContainer.encode(message.incoming, writer.uint32(458).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerLeadCall {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerLeadCall();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.agency = BaseUser.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.lead = Consumer.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.agent = Agent.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.createdAt = longToNumber(reader.int64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.lastStatus = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.duration = reader.int32();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.direction = reader.int32() as any;
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          const entry9 = DialerLeadCall_ParticipantsEntry.decode(reader, reader.uint32());
          if (entry9.value !== undefined) {
            message.participants[entry9.key] = entry9.value;
          }
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.toNumber = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.fromNumber = reader.string();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.systemResultCategory = reader.int32() as any;
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.systemResult = reader.int32() as any;
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.callType = reader.int32() as any;
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.recordingUrl = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.recordingUrlExternal = reader.string();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.cost.push(DialerLeadCall_Cost.decode(reader, reader.uint32()));
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.resultCategory = reader.int32() as any;
          continue;
        case 19:
          if (tag !== 152) {
            break;
          }

          message.result = reader.int32() as any;
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.source = reader.int32() as any;
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.sourceCustomName = reader.string();
          continue;
        case 22:
          if (tag !== 176) {
            break;
          }

          message.terminatedBy = reader.int32() as any;
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.queueStatistics = DialerQueueStatistics.decode(reader, reader.uint32());
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.campaign = DialerReferenceContainer.decode(reader, reader.uint32());
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.list = DialerReferenceContainer.decode(reader, reader.uint32());
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          message.queue = DialerReferenceContainer.decode(reader, reader.uint32());
          continue;
        case 27:
          if (tag !== 218) {
            break;
          }

          message.identifiers = IdentifierContainer.decode(reader, reader.uint32());
          continue;
        case 28:
          if (tag !== 226) {
            break;
          }

          message.tags = TagsContainer.decode(reader, reader.uint32());
          continue;
        case 29:
          if (tag !== 232) {
            break;
          }

          message.durationInQueue = reader.int32();
          continue;
        case 30:
          if (tag !== 240) {
            break;
          }

          message.durationInCall = reader.int32();
          continue;
        case 31:
          if (tag !== 248) {
            break;
          }

          message.connectionTime = reader.int32();
          continue;
        case 32:
          if (tag !== 256) {
            break;
          }

          message.participantCount = reader.int32();
          continue;
        case 33:
          if (tag !== 264) {
            break;
          }

          message.wasConnected = reader.bool();
          continue;
        case 34:
          if (tag !== 274) {
            break;
          }

          message.billable = DialerLeadCall_BillableContainer.decode(reader, reader.uint32());
          continue;
        case 35:
          if (tag !== 280) {
            break;
          }

          message.wasBlocked = reader.bool();
          continue;
        case 36:
          if (tag !== 290) {
            break;
          }

          message.initialDeclineReason = DialerCallDeclineReasonContainer.decode(reader, reader.uint32());
          continue;
        case 37:
          if (tag !== 298) {
            break;
          }

          message.related = RelatedContainer.decode(reader, reader.uint32());
          continue;
        case 38:
          if (tag !== 304) {
            break;
          }

          message.wasVoicemail = reader.bool();
          continue;
        case 39:
          if (tag !== 314) {
            break;
          }

          message.flow = DialerCallFlow.decode(reader, reader.uint32());
          continue;
        case 40:
          if (tag !== 322) {
            break;
          }

          message.flowOutgoing = DialerCallFlow.decode(reader, reader.uint32());
          continue;
        case 41:
          if (tag !== 330) {
            break;
          }

          message.flowIncoming = DialerCallFlow.decode(reader, reader.uint32());
          continue;
        case 42:
          if (tag !== 338) {
            break;
          }

          message.relatedOutgoing = RelatedContainer.decode(reader, reader.uint32());
          continue;
        case 43:
          if (tag !== 346) {
            break;
          }

          message.flowStepB1 = DialerFlowStep.decode(reader, reader.uint32());
          continue;
        case 45:
          if (tag !== 362) {
            break;
          }

          message.flowOutgoingStep1 = DialerFlowStep.decode(reader, reader.uint32());
          continue;
        case 46:
          if (tag !== 370) {
            break;
          }

          message.flowOutgoingStep2 = DialerFlowStep.decode(reader, reader.uint32());
          continue;
        case 47:
          if (tag !== 378) {
            break;
          }

          message.flowIncomingStep1 = DialerFlowStep.decode(reader, reader.uint32());
          continue;
        case 48:
          if (tag !== 386) {
            break;
          }

          message.sold = DialerLeadCall_BillableContainer.decode(reader, reader.uint32());
          continue;
        case 49:
          if (tag !== 394) {
            break;
          }

          message.internalBilling = DialerInternalBilling.decode(reader, reader.uint32());
          continue;
        case 50:
          if (tag !== 402) {
            break;
          }

          message.enrollmentCode = EnrollmentCode.decode(reader, reader.uint32());
          continue;
        case 51:
          if (tag !== 410) {
            break;
          }

          message.transcriptUrl = reader.string();
          continue;
        case 52:
          if (tag !== 418) {
            break;
          }

          message.transcriptionStatus = TranscriptStatusLegacy.decode(reader, reader.uint32());
          continue;
        case 53:
          if (tag !== 426) {
            break;
          }

          message.transcript = TranscriptContainer.decode(reader, reader.uint32());
          continue;
        case 54:
          if (tag !== 434) {
            break;
          }

          message.customPrompts = CustomPromptContainer.decode(reader, reader.uint32());
          continue;
        case 55:
          if (tag !== 442) {
            break;
          }

          message.status = reader.string();
          continue;
        case 56:
          if (tag !== 450) {
            break;
          }

          message.logs.push(Struct.unwrap(Struct.decode(reader, reader.uint32())));
          continue;
        case 57:
          if (tag !== 458) {
            break;
          }

          message.incoming = DialerLeadCall_IncomingContainer.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerLeadCall {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      agency: isSet(object.agency) ? BaseUser.fromJSON(object.agency) : undefined,
      lead: isSet(object.lead) ? Consumer.fromJSON(object.lead) : undefined,
      agent: isSet(object.agent) ? Agent.fromJSON(object.agent) : undefined,
      createdAt: isSet(object.createdAt) ? Number(object.createdAt) : undefined,
      lastStatus: isSet(object.lastStatus) ? dialerLeadCall_CallStatusFromJSON(object.lastStatus) : undefined,
      duration: isSet(object.duration) ? Number(object.duration) : undefined,
      direction: isSet(object.direction) ? dialerLeadCallDirectionFromJSON(object.direction) : undefined,
      participants: isObject(object.participants)
        ? Object.entries(object.participants).reduce<{ [key: string]: DialerLeadCall_Participant }>(
          (acc, [key, value]) => {
            acc[key] = DialerLeadCall_Participant.fromJSON(value);
            return acc;
          },
          {},
        )
        : {},
      toNumber: isSet(object.toNumber) ? String(object.toNumber) : undefined,
      fromNumber: isSet(object.fromNumber) ? String(object.fromNumber) : undefined,
      systemResultCategory: isSet(object.systemResultCategory)
        ? consumer_Dialer_ResultCategoryFromJSON(object.systemResultCategory)
        : undefined,
      systemResult: isSet(object.systemResult) ? consumer_Dialer_ResultFromJSON(object.systemResult) : undefined,
      callType: isSet(object.callType) ? dialerClientState_CallTypeFromJSON(object.callType) : undefined,
      recordingUrl: isSet(object.recordingUrl) ? String(object.recordingUrl) : undefined,
      recordingUrlExternal: isSet(object.recordingUrlExternal) ? String(object.recordingUrlExternal) : undefined,
      cost: Array.isArray(object?.cost) ? object.cost.map((e: any) => DialerLeadCall_Cost.fromJSON(e)) : [],
      resultCategory: isSet(object.resultCategory)
        ? consumer_Dialer_ResultCategoryFromJSON(object.resultCategory)
        : undefined,
      result: isSet(object.result) ? consumer_Dialer_ResultFromJSON(object.result) : undefined,
      source: isSet(object.source) ? sourceFromJSON(object.source) : undefined,
      sourceCustomName: isSet(object.sourceCustomName) ? String(object.sourceCustomName) : undefined,
      terminatedBy: isSet(object.terminatedBy) ? dialerCallTerminatorsFromJSON(object.terminatedBy) : undefined,
      queueStatistics: isSet(object.queueStatistics)
        ? DialerQueueStatistics.fromJSON(object.queueStatistics)
        : undefined,
      campaign: isSet(object.campaign) ? DialerReferenceContainer.fromJSON(object.campaign) : undefined,
      list: isSet(object.list) ? DialerReferenceContainer.fromJSON(object.list) : undefined,
      queue: isSet(object.queue) ? DialerReferenceContainer.fromJSON(object.queue) : undefined,
      identifiers: isSet(object.identifiers) ? IdentifierContainer.fromJSON(object.identifiers) : undefined,
      tags: isSet(object.tags) ? TagsContainer.fromJSON(object.tags) : undefined,
      durationInQueue: isSet(object.durationInQueue) ? Number(object.durationInQueue) : undefined,
      durationInCall: isSet(object.durationInCall) ? Number(object.durationInCall) : undefined,
      connectionTime: isSet(object.connectionTime) ? Number(object.connectionTime) : undefined,
      participantCount: isSet(object.participantCount) ? Number(object.participantCount) : undefined,
      wasConnected: isSet(object.wasConnected) ? Boolean(object.wasConnected) : undefined,
      billable: isSet(object.billable) ? DialerLeadCall_BillableContainer.fromJSON(object.billable) : undefined,
      wasBlocked: isSet(object.wasBlocked) ? Boolean(object.wasBlocked) : undefined,
      initialDeclineReason: isSet(object.initialDeclineReason)
        ? DialerCallDeclineReasonContainer.fromJSON(object.initialDeclineReason)
        : undefined,
      related: isSet(object.related) ? RelatedContainer.fromJSON(object.related) : undefined,
      wasVoicemail: isSet(object.wasVoicemail) ? Boolean(object.wasVoicemail) : undefined,
      flow: isSet(object.flow) ? DialerCallFlow.fromJSON(object.flow) : undefined,
      flowOutgoing: isSet(object.flowOutgoing) ? DialerCallFlow.fromJSON(object.flowOutgoing) : undefined,
      flowIncoming: isSet(object.flowIncoming) ? DialerCallFlow.fromJSON(object.flowIncoming) : undefined,
      relatedOutgoing: isSet(object.relatedOutgoing) ? RelatedContainer.fromJSON(object.relatedOutgoing) : undefined,
      flowStepB1: isSet(object.flowStepB1) ? DialerFlowStep.fromJSON(object.flowStepB1) : undefined,
      flowOutgoingStep1: isSet(object.flowOutgoingStep1)
        ? DialerFlowStep.fromJSON(object.flowOutgoingStep1)
        : undefined,
      flowOutgoingStep2: isSet(object.flowOutgoingStep2)
        ? DialerFlowStep.fromJSON(object.flowOutgoingStep2)
        : undefined,
      flowIncomingStep1: isSet(object.flowIncomingStep1)
        ? DialerFlowStep.fromJSON(object.flowIncomingStep1)
        : undefined,
      sold: isSet(object.sold) ? DialerLeadCall_BillableContainer.fromJSON(object.sold) : undefined,
      internalBilling: isSet(object.internalBilling)
        ? DialerInternalBilling.fromJSON(object.internalBilling)
        : undefined,
      enrollmentCode: isSet(object.enrollmentCode) ? EnrollmentCode.fromJSON(object.enrollmentCode) : undefined,
      transcriptUrl: isSet(object.transcriptUrl) ? String(object.transcriptUrl) : undefined,
      transcriptionStatus: isSet(object.transcriptionStatus)
        ? TranscriptStatusLegacy.fromJSON(object.transcriptionStatus)
        : undefined,
      transcript: isSet(object.transcript) ? TranscriptContainer.fromJSON(object.transcript) : undefined,
      customPrompts: isSet(object.customPrompts) ? CustomPromptContainer.fromJSON(object.customPrompts) : undefined,
      status: isSet(object.status) ? String(object.status) : undefined,
      logs: Array.isArray(object?.logs) ? [...object.logs] : [],
      incoming: isSet(object.incoming) ? DialerLeadCall_IncomingContainer.fromJSON(object.incoming) : undefined,
    };
  },

  toJSON(message: DialerLeadCall): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.agency !== undefined && (obj.agency = message.agency ? BaseUser.toJSON(message.agency) : undefined);
    message.lead !== undefined && (obj.lead = message.lead ? Consumer.toJSON(message.lead) : undefined);
    message.agent !== undefined && (obj.agent = message.agent ? Agent.toJSON(message.agent) : undefined);
    message.createdAt !== undefined && (obj.createdAt = Math.round(message.createdAt));
    message.lastStatus !== undefined && (obj.lastStatus = message.lastStatus !== undefined
      ? dialerLeadCall_CallStatusToJSON(message.lastStatus)
      : undefined);
    message.duration !== undefined && (obj.duration = Math.round(message.duration));
    message.direction !== undefined &&
      (obj.direction = message.direction !== undefined ? dialerLeadCallDirectionToJSON(message.direction) : undefined);
    obj.participants = {};
    if (message.participants) {
      Object.entries(message.participants).forEach(([k, v]) => {
        obj.participants[k] = DialerLeadCall_Participant.toJSON(v);
      });
    }
    message.toNumber !== undefined && (obj.toNumber = message.toNumber);
    message.fromNumber !== undefined && (obj.fromNumber = message.fromNumber);
    message.systemResultCategory !== undefined && (obj.systemResultCategory = message.systemResultCategory !== undefined
      ? consumer_Dialer_ResultCategoryToJSON(message.systemResultCategory)
      : undefined);
    message.systemResult !== undefined && (obj.systemResult = message.systemResult !== undefined
      ? consumer_Dialer_ResultToJSON(message.systemResult)
      : undefined);
    message.callType !== undefined &&
      (obj.callType = message.callType !== undefined ? dialerClientState_CallTypeToJSON(message.callType) : undefined);
    message.recordingUrl !== undefined && (obj.recordingUrl = message.recordingUrl);
    message.recordingUrlExternal !== undefined && (obj.recordingUrlExternal = message.recordingUrlExternal);
    if (message.cost) {
      obj.cost = message.cost.map((e) => e ? DialerLeadCall_Cost.toJSON(e) : undefined);
    } else {
      obj.cost = [];
    }
    message.resultCategory !== undefined && (obj.resultCategory = message.resultCategory !== undefined
      ? consumer_Dialer_ResultCategoryToJSON(message.resultCategory)
      : undefined);
    message.result !== undefined &&
      (obj.result = message.result !== undefined ? consumer_Dialer_ResultToJSON(message.result) : undefined);
    message.source !== undefined &&
      (obj.source = message.source !== undefined ? sourceToJSON(message.source) : undefined);
    message.sourceCustomName !== undefined && (obj.sourceCustomName = message.sourceCustomName);
    message.terminatedBy !== undefined && (obj.terminatedBy = message.terminatedBy !== undefined
      ? dialerCallTerminatorsToJSON(message.terminatedBy)
      : undefined);
    message.queueStatistics !== undefined &&
      (obj.queueStatistics = message.queueStatistics
        ? DialerQueueStatistics.toJSON(message.queueStatistics)
        : undefined);
    message.campaign !== undefined &&
      (obj.campaign = message.campaign ? DialerReferenceContainer.toJSON(message.campaign) : undefined);
    message.list !== undefined && (obj.list = message.list ? DialerReferenceContainer.toJSON(message.list) : undefined);
    message.queue !== undefined &&
      (obj.queue = message.queue ? DialerReferenceContainer.toJSON(message.queue) : undefined);
    message.identifiers !== undefined &&
      (obj.identifiers = message.identifiers ? IdentifierContainer.toJSON(message.identifiers) : undefined);
    message.tags !== undefined && (obj.tags = message.tags ? TagsContainer.toJSON(message.tags) : undefined);
    message.durationInQueue !== undefined && (obj.durationInQueue = Math.round(message.durationInQueue));
    message.durationInCall !== undefined && (obj.durationInCall = Math.round(message.durationInCall));
    message.connectionTime !== undefined && (obj.connectionTime = Math.round(message.connectionTime));
    message.participantCount !== undefined && (obj.participantCount = Math.round(message.participantCount));
    message.wasConnected !== undefined && (obj.wasConnected = message.wasConnected);
    message.billable !== undefined &&
      (obj.billable = message.billable ? DialerLeadCall_BillableContainer.toJSON(message.billable) : undefined);
    message.wasBlocked !== undefined && (obj.wasBlocked = message.wasBlocked);
    message.initialDeclineReason !== undefined && (obj.initialDeclineReason = message.initialDeclineReason
      ? DialerCallDeclineReasonContainer.toJSON(message.initialDeclineReason)
      : undefined);
    message.related !== undefined &&
      (obj.related = message.related ? RelatedContainer.toJSON(message.related) : undefined);
    message.wasVoicemail !== undefined && (obj.wasVoicemail = message.wasVoicemail);
    message.flow !== undefined && (obj.flow = message.flow ? DialerCallFlow.toJSON(message.flow) : undefined);
    message.flowOutgoing !== undefined &&
      (obj.flowOutgoing = message.flowOutgoing ? DialerCallFlow.toJSON(message.flowOutgoing) : undefined);
    message.flowIncoming !== undefined &&
      (obj.flowIncoming = message.flowIncoming ? DialerCallFlow.toJSON(message.flowIncoming) : undefined);
    message.relatedOutgoing !== undefined &&
      (obj.relatedOutgoing = message.relatedOutgoing ? RelatedContainer.toJSON(message.relatedOutgoing) : undefined);
    message.flowStepB1 !== undefined &&
      (obj.flowStepB1 = message.flowStepB1 ? DialerFlowStep.toJSON(message.flowStepB1) : undefined);
    message.flowOutgoingStep1 !== undefined &&
      (obj.flowOutgoingStep1 = message.flowOutgoingStep1
        ? DialerFlowStep.toJSON(message.flowOutgoingStep1)
        : undefined);
    message.flowOutgoingStep2 !== undefined &&
      (obj.flowOutgoingStep2 = message.flowOutgoingStep2
        ? DialerFlowStep.toJSON(message.flowOutgoingStep2)
        : undefined);
    message.flowIncomingStep1 !== undefined &&
      (obj.flowIncomingStep1 = message.flowIncomingStep1
        ? DialerFlowStep.toJSON(message.flowIncomingStep1)
        : undefined);
    message.sold !== undefined &&
      (obj.sold = message.sold ? DialerLeadCall_BillableContainer.toJSON(message.sold) : undefined);
    message.internalBilling !== undefined &&
      (obj.internalBilling = message.internalBilling
        ? DialerInternalBilling.toJSON(message.internalBilling)
        : undefined);
    message.enrollmentCode !== undefined &&
      (obj.enrollmentCode = message.enrollmentCode ? EnrollmentCode.toJSON(message.enrollmentCode) : undefined);
    message.transcriptUrl !== undefined && (obj.transcriptUrl = message.transcriptUrl);
    message.transcriptionStatus !== undefined && (obj.transcriptionStatus = message.transcriptionStatus
      ? TranscriptStatusLegacy.toJSON(message.transcriptionStatus)
      : undefined);
    message.transcript !== undefined &&
      (obj.transcript = message.transcript ? TranscriptContainer.toJSON(message.transcript) : undefined);
    message.customPrompts !== undefined &&
      (obj.customPrompts = message.customPrompts ? CustomPromptContainer.toJSON(message.customPrompts) : undefined);
    message.status !== undefined && (obj.status = message.status);
    if (message.logs) {
      obj.logs = message.logs.map((e) => e);
    } else {
      obj.logs = [];
    }
    message.incoming !== undefined &&
      (obj.incoming = message.incoming ? DialerLeadCall_IncomingContainer.toJSON(message.incoming) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerLeadCall>, I>>(base?: I): DialerLeadCall {
    return DialerLeadCall.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerLeadCall>, I>>(object: I): DialerLeadCall {
    const message = createBaseDialerLeadCall();
    message.id = object.id ?? "";
    message.agency = (object.agency !== undefined && object.agency !== null)
      ? BaseUser.fromPartial(object.agency)
      : undefined;
    message.lead = (object.lead !== undefined && object.lead !== null) ? Consumer.fromPartial(object.lead) : undefined;
    message.agent = (object.agent !== undefined && object.agent !== null) ? Agent.fromPartial(object.agent) : undefined;
    message.createdAt = object.createdAt ?? undefined;
    message.lastStatus = object.lastStatus ?? undefined;
    message.duration = object.duration ?? undefined;
    message.direction = object.direction ?? undefined;
    message.participants = Object.entries(object.participants ?? {}).reduce<
      { [key: string]: DialerLeadCall_Participant }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = DialerLeadCall_Participant.fromPartial(value);
      }
      return acc;
    }, {});
    message.toNumber = object.toNumber ?? undefined;
    message.fromNumber = object.fromNumber ?? undefined;
    message.systemResultCategory = object.systemResultCategory ?? undefined;
    message.systemResult = object.systemResult ?? undefined;
    message.callType = object.callType ?? undefined;
    message.recordingUrl = object.recordingUrl ?? undefined;
    message.recordingUrlExternal = object.recordingUrlExternal ?? undefined;
    message.cost = object.cost?.map((e) => DialerLeadCall_Cost.fromPartial(e)) || [];
    message.resultCategory = object.resultCategory ?? undefined;
    message.result = object.result ?? undefined;
    message.source = object.source ?? undefined;
    message.sourceCustomName = object.sourceCustomName ?? undefined;
    message.terminatedBy = object.terminatedBy ?? undefined;
    message.queueStatistics = (object.queueStatistics !== undefined && object.queueStatistics !== null)
      ? DialerQueueStatistics.fromPartial(object.queueStatistics)
      : undefined;
    message.campaign = (object.campaign !== undefined && object.campaign !== null)
      ? DialerReferenceContainer.fromPartial(object.campaign)
      : undefined;
    message.list = (object.list !== undefined && object.list !== null)
      ? DialerReferenceContainer.fromPartial(object.list)
      : undefined;
    message.queue = (object.queue !== undefined && object.queue !== null)
      ? DialerReferenceContainer.fromPartial(object.queue)
      : undefined;
    message.identifiers = (object.identifiers !== undefined && object.identifiers !== null)
      ? IdentifierContainer.fromPartial(object.identifiers)
      : undefined;
    message.tags = (object.tags !== undefined && object.tags !== null)
      ? TagsContainer.fromPartial(object.tags)
      : undefined;
    message.durationInQueue = object.durationInQueue ?? undefined;
    message.durationInCall = object.durationInCall ?? undefined;
    message.connectionTime = object.connectionTime ?? undefined;
    message.participantCount = object.participantCount ?? undefined;
    message.wasConnected = object.wasConnected ?? undefined;
    message.billable = (object.billable !== undefined && object.billable !== null)
      ? DialerLeadCall_BillableContainer.fromPartial(object.billable)
      : undefined;
    message.wasBlocked = object.wasBlocked ?? undefined;
    message.initialDeclineReason = (object.initialDeclineReason !== undefined && object.initialDeclineReason !== null)
      ? DialerCallDeclineReasonContainer.fromPartial(object.initialDeclineReason)
      : undefined;
    message.related = (object.related !== undefined && object.related !== null)
      ? RelatedContainer.fromPartial(object.related)
      : undefined;
    message.wasVoicemail = object.wasVoicemail ?? undefined;
    message.flow = (object.flow !== undefined && object.flow !== null)
      ? DialerCallFlow.fromPartial(object.flow)
      : undefined;
    message.flowOutgoing = (object.flowOutgoing !== undefined && object.flowOutgoing !== null)
      ? DialerCallFlow.fromPartial(object.flowOutgoing)
      : undefined;
    message.flowIncoming = (object.flowIncoming !== undefined && object.flowIncoming !== null)
      ? DialerCallFlow.fromPartial(object.flowIncoming)
      : undefined;
    message.relatedOutgoing = (object.relatedOutgoing !== undefined && object.relatedOutgoing !== null)
      ? RelatedContainer.fromPartial(object.relatedOutgoing)
      : undefined;
    message.flowStepB1 = (object.flowStepB1 !== undefined && object.flowStepB1 !== null)
      ? DialerFlowStep.fromPartial(object.flowStepB1)
      : undefined;
    message.flowOutgoingStep1 = (object.flowOutgoingStep1 !== undefined && object.flowOutgoingStep1 !== null)
      ? DialerFlowStep.fromPartial(object.flowOutgoingStep1)
      : undefined;
    message.flowOutgoingStep2 = (object.flowOutgoingStep2 !== undefined && object.flowOutgoingStep2 !== null)
      ? DialerFlowStep.fromPartial(object.flowOutgoingStep2)
      : undefined;
    message.flowIncomingStep1 = (object.flowIncomingStep1 !== undefined && object.flowIncomingStep1 !== null)
      ? DialerFlowStep.fromPartial(object.flowIncomingStep1)
      : undefined;
    message.sold = (object.sold !== undefined && object.sold !== null)
      ? DialerLeadCall_BillableContainer.fromPartial(object.sold)
      : undefined;
    message.internalBilling = (object.internalBilling !== undefined && object.internalBilling !== null)
      ? DialerInternalBilling.fromPartial(object.internalBilling)
      : undefined;
    message.enrollmentCode = (object.enrollmentCode !== undefined && object.enrollmentCode !== null)
      ? EnrollmentCode.fromPartial(object.enrollmentCode)
      : undefined;
    message.transcriptUrl = object.transcriptUrl ?? undefined;
    message.transcriptionStatus = (object.transcriptionStatus !== undefined && object.transcriptionStatus !== null)
      ? TranscriptStatusLegacy.fromPartial(object.transcriptionStatus)
      : undefined;
    message.transcript = (object.transcript !== undefined && object.transcript !== null)
      ? TranscriptContainer.fromPartial(object.transcript)
      : undefined;
    message.customPrompts = (object.customPrompts !== undefined && object.customPrompts !== null)
      ? CustomPromptContainer.fromPartial(object.customPrompts)
      : undefined;
    message.status = object.status ?? undefined;
    message.logs = object.logs?.map((e) => e) || [];
    message.incoming = (object.incoming !== undefined && object.incoming !== null)
      ? DialerLeadCall_IncomingContainer.fromPartial(object.incoming)
      : undefined;
    return message;
  },
};

function createBaseDialerLeadCall_ParticipantsEntry(): DialerLeadCall_ParticipantsEntry {
  return { key: "", value: undefined };
}

export const DialerLeadCall_ParticipantsEntry = {
  encode(message: DialerLeadCall_ParticipantsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      DialerLeadCall_Participant.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerLeadCall_ParticipantsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerLeadCall_ParticipantsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = DialerLeadCall_Participant.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerLeadCall_ParticipantsEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? DialerLeadCall_Participant.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: DialerLeadCall_ParticipantsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined &&
      (obj.value = message.value ? DialerLeadCall_Participant.toJSON(message.value) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerLeadCall_ParticipantsEntry>, I>>(
    base?: I,
  ): DialerLeadCall_ParticipantsEntry {
    return DialerLeadCall_ParticipantsEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerLeadCall_ParticipantsEntry>, I>>(
    object: I,
  ): DialerLeadCall_ParticipantsEntry {
    const message = createBaseDialerLeadCall_ParticipantsEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? DialerLeadCall_Participant.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseDialerLeadCall_IncomingContainer(): DialerLeadCall_IncomingContainer {
  return { participants: {}, flow: undefined };
}

export const DialerLeadCall_IncomingContainer = {
  encode(message: DialerLeadCall_IncomingContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.participants).forEach(([key, value]) => {
      DialerLeadCall_IncomingContainer_ParticipantsEntry.encode({ key: key as any, value }, writer.uint32(10).fork())
        .ldelim();
    });
    if (message.flow !== undefined) {
      DialerCallFlow.encode(message.flow, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerLeadCall_IncomingContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerLeadCall_IncomingContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = DialerLeadCall_IncomingContainer_ParticipantsEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.participants[entry1.key] = entry1.value;
          }
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.flow = DialerCallFlow.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerLeadCall_IncomingContainer {
    return {
      participants: isObject(object.participants)
        ? Object.entries(object.participants).reduce<{ [key: string]: DialerLeadCall_Participant }>(
          (acc, [key, value]) => {
            acc[key] = DialerLeadCall_Participant.fromJSON(value);
            return acc;
          },
          {},
        )
        : {},
      flow: isSet(object.flow) ? DialerCallFlow.fromJSON(object.flow) : undefined,
    };
  },

  toJSON(message: DialerLeadCall_IncomingContainer): unknown {
    const obj: any = {};
    obj.participants = {};
    if (message.participants) {
      Object.entries(message.participants).forEach(([k, v]) => {
        obj.participants[k] = DialerLeadCall_Participant.toJSON(v);
      });
    }
    message.flow !== undefined && (obj.flow = message.flow ? DialerCallFlow.toJSON(message.flow) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerLeadCall_IncomingContainer>, I>>(
    base?: I,
  ): DialerLeadCall_IncomingContainer {
    return DialerLeadCall_IncomingContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerLeadCall_IncomingContainer>, I>>(
    object: I,
  ): DialerLeadCall_IncomingContainer {
    const message = createBaseDialerLeadCall_IncomingContainer();
    message.participants = Object.entries(object.participants ?? {}).reduce<
      { [key: string]: DialerLeadCall_Participant }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = DialerLeadCall_Participant.fromPartial(value);
      }
      return acc;
    }, {});
    message.flow = (object.flow !== undefined && object.flow !== null)
      ? DialerCallFlow.fromPartial(object.flow)
      : undefined;
    return message;
  },
};

function createBaseDialerLeadCall_IncomingContainer_ParticipantsEntry(): DialerLeadCall_IncomingContainer_ParticipantsEntry {
  return { key: "", value: undefined };
}

export const DialerLeadCall_IncomingContainer_ParticipantsEntry = {
  encode(
    message: DialerLeadCall_IncomingContainer_ParticipantsEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      DialerLeadCall_Participant.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerLeadCall_IncomingContainer_ParticipantsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerLeadCall_IncomingContainer_ParticipantsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = DialerLeadCall_Participant.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerLeadCall_IncomingContainer_ParticipantsEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? DialerLeadCall_Participant.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: DialerLeadCall_IncomingContainer_ParticipantsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined &&
      (obj.value = message.value ? DialerLeadCall_Participant.toJSON(message.value) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerLeadCall_IncomingContainer_ParticipantsEntry>, I>>(
    base?: I,
  ): DialerLeadCall_IncomingContainer_ParticipantsEntry {
    return DialerLeadCall_IncomingContainer_ParticipantsEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerLeadCall_IncomingContainer_ParticipantsEntry>, I>>(
    object: I,
  ): DialerLeadCall_IncomingContainer_ParticipantsEntry {
    const message = createBaseDialerLeadCall_IncomingContainer_ParticipantsEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? DialerLeadCall_Participant.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseDialerLeadCall_BillableContainer(): DialerLeadCall_BillableContainer {
  return {
    hasBillableSetting: undefined,
    isBillable: undefined,
    billableSeconds: undefined,
    countingType: undefined,
    cost: undefined,
  };
}

export const DialerLeadCall_BillableContainer = {
  encode(message: DialerLeadCall_BillableContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.hasBillableSetting !== undefined) {
      writer.uint32(8).bool(message.hasBillableSetting);
    }
    if (message.isBillable !== undefined) {
      writer.uint32(16).bool(message.isBillable);
    }
    if (message.billableSeconds !== undefined) {
      writer.uint32(24).int32(message.billableSeconds);
    }
    if (message.countingType !== undefined) {
      writer.uint32(32).int32(message.countingType);
    }
    if (message.cost !== undefined) {
      DialerLeadCall_BillableContainerCost.encode(message.cost, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerLeadCall_BillableContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerLeadCall_BillableContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.hasBillableSetting = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isBillable = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.billableSeconds = reader.int32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.countingType = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.cost = DialerLeadCall_BillableContainerCost.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerLeadCall_BillableContainer {
    return {
      hasBillableSetting: isSet(object.hasBillableSetting) ? Boolean(object.hasBillableSetting) : undefined,
      isBillable: isSet(object.isBillable) ? Boolean(object.isBillable) : undefined,
      billableSeconds: isSet(object.billableSeconds) ? Number(object.billableSeconds) : undefined,
      countingType: isSet(object.countingType)
        ? dialerBillableSettingsCountingTypeFromJSON(object.countingType)
        : undefined,
      cost: isSet(object.cost) ? DialerLeadCall_BillableContainerCost.fromJSON(object.cost) : undefined,
    };
  },

  toJSON(message: DialerLeadCall_BillableContainer): unknown {
    const obj: any = {};
    message.hasBillableSetting !== undefined && (obj.hasBillableSetting = message.hasBillableSetting);
    message.isBillable !== undefined && (obj.isBillable = message.isBillable);
    message.billableSeconds !== undefined && (obj.billableSeconds = Math.round(message.billableSeconds));
    message.countingType !== undefined && (obj.countingType = message.countingType !== undefined
      ? dialerBillableSettingsCountingTypeToJSON(message.countingType)
      : undefined);
    message.cost !== undefined &&
      (obj.cost = message.cost ? DialerLeadCall_BillableContainerCost.toJSON(message.cost) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerLeadCall_BillableContainer>, I>>(
    base?: I,
  ): DialerLeadCall_BillableContainer {
    return DialerLeadCall_BillableContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerLeadCall_BillableContainer>, I>>(
    object: I,
  ): DialerLeadCall_BillableContainer {
    const message = createBaseDialerLeadCall_BillableContainer();
    message.hasBillableSetting = object.hasBillableSetting ?? undefined;
    message.isBillable = object.isBillable ?? undefined;
    message.billableSeconds = object.billableSeconds ?? undefined;
    message.countingType = object.countingType ?? undefined;
    message.cost = (object.cost !== undefined && object.cost !== null)
      ? DialerLeadCall_BillableContainerCost.fromPartial(object.cost)
      : undefined;
    return message;
  },
};

function createBaseDialerLeadCall_BillableContainerCost(): DialerLeadCall_BillableContainerCost {
  return { total: undefined, base: undefined, minutes: undefined, billedMinutes: undefined };
}

export const DialerLeadCall_BillableContainerCost = {
  encode(message: DialerLeadCall_BillableContainerCost, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.total !== undefined) {
      writer.uint32(9).double(message.total);
    }
    if (message.base !== undefined) {
      writer.uint32(17).double(message.base);
    }
    if (message.minutes !== undefined) {
      writer.uint32(25).double(message.minutes);
    }
    if (message.billedMinutes !== undefined) {
      writer.uint32(33).double(message.billedMinutes);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerLeadCall_BillableContainerCost {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerLeadCall_BillableContainerCost();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.total = reader.double();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.base = reader.double();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.minutes = reader.double();
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.billedMinutes = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerLeadCall_BillableContainerCost {
    return {
      total: isSet(object.total) ? Number(object.total) : undefined,
      base: isSet(object.base) ? Number(object.base) : undefined,
      minutes: isSet(object.minutes) ? Number(object.minutes) : undefined,
      billedMinutes: isSet(object.billedMinutes) ? Number(object.billedMinutes) : undefined,
    };
  },

  toJSON(message: DialerLeadCall_BillableContainerCost): unknown {
    const obj: any = {};
    message.total !== undefined && (obj.total = message.total);
    message.base !== undefined && (obj.base = message.base);
    message.minutes !== undefined && (obj.minutes = message.minutes);
    message.billedMinutes !== undefined && (obj.billedMinutes = message.billedMinutes);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerLeadCall_BillableContainerCost>, I>>(
    base?: I,
  ): DialerLeadCall_BillableContainerCost {
    return DialerLeadCall_BillableContainerCost.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerLeadCall_BillableContainerCost>, I>>(
    object: I,
  ): DialerLeadCall_BillableContainerCost {
    const message = createBaseDialerLeadCall_BillableContainerCost();
    message.total = object.total ?? undefined;
    message.base = object.base ?? undefined;
    message.minutes = object.minutes ?? undefined;
    message.billedMinutes = object.billedMinutes ?? undefined;
    return message;
  },
};

function createBaseDialerLeadCall_Cost(): DialerLeadCall_Cost {
  return {
    billedDurationSecs: undefined,
    costParts: [],
    total: undefined,
    status: undefined,
    isConferenceCost: undefined,
    caller: undefined,
  };
}

export const DialerLeadCall_Cost = {
  encode(message: DialerLeadCall_Cost, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.billedDurationSecs !== undefined) {
      writer.uint32(8).int32(message.billedDurationSecs);
    }
    for (const v of message.costParts) {
      DialerLeadCall_Cost_CostPart.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.total !== undefined) {
      writer.uint32(25).double(message.total);
    }
    if (message.status !== undefined) {
      writer.uint32(34).string(message.status);
    }
    if (message.isConferenceCost !== undefined) {
      writer.uint32(40).bool(message.isConferenceCost);
    }
    if (message.caller !== undefined) {
      DialerClientState_ClientStateTarget.encode(message.caller, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerLeadCall_Cost {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerLeadCall_Cost();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.billedDurationSecs = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.costParts.push(DialerLeadCall_Cost_CostPart.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.total = reader.double();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.status = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.isConferenceCost = reader.bool();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.caller = DialerClientState_ClientStateTarget.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerLeadCall_Cost {
    return {
      billedDurationSecs: isSet(object.billedDurationSecs) ? Number(object.billedDurationSecs) : undefined,
      costParts: Array.isArray(object?.costParts)
        ? object.costParts.map((e: any) => DialerLeadCall_Cost_CostPart.fromJSON(e))
        : [],
      total: isSet(object.total) ? Number(object.total) : undefined,
      status: isSet(object.status) ? String(object.status) : undefined,
      isConferenceCost: isSet(object.isConferenceCost) ? Boolean(object.isConferenceCost) : undefined,
      caller: isSet(object.caller) ? DialerClientState_ClientStateTarget.fromJSON(object.caller) : undefined,
    };
  },

  toJSON(message: DialerLeadCall_Cost): unknown {
    const obj: any = {};
    message.billedDurationSecs !== undefined && (obj.billedDurationSecs = Math.round(message.billedDurationSecs));
    if (message.costParts) {
      obj.costParts = message.costParts.map((e) => e ? DialerLeadCall_Cost_CostPart.toJSON(e) : undefined);
    } else {
      obj.costParts = [];
    }
    message.total !== undefined && (obj.total = message.total);
    message.status !== undefined && (obj.status = message.status);
    message.isConferenceCost !== undefined && (obj.isConferenceCost = message.isConferenceCost);
    message.caller !== undefined &&
      (obj.caller = message.caller ? DialerClientState_ClientStateTarget.toJSON(message.caller) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerLeadCall_Cost>, I>>(base?: I): DialerLeadCall_Cost {
    return DialerLeadCall_Cost.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerLeadCall_Cost>, I>>(object: I): DialerLeadCall_Cost {
    const message = createBaseDialerLeadCall_Cost();
    message.billedDurationSecs = object.billedDurationSecs ?? undefined;
    message.costParts = object.costParts?.map((e) => DialerLeadCall_Cost_CostPart.fromPartial(e)) || [];
    message.total = object.total ?? undefined;
    message.status = object.status ?? undefined;
    message.isConferenceCost = object.isConferenceCost ?? undefined;
    message.caller = (object.caller !== undefined && object.caller !== null)
      ? DialerClientState_ClientStateTarget.fromPartial(object.caller)
      : undefined;
    return message;
  },
};

function createBaseDialerLeadCall_Cost_CostPart(): DialerLeadCall_Cost_CostPart {
  return { billedDurationSecs: undefined, callPart: undefined, cost: undefined, currency: undefined, rate: undefined };
}

export const DialerLeadCall_Cost_CostPart = {
  encode(message: DialerLeadCall_Cost_CostPart, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.billedDurationSecs !== undefined) {
      writer.uint32(8).int32(message.billedDurationSecs);
    }
    if (message.callPart !== undefined) {
      writer.uint32(18).string(message.callPart);
    }
    if (message.cost !== undefined) {
      writer.uint32(25).double(message.cost);
    }
    if (message.currency !== undefined) {
      writer.uint32(34).string(message.currency);
    }
    if (message.rate !== undefined) {
      writer.uint32(41).double(message.rate);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerLeadCall_Cost_CostPart {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerLeadCall_Cost_CostPart();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.billedDurationSecs = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.callPart = reader.string();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.cost = reader.double();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.currency = reader.string();
          continue;
        case 5:
          if (tag !== 41) {
            break;
          }

          message.rate = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerLeadCall_Cost_CostPart {
    return {
      billedDurationSecs: isSet(object.billedDurationSecs) ? Number(object.billedDurationSecs) : undefined,
      callPart: isSet(object.callPart) ? String(object.callPart) : undefined,
      cost: isSet(object.cost) ? Number(object.cost) : undefined,
      currency: isSet(object.currency) ? String(object.currency) : undefined,
      rate: isSet(object.rate) ? Number(object.rate) : undefined,
    };
  },

  toJSON(message: DialerLeadCall_Cost_CostPart): unknown {
    const obj: any = {};
    message.billedDurationSecs !== undefined && (obj.billedDurationSecs = Math.round(message.billedDurationSecs));
    message.callPart !== undefined && (obj.callPart = message.callPart);
    message.cost !== undefined && (obj.cost = message.cost);
    message.currency !== undefined && (obj.currency = message.currency);
    message.rate !== undefined && (obj.rate = message.rate);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerLeadCall_Cost_CostPart>, I>>(base?: I): DialerLeadCall_Cost_CostPart {
    return DialerLeadCall_Cost_CostPart.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerLeadCall_Cost_CostPart>, I>>(object: I): DialerLeadCall_Cost_CostPart {
    const message = createBaseDialerLeadCall_Cost_CostPart();
    message.billedDurationSecs = object.billedDurationSecs ?? undefined;
    message.callPart = object.callPart ?? undefined;
    message.cost = object.cost ?? undefined;
    message.currency = object.currency ?? undefined;
    message.rate = object.rate ?? undefined;
    return message;
  },
};

function createBaseDialerLeadCall_Participant(): DialerLeadCall_Participant {
  return {
    role: undefined,
    calledTime: undefined,
    joinedTime: undefined,
    leftTime: undefined,
    declinedTime: undefined,
    firstName: undefined,
    lastName: undefined,
    profileImage: undefined,
    type: undefined,
    callControlId: undefined,
  };
}

export const DialerLeadCall_Participant = {
  encode(message: DialerLeadCall_Participant, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.role !== undefined) {
      writer.uint32(10).string(message.role);
    }
    if (message.calledTime !== undefined) {
      writer.uint32(16).int64(message.calledTime);
    }
    if (message.joinedTime !== undefined) {
      writer.uint32(24).int64(message.joinedTime);
    }
    if (message.leftTime !== undefined) {
      writer.uint32(32).int64(message.leftTime);
    }
    if (message.declinedTime !== undefined) {
      writer.uint32(40).int64(message.declinedTime);
    }
    if (message.firstName !== undefined) {
      writer.uint32(50).string(message.firstName);
    }
    if (message.lastName !== undefined) {
      writer.uint32(58).string(message.lastName);
    }
    if (message.profileImage !== undefined) {
      writer.uint32(66).string(message.profileImage);
    }
    if (message.type !== undefined) {
      writer.uint32(72).int32(message.type);
    }
    if (message.callControlId !== undefined) {
      writer.uint32(82).string(message.callControlId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerLeadCall_Participant {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerLeadCall_Participant();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.role = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.calledTime = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.joinedTime = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.leftTime = longToNumber(reader.int64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.declinedTime = longToNumber(reader.int64() as Long);
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.lastName = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.profileImage = reader.string();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.callControlId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerLeadCall_Participant {
    return {
      role: isSet(object.role) ? String(object.role) : undefined,
      calledTime: isSet(object.calledTime) ? Number(object.calledTime) : undefined,
      joinedTime: isSet(object.joinedTime) ? Number(object.joinedTime) : undefined,
      leftTime: isSet(object.leftTime) ? Number(object.leftTime) : undefined,
      declinedTime: isSet(object.declinedTime) ? Number(object.declinedTime) : undefined,
      firstName: isSet(object.firstName) ? String(object.firstName) : undefined,
      lastName: isSet(object.lastName) ? String(object.lastName) : undefined,
      profileImage: isSet(object.profileImage) ? String(object.profileImage) : undefined,
      type: isSet(object.type) ? dialerClientState_ClientStateTarget_TargetTypeFromJSON(object.type) : undefined,
      callControlId: isSet(object.callControlId) ? String(object.callControlId) : undefined,
    };
  },

  toJSON(message: DialerLeadCall_Participant): unknown {
    const obj: any = {};
    message.role !== undefined && (obj.role = message.role);
    message.calledTime !== undefined && (obj.calledTime = Math.round(message.calledTime));
    message.joinedTime !== undefined && (obj.joinedTime = Math.round(message.joinedTime));
    message.leftTime !== undefined && (obj.leftTime = Math.round(message.leftTime));
    message.declinedTime !== undefined && (obj.declinedTime = Math.round(message.declinedTime));
    message.firstName !== undefined && (obj.firstName = message.firstName);
    message.lastName !== undefined && (obj.lastName = message.lastName);
    message.profileImage !== undefined && (obj.profileImage = message.profileImage);
    message.type !== undefined && (obj.type = message.type !== undefined
      ? dialerClientState_ClientStateTarget_TargetTypeToJSON(message.type)
      : undefined);
    message.callControlId !== undefined && (obj.callControlId = message.callControlId);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerLeadCall_Participant>, I>>(base?: I): DialerLeadCall_Participant {
    return DialerLeadCall_Participant.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerLeadCall_Participant>, I>>(object: I): DialerLeadCall_Participant {
    const message = createBaseDialerLeadCall_Participant();
    message.role = object.role ?? undefined;
    message.calledTime = object.calledTime ?? undefined;
    message.joinedTime = object.joinedTime ?? undefined;
    message.leftTime = object.leftTime ?? undefined;
    message.declinedTime = object.declinedTime ?? undefined;
    message.firstName = object.firstName ?? undefined;
    message.lastName = object.lastName ?? undefined;
    message.profileImage = object.profileImage ?? undefined;
    message.type = object.type ?? undefined;
    message.callControlId = object.callControlId ?? undefined;
    return message;
  },
};

function createBaseDialerLeadCall_OldParticipant(): DialerLeadCall_OldParticipant {
  return { role: undefined, active: undefined, callOrder: undefined };
}

export const DialerLeadCall_OldParticipant = {
  encode(message: DialerLeadCall_OldParticipant, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.role !== undefined) {
      writer.uint32(10).string(message.role);
    }
    if (message.active !== undefined) {
      writer.uint32(16).bool(message.active);
    }
    if (message.callOrder !== undefined) {
      writer.uint32(24).int32(message.callOrder);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerLeadCall_OldParticipant {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerLeadCall_OldParticipant();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.role = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.active = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.callOrder = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerLeadCall_OldParticipant {
    return {
      role: isSet(object.role) ? String(object.role) : undefined,
      active: isSet(object.active) ? Boolean(object.active) : undefined,
      callOrder: isSet(object.callOrder) ? Number(object.callOrder) : undefined,
    };
  },

  toJSON(message: DialerLeadCall_OldParticipant): unknown {
    const obj: any = {};
    message.role !== undefined && (obj.role = message.role);
    message.active !== undefined && (obj.active = message.active);
    message.callOrder !== undefined && (obj.callOrder = Math.round(message.callOrder));
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerLeadCall_OldParticipant>, I>>(base?: I): DialerLeadCall_OldParticipant {
    return DialerLeadCall_OldParticipant.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerLeadCall_OldParticipant>, I>>(
    object: I,
  ): DialerLeadCall_OldParticipant {
    const message = createBaseDialerLeadCall_OldParticipant();
    message.role = object.role ?? undefined;
    message.active = object.active ?? undefined;
    message.callOrder = object.callOrder ?? undefined;
    return message;
  },
};

function createBaseDialerLeadCall_TelnyxLog(): DialerLeadCall_TelnyxLog {
  return {
    id: undefined,
    to: undefined,
    eventType: undefined,
    recordType: undefined,
    direction: undefined,
    occurredAt: undefined,
    payload: undefined,
  };
}

export const DialerLeadCall_TelnyxLog = {
  encode(message: DialerLeadCall_TelnyxLog, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.to !== undefined) {
      writer.uint32(18).string(message.to);
    }
    if (message.eventType !== undefined) {
      writer.uint32(26).string(message.eventType);
    }
    if (message.recordType !== undefined) {
      writer.uint32(34).string(message.recordType);
    }
    if (message.direction !== undefined) {
      writer.uint32(42).string(message.direction);
    }
    if (message.occurredAt !== undefined) {
      writer.uint32(50).string(message.occurredAt);
    }
    if (message.payload !== undefined) {
      Struct.encode(Struct.wrap(message.payload), writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerLeadCall_TelnyxLog {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerLeadCall_TelnyxLog();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.to = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.eventType = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.recordType = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.direction = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.occurredAt = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.payload = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerLeadCall_TelnyxLog {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      to: isSet(object.to) ? String(object.to) : undefined,
      eventType: isSet(object.eventType) ? String(object.eventType) : undefined,
      recordType: isSet(object.recordType) ? String(object.recordType) : undefined,
      direction: isSet(object.direction) ? String(object.direction) : undefined,
      occurredAt: isSet(object.occurredAt) ? String(object.occurredAt) : undefined,
      payload: isObject(object.payload) ? object.payload : undefined,
    };
  },

  toJSON(message: DialerLeadCall_TelnyxLog): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.to !== undefined && (obj.to = message.to);
    message.eventType !== undefined && (obj.eventType = message.eventType);
    message.recordType !== undefined && (obj.recordType = message.recordType);
    message.direction !== undefined && (obj.direction = message.direction);
    message.occurredAt !== undefined && (obj.occurredAt = message.occurredAt);
    message.payload !== undefined && (obj.payload = message.payload);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerLeadCall_TelnyxLog>, I>>(base?: I): DialerLeadCall_TelnyxLog {
    return DialerLeadCall_TelnyxLog.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerLeadCall_TelnyxLog>, I>>(object: I): DialerLeadCall_TelnyxLog {
    const message = createBaseDialerLeadCall_TelnyxLog();
    message.id = object.id ?? undefined;
    message.to = object.to ?? undefined;
    message.eventType = object.eventType ?? undefined;
    message.recordType = object.recordType ?? undefined;
    message.direction = object.direction ?? undefined;
    message.occurredAt = object.occurredAt ?? undefined;
    message.payload = object.payload ?? undefined;
    return message;
  },
};

function createBaseDialerLeadCall_TelnyxPayload(): DialerLeadCall_TelnyxPayload {
  return {
    callControlId: undefined,
    callLegId: undefined,
    callSessionId: undefined,
    clientState: undefined,
    connectionId: undefined,
    direction: undefined,
    from: undefined,
    state: undefined,
    to: undefined,
  };
}

export const DialerLeadCall_TelnyxPayload = {
  encode(message: DialerLeadCall_TelnyxPayload, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.callControlId !== undefined) {
      writer.uint32(10).string(message.callControlId);
    }
    if (message.callLegId !== undefined) {
      writer.uint32(18).string(message.callLegId);
    }
    if (message.callSessionId !== undefined) {
      writer.uint32(26).string(message.callSessionId);
    }
    if (message.clientState !== undefined) {
      writer.uint32(34).string(message.clientState);
    }
    if (message.connectionId !== undefined) {
      writer.uint32(42).string(message.connectionId);
    }
    if (message.direction !== undefined) {
      writer.uint32(50).string(message.direction);
    }
    if (message.from !== undefined) {
      writer.uint32(58).string(message.from);
    }
    if (message.state !== undefined) {
      writer.uint32(66).string(message.state);
    }
    if (message.to !== undefined) {
      writer.uint32(74).string(message.to);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerLeadCall_TelnyxPayload {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerLeadCall_TelnyxPayload();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.callControlId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.callLegId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.callSessionId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.clientState = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.connectionId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.direction = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.from = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.state = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.to = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerLeadCall_TelnyxPayload {
    return {
      callControlId: isSet(object.callControlId) ? String(object.callControlId) : undefined,
      callLegId: isSet(object.callLegId) ? String(object.callLegId) : undefined,
      callSessionId: isSet(object.callSessionId) ? String(object.callSessionId) : undefined,
      clientState: isSet(object.clientState) ? String(object.clientState) : undefined,
      connectionId: isSet(object.connectionId) ? String(object.connectionId) : undefined,
      direction: isSet(object.direction) ? String(object.direction) : undefined,
      from: isSet(object.from) ? String(object.from) : undefined,
      state: isSet(object.state) ? String(object.state) : undefined,
      to: isSet(object.to) ? String(object.to) : undefined,
    };
  },

  toJSON(message: DialerLeadCall_TelnyxPayload): unknown {
    const obj: any = {};
    message.callControlId !== undefined && (obj.callControlId = message.callControlId);
    message.callLegId !== undefined && (obj.callLegId = message.callLegId);
    message.callSessionId !== undefined && (obj.callSessionId = message.callSessionId);
    message.clientState !== undefined && (obj.clientState = message.clientState);
    message.connectionId !== undefined && (obj.connectionId = message.connectionId);
    message.direction !== undefined && (obj.direction = message.direction);
    message.from !== undefined && (obj.from = message.from);
    message.state !== undefined && (obj.state = message.state);
    message.to !== undefined && (obj.to = message.to);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerLeadCall_TelnyxPayload>, I>>(base?: I): DialerLeadCall_TelnyxPayload {
    return DialerLeadCall_TelnyxPayload.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerLeadCall_TelnyxPayload>, I>>(object: I): DialerLeadCall_TelnyxPayload {
    const message = createBaseDialerLeadCall_TelnyxPayload();
    message.callControlId = object.callControlId ?? undefined;
    message.callLegId = object.callLegId ?? undefined;
    message.callSessionId = object.callSessionId ?? undefined;
    message.clientState = object.clientState ?? undefined;
    message.connectionId = object.connectionId ?? undefined;
    message.direction = object.direction ?? undefined;
    message.from = object.from ?? undefined;
    message.state = object.state ?? undefined;
    message.to = object.to ?? undefined;
    return message;
  },
};

function createBaseDialerCampaign(): DialerCampaign {
  return {
    id: undefined,
    name: undefined,
    description: undefined,
    type: undefined,
    callerId: undefined,
    blindTransferCallerId: undefined,
    customCallerId: undefined,
    status: undefined,
    dialSettings: undefined,
    advancedDialSettings: undefined,
    agentSettings: undefined,
    holdSettings: undefined,
    callAnalysis: undefined,
    apiLeadCreationSettings: undefined,
    leadCreationSettings: undefined,
    leadCallingOrder: undefined,
    schedule: undefined,
    statistics: undefined,
    leadLists: undefined,
    allowAllMarketingAgents: undefined,
    marketingAgents: {},
    numbers: {},
    agencyId: undefined,
    scriptCallLaunch: undefined,
    dispoStatusCallLaunch: undefined,
  };
}

export const DialerCampaign = {
  encode(message: DialerCampaign, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== undefined) {
      writer.uint32(26).string(message.description);
    }
    if (message.type !== undefined) {
      writer.uint32(34).string(message.type);
    }
    if (message.callerId !== undefined) {
      writer.uint32(42).string(message.callerId);
    }
    if (message.blindTransferCallerId !== undefined) {
      writer.uint32(50).string(message.blindTransferCallerId);
    }
    if (message.customCallerId !== undefined) {
      writer.uint32(58).string(message.customCallerId);
    }
    if (message.status !== undefined) {
      writer.uint32(64).bool(message.status);
    }
    if (message.dialSettings !== undefined) {
      DialerCampaign_DialSettings.encode(message.dialSettings, writer.uint32(74).fork()).ldelim();
    }
    if (message.advancedDialSettings !== undefined) {
      DialerCampaign_AdvancedDialSettings.encode(message.advancedDialSettings, writer.uint32(82).fork()).ldelim();
    }
    if (message.agentSettings !== undefined) {
      DialerCampaign_AgentSettings.encode(message.agentSettings, writer.uint32(90).fork()).ldelim();
    }
    if (message.holdSettings !== undefined) {
      DialerCampaign_HoldSettings.encode(message.holdSettings, writer.uint32(98).fork()).ldelim();
    }
    if (message.callAnalysis !== undefined) {
      DialerCampaign_CallAnalysis.encode(message.callAnalysis, writer.uint32(106).fork()).ldelim();
    }
    if (message.apiLeadCreationSettings !== undefined) {
      DialerCampaign_ApiLeadCreationSettings.encode(message.apiLeadCreationSettings, writer.uint32(114).fork())
        .ldelim();
    }
    if (message.leadCreationSettings !== undefined) {
      DialerCampaign_LeadCreationSettings.encode(message.leadCreationSettings, writer.uint32(122).fork()).ldelim();
    }
    if (message.leadCallingOrder !== undefined) {
      DialerCampaign_LeadCallingOrder.encode(message.leadCallingOrder, writer.uint32(130).fork()).ldelim();
    }
    if (message.schedule !== undefined) {
      DialerSchedule.encode(message.schedule, writer.uint32(138).fork()).ldelim();
    }
    if (message.statistics !== undefined) {
      DialerCampaign_Statistics.encode(message.statistics, writer.uint32(146).fork()).ldelim();
    }
    if (message.leadLists !== undefined) {
      DialerCampaign_LeadListContainer.encode(message.leadLists, writer.uint32(154).fork()).ldelim();
    }
    if (message.allowAllMarketingAgents !== undefined) {
      writer.uint32(160).bool(message.allowAllMarketingAgents);
    }
    Object.entries(message.marketingAgents).forEach(([key, value]) => {
      DialerCampaign_MarketingAgentsEntry.encode({ key: key as any, value }, writer.uint32(170).fork()).ldelim();
    });
    Object.entries(message.numbers).forEach(([key, value]) => {
      DialerCampaign_NumbersEntry.encode({ key: key as any, value }, writer.uint32(178).fork()).ldelim();
    });
    if (message.agencyId !== undefined) {
      writer.uint32(186).string(message.agencyId);
    }
    if (message.scriptCallLaunch !== undefined) {
      ScriptCallLaunch.encode(message.scriptCallLaunch, writer.uint32(194).fork()).ldelim();
    }
    if (message.dispoStatusCallLaunch !== undefined) {
      DialerDispoStatusCallLaunch.encode(message.dispoStatusCallLaunch, writer.uint32(202).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerCampaign {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerCampaign();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.type = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.callerId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.blindTransferCallerId = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.customCallerId = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.status = reader.bool();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.dialSettings = DialerCampaign_DialSettings.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.advancedDialSettings = DialerCampaign_AdvancedDialSettings.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.agentSettings = DialerCampaign_AgentSettings.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.holdSettings = DialerCampaign_HoldSettings.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.callAnalysis = DialerCampaign_CallAnalysis.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.apiLeadCreationSettings = DialerCampaign_ApiLeadCreationSettings.decode(reader, reader.uint32());
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.leadCreationSettings = DialerCampaign_LeadCreationSettings.decode(reader, reader.uint32());
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.leadCallingOrder = DialerCampaign_LeadCallingOrder.decode(reader, reader.uint32());
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.schedule = DialerSchedule.decode(reader, reader.uint32());
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.statistics = DialerCampaign_Statistics.decode(reader, reader.uint32());
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.leadLists = DialerCampaign_LeadListContainer.decode(reader, reader.uint32());
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.allowAllMarketingAgents = reader.bool();
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          const entry21 = DialerCampaign_MarketingAgentsEntry.decode(reader, reader.uint32());
          if (entry21.value !== undefined) {
            message.marketingAgents[entry21.key] = entry21.value;
          }
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          const entry22 = DialerCampaign_NumbersEntry.decode(reader, reader.uint32());
          if (entry22.value !== undefined) {
            message.numbers[entry22.key] = entry22.value;
          }
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.agencyId = reader.string();
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.scriptCallLaunch = ScriptCallLaunch.decode(reader, reader.uint32());
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.dispoStatusCallLaunch = DialerDispoStatusCallLaunch.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerCampaign {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      description: isSet(object.description) ? String(object.description) : undefined,
      type: isSet(object.type) ? String(object.type) : undefined,
      callerId: isSet(object.callerId) ? String(object.callerId) : undefined,
      blindTransferCallerId: isSet(object.blindTransferCallerId) ? String(object.blindTransferCallerId) : undefined,
      customCallerId: isSet(object.customCallerId) ? String(object.customCallerId) : undefined,
      status: isSet(object.status) ? Boolean(object.status) : undefined,
      dialSettings: isSet(object.dialSettings) ? DialerCampaign_DialSettings.fromJSON(object.dialSettings) : undefined,
      advancedDialSettings: isSet(object.advancedDialSettings)
        ? DialerCampaign_AdvancedDialSettings.fromJSON(object.advancedDialSettings)
        : undefined,
      agentSettings: isSet(object.agentSettings)
        ? DialerCampaign_AgentSettings.fromJSON(object.agentSettings)
        : undefined,
      holdSettings: isSet(object.holdSettings) ? DialerCampaign_HoldSettings.fromJSON(object.holdSettings) : undefined,
      callAnalysis: isSet(object.callAnalysis) ? DialerCampaign_CallAnalysis.fromJSON(object.callAnalysis) : undefined,
      apiLeadCreationSettings: isSet(object.apiLeadCreationSettings)
        ? DialerCampaign_ApiLeadCreationSettings.fromJSON(object.apiLeadCreationSettings)
        : undefined,
      leadCreationSettings: isSet(object.leadCreationSettings)
        ? DialerCampaign_LeadCreationSettings.fromJSON(object.leadCreationSettings)
        : undefined,
      leadCallingOrder: isSet(object.leadCallingOrder)
        ? DialerCampaign_LeadCallingOrder.fromJSON(object.leadCallingOrder)
        : undefined,
      schedule: isSet(object.schedule) ? DialerSchedule.fromJSON(object.schedule) : undefined,
      statistics: isSet(object.statistics) ? DialerCampaign_Statistics.fromJSON(object.statistics) : undefined,
      leadLists: isSet(object.leadLists) ? DialerCampaign_LeadListContainer.fromJSON(object.leadLists) : undefined,
      allowAllMarketingAgents: isSet(object.allowAllMarketingAgents)
        ? Boolean(object.allowAllMarketingAgents)
        : undefined,
      marketingAgents: isObject(object.marketingAgents)
        ? Object.entries(object.marketingAgents).reduce<{ [key: string]: User }>((acc, [key, value]) => {
          acc[key] = User.fromJSON(value);
          return acc;
        }, {})
        : {},
      numbers: isObject(object.numbers)
        ? Object.entries(object.numbers).reduce<{ [key: string]: DialerNumber }>((acc, [key, value]) => {
          acc[key] = DialerNumber.fromJSON(value);
          return acc;
        }, {})
        : {},
      agencyId: isSet(object.agencyId) ? String(object.agencyId) : undefined,
      scriptCallLaunch: isSet(object.scriptCallLaunch) ? ScriptCallLaunch.fromJSON(object.scriptCallLaunch) : undefined,
      dispoStatusCallLaunch: isSet(object.dispoStatusCallLaunch)
        ? DialerDispoStatusCallLaunch.fromJSON(object.dispoStatusCallLaunch)
        : undefined,
    };
  },

  toJSON(message: DialerCampaign): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.description !== undefined && (obj.description = message.description);
    message.type !== undefined && (obj.type = message.type);
    message.callerId !== undefined && (obj.callerId = message.callerId);
    message.blindTransferCallerId !== undefined && (obj.blindTransferCallerId = message.blindTransferCallerId);
    message.customCallerId !== undefined && (obj.customCallerId = message.customCallerId);
    message.status !== undefined && (obj.status = message.status);
    message.dialSettings !== undefined &&
      (obj.dialSettings = message.dialSettings ? DialerCampaign_DialSettings.toJSON(message.dialSettings) : undefined);
    message.advancedDialSettings !== undefined && (obj.advancedDialSettings = message.advancedDialSettings
      ? DialerCampaign_AdvancedDialSettings.toJSON(message.advancedDialSettings)
      : undefined);
    message.agentSettings !== undefined && (obj.agentSettings = message.agentSettings
      ? DialerCampaign_AgentSettings.toJSON(message.agentSettings)
      : undefined);
    message.holdSettings !== undefined &&
      (obj.holdSettings = message.holdSettings ? DialerCampaign_HoldSettings.toJSON(message.holdSettings) : undefined);
    message.callAnalysis !== undefined &&
      (obj.callAnalysis = message.callAnalysis ? DialerCampaign_CallAnalysis.toJSON(message.callAnalysis) : undefined);
    message.apiLeadCreationSettings !== undefined && (obj.apiLeadCreationSettings = message.apiLeadCreationSettings
      ? DialerCampaign_ApiLeadCreationSettings.toJSON(message.apiLeadCreationSettings)
      : undefined);
    message.leadCreationSettings !== undefined && (obj.leadCreationSettings = message.leadCreationSettings
      ? DialerCampaign_LeadCreationSettings.toJSON(message.leadCreationSettings)
      : undefined);
    message.leadCallingOrder !== undefined && (obj.leadCallingOrder = message.leadCallingOrder
      ? DialerCampaign_LeadCallingOrder.toJSON(message.leadCallingOrder)
      : undefined);
    message.schedule !== undefined &&
      (obj.schedule = message.schedule ? DialerSchedule.toJSON(message.schedule) : undefined);
    message.statistics !== undefined &&
      (obj.statistics = message.statistics ? DialerCampaign_Statistics.toJSON(message.statistics) : undefined);
    message.leadLists !== undefined &&
      (obj.leadLists = message.leadLists ? DialerCampaign_LeadListContainer.toJSON(message.leadLists) : undefined);
    message.allowAllMarketingAgents !== undefined && (obj.allowAllMarketingAgents = message.allowAllMarketingAgents);
    obj.marketingAgents = {};
    if (message.marketingAgents) {
      Object.entries(message.marketingAgents).forEach(([k, v]) => {
        obj.marketingAgents[k] = User.toJSON(v);
      });
    }
    obj.numbers = {};
    if (message.numbers) {
      Object.entries(message.numbers).forEach(([k, v]) => {
        obj.numbers[k] = DialerNumber.toJSON(v);
      });
    }
    message.agencyId !== undefined && (obj.agencyId = message.agencyId);
    message.scriptCallLaunch !== undefined &&
      (obj.scriptCallLaunch = message.scriptCallLaunch ? ScriptCallLaunch.toJSON(message.scriptCallLaunch) : undefined);
    message.dispoStatusCallLaunch !== undefined && (obj.dispoStatusCallLaunch = message.dispoStatusCallLaunch
      ? DialerDispoStatusCallLaunch.toJSON(message.dispoStatusCallLaunch)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerCampaign>, I>>(base?: I): DialerCampaign {
    return DialerCampaign.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerCampaign>, I>>(object: I): DialerCampaign {
    const message = createBaseDialerCampaign();
    message.id = object.id ?? undefined;
    message.name = object.name ?? undefined;
    message.description = object.description ?? undefined;
    message.type = object.type ?? undefined;
    message.callerId = object.callerId ?? undefined;
    message.blindTransferCallerId = object.blindTransferCallerId ?? undefined;
    message.customCallerId = object.customCallerId ?? undefined;
    message.status = object.status ?? undefined;
    message.dialSettings = (object.dialSettings !== undefined && object.dialSettings !== null)
      ? DialerCampaign_DialSettings.fromPartial(object.dialSettings)
      : undefined;
    message.advancedDialSettings = (object.advancedDialSettings !== undefined && object.advancedDialSettings !== null)
      ? DialerCampaign_AdvancedDialSettings.fromPartial(object.advancedDialSettings)
      : undefined;
    message.agentSettings = (object.agentSettings !== undefined && object.agentSettings !== null)
      ? DialerCampaign_AgentSettings.fromPartial(object.agentSettings)
      : undefined;
    message.holdSettings = (object.holdSettings !== undefined && object.holdSettings !== null)
      ? DialerCampaign_HoldSettings.fromPartial(object.holdSettings)
      : undefined;
    message.callAnalysis = (object.callAnalysis !== undefined && object.callAnalysis !== null)
      ? DialerCampaign_CallAnalysis.fromPartial(object.callAnalysis)
      : undefined;
    message.apiLeadCreationSettings =
      (object.apiLeadCreationSettings !== undefined && object.apiLeadCreationSettings !== null)
        ? DialerCampaign_ApiLeadCreationSettings.fromPartial(object.apiLeadCreationSettings)
        : undefined;
    message.leadCreationSettings = (object.leadCreationSettings !== undefined && object.leadCreationSettings !== null)
      ? DialerCampaign_LeadCreationSettings.fromPartial(object.leadCreationSettings)
      : undefined;
    message.leadCallingOrder = (object.leadCallingOrder !== undefined && object.leadCallingOrder !== null)
      ? DialerCampaign_LeadCallingOrder.fromPartial(object.leadCallingOrder)
      : undefined;
    message.schedule = (object.schedule !== undefined && object.schedule !== null)
      ? DialerSchedule.fromPartial(object.schedule)
      : undefined;
    message.statistics = (object.statistics !== undefined && object.statistics !== null)
      ? DialerCampaign_Statistics.fromPartial(object.statistics)
      : undefined;
    message.leadLists = (object.leadLists !== undefined && object.leadLists !== null)
      ? DialerCampaign_LeadListContainer.fromPartial(object.leadLists)
      : undefined;
    message.allowAllMarketingAgents = object.allowAllMarketingAgents ?? undefined;
    message.marketingAgents = Object.entries(object.marketingAgents ?? {}).reduce<{ [key: string]: User }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = User.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    message.numbers = Object.entries(object.numbers ?? {}).reduce<{ [key: string]: DialerNumber }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = DialerNumber.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    message.agencyId = object.agencyId ?? undefined;
    message.scriptCallLaunch = (object.scriptCallLaunch !== undefined && object.scriptCallLaunch !== null)
      ? ScriptCallLaunch.fromPartial(object.scriptCallLaunch)
      : undefined;
    message.dispoStatusCallLaunch =
      (object.dispoStatusCallLaunch !== undefined && object.dispoStatusCallLaunch !== null)
        ? DialerDispoStatusCallLaunch.fromPartial(object.dispoStatusCallLaunch)
        : undefined;
    return message;
  },
};

function createBaseDialerCampaign_MarketingAgentsEntry(): DialerCampaign_MarketingAgentsEntry {
  return { key: "", value: undefined };
}

export const DialerCampaign_MarketingAgentsEntry = {
  encode(message: DialerCampaign_MarketingAgentsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      User.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerCampaign_MarketingAgentsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerCampaign_MarketingAgentsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = User.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerCampaign_MarketingAgentsEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? User.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: DialerCampaign_MarketingAgentsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value ? User.toJSON(message.value) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerCampaign_MarketingAgentsEntry>, I>>(
    base?: I,
  ): DialerCampaign_MarketingAgentsEntry {
    return DialerCampaign_MarketingAgentsEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerCampaign_MarketingAgentsEntry>, I>>(
    object: I,
  ): DialerCampaign_MarketingAgentsEntry {
    const message = createBaseDialerCampaign_MarketingAgentsEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null) ? User.fromPartial(object.value) : undefined;
    return message;
  },
};

function createBaseDialerCampaign_NumbersEntry(): DialerCampaign_NumbersEntry {
  return { key: "", value: undefined };
}

export const DialerCampaign_NumbersEntry = {
  encode(message: DialerCampaign_NumbersEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      DialerNumber.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerCampaign_NumbersEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerCampaign_NumbersEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = DialerNumber.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerCampaign_NumbersEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? DialerNumber.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: DialerCampaign_NumbersEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value ? DialerNumber.toJSON(message.value) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerCampaign_NumbersEntry>, I>>(base?: I): DialerCampaign_NumbersEntry {
    return DialerCampaign_NumbersEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerCampaign_NumbersEntry>, I>>(object: I): DialerCampaign_NumbersEntry {
    const message = createBaseDialerCampaign_NumbersEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? DialerNumber.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseDialerCampaign_DialSettings(): DialerCampaign_DialSettings {
  return {
    dialMethod: undefined,
    autoDialTimeout: undefined,
    manualDialTimeout: undefined,
    dialLevel: undefined,
    placeCallsForAgent: undefined,
    dropLockoutTime: undefined,
    softLimitTimeBeforeDial: undefined,
    hardLimitTimeBeforeDial: undefined,
    softLimitNumberOfDialsByHour: undefined,
    hardLimitNumberOfDialsByHour: undefined,
    softLimitNumberOfDialsByDay: undefined,
    hardLimitNumberOfDialsByDay: undefined,
    softLimitNumberOfDialsByWeek: undefined,
    hardLimitNumberOfDialsByWeek: undefined,
    softLimitNumberOfDialsByMonth: undefined,
    hardLimitNumberOfDialsByMonth: undefined,
    linesPerAgent: undefined,
    maxAgentsReady: undefined,
    maxNumberOfLines: undefined,
  };
}

export const DialerCampaign_DialSettings = {
  encode(message: DialerCampaign_DialSettings, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.dialMethod !== undefined) {
      writer.uint32(8).int32(message.dialMethod);
    }
    if (message.autoDialTimeout !== undefined) {
      writer.uint32(16).int32(message.autoDialTimeout);
    }
    if (message.manualDialTimeout !== undefined) {
      writer.uint32(24).int32(message.manualDialTimeout);
    }
    if (message.dialLevel !== undefined) {
      writer.uint32(32).int32(message.dialLevel);
    }
    if (message.placeCallsForAgent !== undefined) {
      writer.uint32(40).int32(message.placeCallsForAgent);
    }
    if (message.dropLockoutTime !== undefined) {
      writer.uint32(48).bool(message.dropLockoutTime);
    }
    if (message.softLimitTimeBeforeDial !== undefined) {
      writer.uint32(57).double(message.softLimitTimeBeforeDial);
    }
    if (message.hardLimitTimeBeforeDial !== undefined) {
      writer.uint32(65).double(message.hardLimitTimeBeforeDial);
    }
    if (message.softLimitNumberOfDialsByHour !== undefined) {
      writer.uint32(73).double(message.softLimitNumberOfDialsByHour);
    }
    if (message.hardLimitNumberOfDialsByHour !== undefined) {
      writer.uint32(81).double(message.hardLimitNumberOfDialsByHour);
    }
    if (message.softLimitNumberOfDialsByDay !== undefined) {
      writer.uint32(89).double(message.softLimitNumberOfDialsByDay);
    }
    if (message.hardLimitNumberOfDialsByDay !== undefined) {
      writer.uint32(97).double(message.hardLimitNumberOfDialsByDay);
    }
    if (message.softLimitNumberOfDialsByWeek !== undefined) {
      writer.uint32(105).double(message.softLimitNumberOfDialsByWeek);
    }
    if (message.hardLimitNumberOfDialsByWeek !== undefined) {
      writer.uint32(113).double(message.hardLimitNumberOfDialsByWeek);
    }
    if (message.softLimitNumberOfDialsByMonth !== undefined) {
      writer.uint32(121).double(message.softLimitNumberOfDialsByMonth);
    }
    if (message.hardLimitNumberOfDialsByMonth !== undefined) {
      writer.uint32(129).double(message.hardLimitNumberOfDialsByMonth);
    }
    if (message.linesPerAgent !== undefined) {
      writer.uint32(137).double(message.linesPerAgent);
    }
    if (message.maxAgentsReady !== undefined) {
      writer.uint32(145).double(message.maxAgentsReady);
    }
    if (message.maxNumberOfLines !== undefined) {
      writer.uint32(153).double(message.maxNumberOfLines);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerCampaign_DialSettings {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerCampaign_DialSettings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.dialMethod = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.autoDialTimeout = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.manualDialTimeout = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.dialLevel = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.placeCallsForAgent = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.dropLockoutTime = reader.bool();
          continue;
        case 7:
          if (tag !== 57) {
            break;
          }

          message.softLimitTimeBeforeDial = reader.double();
          continue;
        case 8:
          if (tag !== 65) {
            break;
          }

          message.hardLimitTimeBeforeDial = reader.double();
          continue;
        case 9:
          if (tag !== 73) {
            break;
          }

          message.softLimitNumberOfDialsByHour = reader.double();
          continue;
        case 10:
          if (tag !== 81) {
            break;
          }

          message.hardLimitNumberOfDialsByHour = reader.double();
          continue;
        case 11:
          if (tag !== 89) {
            break;
          }

          message.softLimitNumberOfDialsByDay = reader.double();
          continue;
        case 12:
          if (tag !== 97) {
            break;
          }

          message.hardLimitNumberOfDialsByDay = reader.double();
          continue;
        case 13:
          if (tag !== 105) {
            break;
          }

          message.softLimitNumberOfDialsByWeek = reader.double();
          continue;
        case 14:
          if (tag !== 113) {
            break;
          }

          message.hardLimitNumberOfDialsByWeek = reader.double();
          continue;
        case 15:
          if (tag !== 121) {
            break;
          }

          message.softLimitNumberOfDialsByMonth = reader.double();
          continue;
        case 16:
          if (tag !== 129) {
            break;
          }

          message.hardLimitNumberOfDialsByMonth = reader.double();
          continue;
        case 17:
          if (tag !== 137) {
            break;
          }

          message.linesPerAgent = reader.double();
          continue;
        case 18:
          if (tag !== 145) {
            break;
          }

          message.maxAgentsReady = reader.double();
          continue;
        case 19:
          if (tag !== 153) {
            break;
          }

          message.maxNumberOfLines = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerCampaign_DialSettings {
    return {
      dialMethod: isSet(object.dialMethod) ? dialerCampaign_DialMethodFromJSON(object.dialMethod) : undefined,
      autoDialTimeout: isSet(object.autoDialTimeout)
        ? dialerCampaign_DialTimeoutFromJSON(object.autoDialTimeout)
        : undefined,
      manualDialTimeout: isSet(object.manualDialTimeout)
        ? dialerCampaign_DialTimeoutFromJSON(object.manualDialTimeout)
        : undefined,
      dialLevel: isSet(object.dialLevel) ? dialerCampaign_DialLevelFromJSON(object.dialLevel) : undefined,
      placeCallsForAgent: isSet(object.placeCallsForAgent)
        ? dialerCampaign_PlaceCallsOptionsFromJSON(object.placeCallsForAgent)
        : undefined,
      dropLockoutTime: isSet(object.dropLockoutTime) ? Boolean(object.dropLockoutTime) : undefined,
      softLimitTimeBeforeDial: isSet(object.softLimitTimeBeforeDial)
        ? Number(object.softLimitTimeBeforeDial)
        : undefined,
      hardLimitTimeBeforeDial: isSet(object.hardLimitTimeBeforeDial)
        ? Number(object.hardLimitTimeBeforeDial)
        : undefined,
      softLimitNumberOfDialsByHour: isSet(object.softLimitNumberOfDialsByHour)
        ? Number(object.softLimitNumberOfDialsByHour)
        : undefined,
      hardLimitNumberOfDialsByHour: isSet(object.hardLimitNumberOfDialsByHour)
        ? Number(object.hardLimitNumberOfDialsByHour)
        : undefined,
      softLimitNumberOfDialsByDay: isSet(object.softLimitNumberOfDialsByDay)
        ? Number(object.softLimitNumberOfDialsByDay)
        : undefined,
      hardLimitNumberOfDialsByDay: isSet(object.hardLimitNumberOfDialsByDay)
        ? Number(object.hardLimitNumberOfDialsByDay)
        : undefined,
      softLimitNumberOfDialsByWeek: isSet(object.softLimitNumberOfDialsByWeek)
        ? Number(object.softLimitNumberOfDialsByWeek)
        : undefined,
      hardLimitNumberOfDialsByWeek: isSet(object.hardLimitNumberOfDialsByWeek)
        ? Number(object.hardLimitNumberOfDialsByWeek)
        : undefined,
      softLimitNumberOfDialsByMonth: isSet(object.softLimitNumberOfDialsByMonth)
        ? Number(object.softLimitNumberOfDialsByMonth)
        : undefined,
      hardLimitNumberOfDialsByMonth: isSet(object.hardLimitNumberOfDialsByMonth)
        ? Number(object.hardLimitNumberOfDialsByMonth)
        : undefined,
      linesPerAgent: isSet(object.linesPerAgent) ? Number(object.linesPerAgent) : undefined,
      maxAgentsReady: isSet(object.maxAgentsReady) ? Number(object.maxAgentsReady) : undefined,
      maxNumberOfLines: isSet(object.maxNumberOfLines) ? Number(object.maxNumberOfLines) : undefined,
    };
  },

  toJSON(message: DialerCampaign_DialSettings): unknown {
    const obj: any = {};
    message.dialMethod !== undefined && (obj.dialMethod = message.dialMethod !== undefined
      ? dialerCampaign_DialMethodToJSON(message.dialMethod)
      : undefined);
    message.autoDialTimeout !== undefined && (obj.autoDialTimeout = message.autoDialTimeout !== undefined
      ? dialerCampaign_DialTimeoutToJSON(message.autoDialTimeout)
      : undefined);
    message.manualDialTimeout !== undefined && (obj.manualDialTimeout = message.manualDialTimeout !== undefined
      ? dialerCampaign_DialTimeoutToJSON(message.manualDialTimeout)
      : undefined);
    message.dialLevel !== undefined &&
      (obj.dialLevel = message.dialLevel !== undefined ? dialerCampaign_DialLevelToJSON(message.dialLevel) : undefined);
    message.placeCallsForAgent !== undefined && (obj.placeCallsForAgent = message.placeCallsForAgent !== undefined
      ? dialerCampaign_PlaceCallsOptionsToJSON(message.placeCallsForAgent)
      : undefined);
    message.dropLockoutTime !== undefined && (obj.dropLockoutTime = message.dropLockoutTime);
    message.softLimitTimeBeforeDial !== undefined && (obj.softLimitTimeBeforeDial = message.softLimitTimeBeforeDial);
    message.hardLimitTimeBeforeDial !== undefined && (obj.hardLimitTimeBeforeDial = message.hardLimitTimeBeforeDial);
    message.softLimitNumberOfDialsByHour !== undefined &&
      (obj.softLimitNumberOfDialsByHour = message.softLimitNumberOfDialsByHour);
    message.hardLimitNumberOfDialsByHour !== undefined &&
      (obj.hardLimitNumberOfDialsByHour = message.hardLimitNumberOfDialsByHour);
    message.softLimitNumberOfDialsByDay !== undefined &&
      (obj.softLimitNumberOfDialsByDay = message.softLimitNumberOfDialsByDay);
    message.hardLimitNumberOfDialsByDay !== undefined &&
      (obj.hardLimitNumberOfDialsByDay = message.hardLimitNumberOfDialsByDay);
    message.softLimitNumberOfDialsByWeek !== undefined &&
      (obj.softLimitNumberOfDialsByWeek = message.softLimitNumberOfDialsByWeek);
    message.hardLimitNumberOfDialsByWeek !== undefined &&
      (obj.hardLimitNumberOfDialsByWeek = message.hardLimitNumberOfDialsByWeek);
    message.softLimitNumberOfDialsByMonth !== undefined &&
      (obj.softLimitNumberOfDialsByMonth = message.softLimitNumberOfDialsByMonth);
    message.hardLimitNumberOfDialsByMonth !== undefined &&
      (obj.hardLimitNumberOfDialsByMonth = message.hardLimitNumberOfDialsByMonth);
    message.linesPerAgent !== undefined && (obj.linesPerAgent = message.linesPerAgent);
    message.maxAgentsReady !== undefined && (obj.maxAgentsReady = message.maxAgentsReady);
    message.maxNumberOfLines !== undefined && (obj.maxNumberOfLines = message.maxNumberOfLines);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerCampaign_DialSettings>, I>>(base?: I): DialerCampaign_DialSettings {
    return DialerCampaign_DialSettings.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerCampaign_DialSettings>, I>>(object: I): DialerCampaign_DialSettings {
    const message = createBaseDialerCampaign_DialSettings();
    message.dialMethod = object.dialMethod ?? undefined;
    message.autoDialTimeout = object.autoDialTimeout ?? undefined;
    message.manualDialTimeout = object.manualDialTimeout ?? undefined;
    message.dialLevel = object.dialLevel ?? undefined;
    message.placeCallsForAgent = object.placeCallsForAgent ?? undefined;
    message.dropLockoutTime = object.dropLockoutTime ?? undefined;
    message.softLimitTimeBeforeDial = object.softLimitTimeBeforeDial ?? undefined;
    message.hardLimitTimeBeforeDial = object.hardLimitTimeBeforeDial ?? undefined;
    message.softLimitNumberOfDialsByHour = object.softLimitNumberOfDialsByHour ?? undefined;
    message.hardLimitNumberOfDialsByHour = object.hardLimitNumberOfDialsByHour ?? undefined;
    message.softLimitNumberOfDialsByDay = object.softLimitNumberOfDialsByDay ?? undefined;
    message.hardLimitNumberOfDialsByDay = object.hardLimitNumberOfDialsByDay ?? undefined;
    message.softLimitNumberOfDialsByWeek = object.softLimitNumberOfDialsByWeek ?? undefined;
    message.hardLimitNumberOfDialsByWeek = object.hardLimitNumberOfDialsByWeek ?? undefined;
    message.softLimitNumberOfDialsByMonth = object.softLimitNumberOfDialsByMonth ?? undefined;
    message.hardLimitNumberOfDialsByMonth = object.hardLimitNumberOfDialsByMonth ?? undefined;
    message.linesPerAgent = object.linesPerAgent ?? undefined;
    message.maxAgentsReady = object.maxAgentsReady ?? undefined;
    message.maxNumberOfLines = object.maxNumberOfLines ?? undefined;
    return message;
  },
};

function createBaseDialerCampaign_AdvancedDialSettings(): DialerCampaign_AdvancedDialSettings {
  return {
    dialLevelDifferenceAgentTarget: undefined,
    agentTargetValue: undefined,
    dialThreshold: undefined,
    stopDialingPastThreshold: undefined,
  };
}

export const DialerCampaign_AdvancedDialSettings = {
  encode(message: DialerCampaign_AdvancedDialSettings, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.dialLevelDifferenceAgentTarget !== undefined) {
      writer.uint32(8).bool(message.dialLevelDifferenceAgentTarget);
    }
    if (message.agentTargetValue !== undefined) {
      writer.uint32(18).string(message.agentTargetValue);
    }
    if (message.dialThreshold !== undefined) {
      writer.uint32(24).bool(message.dialThreshold);
    }
    if (message.stopDialingPastThreshold !== undefined) {
      writer.uint32(32).bool(message.stopDialingPastThreshold);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerCampaign_AdvancedDialSettings {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerCampaign_AdvancedDialSettings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.dialLevelDifferenceAgentTarget = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.agentTargetValue = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.dialThreshold = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.stopDialingPastThreshold = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerCampaign_AdvancedDialSettings {
    return {
      dialLevelDifferenceAgentTarget: isSet(object.dialLevelDifferenceAgentTarget)
        ? Boolean(object.dialLevelDifferenceAgentTarget)
        : undefined,
      agentTargetValue: isSet(object.agentTargetValue) ? String(object.agentTargetValue) : undefined,
      dialThreshold: isSet(object.dialThreshold) ? Boolean(object.dialThreshold) : undefined,
      stopDialingPastThreshold: isSet(object.stopDialingPastThreshold)
        ? Boolean(object.stopDialingPastThreshold)
        : undefined,
    };
  },

  toJSON(message: DialerCampaign_AdvancedDialSettings): unknown {
    const obj: any = {};
    message.dialLevelDifferenceAgentTarget !== undefined &&
      (obj.dialLevelDifferenceAgentTarget = message.dialLevelDifferenceAgentTarget);
    message.agentTargetValue !== undefined && (obj.agentTargetValue = message.agentTargetValue);
    message.dialThreshold !== undefined && (obj.dialThreshold = message.dialThreshold);
    message.stopDialingPastThreshold !== undefined && (obj.stopDialingPastThreshold = message.stopDialingPastThreshold);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerCampaign_AdvancedDialSettings>, I>>(
    base?: I,
  ): DialerCampaign_AdvancedDialSettings {
    return DialerCampaign_AdvancedDialSettings.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerCampaign_AdvancedDialSettings>, I>>(
    object: I,
  ): DialerCampaign_AdvancedDialSettings {
    const message = createBaseDialerCampaign_AdvancedDialSettings();
    message.dialLevelDifferenceAgentTarget = object.dialLevelDifferenceAgentTarget ?? undefined;
    message.agentTargetValue = object.agentTargetValue ?? undefined;
    message.dialThreshold = object.dialThreshold ?? undefined;
    message.stopDialingPastThreshold = object.stopDialingPastThreshold ?? undefined;
    return message;
  },
};

function createBaseDialerCampaign_AgentSettings(): DialerCampaign_AgentSettings {
  return {
    callRoutingMethod: undefined,
    allowSchedulingCallbacks: undefined,
    disableAlteringLeadData: undefined,
    disableAlteringPhoneNumbers: undefined,
  };
}

export const DialerCampaign_AgentSettings = {
  encode(message: DialerCampaign_AgentSettings, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.callRoutingMethod !== undefined) {
      writer.uint32(10).string(message.callRoutingMethod);
    }
    if (message.allowSchedulingCallbacks !== undefined) {
      writer.uint32(16).bool(message.allowSchedulingCallbacks);
    }
    if (message.disableAlteringLeadData !== undefined) {
      writer.uint32(24).bool(message.disableAlteringLeadData);
    }
    if (message.disableAlteringPhoneNumbers !== undefined) {
      writer.uint32(32).bool(message.disableAlteringPhoneNumbers);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerCampaign_AgentSettings {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerCampaign_AgentSettings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.callRoutingMethod = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.allowSchedulingCallbacks = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.disableAlteringLeadData = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.disableAlteringPhoneNumbers = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerCampaign_AgentSettings {
    return {
      callRoutingMethod: isSet(object.callRoutingMethod) ? String(object.callRoutingMethod) : undefined,
      allowSchedulingCallbacks: isSet(object.allowSchedulingCallbacks)
        ? Boolean(object.allowSchedulingCallbacks)
        : undefined,
      disableAlteringLeadData: isSet(object.disableAlteringLeadData)
        ? Boolean(object.disableAlteringLeadData)
        : undefined,
      disableAlteringPhoneNumbers: isSet(object.disableAlteringPhoneNumbers)
        ? Boolean(object.disableAlteringPhoneNumbers)
        : undefined,
    };
  },

  toJSON(message: DialerCampaign_AgentSettings): unknown {
    const obj: any = {};
    message.callRoutingMethod !== undefined && (obj.callRoutingMethod = message.callRoutingMethod);
    message.allowSchedulingCallbacks !== undefined && (obj.allowSchedulingCallbacks = message.allowSchedulingCallbacks);
    message.disableAlteringLeadData !== undefined && (obj.disableAlteringLeadData = message.disableAlteringLeadData);
    message.disableAlteringPhoneNumbers !== undefined &&
      (obj.disableAlteringPhoneNumbers = message.disableAlteringPhoneNumbers);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerCampaign_AgentSettings>, I>>(base?: I): DialerCampaign_AgentSettings {
    return DialerCampaign_AgentSettings.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerCampaign_AgentSettings>, I>>(object: I): DialerCampaign_AgentSettings {
    const message = createBaseDialerCampaign_AgentSettings();
    message.callRoutingMethod = object.callRoutingMethod ?? undefined;
    message.allowSchedulingCallbacks = object.allowSchedulingCallbacks ?? undefined;
    message.disableAlteringLeadData = object.disableAlteringLeadData ?? undefined;
    message.disableAlteringPhoneNumbers = object.disableAlteringPhoneNumbers ?? undefined;
    return message;
  },
};

function createBaseDialerCampaign_HoldSettings(): DialerCampaign_HoldSettings {
  return { holdMusic: undefined, maxHoldTime: undefined, agentAlertInterval: undefined, agentAlertSound: undefined };
}

export const DialerCampaign_HoldSettings = {
  encode(message: DialerCampaign_HoldSettings, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.holdMusic !== undefined) {
      writer.uint32(8).int32(message.holdMusic);
    }
    if (message.maxHoldTime !== undefined) {
      writer.uint32(16).int32(message.maxHoldTime);
    }
    if (message.agentAlertInterval !== undefined) {
      writer.uint32(26).string(message.agentAlertInterval);
    }
    if (message.agentAlertSound !== undefined) {
      writer.uint32(32).int32(message.agentAlertSound);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerCampaign_HoldSettings {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerCampaign_HoldSettings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.holdMusic = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.maxHoldTime = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.agentAlertInterval = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.agentAlertSound = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerCampaign_HoldSettings {
    return {
      holdMusic: isSet(object.holdMusic) ? dialerCampaign_HoldMusicFromJSON(object.holdMusic) : undefined,
      maxHoldTime: isSet(object.maxHoldTime) ? dialerCampaign_MaxHoldTimeFromJSON(object.maxHoldTime) : undefined,
      agentAlertInterval: isSet(object.agentAlertInterval) ? String(object.agentAlertInterval) : undefined,
      agentAlertSound: isSet(object.agentAlertSound)
        ? dialerCampaign_AgentAlertSoundFromJSON(object.agentAlertSound)
        : undefined,
    };
  },

  toJSON(message: DialerCampaign_HoldSettings): unknown {
    const obj: any = {};
    message.holdMusic !== undefined &&
      (obj.holdMusic = message.holdMusic !== undefined ? dialerCampaign_HoldMusicToJSON(message.holdMusic) : undefined);
    message.maxHoldTime !== undefined && (obj.maxHoldTime = message.maxHoldTime !== undefined
      ? dialerCampaign_MaxHoldTimeToJSON(message.maxHoldTime)
      : undefined);
    message.agentAlertInterval !== undefined && (obj.agentAlertInterval = message.agentAlertInterval);
    message.agentAlertSound !== undefined && (obj.agentAlertSound = message.agentAlertSound !== undefined
      ? dialerCampaign_AgentAlertSoundToJSON(message.agentAlertSound)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerCampaign_HoldSettings>, I>>(base?: I): DialerCampaign_HoldSettings {
    return DialerCampaign_HoldSettings.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerCampaign_HoldSettings>, I>>(object: I): DialerCampaign_HoldSettings {
    const message = createBaseDialerCampaign_HoldSettings();
    message.holdMusic = object.holdMusic ?? undefined;
    message.maxHoldTime = object.maxHoldTime ?? undefined;
    message.agentAlertInterval = object.agentAlertInterval ?? undefined;
    message.agentAlertSound = object.agentAlertSound ?? undefined;
    return message;
  },
};

function createBaseDialerCampaign_OverflowCallSettings(): DialerCampaign_OverflowCallSettings {
  return { maxWaitTimeSeconds: undefined, overflowDestination: undefined, overflowDestination2: undefined };
}

export const DialerCampaign_OverflowCallSettings = {
  encode(message: DialerCampaign_OverflowCallSettings, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.maxWaitTimeSeconds !== undefined) {
      writer.uint32(9).double(message.maxWaitTimeSeconds);
    }
    if (message.overflowDestination !== undefined) {
      writer.uint32(16).int32(message.overflowDestination);
    }
    if (message.overflowDestination2 !== undefined) {
      writer.uint32(24).int32(message.overflowDestination2);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerCampaign_OverflowCallSettings {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerCampaign_OverflowCallSettings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.maxWaitTimeSeconds = reader.double();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.overflowDestination = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.overflowDestination2 = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerCampaign_OverflowCallSettings {
    return {
      maxWaitTimeSeconds: isSet(object.maxWaitTimeSeconds) ? Number(object.maxWaitTimeSeconds) : undefined,
      overflowDestination: isSet(object.overflowDestination)
        ? dialerCampaign_OverflowDestinationFromJSON(object.overflowDestination)
        : undefined,
      overflowDestination2: isSet(object.overflowDestination2)
        ? dialerCampaign_OverflowDestinationFromJSON(object.overflowDestination2)
        : undefined,
    };
  },

  toJSON(message: DialerCampaign_OverflowCallSettings): unknown {
    const obj: any = {};
    message.maxWaitTimeSeconds !== undefined && (obj.maxWaitTimeSeconds = message.maxWaitTimeSeconds);
    message.overflowDestination !== undefined && (obj.overflowDestination = message.overflowDestination !== undefined
      ? dialerCampaign_OverflowDestinationToJSON(message.overflowDestination)
      : undefined);
    message.overflowDestination2 !== undefined && (obj.overflowDestination2 = message.overflowDestination2 !== undefined
      ? dialerCampaign_OverflowDestinationToJSON(message.overflowDestination2)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerCampaign_OverflowCallSettings>, I>>(
    base?: I,
  ): DialerCampaign_OverflowCallSettings {
    return DialerCampaign_OverflowCallSettings.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerCampaign_OverflowCallSettings>, I>>(
    object: I,
  ): DialerCampaign_OverflowCallSettings {
    const message = createBaseDialerCampaign_OverflowCallSettings();
    message.maxWaitTimeSeconds = object.maxWaitTimeSeconds ?? undefined;
    message.overflowDestination = object.overflowDestination ?? undefined;
    message.overflowDestination2 = object.overflowDestination2 ?? undefined;
    return message;
  },
};

function createBaseDialerCampaign_CallAnalysis(): DialerCampaign_CallAnalysis {
  return {
    detectAnsweringMachineAndFax: undefined,
    totalAnalysisTimeMs: undefined,
    afterGreetingSilenceMs: undefined,
    betweenWordsSilenceMs: undefined,
    greetingDurationMs: undefined,
    initialSilenceMs: undefined,
    maximumNumberOfWords: undefined,
    maximumWordLengthMs: undefined,
    silenceThreshold: undefined,
    greetingTotalAnalysisTimeMs: undefined,
    greetingSilenceDurationMs: undefined,
    connectCallToAgent: undefined,
  };
}

export const DialerCampaign_CallAnalysis = {
  encode(message: DialerCampaign_CallAnalysis, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.detectAnsweringMachineAndFax !== undefined) {
      writer.uint32(10).string(message.detectAnsweringMachineAndFax);
    }
    if (message.totalAnalysisTimeMs !== undefined) {
      writer.uint32(17).double(message.totalAnalysisTimeMs);
    }
    if (message.afterGreetingSilenceMs !== undefined) {
      writer.uint32(25).double(message.afterGreetingSilenceMs);
    }
    if (message.betweenWordsSilenceMs !== undefined) {
      writer.uint32(33).double(message.betweenWordsSilenceMs);
    }
    if (message.greetingDurationMs !== undefined) {
      writer.uint32(41).double(message.greetingDurationMs);
    }
    if (message.initialSilenceMs !== undefined) {
      writer.uint32(49).double(message.initialSilenceMs);
    }
    if (message.maximumNumberOfWords !== undefined) {
      writer.uint32(57).double(message.maximumNumberOfWords);
    }
    if (message.maximumWordLengthMs !== undefined) {
      writer.uint32(65).double(message.maximumWordLengthMs);
    }
    if (message.silenceThreshold !== undefined) {
      writer.uint32(73).double(message.silenceThreshold);
    }
    if (message.greetingTotalAnalysisTimeMs !== undefined) {
      writer.uint32(81).double(message.greetingTotalAnalysisTimeMs);
    }
    if (message.greetingSilenceDurationMs !== undefined) {
      writer.uint32(89).double(message.greetingSilenceDurationMs);
    }
    if (message.connectCallToAgent !== undefined) {
      writer.uint32(98).string(message.connectCallToAgent);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerCampaign_CallAnalysis {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerCampaign_CallAnalysis();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.detectAnsweringMachineAndFax = reader.string();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.totalAnalysisTimeMs = reader.double();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.afterGreetingSilenceMs = reader.double();
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.betweenWordsSilenceMs = reader.double();
          continue;
        case 5:
          if (tag !== 41) {
            break;
          }

          message.greetingDurationMs = reader.double();
          continue;
        case 6:
          if (tag !== 49) {
            break;
          }

          message.initialSilenceMs = reader.double();
          continue;
        case 7:
          if (tag !== 57) {
            break;
          }

          message.maximumNumberOfWords = reader.double();
          continue;
        case 8:
          if (tag !== 65) {
            break;
          }

          message.maximumWordLengthMs = reader.double();
          continue;
        case 9:
          if (tag !== 73) {
            break;
          }

          message.silenceThreshold = reader.double();
          continue;
        case 10:
          if (tag !== 81) {
            break;
          }

          message.greetingTotalAnalysisTimeMs = reader.double();
          continue;
        case 11:
          if (tag !== 89) {
            break;
          }

          message.greetingSilenceDurationMs = reader.double();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.connectCallToAgent = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerCampaign_CallAnalysis {
    return {
      detectAnsweringMachineAndFax: isSet(object.detectAnsweringMachineAndFax)
        ? String(object.detectAnsweringMachineAndFax)
        : undefined,
      totalAnalysisTimeMs: isSet(object.totalAnalysisTimeMs) ? Number(object.totalAnalysisTimeMs) : undefined,
      afterGreetingSilenceMs: isSet(object.afterGreetingSilenceMs) ? Number(object.afterGreetingSilenceMs) : undefined,
      betweenWordsSilenceMs: isSet(object.betweenWordsSilenceMs) ? Number(object.betweenWordsSilenceMs) : undefined,
      greetingDurationMs: isSet(object.greetingDurationMs) ? Number(object.greetingDurationMs) : undefined,
      initialSilenceMs: isSet(object.initialSilenceMs) ? Number(object.initialSilenceMs) : undefined,
      maximumNumberOfWords: isSet(object.maximumNumberOfWords) ? Number(object.maximumNumberOfWords) : undefined,
      maximumWordLengthMs: isSet(object.maximumWordLengthMs) ? Number(object.maximumWordLengthMs) : undefined,
      silenceThreshold: isSet(object.silenceThreshold) ? Number(object.silenceThreshold) : undefined,
      greetingTotalAnalysisTimeMs: isSet(object.greetingTotalAnalysisTimeMs)
        ? Number(object.greetingTotalAnalysisTimeMs)
        : undefined,
      greetingSilenceDurationMs: isSet(object.greetingSilenceDurationMs)
        ? Number(object.greetingSilenceDurationMs)
        : undefined,
      connectCallToAgent: isSet(object.connectCallToAgent) ? String(object.connectCallToAgent) : undefined,
    };
  },

  toJSON(message: DialerCampaign_CallAnalysis): unknown {
    const obj: any = {};
    message.detectAnsweringMachineAndFax !== undefined &&
      (obj.detectAnsweringMachineAndFax = message.detectAnsweringMachineAndFax);
    message.totalAnalysisTimeMs !== undefined && (obj.totalAnalysisTimeMs = message.totalAnalysisTimeMs);
    message.afterGreetingSilenceMs !== undefined && (obj.afterGreetingSilenceMs = message.afterGreetingSilenceMs);
    message.betweenWordsSilenceMs !== undefined && (obj.betweenWordsSilenceMs = message.betweenWordsSilenceMs);
    message.greetingDurationMs !== undefined && (obj.greetingDurationMs = message.greetingDurationMs);
    message.initialSilenceMs !== undefined && (obj.initialSilenceMs = message.initialSilenceMs);
    message.maximumNumberOfWords !== undefined && (obj.maximumNumberOfWords = message.maximumNumberOfWords);
    message.maximumWordLengthMs !== undefined && (obj.maximumWordLengthMs = message.maximumWordLengthMs);
    message.silenceThreshold !== undefined && (obj.silenceThreshold = message.silenceThreshold);
    message.greetingTotalAnalysisTimeMs !== undefined &&
      (obj.greetingTotalAnalysisTimeMs = message.greetingTotalAnalysisTimeMs);
    message.greetingSilenceDurationMs !== undefined &&
      (obj.greetingSilenceDurationMs = message.greetingSilenceDurationMs);
    message.connectCallToAgent !== undefined && (obj.connectCallToAgent = message.connectCallToAgent);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerCampaign_CallAnalysis>, I>>(base?: I): DialerCampaign_CallAnalysis {
    return DialerCampaign_CallAnalysis.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerCampaign_CallAnalysis>, I>>(object: I): DialerCampaign_CallAnalysis {
    const message = createBaseDialerCampaign_CallAnalysis();
    message.detectAnsweringMachineAndFax = object.detectAnsweringMachineAndFax ?? undefined;
    message.totalAnalysisTimeMs = object.totalAnalysisTimeMs ?? undefined;
    message.afterGreetingSilenceMs = object.afterGreetingSilenceMs ?? undefined;
    message.betweenWordsSilenceMs = object.betweenWordsSilenceMs ?? undefined;
    message.greetingDurationMs = object.greetingDurationMs ?? undefined;
    message.initialSilenceMs = object.initialSilenceMs ?? undefined;
    message.maximumNumberOfWords = object.maximumNumberOfWords ?? undefined;
    message.maximumWordLengthMs = object.maximumWordLengthMs ?? undefined;
    message.silenceThreshold = object.silenceThreshold ?? undefined;
    message.greetingTotalAnalysisTimeMs = object.greetingTotalAnalysisTimeMs ?? undefined;
    message.greetingSilenceDurationMs = object.greetingSilenceDurationMs ?? undefined;
    message.connectCallToAgent = object.connectCallToAgent ?? undefined;
    return message;
  },
};

function createBaseDialerCampaign_ApiLeadCreationSettings(): DialerCampaign_ApiLeadCreationSettings {
  return { applyFilterWhenInsertingLeadsViaApi: undefined };
}

export const DialerCampaign_ApiLeadCreationSettings = {
  encode(message: DialerCampaign_ApiLeadCreationSettings, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.applyFilterWhenInsertingLeadsViaApi !== undefined) {
      writer.uint32(8).bool(message.applyFilterWhenInsertingLeadsViaApi);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerCampaign_ApiLeadCreationSettings {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerCampaign_ApiLeadCreationSettings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.applyFilterWhenInsertingLeadsViaApi = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerCampaign_ApiLeadCreationSettings {
    return {
      applyFilterWhenInsertingLeadsViaApi: isSet(object.applyFilterWhenInsertingLeadsViaApi)
        ? Boolean(object.applyFilterWhenInsertingLeadsViaApi)
        : undefined,
    };
  },

  toJSON(message: DialerCampaign_ApiLeadCreationSettings): unknown {
    const obj: any = {};
    message.applyFilterWhenInsertingLeadsViaApi !== undefined &&
      (obj.applyFilterWhenInsertingLeadsViaApi = message.applyFilterWhenInsertingLeadsViaApi);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerCampaign_ApiLeadCreationSettings>, I>>(
    base?: I,
  ): DialerCampaign_ApiLeadCreationSettings {
    return DialerCampaign_ApiLeadCreationSettings.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerCampaign_ApiLeadCreationSettings>, I>>(
    object: I,
  ): DialerCampaign_ApiLeadCreationSettings {
    const message = createBaseDialerCampaign_ApiLeadCreationSettings();
    message.applyFilterWhenInsertingLeadsViaApi = object.applyFilterWhenInsertingLeadsViaApi ?? undefined;
    return message;
  },
};

function createBaseDialerCampaign_LeadCreationSettings(): DialerCampaign_LeadCreationSettings {
  return { allowAgentToChooseList: undefined, defaultListAccessLevel: undefined, defaultListForNewLeads: undefined };
}

export const DialerCampaign_LeadCreationSettings = {
  encode(message: DialerCampaign_LeadCreationSettings, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.allowAgentToChooseList !== undefined) {
      writer.uint32(8).bool(message.allowAgentToChooseList);
    }
    if (message.defaultListAccessLevel !== undefined) {
      writer.uint32(16).int32(message.defaultListAccessLevel);
    }
    if (message.defaultListForNewLeads !== undefined) {
      writer.uint32(26).string(message.defaultListForNewLeads);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerCampaign_LeadCreationSettings {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerCampaign_LeadCreationSettings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.allowAgentToChooseList = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.defaultListAccessLevel = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.defaultListForNewLeads = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerCampaign_LeadCreationSettings {
    return {
      allowAgentToChooseList: isSet(object.allowAgentToChooseList) ? Boolean(object.allowAgentToChooseList) : undefined,
      defaultListAccessLevel: isSet(object.defaultListAccessLevel)
        ? dialerCampaign_DefaultListAccessLevelFromJSON(object.defaultListAccessLevel)
        : undefined,
      defaultListForNewLeads: isSet(object.defaultListForNewLeads) ? String(object.defaultListForNewLeads) : undefined,
    };
  },

  toJSON(message: DialerCampaign_LeadCreationSettings): unknown {
    const obj: any = {};
    message.allowAgentToChooseList !== undefined && (obj.allowAgentToChooseList = message.allowAgentToChooseList);
    message.defaultListAccessLevel !== undefined &&
      (obj.defaultListAccessLevel = message.defaultListAccessLevel !== undefined
        ? dialerCampaign_DefaultListAccessLevelToJSON(message.defaultListAccessLevel)
        : undefined);
    message.defaultListForNewLeads !== undefined && (obj.defaultListForNewLeads = message.defaultListForNewLeads);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerCampaign_LeadCreationSettings>, I>>(
    base?: I,
  ): DialerCampaign_LeadCreationSettings {
    return DialerCampaign_LeadCreationSettings.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerCampaign_LeadCreationSettings>, I>>(
    object: I,
  ): DialerCampaign_LeadCreationSettings {
    const message = createBaseDialerCampaign_LeadCreationSettings();
    message.allowAgentToChooseList = object.allowAgentToChooseList ?? undefined;
    message.defaultListAccessLevel = object.defaultListAccessLevel ?? undefined;
    message.defaultListForNewLeads = object.defaultListForNewLeads ?? undefined;
    return message;
  },
};

function createBaseDialerCampaign_LeadCallingOrder(): DialerCampaign_LeadCallingOrder {
  return {
    primaryLeadOrder: undefined,
    secondaryLeadOrder: undefined,
    useListMix: undefined,
    leadOrderRandomizer: undefined,
    hopperPriorityCallOrder: undefined,
    secondaryHopperPriorityCallOrder: undefined,
    secondaryHopperPriorityBy: undefined,
  };
}

export const DialerCampaign_LeadCallingOrder = {
  encode(message: DialerCampaign_LeadCallingOrder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.primaryLeadOrder !== undefined) {
      writer.uint32(8).int32(message.primaryLeadOrder);
    }
    if (message.secondaryLeadOrder !== undefined) {
      writer.uint32(16).int32(message.secondaryLeadOrder);
    }
    if (message.useListMix !== undefined) {
      writer.uint32(24).bool(message.useListMix);
    }
    if (message.leadOrderRandomizer !== undefined) {
      writer.uint32(32).bool(message.leadOrderRandomizer);
    }
    if (message.hopperPriorityCallOrder !== undefined) {
      writer.uint32(40).int32(message.hopperPriorityCallOrder);
    }
    if (message.secondaryHopperPriorityCallOrder !== undefined) {
      writer.uint32(48).int32(message.secondaryHopperPriorityCallOrder);
    }
    if (message.secondaryHopperPriorityBy !== undefined) {
      writer.uint32(56).int32(message.secondaryHopperPriorityBy);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerCampaign_LeadCallingOrder {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerCampaign_LeadCallingOrder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.primaryLeadOrder = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.secondaryLeadOrder = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.useListMix = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.leadOrderRandomizer = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.hopperPriorityCallOrder = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.secondaryHopperPriorityCallOrder = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.secondaryHopperPriorityBy = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerCampaign_LeadCallingOrder {
    return {
      primaryLeadOrder: isSet(object.primaryLeadOrder)
        ? dialerCampaign_LeadOrderFromJSON(object.primaryLeadOrder)
        : undefined,
      secondaryLeadOrder: isSet(object.secondaryLeadOrder)
        ? dialerCampaign_LeadOrderFromJSON(object.secondaryLeadOrder)
        : undefined,
      useListMix: isSet(object.useListMix) ? Boolean(object.useListMix) : undefined,
      leadOrderRandomizer: isSet(object.leadOrderRandomizer) ? Boolean(object.leadOrderRandomizer) : undefined,
      hopperPriorityCallOrder: isSet(object.hopperPriorityCallOrder)
        ? dialerCampaign_LeadOrderFromJSON(object.hopperPriorityCallOrder)
        : undefined,
      secondaryHopperPriorityCallOrder: isSet(object.secondaryHopperPriorityCallOrder)
        ? dialerCampaign_LeadOrderFromJSON(object.secondaryHopperPriorityCallOrder)
        : undefined,
      secondaryHopperPriorityBy: isSet(object.secondaryHopperPriorityBy)
        ? dialerCampaign_LeadOrderFromJSON(object.secondaryHopperPriorityBy)
        : undefined,
    };
  },

  toJSON(message: DialerCampaign_LeadCallingOrder): unknown {
    const obj: any = {};
    message.primaryLeadOrder !== undefined && (obj.primaryLeadOrder = message.primaryLeadOrder !== undefined
      ? dialerCampaign_LeadOrderToJSON(message.primaryLeadOrder)
      : undefined);
    message.secondaryLeadOrder !== undefined && (obj.secondaryLeadOrder = message.secondaryLeadOrder !== undefined
      ? dialerCampaign_LeadOrderToJSON(message.secondaryLeadOrder)
      : undefined);
    message.useListMix !== undefined && (obj.useListMix = message.useListMix);
    message.leadOrderRandomizer !== undefined && (obj.leadOrderRandomizer = message.leadOrderRandomizer);
    message.hopperPriorityCallOrder !== undefined &&
      (obj.hopperPriorityCallOrder = message.hopperPriorityCallOrder !== undefined
        ? dialerCampaign_LeadOrderToJSON(message.hopperPriorityCallOrder)
        : undefined);
    message.secondaryHopperPriorityCallOrder !== undefined &&
      (obj.secondaryHopperPriorityCallOrder = message.secondaryHopperPriorityCallOrder !== undefined
        ? dialerCampaign_LeadOrderToJSON(message.secondaryHopperPriorityCallOrder)
        : undefined);
    message.secondaryHopperPriorityBy !== undefined &&
      (obj.secondaryHopperPriorityBy = message.secondaryHopperPriorityBy !== undefined
        ? dialerCampaign_LeadOrderToJSON(message.secondaryHopperPriorityBy)
        : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerCampaign_LeadCallingOrder>, I>>(base?: I): DialerCampaign_LeadCallingOrder {
    return DialerCampaign_LeadCallingOrder.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerCampaign_LeadCallingOrder>, I>>(
    object: I,
  ): DialerCampaign_LeadCallingOrder {
    const message = createBaseDialerCampaign_LeadCallingOrder();
    message.primaryLeadOrder = object.primaryLeadOrder ?? undefined;
    message.secondaryLeadOrder = object.secondaryLeadOrder ?? undefined;
    message.useListMix = object.useListMix ?? undefined;
    message.leadOrderRandomizer = object.leadOrderRandomizer ?? undefined;
    message.hopperPriorityCallOrder = object.hopperPriorityCallOrder ?? undefined;
    message.secondaryHopperPriorityCallOrder = object.secondaryHopperPriorityCallOrder ?? undefined;
    message.secondaryHopperPriorityBy = object.secondaryHopperPriorityBy ?? undefined;
    return message;
  },
};

function createBaseDialerCampaign_Statistics(): DialerCampaign_Statistics {
  return {};
}

export const DialerCampaign_Statistics = {
  encode(_: DialerCampaign_Statistics, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerCampaign_Statistics {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerCampaign_Statistics();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): DialerCampaign_Statistics {
    return {};
  },

  toJSON(_: DialerCampaign_Statistics): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerCampaign_Statistics>, I>>(base?: I): DialerCampaign_Statistics {
    return DialerCampaign_Statistics.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerCampaign_Statistics>, I>>(_: I): DialerCampaign_Statistics {
    const message = createBaseDialerCampaign_Statistics();
    return message;
  },
};

function createBaseDialerCampaign_OnlineStats(): DialerCampaign_OnlineStats {
  return {
    enabled: undefined,
    available: undefined,
    ready: undefined,
    calling: undefined,
    availableCount: undefined,
    readyCount: undefined,
    callingCount: undefined,
    campaignId: undefined,
    agencyId: undefined,
    linesPerAgent: undefined,
    maxAgentsReady: undefined,
    maxNumberOfLines: undefined,
  };
}

export const DialerCampaign_OnlineStats = {
  encode(message: DialerCampaign_OnlineStats, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.enabled !== undefined) {
      writer.uint32(8).bool(message.enabled);
    }
    if (message.available !== undefined) {
      Struct.encode(Struct.wrap(message.available), writer.uint32(18).fork()).ldelim();
    }
    if (message.ready !== undefined) {
      Struct.encode(Struct.wrap(message.ready), writer.uint32(26).fork()).ldelim();
    }
    if (message.calling !== undefined) {
      Struct.encode(Struct.wrap(message.calling), writer.uint32(34).fork()).ldelim();
    }
    if (message.availableCount !== undefined) {
      writer.uint32(40).int32(message.availableCount);
    }
    if (message.readyCount !== undefined) {
      writer.uint32(48).int32(message.readyCount);
    }
    if (message.callingCount !== undefined) {
      writer.uint32(56).int32(message.callingCount);
    }
    if (message.campaignId !== undefined) {
      writer.uint32(66).string(message.campaignId);
    }
    if (message.agencyId !== undefined) {
      writer.uint32(74).string(message.agencyId);
    }
    if (message.linesPerAgent !== undefined) {
      writer.uint32(81).double(message.linesPerAgent);
    }
    if (message.maxAgentsReady !== undefined) {
      writer.uint32(88).int32(message.maxAgentsReady);
    }
    if (message.maxNumberOfLines !== undefined) {
      writer.uint32(96).int32(message.maxNumberOfLines);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerCampaign_OnlineStats {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerCampaign_OnlineStats();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.available = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.ready = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.calling = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.availableCount = reader.int32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.readyCount = reader.int32();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.callingCount = reader.int32();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.campaignId = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.agencyId = reader.string();
          continue;
        case 10:
          if (tag !== 81) {
            break;
          }

          message.linesPerAgent = reader.double();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.maxAgentsReady = reader.int32();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.maxNumberOfLines = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerCampaign_OnlineStats {
    return {
      enabled: isSet(object.enabled) ? Boolean(object.enabled) : undefined,
      available: isObject(object.available) ? object.available : undefined,
      ready: isObject(object.ready) ? object.ready : undefined,
      calling: isObject(object.calling) ? object.calling : undefined,
      availableCount: isSet(object.availableCount) ? Number(object.availableCount) : undefined,
      readyCount: isSet(object.readyCount) ? Number(object.readyCount) : undefined,
      callingCount: isSet(object.callingCount) ? Number(object.callingCount) : undefined,
      campaignId: isSet(object.campaignId) ? String(object.campaignId) : undefined,
      agencyId: isSet(object.agencyId) ? String(object.agencyId) : undefined,
      linesPerAgent: isSet(object.linesPerAgent) ? Number(object.linesPerAgent) : undefined,
      maxAgentsReady: isSet(object.maxAgentsReady) ? Number(object.maxAgentsReady) : undefined,
      maxNumberOfLines: isSet(object.maxNumberOfLines) ? Number(object.maxNumberOfLines) : undefined,
    };
  },

  toJSON(message: DialerCampaign_OnlineStats): unknown {
    const obj: any = {};
    message.enabled !== undefined && (obj.enabled = message.enabled);
    message.available !== undefined && (obj.available = message.available);
    message.ready !== undefined && (obj.ready = message.ready);
    message.calling !== undefined && (obj.calling = message.calling);
    message.availableCount !== undefined && (obj.availableCount = Math.round(message.availableCount));
    message.readyCount !== undefined && (obj.readyCount = Math.round(message.readyCount));
    message.callingCount !== undefined && (obj.callingCount = Math.round(message.callingCount));
    message.campaignId !== undefined && (obj.campaignId = message.campaignId);
    message.agencyId !== undefined && (obj.agencyId = message.agencyId);
    message.linesPerAgent !== undefined && (obj.linesPerAgent = message.linesPerAgent);
    message.maxAgentsReady !== undefined && (obj.maxAgentsReady = Math.round(message.maxAgentsReady));
    message.maxNumberOfLines !== undefined && (obj.maxNumberOfLines = Math.round(message.maxNumberOfLines));
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerCampaign_OnlineStats>, I>>(base?: I): DialerCampaign_OnlineStats {
    return DialerCampaign_OnlineStats.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerCampaign_OnlineStats>, I>>(object: I): DialerCampaign_OnlineStats {
    const message = createBaseDialerCampaign_OnlineStats();
    message.enabled = object.enabled ?? undefined;
    message.available = object.available ?? undefined;
    message.ready = object.ready ?? undefined;
    message.calling = object.calling ?? undefined;
    message.availableCount = object.availableCount ?? undefined;
    message.readyCount = object.readyCount ?? undefined;
    message.callingCount = object.callingCount ?? undefined;
    message.campaignId = object.campaignId ?? undefined;
    message.agencyId = object.agencyId ?? undefined;
    message.linesPerAgent = object.linesPerAgent ?? undefined;
    message.maxAgentsReady = object.maxAgentsReady ?? undefined;
    message.maxNumberOfLines = object.maxNumberOfLines ?? undefined;
    return message;
  },
};

function createBaseDialerCampaign_LeadListContainer(): DialerCampaign_LeadListContainer {
  return { leadLists: [] };
}

export const DialerCampaign_LeadListContainer = {
  encode(message: DialerCampaign_LeadListContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.leadLists) {
      LeadList.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerCampaign_LeadListContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerCampaign_LeadListContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.leadLists.push(LeadList.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerCampaign_LeadListContainer {
    return {
      leadLists: Array.isArray(object?.leadLists) ? object.leadLists.map((e: any) => LeadList.fromJSON(e)) : [],
    };
  },

  toJSON(message: DialerCampaign_LeadListContainer): unknown {
    const obj: any = {};
    if (message.leadLists) {
      obj.leadLists = message.leadLists.map((e) => e ? LeadList.toJSON(e) : undefined);
    } else {
      obj.leadLists = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerCampaign_LeadListContainer>, I>>(
    base?: I,
  ): DialerCampaign_LeadListContainer {
    return DialerCampaign_LeadListContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerCampaign_LeadListContainer>, I>>(
    object: I,
  ): DialerCampaign_LeadListContainer {
    const message = createBaseDialerCampaign_LeadListContainer();
    message.leadLists = object.leadLists?.map((e) => LeadList.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDialerCampaignStatisticsToday(): DialerCampaignStatisticsToday {
  return {
    day: undefined,
    totalCalls: undefined,
    totalConnectedCalls: undefined,
    totalEndedCalls: undefined,
    totalAnsweringMachineCalls: undefined,
  };
}

export const DialerCampaignStatisticsToday = {
  encode(message: DialerCampaignStatisticsToday, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.day !== undefined) {
      writer.uint32(10).string(message.day);
    }
    if (message.totalCalls !== undefined) {
      writer.uint32(16).int32(message.totalCalls);
    }
    if (message.totalConnectedCalls !== undefined) {
      writer.uint32(24).int32(message.totalConnectedCalls);
    }
    if (message.totalEndedCalls !== undefined) {
      writer.uint32(32).int32(message.totalEndedCalls);
    }
    if (message.totalAnsweringMachineCalls !== undefined) {
      writer.uint32(40).int32(message.totalAnsweringMachineCalls);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerCampaignStatisticsToday {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerCampaignStatisticsToday();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.day = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.totalCalls = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.totalConnectedCalls = reader.int32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.totalEndedCalls = reader.int32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.totalAnsweringMachineCalls = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerCampaignStatisticsToday {
    return {
      day: isSet(object.day) ? String(object.day) : undefined,
      totalCalls: isSet(object.totalCalls) ? Number(object.totalCalls) : undefined,
      totalConnectedCalls: isSet(object.totalConnectedCalls) ? Number(object.totalConnectedCalls) : undefined,
      totalEndedCalls: isSet(object.totalEndedCalls) ? Number(object.totalEndedCalls) : undefined,
      totalAnsweringMachineCalls: isSet(object.totalAnsweringMachineCalls)
        ? Number(object.totalAnsweringMachineCalls)
        : undefined,
    };
  },

  toJSON(message: DialerCampaignStatisticsToday): unknown {
    const obj: any = {};
    message.day !== undefined && (obj.day = message.day);
    message.totalCalls !== undefined && (obj.totalCalls = Math.round(message.totalCalls));
    message.totalConnectedCalls !== undefined && (obj.totalConnectedCalls = Math.round(message.totalConnectedCalls));
    message.totalEndedCalls !== undefined && (obj.totalEndedCalls = Math.round(message.totalEndedCalls));
    message.totalAnsweringMachineCalls !== undefined &&
      (obj.totalAnsweringMachineCalls = Math.round(message.totalAnsweringMachineCalls));
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerCampaignStatisticsToday>, I>>(base?: I): DialerCampaignStatisticsToday {
    return DialerCampaignStatisticsToday.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerCampaignStatisticsToday>, I>>(
    object: I,
  ): DialerCampaignStatisticsToday {
    const message = createBaseDialerCampaignStatisticsToday();
    message.day = object.day ?? undefined;
    message.totalCalls = object.totalCalls ?? undefined;
    message.totalConnectedCalls = object.totalConnectedCalls ?? undefined;
    message.totalEndedCalls = object.totalEndedCalls ?? undefined;
    message.totalAnsweringMachineCalls = object.totalAnsweringMachineCalls ?? undefined;
    return message;
  },
};

function createBaseDialerNumber(): DialerNumber {
  return {
    id: undefined,
    name: undefined,
    number: undefined,
    prefix: undefined,
    zip: undefined,
    state: undefined,
    stats: undefined,
    agencyId: undefined,
    active: undefined,
  };
}

export const DialerNumber = {
  encode(message: DialerNumber, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.number !== undefined) {
      writer.uint32(26).string(message.number);
    }
    if (message.prefix !== undefined) {
      writer.uint32(34).string(message.prefix);
    }
    if (message.zip !== undefined) {
      writer.uint32(42).string(message.zip);
    }
    if (message.state !== undefined) {
      writer.uint32(50).string(message.state);
    }
    if (message.stats !== undefined) {
      DialerNumber_Stats.encode(message.stats, writer.uint32(58).fork()).ldelim();
    }
    if (message.agencyId !== undefined) {
      writer.uint32(66).string(message.agencyId);
    }
    if (message.active !== undefined) {
      writer.uint32(72).bool(message.active);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerNumber {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerNumber();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.number = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.prefix = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.zip = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.state = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.stats = DialerNumber_Stats.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.agencyId = reader.string();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.active = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerNumber {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      number: isSet(object.number) ? String(object.number) : undefined,
      prefix: isSet(object.prefix) ? String(object.prefix) : undefined,
      zip: isSet(object.zip) ? String(object.zip) : undefined,
      state: isSet(object.state) ? String(object.state) : undefined,
      stats: isSet(object.stats) ? DialerNumber_Stats.fromJSON(object.stats) : undefined,
      agencyId: isSet(object.agencyId) ? String(object.agencyId) : undefined,
      active: isSet(object.active) ? Boolean(object.active) : undefined,
    };
  },

  toJSON(message: DialerNumber): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.number !== undefined && (obj.number = message.number);
    message.prefix !== undefined && (obj.prefix = message.prefix);
    message.zip !== undefined && (obj.zip = message.zip);
    message.state !== undefined && (obj.state = message.state);
    message.stats !== undefined && (obj.stats = message.stats ? DialerNumber_Stats.toJSON(message.stats) : undefined);
    message.agencyId !== undefined && (obj.agencyId = message.agencyId);
    message.active !== undefined && (obj.active = message.active);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerNumber>, I>>(base?: I): DialerNumber {
    return DialerNumber.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerNumber>, I>>(object: I): DialerNumber {
    const message = createBaseDialerNumber();
    message.id = object.id ?? undefined;
    message.name = object.name ?? undefined;
    message.number = object.number ?? undefined;
    message.prefix = object.prefix ?? undefined;
    message.zip = object.zip ?? undefined;
    message.state = object.state ?? undefined;
    message.stats = (object.stats !== undefined && object.stats !== null)
      ? DialerNumber_Stats.fromPartial(object.stats)
      : undefined;
    message.agencyId = object.agencyId ?? undefined;
    message.active = object.active ?? undefined;
    return message;
  },
};

function createBaseDialerNumber_Stats(): DialerNumber_Stats {
  return { callsScheduled: undefined, callStarted: undefined, callConnected: undefined, callEnded: undefined };
}

export const DialerNumber_Stats = {
  encode(message: DialerNumber_Stats, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.callsScheduled !== undefined) {
      DialerNumber_Stats_ByTimeRange.encode(message.callsScheduled, writer.uint32(10).fork()).ldelim();
    }
    if (message.callStarted !== undefined) {
      DialerNumber_Stats_ByTimeRange.encode(message.callStarted, writer.uint32(18).fork()).ldelim();
    }
    if (message.callConnected !== undefined) {
      DialerNumber_Stats_ByTimeRange.encode(message.callConnected, writer.uint32(26).fork()).ldelim();
    }
    if (message.callEnded !== undefined) {
      DialerNumber_Stats_ByTimeRange.encode(message.callEnded, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerNumber_Stats {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerNumber_Stats();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.callsScheduled = DialerNumber_Stats_ByTimeRange.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.callStarted = DialerNumber_Stats_ByTimeRange.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.callConnected = DialerNumber_Stats_ByTimeRange.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.callEnded = DialerNumber_Stats_ByTimeRange.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerNumber_Stats {
    return {
      callsScheduled: isSet(object.callsScheduled)
        ? DialerNumber_Stats_ByTimeRange.fromJSON(object.callsScheduled)
        : undefined,
      callStarted: isSet(object.callStarted) ? DialerNumber_Stats_ByTimeRange.fromJSON(object.callStarted) : undefined,
      callConnected: isSet(object.callConnected)
        ? DialerNumber_Stats_ByTimeRange.fromJSON(object.callConnected)
        : undefined,
      callEnded: isSet(object.callEnded) ? DialerNumber_Stats_ByTimeRange.fromJSON(object.callEnded) : undefined,
    };
  },

  toJSON(message: DialerNumber_Stats): unknown {
    const obj: any = {};
    message.callsScheduled !== undefined && (obj.callsScheduled = message.callsScheduled
      ? DialerNumber_Stats_ByTimeRange.toJSON(message.callsScheduled)
      : undefined);
    message.callStarted !== undefined &&
      (obj.callStarted = message.callStarted ? DialerNumber_Stats_ByTimeRange.toJSON(message.callStarted) : undefined);
    message.callConnected !== undefined && (obj.callConnected = message.callConnected
      ? DialerNumber_Stats_ByTimeRange.toJSON(message.callConnected)
      : undefined);
    message.callEnded !== undefined &&
      (obj.callEnded = message.callEnded ? DialerNumber_Stats_ByTimeRange.toJSON(message.callEnded) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerNumber_Stats>, I>>(base?: I): DialerNumber_Stats {
    return DialerNumber_Stats.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerNumber_Stats>, I>>(object: I): DialerNumber_Stats {
    const message = createBaseDialerNumber_Stats();
    message.callsScheduled = (object.callsScheduled !== undefined && object.callsScheduled !== null)
      ? DialerNumber_Stats_ByTimeRange.fromPartial(object.callsScheduled)
      : undefined;
    message.callStarted = (object.callStarted !== undefined && object.callStarted !== null)
      ? DialerNumber_Stats_ByTimeRange.fromPartial(object.callStarted)
      : undefined;
    message.callConnected = (object.callConnected !== undefined && object.callConnected !== null)
      ? DialerNumber_Stats_ByTimeRange.fromPartial(object.callConnected)
      : undefined;
    message.callEnded = (object.callEnded !== undefined && object.callEnded !== null)
      ? DialerNumber_Stats_ByTimeRange.fromPartial(object.callEnded)
      : undefined;
    return message;
  },
};

function createBaseDialerNumber_Stats_ByTimeRange(): DialerNumber_Stats_ByTimeRange {
  return { day: undefined, week: undefined, month: undefined, lastCall: undefined };
}

export const DialerNumber_Stats_ByTimeRange = {
  encode(message: DialerNumber_Stats_ByTimeRange, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.day !== undefined) {
      Struct.encode(Struct.wrap(message.day), writer.uint32(10).fork()).ldelim();
    }
    if (message.week !== undefined) {
      Struct.encode(Struct.wrap(message.week), writer.uint32(18).fork()).ldelim();
    }
    if (message.month !== undefined) {
      Struct.encode(Struct.wrap(message.month), writer.uint32(26).fork()).ldelim();
    }
    if (message.lastCall !== undefined) {
      writer.uint32(32).int64(message.lastCall);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerNumber_Stats_ByTimeRange {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerNumber_Stats_ByTimeRange();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.day = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.week = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.month = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.lastCall = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerNumber_Stats_ByTimeRange {
    return {
      day: isObject(object.day) ? object.day : undefined,
      week: isObject(object.week) ? object.week : undefined,
      month: isObject(object.month) ? object.month : undefined,
      lastCall: isSet(object.lastCall) ? Number(object.lastCall) : undefined,
    };
  },

  toJSON(message: DialerNumber_Stats_ByTimeRange): unknown {
    const obj: any = {};
    message.day !== undefined && (obj.day = message.day);
    message.week !== undefined && (obj.week = message.week);
    message.month !== undefined && (obj.month = message.month);
    message.lastCall !== undefined && (obj.lastCall = Math.round(message.lastCall));
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerNumber_Stats_ByTimeRange>, I>>(base?: I): DialerNumber_Stats_ByTimeRange {
    return DialerNumber_Stats_ByTimeRange.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerNumber_Stats_ByTimeRange>, I>>(
    object: I,
  ): DialerNumber_Stats_ByTimeRange {
    const message = createBaseDialerNumber_Stats_ByTimeRange();
    message.day = object.day ?? undefined;
    message.week = object.week ?? undefined;
    message.month = object.month ?? undefined;
    message.lastCall = object.lastCall ?? undefined;
    return message;
  },
};

function createBaseDialerCurrentCall(): DialerCurrentCall {
  return {
    sip: undefined,
    status: undefined,
    validUntil: undefined,
    nextStatus: undefined,
    callControlId: undefined,
    callId: undefined,
    pauseReason: undefined,
    clientState: undefined,
    userId: undefined,
    parentUserId: undefined,
    updatedAt: undefined,
    statusSetter: undefined,
    lastConnectionTimeout: undefined,
    wasMailbox: undefined,
    wasNotConnected: undefined,
  };
}

export const DialerCurrentCall = {
  encode(message: DialerCurrentCall, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sip !== undefined) {
      writer.uint32(10).string(message.sip);
    }
    if (message.status !== undefined) {
      writer.uint32(16).int32(message.status);
    }
    if (message.validUntil !== undefined) {
      writer.uint32(24).int64(message.validUntil);
    }
    if (message.nextStatus !== undefined) {
      writer.uint32(32).int32(message.nextStatus);
    }
    if (message.callControlId !== undefined) {
      writer.uint32(42).string(message.callControlId);
    }
    if (message.callId !== undefined) {
      writer.uint32(50).string(message.callId);
    }
    if (message.pauseReason !== undefined) {
      writer.uint32(56).int32(message.pauseReason);
    }
    if (message.clientState !== undefined) {
      DialerClientState.encode(message.clientState, writer.uint32(66).fork()).ldelim();
    }
    if (message.userId !== undefined) {
      writer.uint32(74).string(message.userId);
    }
    if (message.parentUserId !== undefined) {
      writer.uint32(82).string(message.parentUserId);
    }
    if (message.updatedAt !== undefined) {
      writer.uint32(88).int64(message.updatedAt);
    }
    if (message.statusSetter !== undefined) {
      writer.uint32(96).int32(message.statusSetter);
    }
    if (message.lastConnectionTimeout !== undefined) {
      writer.uint32(104).int64(message.lastConnectionTimeout);
    }
    if (message.wasMailbox !== undefined) {
      writer.uint32(112).bool(message.wasMailbox);
    }
    if (message.wasNotConnected !== undefined) {
      writer.uint32(120).bool(message.wasNotConnected);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerCurrentCall {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerCurrentCall();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.sip = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.validUntil = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.nextStatus = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.callControlId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.callId = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.pauseReason = reader.int32() as any;
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.clientState = DialerClientState.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.parentUserId = reader.string();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.updatedAt = longToNumber(reader.int64() as Long);
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.statusSetter = reader.int32() as any;
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.lastConnectionTimeout = longToNumber(reader.int64() as Long);
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.wasMailbox = reader.bool();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.wasNotConnected = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerCurrentCall {
    return {
      sip: isSet(object.sip) ? String(object.sip) : undefined,
      status: isSet(object.status) ? dialerAgentStatusFromJSON(object.status) : undefined,
      validUntil: isSet(object.validUntil) ? Number(object.validUntil) : undefined,
      nextStatus: isSet(object.nextStatus) ? dialerAgentStatusFromJSON(object.nextStatus) : undefined,
      callControlId: isSet(object.callControlId) ? String(object.callControlId) : undefined,
      callId: isSet(object.callId) ? String(object.callId) : undefined,
      pauseReason: isSet(object.pauseReason) ? dialerPauseReasonFromJSON(object.pauseReason) : undefined,
      clientState: isSet(object.clientState) ? DialerClientState.fromJSON(object.clientState) : undefined,
      userId: isSet(object.userId) ? String(object.userId) : undefined,
      parentUserId: isSet(object.parentUserId) ? String(object.parentUserId) : undefined,
      updatedAt: isSet(object.updatedAt) ? Number(object.updatedAt) : undefined,
      statusSetter: isSet(object.statusSetter)
        ? dialerCurrentCall_StatusSetterFromJSON(object.statusSetter)
        : undefined,
      lastConnectionTimeout: isSet(object.lastConnectionTimeout) ? Number(object.lastConnectionTimeout) : undefined,
      wasMailbox: isSet(object.wasMailbox) ? Boolean(object.wasMailbox) : undefined,
      wasNotConnected: isSet(object.wasNotConnected) ? Boolean(object.wasNotConnected) : undefined,
    };
  },

  toJSON(message: DialerCurrentCall): unknown {
    const obj: any = {};
    message.sip !== undefined && (obj.sip = message.sip);
    message.status !== undefined &&
      (obj.status = message.status !== undefined ? dialerAgentStatusToJSON(message.status) : undefined);
    message.validUntil !== undefined && (obj.validUntil = Math.round(message.validUntil));
    message.nextStatus !== undefined &&
      (obj.nextStatus = message.nextStatus !== undefined ? dialerAgentStatusToJSON(message.nextStatus) : undefined);
    message.callControlId !== undefined && (obj.callControlId = message.callControlId);
    message.callId !== undefined && (obj.callId = message.callId);
    message.pauseReason !== undefined &&
      (obj.pauseReason = message.pauseReason !== undefined ? dialerPauseReasonToJSON(message.pauseReason) : undefined);
    message.clientState !== undefined &&
      (obj.clientState = message.clientState ? DialerClientState.toJSON(message.clientState) : undefined);
    message.userId !== undefined && (obj.userId = message.userId);
    message.parentUserId !== undefined && (obj.parentUserId = message.parentUserId);
    message.updatedAt !== undefined && (obj.updatedAt = Math.round(message.updatedAt));
    message.statusSetter !== undefined && (obj.statusSetter = message.statusSetter !== undefined
      ? dialerCurrentCall_StatusSetterToJSON(message.statusSetter)
      : undefined);
    message.lastConnectionTimeout !== undefined &&
      (obj.lastConnectionTimeout = Math.round(message.lastConnectionTimeout));
    message.wasMailbox !== undefined && (obj.wasMailbox = message.wasMailbox);
    message.wasNotConnected !== undefined && (obj.wasNotConnected = message.wasNotConnected);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerCurrentCall>, I>>(base?: I): DialerCurrentCall {
    return DialerCurrentCall.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerCurrentCall>, I>>(object: I): DialerCurrentCall {
    const message = createBaseDialerCurrentCall();
    message.sip = object.sip ?? undefined;
    message.status = object.status ?? undefined;
    message.validUntil = object.validUntil ?? undefined;
    message.nextStatus = object.nextStatus ?? undefined;
    message.callControlId = object.callControlId ?? undefined;
    message.callId = object.callId ?? undefined;
    message.pauseReason = object.pauseReason ?? undefined;
    message.clientState = (object.clientState !== undefined && object.clientState !== null)
      ? DialerClientState.fromPartial(object.clientState)
      : undefined;
    message.userId = object.userId ?? undefined;
    message.parentUserId = object.parentUserId ?? undefined;
    message.updatedAt = object.updatedAt ?? undefined;
    message.statusSetter = object.statusSetter ?? undefined;
    message.lastConnectionTimeout = object.lastConnectionTimeout ?? undefined;
    message.wasMailbox = object.wasMailbox ?? undefined;
    message.wasNotConnected = object.wasNotConnected ?? undefined;
    return message;
  },
};

function createBaseDialerClientState(): DialerClientState {
  return {
    uuid: undefined,
    target: undefined,
    callType: undefined,
    action: undefined,
    participants: undefined,
    isConference: undefined,
    conferenceId: undefined,
    caller: undefined,
    inboundAction: undefined,
    chatId: undefined,
    invoker: undefined,
    settings: undefined,
    flow: undefined,
    hangupRouterHandler: undefined,
    outgoingFlowRelated: undefined,
  };
}

export const DialerClientState = {
  encode(message: DialerClientState, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.uuid !== undefined) {
      writer.uint32(10).string(message.uuid);
    }
    if (message.target !== undefined) {
      DialerClientState_ClientStateTarget.encode(message.target, writer.uint32(18).fork()).ldelim();
    }
    if (message.callType !== undefined) {
      writer.uint32(24).int32(message.callType);
    }
    if (message.action !== undefined) {
      DialerClientState_ClientStateAction.encode(message.action, writer.uint32(34).fork()).ldelim();
    }
    if (message.participants !== undefined) {
      DialerClientState_Participants.encode(message.participants, writer.uint32(42).fork()).ldelim();
    }
    if (message.isConference !== undefined) {
      writer.uint32(48).bool(message.isConference);
    }
    if (message.conferenceId !== undefined) {
      writer.uint32(58).string(message.conferenceId);
    }
    if (message.caller !== undefined) {
      DialerClientState_ClientStateTarget.encode(message.caller, writer.uint32(66).fork()).ldelim();
    }
    if (message.inboundAction !== undefined) {
      DialerClientState_ClientStateInboundAction.encode(message.inboundAction, writer.uint32(74).fork()).ldelim();
    }
    if (message.chatId !== undefined) {
      writer.uint32(82).string(message.chatId);
    }
    if (message.invoker !== undefined) {
      DialerClientState_Invoker.encode(message.invoker, writer.uint32(114).fork()).ldelim();
    }
    if (message.settings !== undefined) {
      DialerClientState_Settings.encode(message.settings, writer.uint32(122).fork()).ldelim();
    }
    if (message.flow !== undefined) {
      DialerCallFlow.encode(message.flow, writer.uint32(138).fork()).ldelim();
    }
    if (message.hangupRouterHandler !== undefined) {
      DialerClientState_HangupRouterHandler.encode(message.hangupRouterHandler, writer.uint32(146).fork()).ldelim();
    }
    if (message.outgoingFlowRelated !== undefined) {
      RelatedContainer.encode(message.outgoingFlowRelated, writer.uint32(154).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerClientState {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerClientState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.uuid = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.target = DialerClientState_ClientStateTarget.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.callType = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.action = DialerClientState_ClientStateAction.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.participants = DialerClientState_Participants.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.isConference = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.conferenceId = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.caller = DialerClientState_ClientStateTarget.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.inboundAction = DialerClientState_ClientStateInboundAction.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.chatId = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.invoker = DialerClientState_Invoker.decode(reader, reader.uint32());
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.settings = DialerClientState_Settings.decode(reader, reader.uint32());
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.flow = DialerCallFlow.decode(reader, reader.uint32());
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.hangupRouterHandler = DialerClientState_HangupRouterHandler.decode(reader, reader.uint32());
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.outgoingFlowRelated = RelatedContainer.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerClientState {
    return {
      uuid: isSet(object.uuid) ? String(object.uuid) : undefined,
      target: isSet(object.target) ? DialerClientState_ClientStateTarget.fromJSON(object.target) : undefined,
      callType: isSet(object.callType) ? dialerClientState_CallTypeFromJSON(object.callType) : undefined,
      action: isSet(object.action) ? DialerClientState_ClientStateAction.fromJSON(object.action) : undefined,
      participants: isSet(object.participants)
        ? DialerClientState_Participants.fromJSON(object.participants)
        : undefined,
      isConference: isSet(object.isConference) ? Boolean(object.isConference) : undefined,
      conferenceId: isSet(object.conferenceId) ? String(object.conferenceId) : undefined,
      caller: isSet(object.caller) ? DialerClientState_ClientStateTarget.fromJSON(object.caller) : undefined,
      inboundAction: isSet(object.inboundAction)
        ? DialerClientState_ClientStateInboundAction.fromJSON(object.inboundAction)
        : undefined,
      chatId: isSet(object.chatId) ? String(object.chatId) : undefined,
      invoker: isSet(object.invoker) ? DialerClientState_Invoker.fromJSON(object.invoker) : undefined,
      settings: isSet(object.settings) ? DialerClientState_Settings.fromJSON(object.settings) : undefined,
      flow: isSet(object.flow) ? DialerCallFlow.fromJSON(object.flow) : undefined,
      hangupRouterHandler: isSet(object.hangupRouterHandler)
        ? DialerClientState_HangupRouterHandler.fromJSON(object.hangupRouterHandler)
        : undefined,
      outgoingFlowRelated: isSet(object.outgoingFlowRelated)
        ? RelatedContainer.fromJSON(object.outgoingFlowRelated)
        : undefined,
    };
  },

  toJSON(message: DialerClientState): unknown {
    const obj: any = {};
    message.uuid !== undefined && (obj.uuid = message.uuid);
    message.target !== undefined &&
      (obj.target = message.target ? DialerClientState_ClientStateTarget.toJSON(message.target) : undefined);
    message.callType !== undefined &&
      (obj.callType = message.callType !== undefined ? dialerClientState_CallTypeToJSON(message.callType) : undefined);
    message.action !== undefined &&
      (obj.action = message.action ? DialerClientState_ClientStateAction.toJSON(message.action) : undefined);
    message.participants !== undefined &&
      (obj.participants = message.participants
        ? DialerClientState_Participants.toJSON(message.participants)
        : undefined);
    message.isConference !== undefined && (obj.isConference = message.isConference);
    message.conferenceId !== undefined && (obj.conferenceId = message.conferenceId);
    message.caller !== undefined &&
      (obj.caller = message.caller ? DialerClientState_ClientStateTarget.toJSON(message.caller) : undefined);
    message.inboundAction !== undefined && (obj.inboundAction = message.inboundAction
      ? DialerClientState_ClientStateInboundAction.toJSON(message.inboundAction)
      : undefined);
    message.chatId !== undefined && (obj.chatId = message.chatId);
    message.invoker !== undefined &&
      (obj.invoker = message.invoker ? DialerClientState_Invoker.toJSON(message.invoker) : undefined);
    message.settings !== undefined &&
      (obj.settings = message.settings ? DialerClientState_Settings.toJSON(message.settings) : undefined);
    message.flow !== undefined && (obj.flow = message.flow ? DialerCallFlow.toJSON(message.flow) : undefined);
    message.hangupRouterHandler !== undefined && (obj.hangupRouterHandler = message.hangupRouterHandler
      ? DialerClientState_HangupRouterHandler.toJSON(message.hangupRouterHandler)
      : undefined);
    message.outgoingFlowRelated !== undefined && (obj.outgoingFlowRelated = message.outgoingFlowRelated
      ? RelatedContainer.toJSON(message.outgoingFlowRelated)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerClientState>, I>>(base?: I): DialerClientState {
    return DialerClientState.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerClientState>, I>>(object: I): DialerClientState {
    const message = createBaseDialerClientState();
    message.uuid = object.uuid ?? undefined;
    message.target = (object.target !== undefined && object.target !== null)
      ? DialerClientState_ClientStateTarget.fromPartial(object.target)
      : undefined;
    message.callType = object.callType ?? undefined;
    message.action = (object.action !== undefined && object.action !== null)
      ? DialerClientState_ClientStateAction.fromPartial(object.action)
      : undefined;
    message.participants = (object.participants !== undefined && object.participants !== null)
      ? DialerClientState_Participants.fromPartial(object.participants)
      : undefined;
    message.isConference = object.isConference ?? undefined;
    message.conferenceId = object.conferenceId ?? undefined;
    message.caller = (object.caller !== undefined && object.caller !== null)
      ? DialerClientState_ClientStateTarget.fromPartial(object.caller)
      : undefined;
    message.inboundAction = (object.inboundAction !== undefined && object.inboundAction !== null)
      ? DialerClientState_ClientStateInboundAction.fromPartial(object.inboundAction)
      : undefined;
    message.chatId = object.chatId ?? undefined;
    message.invoker = (object.invoker !== undefined && object.invoker !== null)
      ? DialerClientState_Invoker.fromPartial(object.invoker)
      : undefined;
    message.settings = (object.settings !== undefined && object.settings !== null)
      ? DialerClientState_Settings.fromPartial(object.settings)
      : undefined;
    message.flow = (object.flow !== undefined && object.flow !== null)
      ? DialerCallFlow.fromPartial(object.flow)
      : undefined;
    message.hangupRouterHandler = (object.hangupRouterHandler !== undefined && object.hangupRouterHandler !== null)
      ? DialerClientState_HangupRouterHandler.fromPartial(object.hangupRouterHandler)
      : undefined;
    message.outgoingFlowRelated = (object.outgoingFlowRelated !== undefined && object.outgoingFlowRelated !== null)
      ? RelatedContainer.fromPartial(object.outgoingFlowRelated)
      : undefined;
    return message;
  },
};

function createBaseDialerClientState_HangupRouterHandler(): DialerClientState_HangupRouterHandler {
  return { queue: undefined, nextPrioritizationItems: [], sourceCallControlId: undefined };
}

export const DialerClientState_HangupRouterHandler = {
  encode(message: DialerClientState_HangupRouterHandler, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.queue !== undefined) {
      UserIdContainer.encode(message.queue, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.nextPrioritizationItems) {
      DialerQueuePrioritizationItem.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.sourceCallControlId !== undefined) {
      writer.uint32(26).string(message.sourceCallControlId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerClientState_HangupRouterHandler {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerClientState_HangupRouterHandler();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.queue = UserIdContainer.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nextPrioritizationItems.push(DialerQueuePrioritizationItem.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.sourceCallControlId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerClientState_HangupRouterHandler {
    return {
      queue: isSet(object.queue) ? UserIdContainer.fromJSON(object.queue) : undefined,
      nextPrioritizationItems: Array.isArray(object?.nextPrioritizationItems)
        ? object.nextPrioritizationItems.map((e: any) => DialerQueuePrioritizationItem.fromJSON(e))
        : [],
      sourceCallControlId: isSet(object.sourceCallControlId) ? String(object.sourceCallControlId) : undefined,
    };
  },

  toJSON(message: DialerClientState_HangupRouterHandler): unknown {
    const obj: any = {};
    message.queue !== undefined && (obj.queue = message.queue ? UserIdContainer.toJSON(message.queue) : undefined);
    if (message.nextPrioritizationItems) {
      obj.nextPrioritizationItems = message.nextPrioritizationItems.map((e) =>
        e ? DialerQueuePrioritizationItem.toJSON(e) : undefined
      );
    } else {
      obj.nextPrioritizationItems = [];
    }
    message.sourceCallControlId !== undefined && (obj.sourceCallControlId = message.sourceCallControlId);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerClientState_HangupRouterHandler>, I>>(
    base?: I,
  ): DialerClientState_HangupRouterHandler {
    return DialerClientState_HangupRouterHandler.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerClientState_HangupRouterHandler>, I>>(
    object: I,
  ): DialerClientState_HangupRouterHandler {
    const message = createBaseDialerClientState_HangupRouterHandler();
    message.queue = (object.queue !== undefined && object.queue !== null)
      ? UserIdContainer.fromPartial(object.queue)
      : undefined;
    message.nextPrioritizationItems =
      object.nextPrioritizationItems?.map((e) => DialerQueuePrioritizationItem.fromPartial(e)) || [];
    message.sourceCallControlId = object.sourceCallControlId ?? undefined;
    return message;
  },
};

function createBaseDialerClientState_Settings(): DialerClientState_Settings {
  return { connectCallToAgent: undefined };
}

export const DialerClientState_Settings = {
  encode(message: DialerClientState_Settings, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.connectCallToAgent !== undefined) {
      writer.uint32(10).string(message.connectCallToAgent);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerClientState_Settings {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerClientState_Settings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.connectCallToAgent = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerClientState_Settings {
    return { connectCallToAgent: isSet(object.connectCallToAgent) ? String(object.connectCallToAgent) : undefined };
  },

  toJSON(message: DialerClientState_Settings): unknown {
    const obj: any = {};
    message.connectCallToAgent !== undefined && (obj.connectCallToAgent = message.connectCallToAgent);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerClientState_Settings>, I>>(base?: I): DialerClientState_Settings {
    return DialerClientState_Settings.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerClientState_Settings>, I>>(object: I): DialerClientState_Settings {
    const message = createBaseDialerClientState_Settings();
    message.connectCallToAgent = object.connectCallToAgent ?? undefined;
    return message;
  },
};

function createBaseDialerClientState_ClientStateTarget(): DialerClientState_ClientStateTarget {
  return {
    id: undefined,
    parentUserId: undefined,
    number: undefined,
    type: undefined,
    callControlId: undefined,
    campaignId: undefined,
    data: undefined,
    conferenceId: undefined,
    clientState: undefined,
    queueId: undefined,
    queueMessageId: undefined,
    dialerRole: undefined,
    wasMailbox: undefined,
    wasDropped: undefined,
    wasNotConnected: undefined,
    skill: undefined,
    joinedTime: undefined,
    assignedQueuePath: undefined,
  };
}

export const DialerClientState_ClientStateTarget = {
  encode(message: DialerClientState_ClientStateTarget, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.parentUserId !== undefined) {
      writer.uint32(18).string(message.parentUserId);
    }
    if (message.number !== undefined) {
      writer.uint32(26).string(message.number);
    }
    if (message.type !== undefined) {
      writer.uint32(32).int32(message.type);
    }
    if (message.callControlId !== undefined) {
      writer.uint32(42).string(message.callControlId);
    }
    if (message.campaignId !== undefined) {
      writer.uint32(50).string(message.campaignId);
    }
    if (message.data !== undefined) {
      DialerClientState_ClientStateTarget_Data.encode(message.data, writer.uint32(58).fork()).ldelim();
    }
    if (message.conferenceId !== undefined) {
      writer.uint32(66).string(message.conferenceId);
    }
    if (message.clientState !== undefined) {
      DialerClientState.encode(message.clientState, writer.uint32(74).fork()).ldelim();
    }
    if (message.queueId !== undefined) {
      writer.uint32(82).string(message.queueId);
    }
    if (message.queueMessageId !== undefined) {
      writer.uint32(90).string(message.queueMessageId);
    }
    if (message.dialerRole !== undefined) {
      writer.uint32(96).int32(message.dialerRole);
    }
    if (message.wasMailbox !== undefined) {
      writer.uint32(104).bool(message.wasMailbox);
    }
    if (message.wasDropped !== undefined) {
      writer.uint32(112).bool(message.wasDropped);
    }
    if (message.wasNotConnected !== undefined) {
      writer.uint32(120).bool(message.wasNotConnected);
    }
    if (message.skill !== undefined) {
      SkillListContainer.encode(message.skill, writer.uint32(130).fork()).ldelim();
    }
    if (message.joinedTime !== undefined) {
      writer.uint32(136).int64(message.joinedTime);
    }
    if (message.assignedQueuePath !== undefined) {
      StringListContainer.encode(message.assignedQueuePath, writer.uint32(146).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerClientState_ClientStateTarget {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerClientState_ClientStateTarget();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.parentUserId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.number = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.callControlId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.campaignId = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.data = DialerClientState_ClientStateTarget_Data.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.conferenceId = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.clientState = DialerClientState.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.queueId = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.queueMessageId = reader.string();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.dialerRole = reader.int32() as any;
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.wasMailbox = reader.bool();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.wasDropped = reader.bool();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.wasNotConnected = reader.bool();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.skill = SkillListContainer.decode(reader, reader.uint32());
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.joinedTime = longToNumber(reader.int64() as Long);
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.assignedQueuePath = StringListContainer.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerClientState_ClientStateTarget {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      parentUserId: isSet(object.parentUserId) ? String(object.parentUserId) : undefined,
      number: isSet(object.number) ? String(object.number) : undefined,
      type: isSet(object.type) ? dialerClientState_ClientStateTarget_TargetTypeFromJSON(object.type) : undefined,
      callControlId: isSet(object.callControlId) ? String(object.callControlId) : undefined,
      campaignId: isSet(object.campaignId) ? String(object.campaignId) : undefined,
      data: isSet(object.data) ? DialerClientState_ClientStateTarget_Data.fromJSON(object.data) : undefined,
      conferenceId: isSet(object.conferenceId) ? String(object.conferenceId) : undefined,
      clientState: isSet(object.clientState) ? DialerClientState.fromJSON(object.clientState) : undefined,
      queueId: isSet(object.queueId) ? String(object.queueId) : undefined,
      queueMessageId: isSet(object.queueMessageId) ? String(object.queueMessageId) : undefined,
      dialerRole: isSet(object.dialerRole) ? dialerRoleFromJSON(object.dialerRole) : undefined,
      wasMailbox: isSet(object.wasMailbox) ? Boolean(object.wasMailbox) : undefined,
      wasDropped: isSet(object.wasDropped) ? Boolean(object.wasDropped) : undefined,
      wasNotConnected: isSet(object.wasNotConnected) ? Boolean(object.wasNotConnected) : undefined,
      skill: isSet(object.skill) ? SkillListContainer.fromJSON(object.skill) : undefined,
      joinedTime: isSet(object.joinedTime) ? Number(object.joinedTime) : undefined,
      assignedQueuePath: isSet(object.assignedQueuePath)
        ? StringListContainer.fromJSON(object.assignedQueuePath)
        : undefined,
    };
  },

  toJSON(message: DialerClientState_ClientStateTarget): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.parentUserId !== undefined && (obj.parentUserId = message.parentUserId);
    message.number !== undefined && (obj.number = message.number);
    message.type !== undefined && (obj.type = message.type !== undefined
      ? dialerClientState_ClientStateTarget_TargetTypeToJSON(message.type)
      : undefined);
    message.callControlId !== undefined && (obj.callControlId = message.callControlId);
    message.campaignId !== undefined && (obj.campaignId = message.campaignId);
    message.data !== undefined &&
      (obj.data = message.data ? DialerClientState_ClientStateTarget_Data.toJSON(message.data) : undefined);
    message.conferenceId !== undefined && (obj.conferenceId = message.conferenceId);
    message.clientState !== undefined &&
      (obj.clientState = message.clientState ? DialerClientState.toJSON(message.clientState) : undefined);
    message.queueId !== undefined && (obj.queueId = message.queueId);
    message.queueMessageId !== undefined && (obj.queueMessageId = message.queueMessageId);
    message.dialerRole !== undefined &&
      (obj.dialerRole = message.dialerRole !== undefined ? dialerRoleToJSON(message.dialerRole) : undefined);
    message.wasMailbox !== undefined && (obj.wasMailbox = message.wasMailbox);
    message.wasDropped !== undefined && (obj.wasDropped = message.wasDropped);
    message.wasNotConnected !== undefined && (obj.wasNotConnected = message.wasNotConnected);
    message.skill !== undefined && (obj.skill = message.skill ? SkillListContainer.toJSON(message.skill) : undefined);
    message.joinedTime !== undefined && (obj.joinedTime = Math.round(message.joinedTime));
    message.assignedQueuePath !== undefined && (obj.assignedQueuePath = message.assignedQueuePath
      ? StringListContainer.toJSON(message.assignedQueuePath)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerClientState_ClientStateTarget>, I>>(
    base?: I,
  ): DialerClientState_ClientStateTarget {
    return DialerClientState_ClientStateTarget.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerClientState_ClientStateTarget>, I>>(
    object: I,
  ): DialerClientState_ClientStateTarget {
    const message = createBaseDialerClientState_ClientStateTarget();
    message.id = object.id ?? undefined;
    message.parentUserId = object.parentUserId ?? undefined;
    message.number = object.number ?? undefined;
    message.type = object.type ?? undefined;
    message.callControlId = object.callControlId ?? undefined;
    message.campaignId = object.campaignId ?? undefined;
    message.data = (object.data !== undefined && object.data !== null)
      ? DialerClientState_ClientStateTarget_Data.fromPartial(object.data)
      : undefined;
    message.conferenceId = object.conferenceId ?? undefined;
    message.clientState = (object.clientState !== undefined && object.clientState !== null)
      ? DialerClientState.fromPartial(object.clientState)
      : undefined;
    message.queueId = object.queueId ?? undefined;
    message.queueMessageId = object.queueMessageId ?? undefined;
    message.dialerRole = object.dialerRole ?? undefined;
    message.wasMailbox = object.wasMailbox ?? undefined;
    message.wasDropped = object.wasDropped ?? undefined;
    message.wasNotConnected = object.wasNotConnected ?? undefined;
    message.skill = (object.skill !== undefined && object.skill !== null)
      ? SkillListContainer.fromPartial(object.skill)
      : undefined;
    message.joinedTime = object.joinedTime ?? undefined;
    message.assignedQueuePath = (object.assignedQueuePath !== undefined && object.assignedQueuePath !== null)
      ? StringListContainer.fromPartial(object.assignedQueuePath)
      : undefined;
    return message;
  },
};

function createBaseDialerClientState_ClientStateTarget_Data(): DialerClientState_ClientStateTarget_Data {
  return { firstName: undefined, lastName: undefined };
}

export const DialerClientState_ClientStateTarget_Data = {
  encode(message: DialerClientState_ClientStateTarget_Data, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.firstName !== undefined) {
      writer.uint32(10).string(message.firstName);
    }
    if (message.lastName !== undefined) {
      writer.uint32(18).string(message.lastName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerClientState_ClientStateTarget_Data {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerClientState_ClientStateTarget_Data();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.lastName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerClientState_ClientStateTarget_Data {
    return {
      firstName: isSet(object.firstName) ? String(object.firstName) : undefined,
      lastName: isSet(object.lastName) ? String(object.lastName) : undefined,
    };
  },

  toJSON(message: DialerClientState_ClientStateTarget_Data): unknown {
    const obj: any = {};
    message.firstName !== undefined && (obj.firstName = message.firstName);
    message.lastName !== undefined && (obj.lastName = message.lastName);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerClientState_ClientStateTarget_Data>, I>>(
    base?: I,
  ): DialerClientState_ClientStateTarget_Data {
    return DialerClientState_ClientStateTarget_Data.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerClientState_ClientStateTarget_Data>, I>>(
    object: I,
  ): DialerClientState_ClientStateTarget_Data {
    const message = createBaseDialerClientState_ClientStateTarget_Data();
    message.firstName = object.firstName ?? undefined;
    message.lastName = object.lastName ?? undefined;
    return message;
  },
};

function createBaseDialerClientState_ClientStateAction(): DialerClientState_ClientStateAction {
  return {
    type: undefined,
    destination: undefined,
    data: undefined,
    hasToAnswer: undefined,
    runAfterEvent: undefined,
    settings: undefined,
  };
}

export const DialerClientState_ClientStateAction = {
  encode(message: DialerClientState_ClientStateAction, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== undefined) {
      writer.uint32(8).int32(message.type);
    }
    if (message.destination !== undefined) {
      writer.uint32(18).string(message.destination);
    }
    if (message.data !== undefined) {
      Struct.encode(Struct.wrap(message.data), writer.uint32(26).fork()).ldelim();
    }
    if (message.hasToAnswer !== undefined) {
      writer.uint32(32).bool(message.hasToAnswer);
    }
    if (message.runAfterEvent !== undefined) {
      writer.uint32(42).string(message.runAfterEvent);
    }
    if (message.settings !== undefined) {
      DialerClientState_ClientStateAction_ActionSettings.encode(message.settings, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerClientState_ClientStateAction {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerClientState_ClientStateAction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.destination = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.data = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.hasToAnswer = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.runAfterEvent = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.settings = DialerClientState_ClientStateAction_ActionSettings.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerClientState_ClientStateAction {
    return {
      type: isSet(object.type) ? dialerClientState_ClientStateAction_ActionTypeFromJSON(object.type) : undefined,
      destination: isSet(object.destination) ? String(object.destination) : undefined,
      data: isObject(object.data) ? object.data : undefined,
      hasToAnswer: isSet(object.hasToAnswer) ? Boolean(object.hasToAnswer) : undefined,
      runAfterEvent: isSet(object.runAfterEvent) ? String(object.runAfterEvent) : undefined,
      settings: isSet(object.settings)
        ? DialerClientState_ClientStateAction_ActionSettings.fromJSON(object.settings)
        : undefined,
    };
  },

  toJSON(message: DialerClientState_ClientStateAction): unknown {
    const obj: any = {};
    message.type !== undefined && (obj.type = message.type !== undefined
      ? dialerClientState_ClientStateAction_ActionTypeToJSON(message.type)
      : undefined);
    message.destination !== undefined && (obj.destination = message.destination);
    message.data !== undefined && (obj.data = message.data);
    message.hasToAnswer !== undefined && (obj.hasToAnswer = message.hasToAnswer);
    message.runAfterEvent !== undefined && (obj.runAfterEvent = message.runAfterEvent);
    message.settings !== undefined && (obj.settings = message.settings
      ? DialerClientState_ClientStateAction_ActionSettings.toJSON(message.settings)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerClientState_ClientStateAction>, I>>(
    base?: I,
  ): DialerClientState_ClientStateAction {
    return DialerClientState_ClientStateAction.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerClientState_ClientStateAction>, I>>(
    object: I,
  ): DialerClientState_ClientStateAction {
    const message = createBaseDialerClientState_ClientStateAction();
    message.type = object.type ?? undefined;
    message.destination = object.destination ?? undefined;
    message.data = object.data ?? undefined;
    message.hasToAnswer = object.hasToAnswer ?? undefined;
    message.runAfterEvent = object.runAfterEvent ?? undefined;
    message.settings = (object.settings !== undefined && object.settings !== null)
      ? DialerClientState_ClientStateAction_ActionSettings.fromPartial(object.settings)
      : undefined;
    return message;
  },
};

function createBaseDialerClientState_ClientStateAction_ActionSettings(): DialerClientState_ClientStateAction_ActionSettings {
  return {
    hangupAfter: undefined,
    musicSetAfter: undefined,
    speakAfter: undefined,
    beepAfter: undefined,
    wasVoicemail: undefined,
    startRecording: undefined,
  };
}

export const DialerClientState_ClientStateAction_ActionSettings = {
  encode(
    message: DialerClientState_ClientStateAction_ActionSettings,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.hangupAfter !== undefined) {
      writer.uint32(8).bool(message.hangupAfter);
    }
    if (message.musicSetAfter !== undefined) {
      writer.uint32(18).string(message.musicSetAfter);
    }
    if (message.speakAfter !== undefined) {
      writer.uint32(26).string(message.speakAfter);
    }
    if (message.beepAfter !== undefined) {
      writer.uint32(32).bool(message.beepAfter);
    }
    if (message.wasVoicemail !== undefined) {
      writer.uint32(40).bool(message.wasVoicemail);
    }
    if (message.startRecording !== undefined) {
      writer.uint32(48).bool(message.startRecording);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerClientState_ClientStateAction_ActionSettings {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerClientState_ClientStateAction_ActionSettings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.hangupAfter = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.musicSetAfter = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.speakAfter = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.beepAfter = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.wasVoicemail = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.startRecording = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerClientState_ClientStateAction_ActionSettings {
    return {
      hangupAfter: isSet(object.hangupAfter) ? Boolean(object.hangupAfter) : undefined,
      musicSetAfter: isSet(object.musicSetAfter) ? String(object.musicSetAfter) : undefined,
      speakAfter: isSet(object.speakAfter) ? String(object.speakAfter) : undefined,
      beepAfter: isSet(object.beepAfter) ? Boolean(object.beepAfter) : undefined,
      wasVoicemail: isSet(object.wasVoicemail) ? Boolean(object.wasVoicemail) : undefined,
      startRecording: isSet(object.startRecording) ? Boolean(object.startRecording) : undefined,
    };
  },

  toJSON(message: DialerClientState_ClientStateAction_ActionSettings): unknown {
    const obj: any = {};
    message.hangupAfter !== undefined && (obj.hangupAfter = message.hangupAfter);
    message.musicSetAfter !== undefined && (obj.musicSetAfter = message.musicSetAfter);
    message.speakAfter !== undefined && (obj.speakAfter = message.speakAfter);
    message.beepAfter !== undefined && (obj.beepAfter = message.beepAfter);
    message.wasVoicemail !== undefined && (obj.wasVoicemail = message.wasVoicemail);
    message.startRecording !== undefined && (obj.startRecording = message.startRecording);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerClientState_ClientStateAction_ActionSettings>, I>>(
    base?: I,
  ): DialerClientState_ClientStateAction_ActionSettings {
    return DialerClientState_ClientStateAction_ActionSettings.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerClientState_ClientStateAction_ActionSettings>, I>>(
    object: I,
  ): DialerClientState_ClientStateAction_ActionSettings {
    const message = createBaseDialerClientState_ClientStateAction_ActionSettings();
    message.hangupAfter = object.hangupAfter ?? undefined;
    message.musicSetAfter = object.musicSetAfter ?? undefined;
    message.speakAfter = object.speakAfter ?? undefined;
    message.beepAfter = object.beepAfter ?? undefined;
    message.wasVoicemail = object.wasVoicemail ?? undefined;
    message.startRecording = object.startRecording ?? undefined;
    return message;
  },
};

function createBaseDialerClientState_ClientStateAction_NextAction(): DialerClientState_ClientStateAction_NextAction {
  return { action: undefined };
}

export const DialerClientState_ClientStateAction_NextAction = {
  encode(
    message: DialerClientState_ClientStateAction_NextAction,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.action !== undefined) {
      DialerClientState_ClientStateAction.encode(message.action, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerClientState_ClientStateAction_NextAction {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerClientState_ClientStateAction_NextAction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.action = DialerClientState_ClientStateAction.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerClientState_ClientStateAction_NextAction {
    return { action: isSet(object.action) ? DialerClientState_ClientStateAction.fromJSON(object.action) : undefined };
  },

  toJSON(message: DialerClientState_ClientStateAction_NextAction): unknown {
    const obj: any = {};
    message.action !== undefined &&
      (obj.action = message.action ? DialerClientState_ClientStateAction.toJSON(message.action) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerClientState_ClientStateAction_NextAction>, I>>(
    base?: I,
  ): DialerClientState_ClientStateAction_NextAction {
    return DialerClientState_ClientStateAction_NextAction.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerClientState_ClientStateAction_NextAction>, I>>(
    object: I,
  ): DialerClientState_ClientStateAction_NextAction {
    const message = createBaseDialerClientState_ClientStateAction_NextAction();
    message.action = (object.action !== undefined && object.action !== null)
      ? DialerClientState_ClientStateAction.fromPartial(object.action)
      : undefined;
    return message;
  },
};

function createBaseDialerClientState_ClientStateInboundAction(): DialerClientState_ClientStateInboundAction {
  return { type: undefined, destination: undefined };
}

export const DialerClientState_ClientStateInboundAction = {
  encode(message: DialerClientState_ClientStateInboundAction, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== undefined) {
      writer.uint32(8).int32(message.type);
    }
    if (message.destination !== undefined) {
      writer.uint32(18).string(message.destination);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerClientState_ClientStateInboundAction {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerClientState_ClientStateInboundAction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.destination = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerClientState_ClientStateInboundAction {
    return {
      type: isSet(object.type) ? dialerClientState_ClientStateInboundAction_ActionTypeFromJSON(object.type) : undefined,
      destination: isSet(object.destination) ? String(object.destination) : undefined,
    };
  },

  toJSON(message: DialerClientState_ClientStateInboundAction): unknown {
    const obj: any = {};
    message.type !== undefined && (obj.type = message.type !== undefined
      ? dialerClientState_ClientStateInboundAction_ActionTypeToJSON(message.type)
      : undefined);
    message.destination !== undefined && (obj.destination = message.destination);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerClientState_ClientStateInboundAction>, I>>(
    base?: I,
  ): DialerClientState_ClientStateInboundAction {
    return DialerClientState_ClientStateInboundAction.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerClientState_ClientStateInboundAction>, I>>(
    object: I,
  ): DialerClientState_ClientStateInboundAction {
    const message = createBaseDialerClientState_ClientStateInboundAction();
    message.type = object.type ?? undefined;
    message.destination = object.destination ?? undefined;
    return message;
  },
};

function createBaseDialerClientState_Participants(): DialerClientState_Participants {
  return { agent: undefined, consumer: undefined };
}

export const DialerClientState_Participants = {
  encode(message: DialerClientState_Participants, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.agent !== undefined) {
      writer.uint32(10).string(message.agent);
    }
    if (message.consumer !== undefined) {
      writer.uint32(18).string(message.consumer);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerClientState_Participants {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerClientState_Participants();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.agent = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.consumer = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerClientState_Participants {
    return {
      agent: isSet(object.agent) ? String(object.agent) : undefined,
      consumer: isSet(object.consumer) ? String(object.consumer) : undefined,
    };
  },

  toJSON(message: DialerClientState_Participants): unknown {
    const obj: any = {};
    message.agent !== undefined && (obj.agent = message.agent);
    message.consumer !== undefined && (obj.consumer = message.consumer);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerClientState_Participants>, I>>(base?: I): DialerClientState_Participants {
    return DialerClientState_Participants.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerClientState_Participants>, I>>(
    object: I,
  ): DialerClientState_Participants {
    const message = createBaseDialerClientState_Participants();
    message.agent = object.agent ?? undefined;
    message.consumer = object.consumer ?? undefined;
    return message;
  },
};

function createBaseDialerClientState_Participants_Participant(): DialerClientState_Participants_Participant {
  return { id: undefined, callControlId: undefined };
}

export const DialerClientState_Participants_Participant = {
  encode(message: DialerClientState_Participants_Participant, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.callControlId !== undefined) {
      writer.uint32(18).string(message.callControlId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerClientState_Participants_Participant {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerClientState_Participants_Participant();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.callControlId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerClientState_Participants_Participant {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      callControlId: isSet(object.callControlId) ? String(object.callControlId) : undefined,
    };
  },

  toJSON(message: DialerClientState_Participants_Participant): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.callControlId !== undefined && (obj.callControlId = message.callControlId);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerClientState_Participants_Participant>, I>>(
    base?: I,
  ): DialerClientState_Participants_Participant {
    return DialerClientState_Participants_Participant.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerClientState_Participants_Participant>, I>>(
    object: I,
  ): DialerClientState_Participants_Participant {
    const message = createBaseDialerClientState_Participants_Participant();
    message.id = object.id ?? undefined;
    message.callControlId = object.callControlId ?? undefined;
    return message;
  },
};

function createBaseDialerClientState_Invoker(): DialerClientState_Invoker {
  return {
    id: undefined,
    parentId: undefined,
    type: undefined,
    scriptId: undefined,
    dispoStatusId: undefined,
    name: undefined,
    enrollmentCodeSettings: undefined,
  };
}

export const DialerClientState_Invoker = {
  encode(message: DialerClientState_Invoker, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.parentId !== undefined) {
      writer.uint32(18).string(message.parentId);
    }
    if (message.type !== undefined) {
      writer.uint32(24).int32(message.type);
    }
    if (message.scriptId !== undefined) {
      writer.uint32(34).string(message.scriptId);
    }
    if (message.dispoStatusId !== undefined) {
      writer.uint32(42).string(message.dispoStatusId);
    }
    if (message.name !== undefined) {
      writer.uint32(50).string(message.name);
    }
    if (message.enrollmentCodeSettings !== undefined) {
      DialerQueueEnrollmentCodeSettings.encode(message.enrollmentCodeSettings, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerClientState_Invoker {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerClientState_Invoker();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.parentId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.scriptId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.dispoStatusId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.name = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.enrollmentCodeSettings = DialerQueueEnrollmentCodeSettings.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerClientState_Invoker {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      parentId: isSet(object.parentId) ? String(object.parentId) : undefined,
      type: isSet(object.type) ? dialerClientState_InvokerTypeFromJSON(object.type) : undefined,
      scriptId: isSet(object.scriptId) ? String(object.scriptId) : undefined,
      dispoStatusId: isSet(object.dispoStatusId) ? String(object.dispoStatusId) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      enrollmentCodeSettings: isSet(object.enrollmentCodeSettings)
        ? DialerQueueEnrollmentCodeSettings.fromJSON(object.enrollmentCodeSettings)
        : undefined,
    };
  },

  toJSON(message: DialerClientState_Invoker): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.parentId !== undefined && (obj.parentId = message.parentId);
    message.type !== undefined &&
      (obj.type = message.type !== undefined ? dialerClientState_InvokerTypeToJSON(message.type) : undefined);
    message.scriptId !== undefined && (obj.scriptId = message.scriptId);
    message.dispoStatusId !== undefined && (obj.dispoStatusId = message.dispoStatusId);
    message.name !== undefined && (obj.name = message.name);
    message.enrollmentCodeSettings !== undefined && (obj.enrollmentCodeSettings = message.enrollmentCodeSettings
      ? DialerQueueEnrollmentCodeSettings.toJSON(message.enrollmentCodeSettings)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerClientState_Invoker>, I>>(base?: I): DialerClientState_Invoker {
    return DialerClientState_Invoker.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerClientState_Invoker>, I>>(object: I): DialerClientState_Invoker {
    const message = createBaseDialerClientState_Invoker();
    message.id = object.id ?? undefined;
    message.parentId = object.parentId ?? undefined;
    message.type = object.type ?? undefined;
    message.scriptId = object.scriptId ?? undefined;
    message.dispoStatusId = object.dispoStatusId ?? undefined;
    message.name = object.name ?? undefined;
    message.enrollmentCodeSettings =
      (object.enrollmentCodeSettings !== undefined && object.enrollmentCodeSettings !== null)
        ? DialerQueueEnrollmentCodeSettings.fromPartial(object.enrollmentCodeSettings)
        : undefined;
    return message;
  },
};

function createBaseDialerQueue(): DialerQueue {
  return {
    id: undefined,
    name: undefined,
    active: undefined,
    createdAt: undefined,
    lastCallAt: undefined,
    callRouting: undefined,
    scriptCallLaunch: undefined,
    schedule: undefined,
    afterHourSettings: undefined,
    allowAllMarketingAgents: undefined,
    marketingAgents: {},
    salesAgents: {},
    numbers: {},
    neededSkills: undefined,
    callCap: undefined,
    priority: undefined,
    agencyId: undefined,
    agency: undefined,
    marketingAgent: undefined,
    salesAgent: undefined,
    salesAgentQueueId: undefined,
    number: undefined,
    dispoStatusCallLaunch: undefined,
    billableSettings: undefined,
    settings: undefined,
    callCapQueue: undefined,
    callCapReceiverQueue: undefined,
    concurrentCallCap: undefined,
    agencySettings: undefined,
    soldSettings: undefined,
    internalBillableSettings: undefined,
    restricted: undefined,
    queueType: undefined,
    prioritization: undefined,
    enrollmentCodeSettings: undefined,
    updatedAt: undefined,
    restrict: undefined,
  };
}

export const DialerQueue = {
  encode(message: DialerQueue, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.active !== undefined) {
      writer.uint32(24).bool(message.active);
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(34).fork()).ldelim();
    }
    if (message.lastCallAt !== undefined) {
      Timestamp.encode(toTimestamp(message.lastCallAt), writer.uint32(42).fork()).ldelim();
    }
    if (message.callRouting !== undefined) {
      DialerQueue_CallRouting.encode(message.callRouting, writer.uint32(50).fork()).ldelim();
    }
    if (message.scriptCallLaunch !== undefined) {
      ScriptCallLaunch.encode(message.scriptCallLaunch, writer.uint32(58).fork()).ldelim();
    }
    if (message.schedule !== undefined) {
      DialerSchedule.encode(message.schedule, writer.uint32(66).fork()).ldelim();
    }
    if (message.afterHourSettings !== undefined) {
      DialerQueue_AfterHourSettings.encode(message.afterHourSettings, writer.uint32(74).fork()).ldelim();
    }
    if (message.allowAllMarketingAgents !== undefined) {
      writer.uint32(80).bool(message.allowAllMarketingAgents);
    }
    Object.entries(message.marketingAgents).forEach(([key, value]) => {
      DialerQueue_MarketingAgentsEntry.encode({ key: key as any, value }, writer.uint32(90).fork()).ldelim();
    });
    Object.entries(message.salesAgents).forEach(([key, value]) => {
      DialerQueue_SalesAgentsEntry.encode({ key: key as any, value }, writer.uint32(98).fork()).ldelim();
    });
    Object.entries(message.numbers).forEach(([key, value]) => {
      DialerQueue_NumbersEntry.encode({ key: key as any, value }, writer.uint32(106).fork()).ldelim();
    });
    if (message.neededSkills !== undefined) {
      Struct.encode(Struct.wrap(message.neededSkills), writer.uint32(114).fork()).ldelim();
    }
    if (message.callCap !== undefined) {
      DialerCallCap.encode(message.callCap, writer.uint32(122).fork()).ldelim();
    }
    if (message.priority !== undefined) {
      writer.uint32(128).int32(message.priority);
    }
    if (message.agencyId !== undefined) {
      writer.uint32(138).string(message.agencyId);
    }
    if (message.agency !== undefined) {
      UserIdContainer.encode(message.agency, writer.uint32(146).fork()).ldelim();
    }
    if (message.marketingAgent !== undefined) {
      StringListContainer.encode(message.marketingAgent, writer.uint32(154).fork()).ldelim();
    }
    if (message.salesAgent !== undefined) {
      StringListContainer.encode(message.salesAgent, writer.uint32(162).fork()).ldelim();
    }
    if (message.salesAgentQueueId !== undefined) {
      StringListContainer.encode(message.salesAgentQueueId, writer.uint32(170).fork()).ldelim();
    }
    if (message.number !== undefined) {
      StringListContainer.encode(message.number, writer.uint32(178).fork()).ldelim();
    }
    if (message.dispoStatusCallLaunch !== undefined) {
      DialerDispoStatusCallLaunch.encode(message.dispoStatusCallLaunch, writer.uint32(186).fork()).ldelim();
    }
    if (message.billableSettings !== undefined) {
      DialerBillableSettings.encode(message.billableSettings, writer.uint32(194).fork()).ldelim();
    }
    if (message.settings !== undefined) {
      DialerQueueSettingsContainer.encode(message.settings, writer.uint32(202).fork()).ldelim();
    }
    if (message.callCapQueue !== undefined) {
      DialerCallCap.encode(message.callCapQueue, writer.uint32(210).fork()).ldelim();
    }
    if (message.callCapReceiverQueue !== undefined) {
      DialerCallCap.encode(message.callCapReceiverQueue, writer.uint32(218).fork()).ldelim();
    }
    if (message.concurrentCallCap !== undefined) {
      DialerConcurrentCallCap.encode(message.concurrentCallCap, writer.uint32(226).fork()).ldelim();
    }
    if (message.agencySettings !== undefined) {
      DialerAgencySettings.encode(message.agencySettings, writer.uint32(234).fork()).ldelim();
    }
    if (message.soldSettings !== undefined) {
      DialerBillableSettings.encode(message.soldSettings, writer.uint32(242).fork()).ldelim();
    }
    if (message.internalBillableSettings !== undefined) {
      DialerBillableSettings.encode(message.internalBillableSettings, writer.uint32(250).fork()).ldelim();
    }
    if (message.restricted !== undefined) {
      writer.uint32(256).bool(message.restricted);
    }
    if (message.queueType !== undefined) {
      writer.uint32(264).int32(message.queueType);
    }
    if (message.prioritization !== undefined) {
      DialerQueuePrioritizationContainer.encode(message.prioritization, writer.uint32(274).fork()).ldelim();
    }
    if (message.enrollmentCodeSettings !== undefined) {
      DialerQueueEnrollmentCodeSettings.encode(message.enrollmentCodeSettings, writer.uint32(282).fork()).ldelim();
    }
    if (message.updatedAt !== undefined) {
      writer.uint32(288).int64(message.updatedAt);
    }
    if (message.restrict !== undefined) {
      DialerQueue_RestrictContainer.encode(message.restrict, writer.uint32(298).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerQueue {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerQueue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.active = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.lastCallAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.callRouting = DialerQueue_CallRouting.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.scriptCallLaunch = ScriptCallLaunch.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.schedule = DialerSchedule.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.afterHourSettings = DialerQueue_AfterHourSettings.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.allowAllMarketingAgents = reader.bool();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          const entry11 = DialerQueue_MarketingAgentsEntry.decode(reader, reader.uint32());
          if (entry11.value !== undefined) {
            message.marketingAgents[entry11.key] = entry11.value;
          }
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          const entry12 = DialerQueue_SalesAgentsEntry.decode(reader, reader.uint32());
          if (entry12.value !== undefined) {
            message.salesAgents[entry12.key] = entry12.value;
          }
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          const entry13 = DialerQueue_NumbersEntry.decode(reader, reader.uint32());
          if (entry13.value !== undefined) {
            message.numbers[entry13.key] = entry13.value;
          }
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.neededSkills = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.callCap = DialerCallCap.decode(reader, reader.uint32());
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.priority = reader.int32();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.agencyId = reader.string();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.agency = UserIdContainer.decode(reader, reader.uint32());
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.marketingAgent = StringListContainer.decode(reader, reader.uint32());
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.salesAgent = StringListContainer.decode(reader, reader.uint32());
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.salesAgentQueueId = StringListContainer.decode(reader, reader.uint32());
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.number = StringListContainer.decode(reader, reader.uint32());
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.dispoStatusCallLaunch = DialerDispoStatusCallLaunch.decode(reader, reader.uint32());
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.billableSettings = DialerBillableSettings.decode(reader, reader.uint32());
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.settings = DialerQueueSettingsContainer.decode(reader, reader.uint32());
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          message.callCapQueue = DialerCallCap.decode(reader, reader.uint32());
          continue;
        case 27:
          if (tag !== 218) {
            break;
          }

          message.callCapReceiverQueue = DialerCallCap.decode(reader, reader.uint32());
          continue;
        case 28:
          if (tag !== 226) {
            break;
          }

          message.concurrentCallCap = DialerConcurrentCallCap.decode(reader, reader.uint32());
          continue;
        case 29:
          if (tag !== 234) {
            break;
          }

          message.agencySettings = DialerAgencySettings.decode(reader, reader.uint32());
          continue;
        case 30:
          if (tag !== 242) {
            break;
          }

          message.soldSettings = DialerBillableSettings.decode(reader, reader.uint32());
          continue;
        case 31:
          if (tag !== 250) {
            break;
          }

          message.internalBillableSettings = DialerBillableSettings.decode(reader, reader.uint32());
          continue;
        case 32:
          if (tag !== 256) {
            break;
          }

          message.restricted = reader.bool();
          continue;
        case 33:
          if (tag !== 264) {
            break;
          }

          message.queueType = reader.int32() as any;
          continue;
        case 34:
          if (tag !== 274) {
            break;
          }

          message.prioritization = DialerQueuePrioritizationContainer.decode(reader, reader.uint32());
          continue;
        case 35:
          if (tag !== 282) {
            break;
          }

          message.enrollmentCodeSettings = DialerQueueEnrollmentCodeSettings.decode(reader, reader.uint32());
          continue;
        case 36:
          if (tag !== 288) {
            break;
          }

          message.updatedAt = longToNumber(reader.int64() as Long);
          continue;
        case 37:
          if (tag !== 298) {
            break;
          }

          message.restrict = DialerQueue_RestrictContainer.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerQueue {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      active: isSet(object.active) ? Boolean(object.active) : undefined,
      createdAt: isSet(object.createdAt) ? fromJsonTimestamp(object.createdAt) : undefined,
      lastCallAt: isSet(object.lastCallAt) ? fromJsonTimestamp(object.lastCallAt) : undefined,
      callRouting: isSet(object.callRouting) ? DialerQueue_CallRouting.fromJSON(object.callRouting) : undefined,
      scriptCallLaunch: isSet(object.scriptCallLaunch) ? ScriptCallLaunch.fromJSON(object.scriptCallLaunch) : undefined,
      schedule: isSet(object.schedule) ? DialerSchedule.fromJSON(object.schedule) : undefined,
      afterHourSettings: isSet(object.afterHourSettings)
        ? DialerQueue_AfterHourSettings.fromJSON(object.afterHourSettings)
        : undefined,
      allowAllMarketingAgents: isSet(object.allowAllMarketingAgents)
        ? Boolean(object.allowAllMarketingAgents)
        : undefined,
      marketingAgents: isObject(object.marketingAgents)
        ? Object.entries(object.marketingAgents).reduce<{ [key: string]: DialerQueue_ReceiverSender }>(
          (acc, [key, value]) => {
            acc[key] = DialerQueue_ReceiverSender.fromJSON(value);
            return acc;
          },
          {},
        )
        : {},
      salesAgents: isObject(object.salesAgents)
        ? Object.entries(object.salesAgents).reduce<{ [key: string]: DialerQueue_ReceiverSender }>(
          (acc, [key, value]) => {
            acc[key] = DialerQueue_ReceiverSender.fromJSON(value);
            return acc;
          },
          {},
        )
        : {},
      numbers: isObject(object.numbers)
        ? Object.entries(object.numbers).reduce<{ [key: string]: DialerNumber }>((acc, [key, value]) => {
          acc[key] = DialerNumber.fromJSON(value);
          return acc;
        }, {})
        : {},
      neededSkills: isObject(object.neededSkills) ? object.neededSkills : undefined,
      callCap: isSet(object.callCap) ? DialerCallCap.fromJSON(object.callCap) : undefined,
      priority: isSet(object.priority) ? Number(object.priority) : undefined,
      agencyId: isSet(object.agencyId) ? String(object.agencyId) : undefined,
      agency: isSet(object.agency) ? UserIdContainer.fromJSON(object.agency) : undefined,
      marketingAgent: isSet(object.marketingAgent) ? StringListContainer.fromJSON(object.marketingAgent) : undefined,
      salesAgent: isSet(object.salesAgent) ? StringListContainer.fromJSON(object.salesAgent) : undefined,
      salesAgentQueueId: isSet(object.salesAgentQueueId)
        ? StringListContainer.fromJSON(object.salesAgentQueueId)
        : undefined,
      number: isSet(object.number) ? StringListContainer.fromJSON(object.number) : undefined,
      dispoStatusCallLaunch: isSet(object.dispoStatusCallLaunch)
        ? DialerDispoStatusCallLaunch.fromJSON(object.dispoStatusCallLaunch)
        : undefined,
      billableSettings: isSet(object.billableSettings)
        ? DialerBillableSettings.fromJSON(object.billableSettings)
        : undefined,
      settings: isSet(object.settings) ? DialerQueueSettingsContainer.fromJSON(object.settings) : undefined,
      callCapQueue: isSet(object.callCapQueue) ? DialerCallCap.fromJSON(object.callCapQueue) : undefined,
      callCapReceiverQueue: isSet(object.callCapReceiverQueue)
        ? DialerCallCap.fromJSON(object.callCapReceiverQueue)
        : undefined,
      concurrentCallCap: isSet(object.concurrentCallCap)
        ? DialerConcurrentCallCap.fromJSON(object.concurrentCallCap)
        : undefined,
      agencySettings: isSet(object.agencySettings) ? DialerAgencySettings.fromJSON(object.agencySettings) : undefined,
      soldSettings: isSet(object.soldSettings) ? DialerBillableSettings.fromJSON(object.soldSettings) : undefined,
      internalBillableSettings: isSet(object.internalBillableSettings)
        ? DialerBillableSettings.fromJSON(object.internalBillableSettings)
        : undefined,
      restricted: isSet(object.restricted) ? Boolean(object.restricted) : undefined,
      queueType: isSet(object.queueType) ? dialerQueueTypeFromJSON(object.queueType) : undefined,
      prioritization: isSet(object.prioritization)
        ? DialerQueuePrioritizationContainer.fromJSON(object.prioritization)
        : undefined,
      enrollmentCodeSettings: isSet(object.enrollmentCodeSettings)
        ? DialerQueueEnrollmentCodeSettings.fromJSON(object.enrollmentCodeSettings)
        : undefined,
      updatedAt: isSet(object.updatedAt) ? Number(object.updatedAt) : undefined,
      restrict: isSet(object.restrict) ? DialerQueue_RestrictContainer.fromJSON(object.restrict) : undefined,
    };
  },

  toJSON(message: DialerQueue): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.active !== undefined && (obj.active = message.active);
    message.createdAt !== undefined && (obj.createdAt = message.createdAt.toISOString());
    message.lastCallAt !== undefined && (obj.lastCallAt = message.lastCallAt.toISOString());
    message.callRouting !== undefined &&
      (obj.callRouting = message.callRouting ? DialerQueue_CallRouting.toJSON(message.callRouting) : undefined);
    message.scriptCallLaunch !== undefined &&
      (obj.scriptCallLaunch = message.scriptCallLaunch ? ScriptCallLaunch.toJSON(message.scriptCallLaunch) : undefined);
    message.schedule !== undefined &&
      (obj.schedule = message.schedule ? DialerSchedule.toJSON(message.schedule) : undefined);
    message.afterHourSettings !== undefined && (obj.afterHourSettings = message.afterHourSettings
      ? DialerQueue_AfterHourSettings.toJSON(message.afterHourSettings)
      : undefined);
    message.allowAllMarketingAgents !== undefined && (obj.allowAllMarketingAgents = message.allowAllMarketingAgents);
    obj.marketingAgents = {};
    if (message.marketingAgents) {
      Object.entries(message.marketingAgents).forEach(([k, v]) => {
        obj.marketingAgents[k] = DialerQueue_ReceiverSender.toJSON(v);
      });
    }
    obj.salesAgents = {};
    if (message.salesAgents) {
      Object.entries(message.salesAgents).forEach(([k, v]) => {
        obj.salesAgents[k] = DialerQueue_ReceiverSender.toJSON(v);
      });
    }
    obj.numbers = {};
    if (message.numbers) {
      Object.entries(message.numbers).forEach(([k, v]) => {
        obj.numbers[k] = DialerNumber.toJSON(v);
      });
    }
    message.neededSkills !== undefined && (obj.neededSkills = message.neededSkills);
    message.callCap !== undefined &&
      (obj.callCap = message.callCap ? DialerCallCap.toJSON(message.callCap) : undefined);
    message.priority !== undefined && (obj.priority = Math.round(message.priority));
    message.agencyId !== undefined && (obj.agencyId = message.agencyId);
    message.agency !== undefined && (obj.agency = message.agency ? UserIdContainer.toJSON(message.agency) : undefined);
    message.marketingAgent !== undefined &&
      (obj.marketingAgent = message.marketingAgent ? StringListContainer.toJSON(message.marketingAgent) : undefined);
    message.salesAgent !== undefined &&
      (obj.salesAgent = message.salesAgent ? StringListContainer.toJSON(message.salesAgent) : undefined);
    message.salesAgentQueueId !== undefined && (obj.salesAgentQueueId = message.salesAgentQueueId
      ? StringListContainer.toJSON(message.salesAgentQueueId)
      : undefined);
    message.number !== undefined &&
      (obj.number = message.number ? StringListContainer.toJSON(message.number) : undefined);
    message.dispoStatusCallLaunch !== undefined && (obj.dispoStatusCallLaunch = message.dispoStatusCallLaunch
      ? DialerDispoStatusCallLaunch.toJSON(message.dispoStatusCallLaunch)
      : undefined);
    message.billableSettings !== undefined && (obj.billableSettings = message.billableSettings
      ? DialerBillableSettings.toJSON(message.billableSettings)
      : undefined);
    message.settings !== undefined &&
      (obj.settings = message.settings ? DialerQueueSettingsContainer.toJSON(message.settings) : undefined);
    message.callCapQueue !== undefined &&
      (obj.callCapQueue = message.callCapQueue ? DialerCallCap.toJSON(message.callCapQueue) : undefined);
    message.callCapReceiverQueue !== undefined && (obj.callCapReceiverQueue = message.callCapReceiverQueue
      ? DialerCallCap.toJSON(message.callCapReceiverQueue)
      : undefined);
    message.concurrentCallCap !== undefined && (obj.concurrentCallCap = message.concurrentCallCap
      ? DialerConcurrentCallCap.toJSON(message.concurrentCallCap)
      : undefined);
    message.agencySettings !== undefined &&
      (obj.agencySettings = message.agencySettings ? DialerAgencySettings.toJSON(message.agencySettings) : undefined);
    message.soldSettings !== undefined &&
      (obj.soldSettings = message.soldSettings ? DialerBillableSettings.toJSON(message.soldSettings) : undefined);
    message.internalBillableSettings !== undefined && (obj.internalBillableSettings = message.internalBillableSettings
      ? DialerBillableSettings.toJSON(message.internalBillableSettings)
      : undefined);
    message.restricted !== undefined && (obj.restricted = message.restricted);
    message.queueType !== undefined &&
      (obj.queueType = message.queueType !== undefined ? dialerQueueTypeToJSON(message.queueType) : undefined);
    message.prioritization !== undefined && (obj.prioritization = message.prioritization
      ? DialerQueuePrioritizationContainer.toJSON(message.prioritization)
      : undefined);
    message.enrollmentCodeSettings !== undefined && (obj.enrollmentCodeSettings = message.enrollmentCodeSettings
      ? DialerQueueEnrollmentCodeSettings.toJSON(message.enrollmentCodeSettings)
      : undefined);
    message.updatedAt !== undefined && (obj.updatedAt = Math.round(message.updatedAt));
    message.restrict !== undefined &&
      (obj.restrict = message.restrict ? DialerQueue_RestrictContainer.toJSON(message.restrict) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerQueue>, I>>(base?: I): DialerQueue {
    return DialerQueue.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerQueue>, I>>(object: I): DialerQueue {
    const message = createBaseDialerQueue();
    message.id = object.id ?? undefined;
    message.name = object.name ?? undefined;
    message.active = object.active ?? undefined;
    message.createdAt = object.createdAt ?? undefined;
    message.lastCallAt = object.lastCallAt ?? undefined;
    message.callRouting = (object.callRouting !== undefined && object.callRouting !== null)
      ? DialerQueue_CallRouting.fromPartial(object.callRouting)
      : undefined;
    message.scriptCallLaunch = (object.scriptCallLaunch !== undefined && object.scriptCallLaunch !== null)
      ? ScriptCallLaunch.fromPartial(object.scriptCallLaunch)
      : undefined;
    message.schedule = (object.schedule !== undefined && object.schedule !== null)
      ? DialerSchedule.fromPartial(object.schedule)
      : undefined;
    message.afterHourSettings = (object.afterHourSettings !== undefined && object.afterHourSettings !== null)
      ? DialerQueue_AfterHourSettings.fromPartial(object.afterHourSettings)
      : undefined;
    message.allowAllMarketingAgents = object.allowAllMarketingAgents ?? undefined;
    message.marketingAgents = Object.entries(object.marketingAgents ?? {}).reduce<
      { [key: string]: DialerQueue_ReceiverSender }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = DialerQueue_ReceiverSender.fromPartial(value);
      }
      return acc;
    }, {});
    message.salesAgents = Object.entries(object.salesAgents ?? {}).reduce<
      { [key: string]: DialerQueue_ReceiverSender }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = DialerQueue_ReceiverSender.fromPartial(value);
      }
      return acc;
    }, {});
    message.numbers = Object.entries(object.numbers ?? {}).reduce<{ [key: string]: DialerNumber }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = DialerNumber.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    message.neededSkills = object.neededSkills ?? undefined;
    message.callCap = (object.callCap !== undefined && object.callCap !== null)
      ? DialerCallCap.fromPartial(object.callCap)
      : undefined;
    message.priority = object.priority ?? undefined;
    message.agencyId = object.agencyId ?? undefined;
    message.agency = (object.agency !== undefined && object.agency !== null)
      ? UserIdContainer.fromPartial(object.agency)
      : undefined;
    message.marketingAgent = (object.marketingAgent !== undefined && object.marketingAgent !== null)
      ? StringListContainer.fromPartial(object.marketingAgent)
      : undefined;
    message.salesAgent = (object.salesAgent !== undefined && object.salesAgent !== null)
      ? StringListContainer.fromPartial(object.salesAgent)
      : undefined;
    message.salesAgentQueueId = (object.salesAgentQueueId !== undefined && object.salesAgentQueueId !== null)
      ? StringListContainer.fromPartial(object.salesAgentQueueId)
      : undefined;
    message.number = (object.number !== undefined && object.number !== null)
      ? StringListContainer.fromPartial(object.number)
      : undefined;
    message.dispoStatusCallLaunch =
      (object.dispoStatusCallLaunch !== undefined && object.dispoStatusCallLaunch !== null)
        ? DialerDispoStatusCallLaunch.fromPartial(object.dispoStatusCallLaunch)
        : undefined;
    message.billableSettings = (object.billableSettings !== undefined && object.billableSettings !== null)
      ? DialerBillableSettings.fromPartial(object.billableSettings)
      : undefined;
    message.settings = (object.settings !== undefined && object.settings !== null)
      ? DialerQueueSettingsContainer.fromPartial(object.settings)
      : undefined;
    message.callCapQueue = (object.callCapQueue !== undefined && object.callCapQueue !== null)
      ? DialerCallCap.fromPartial(object.callCapQueue)
      : undefined;
    message.callCapReceiverQueue = (object.callCapReceiverQueue !== undefined && object.callCapReceiverQueue !== null)
      ? DialerCallCap.fromPartial(object.callCapReceiverQueue)
      : undefined;
    message.concurrentCallCap = (object.concurrentCallCap !== undefined && object.concurrentCallCap !== null)
      ? DialerConcurrentCallCap.fromPartial(object.concurrentCallCap)
      : undefined;
    message.agencySettings = (object.agencySettings !== undefined && object.agencySettings !== null)
      ? DialerAgencySettings.fromPartial(object.agencySettings)
      : undefined;
    message.soldSettings = (object.soldSettings !== undefined && object.soldSettings !== null)
      ? DialerBillableSettings.fromPartial(object.soldSettings)
      : undefined;
    message.internalBillableSettings =
      (object.internalBillableSettings !== undefined && object.internalBillableSettings !== null)
        ? DialerBillableSettings.fromPartial(object.internalBillableSettings)
        : undefined;
    message.restricted = object.restricted ?? undefined;
    message.queueType = object.queueType ?? undefined;
    message.prioritization = (object.prioritization !== undefined && object.prioritization !== null)
      ? DialerQueuePrioritizationContainer.fromPartial(object.prioritization)
      : undefined;
    message.enrollmentCodeSettings =
      (object.enrollmentCodeSettings !== undefined && object.enrollmentCodeSettings !== null)
        ? DialerQueueEnrollmentCodeSettings.fromPartial(object.enrollmentCodeSettings)
        : undefined;
    message.updatedAt = object.updatedAt ?? undefined;
    message.restrict = (object.restrict !== undefined && object.restrict !== null)
      ? DialerQueue_RestrictContainer.fromPartial(object.restrict)
      : undefined;
    return message;
  },
};

function createBaseDialerQueue_MarketingAgentsEntry(): DialerQueue_MarketingAgentsEntry {
  return { key: "", value: undefined };
}

export const DialerQueue_MarketingAgentsEntry = {
  encode(message: DialerQueue_MarketingAgentsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      DialerQueue_ReceiverSender.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerQueue_MarketingAgentsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerQueue_MarketingAgentsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = DialerQueue_ReceiverSender.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerQueue_MarketingAgentsEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? DialerQueue_ReceiverSender.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: DialerQueue_MarketingAgentsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined &&
      (obj.value = message.value ? DialerQueue_ReceiverSender.toJSON(message.value) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerQueue_MarketingAgentsEntry>, I>>(
    base?: I,
  ): DialerQueue_MarketingAgentsEntry {
    return DialerQueue_MarketingAgentsEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerQueue_MarketingAgentsEntry>, I>>(
    object: I,
  ): DialerQueue_MarketingAgentsEntry {
    const message = createBaseDialerQueue_MarketingAgentsEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? DialerQueue_ReceiverSender.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseDialerQueue_SalesAgentsEntry(): DialerQueue_SalesAgentsEntry {
  return { key: "", value: undefined };
}

export const DialerQueue_SalesAgentsEntry = {
  encode(message: DialerQueue_SalesAgentsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      DialerQueue_ReceiverSender.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerQueue_SalesAgentsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerQueue_SalesAgentsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = DialerQueue_ReceiverSender.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerQueue_SalesAgentsEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? DialerQueue_ReceiverSender.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: DialerQueue_SalesAgentsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined &&
      (obj.value = message.value ? DialerQueue_ReceiverSender.toJSON(message.value) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerQueue_SalesAgentsEntry>, I>>(base?: I): DialerQueue_SalesAgentsEntry {
    return DialerQueue_SalesAgentsEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerQueue_SalesAgentsEntry>, I>>(object: I): DialerQueue_SalesAgentsEntry {
    const message = createBaseDialerQueue_SalesAgentsEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? DialerQueue_ReceiverSender.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseDialerQueue_NumbersEntry(): DialerQueue_NumbersEntry {
  return { key: "", value: undefined };
}

export const DialerQueue_NumbersEntry = {
  encode(message: DialerQueue_NumbersEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      DialerNumber.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerQueue_NumbersEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerQueue_NumbersEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = DialerNumber.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerQueue_NumbersEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? DialerNumber.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: DialerQueue_NumbersEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value ? DialerNumber.toJSON(message.value) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerQueue_NumbersEntry>, I>>(base?: I): DialerQueue_NumbersEntry {
    return DialerQueue_NumbersEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerQueue_NumbersEntry>, I>>(object: I): DialerQueue_NumbersEntry {
    const message = createBaseDialerQueue_NumbersEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? DialerNumber.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseDialerQueue_RestrictContainer(): DialerQueue_RestrictContainer {
  return { billableSettings: undefined, soldSettings: undefined };
}

export const DialerQueue_RestrictContainer = {
  encode(message: DialerQueue_RestrictContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.billableSettings !== undefined) {
      writer.uint32(8).bool(message.billableSettings);
    }
    if (message.soldSettings !== undefined) {
      writer.uint32(16).bool(message.soldSettings);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerQueue_RestrictContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerQueue_RestrictContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.billableSettings = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.soldSettings = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerQueue_RestrictContainer {
    return {
      billableSettings: isSet(object.billableSettings) ? Boolean(object.billableSettings) : undefined,
      soldSettings: isSet(object.soldSettings) ? Boolean(object.soldSettings) : undefined,
    };
  },

  toJSON(message: DialerQueue_RestrictContainer): unknown {
    const obj: any = {};
    message.billableSettings !== undefined && (obj.billableSettings = message.billableSettings);
    message.soldSettings !== undefined && (obj.soldSettings = message.soldSettings);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerQueue_RestrictContainer>, I>>(base?: I): DialerQueue_RestrictContainer {
    return DialerQueue_RestrictContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerQueue_RestrictContainer>, I>>(
    object: I,
  ): DialerQueue_RestrictContainer {
    const message = createBaseDialerQueue_RestrictContainer();
    message.billableSettings = object.billableSettings ?? undefined;
    message.soldSettings = object.soldSettings ?? undefined;
    return message;
  },
};

function createBaseDialerQueue_ReceiverSender(): DialerQueue_ReceiverSender {
  return {
    id: undefined,
    name: undefined,
    firstName: undefined,
    lastName: undefined,
    npn: undefined,
    profileImage: undefined,
    agency: undefined,
    type: undefined,
    soldSettings: undefined,
    advanceSettings: undefined,
  };
}

export const DialerQueue_ReceiverSender = {
  encode(message: DialerQueue_ReceiverSender, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.firstName !== undefined) {
      writer.uint32(26).string(message.firstName);
    }
    if (message.lastName !== undefined) {
      writer.uint32(34).string(message.lastName);
    }
    if (message.npn !== undefined) {
      writer.uint32(42).string(message.npn);
    }
    if (message.profileImage !== undefined) {
      writer.uint32(50).string(message.profileImage);
    }
    if (message.agency !== undefined) {
      UserIdContainer.encode(message.agency, writer.uint32(58).fork()).ldelim();
    }
    if (message.type !== undefined) {
      writer.uint32(64).int32(message.type);
    }
    if (message.soldSettings !== undefined) {
      DialerBillableSettings.encode(message.soldSettings, writer.uint32(74).fork()).ldelim();
    }
    if (message.advanceSettings !== undefined) {
      AdvanceSettings.encode(message.advanceSettings, writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerQueue_ReceiverSender {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerQueue_ReceiverSender();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.lastName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.npn = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.profileImage = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.agency = UserIdContainer.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.soldSettings = DialerBillableSettings.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.advanceSettings = AdvanceSettings.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerQueue_ReceiverSender {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      firstName: isSet(object.firstName) ? String(object.firstName) : undefined,
      lastName: isSet(object.lastName) ? String(object.lastName) : undefined,
      npn: isSet(object.npn) ? String(object.npn) : undefined,
      profileImage: isSet(object.profileImage) ? String(object.profileImage) : undefined,
      agency: isSet(object.agency) ? UserIdContainer.fromJSON(object.agency) : undefined,
      type: isSet(object.type) ? dialerClientState_ClientStateTarget_TargetTypeFromJSON(object.type) : undefined,
      soldSettings: isSet(object.soldSettings) ? DialerBillableSettings.fromJSON(object.soldSettings) : undefined,
      advanceSettings: isSet(object.advanceSettings) ? AdvanceSettings.fromJSON(object.advanceSettings) : undefined,
    };
  },

  toJSON(message: DialerQueue_ReceiverSender): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.firstName !== undefined && (obj.firstName = message.firstName);
    message.lastName !== undefined && (obj.lastName = message.lastName);
    message.npn !== undefined && (obj.npn = message.npn);
    message.profileImage !== undefined && (obj.profileImage = message.profileImage);
    message.agency !== undefined && (obj.agency = message.agency ? UserIdContainer.toJSON(message.agency) : undefined);
    message.type !== undefined && (obj.type = message.type !== undefined
      ? dialerClientState_ClientStateTarget_TargetTypeToJSON(message.type)
      : undefined);
    message.soldSettings !== undefined &&
      (obj.soldSettings = message.soldSettings ? DialerBillableSettings.toJSON(message.soldSettings) : undefined);
    message.advanceSettings !== undefined &&
      (obj.advanceSettings = message.advanceSettings ? AdvanceSettings.toJSON(message.advanceSettings) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerQueue_ReceiverSender>, I>>(base?: I): DialerQueue_ReceiverSender {
    return DialerQueue_ReceiverSender.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerQueue_ReceiverSender>, I>>(object: I): DialerQueue_ReceiverSender {
    const message = createBaseDialerQueue_ReceiverSender();
    message.id = object.id ?? undefined;
    message.name = object.name ?? undefined;
    message.firstName = object.firstName ?? undefined;
    message.lastName = object.lastName ?? undefined;
    message.npn = object.npn ?? undefined;
    message.profileImage = object.profileImage ?? undefined;
    message.agency = (object.agency !== undefined && object.agency !== null)
      ? UserIdContainer.fromPartial(object.agency)
      : undefined;
    message.type = object.type ?? undefined;
    message.soldSettings = (object.soldSettings !== undefined && object.soldSettings !== null)
      ? DialerBillableSettings.fromPartial(object.soldSettings)
      : undefined;
    message.advanceSettings = (object.advanceSettings !== undefined && object.advanceSettings !== null)
      ? AdvanceSettings.fromPartial(object.advanceSettings)
      : undefined;
    return message;
  },
};

function createBaseDialerQueue_CallRouting(): DialerQueue_CallRouting {
  return {
    redirectToNumber: undefined,
    number: undefined,
    transferFromAgentPriority: undefined,
    autoAccept: undefined,
    hangup: undefined,
    skills: undefined,
    inverseQueuePriority: undefined,
    leadBlock: undefined,
    agentSelection: undefined,
    shareLeadData: undefined,
    timeoutRedirectInS: undefined,
    ignoreDelayInQueue: undefined,
  };
}

export const DialerQueue_CallRouting = {
  encode(message: DialerQueue_CallRouting, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.redirectToNumber !== undefined) {
      writer.uint32(8).bool(message.redirectToNumber);
    }
    if (message.number !== undefined) {
      writer.uint32(18).string(message.number);
    }
    if (message.transferFromAgentPriority !== undefined) {
      writer.uint32(24).bool(message.transferFromAgentPriority);
    }
    if (message.autoAccept !== undefined) {
      DialerQueue_CallRouting_AutoAccept.encode(message.autoAccept, writer.uint32(34).fork()).ldelim();
    }
    if (message.hangup !== undefined) {
      DialerQueue_CallRouting_Hangup.encode(message.hangup, writer.uint32(42).fork()).ldelim();
    }
    if (message.skills !== undefined) {
      DialerQueue_CallRouting_CallRoutingSkills.encode(message.skills, writer.uint32(50).fork()).ldelim();
    }
    if (message.inverseQueuePriority !== undefined) {
      writer.uint32(56).bool(message.inverseQueuePriority);
    }
    if (message.leadBlock !== undefined) {
      DialerQueue_CallRouting_LeadBlock.encode(message.leadBlock, writer.uint32(66).fork()).ldelim();
    }
    if (message.agentSelection !== undefined) {
      DialerAgentSelection.encode(message.agentSelection, writer.uint32(74).fork()).ldelim();
    }
    if (message.shareLeadData !== undefined) {
      writer.uint32(80).bool(message.shareLeadData);
    }
    if (message.timeoutRedirectInS !== undefined) {
      writer.uint32(88).int32(message.timeoutRedirectInS);
    }
    if (message.ignoreDelayInQueue !== undefined) {
      writer.uint32(96).bool(message.ignoreDelayInQueue);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerQueue_CallRouting {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerQueue_CallRouting();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.redirectToNumber = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.number = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.transferFromAgentPriority = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.autoAccept = DialerQueue_CallRouting_AutoAccept.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.hangup = DialerQueue_CallRouting_Hangup.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.skills = DialerQueue_CallRouting_CallRoutingSkills.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.inverseQueuePriority = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.leadBlock = DialerQueue_CallRouting_LeadBlock.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.agentSelection = DialerAgentSelection.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.shareLeadData = reader.bool();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.timeoutRedirectInS = reader.int32();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.ignoreDelayInQueue = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerQueue_CallRouting {
    return {
      redirectToNumber: isSet(object.redirectToNumber) ? Boolean(object.redirectToNumber) : undefined,
      number: isSet(object.number) ? String(object.number) : undefined,
      transferFromAgentPriority: isSet(object.transferFromAgentPriority)
        ? Boolean(object.transferFromAgentPriority)
        : undefined,
      autoAccept: isSet(object.autoAccept) ? DialerQueue_CallRouting_AutoAccept.fromJSON(object.autoAccept) : undefined,
      hangup: isSet(object.hangup) ? DialerQueue_CallRouting_Hangup.fromJSON(object.hangup) : undefined,
      skills: isSet(object.skills) ? DialerQueue_CallRouting_CallRoutingSkills.fromJSON(object.skills) : undefined,
      inverseQueuePriority: isSet(object.inverseQueuePriority) ? Boolean(object.inverseQueuePriority) : undefined,
      leadBlock: isSet(object.leadBlock) ? DialerQueue_CallRouting_LeadBlock.fromJSON(object.leadBlock) : undefined,
      agentSelection: isSet(object.agentSelection) ? DialerAgentSelection.fromJSON(object.agentSelection) : undefined,
      shareLeadData: isSet(object.shareLeadData) ? Boolean(object.shareLeadData) : undefined,
      timeoutRedirectInS: isSet(object.timeoutRedirectInS) ? Number(object.timeoutRedirectInS) : undefined,
      ignoreDelayInQueue: isSet(object.ignoreDelayInQueue) ? Boolean(object.ignoreDelayInQueue) : undefined,
    };
  },

  toJSON(message: DialerQueue_CallRouting): unknown {
    const obj: any = {};
    message.redirectToNumber !== undefined && (obj.redirectToNumber = message.redirectToNumber);
    message.number !== undefined && (obj.number = message.number);
    message.transferFromAgentPriority !== undefined &&
      (obj.transferFromAgentPriority = message.transferFromAgentPriority);
    message.autoAccept !== undefined &&
      (obj.autoAccept = message.autoAccept ? DialerQueue_CallRouting_AutoAccept.toJSON(message.autoAccept) : undefined);
    message.hangup !== undefined &&
      (obj.hangup = message.hangup ? DialerQueue_CallRouting_Hangup.toJSON(message.hangup) : undefined);
    message.skills !== undefined &&
      (obj.skills = message.skills ? DialerQueue_CallRouting_CallRoutingSkills.toJSON(message.skills) : undefined);
    message.inverseQueuePriority !== undefined && (obj.inverseQueuePriority = message.inverseQueuePriority);
    message.leadBlock !== undefined &&
      (obj.leadBlock = message.leadBlock ? DialerQueue_CallRouting_LeadBlock.toJSON(message.leadBlock) : undefined);
    message.agentSelection !== undefined &&
      (obj.agentSelection = message.agentSelection ? DialerAgentSelection.toJSON(message.agentSelection) : undefined);
    message.shareLeadData !== undefined && (obj.shareLeadData = message.shareLeadData);
    message.timeoutRedirectInS !== undefined && (obj.timeoutRedirectInS = Math.round(message.timeoutRedirectInS));
    message.ignoreDelayInQueue !== undefined && (obj.ignoreDelayInQueue = message.ignoreDelayInQueue);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerQueue_CallRouting>, I>>(base?: I): DialerQueue_CallRouting {
    return DialerQueue_CallRouting.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerQueue_CallRouting>, I>>(object: I): DialerQueue_CallRouting {
    const message = createBaseDialerQueue_CallRouting();
    message.redirectToNumber = object.redirectToNumber ?? undefined;
    message.number = object.number ?? undefined;
    message.transferFromAgentPriority = object.transferFromAgentPriority ?? undefined;
    message.autoAccept = (object.autoAccept !== undefined && object.autoAccept !== null)
      ? DialerQueue_CallRouting_AutoAccept.fromPartial(object.autoAccept)
      : undefined;
    message.hangup = (object.hangup !== undefined && object.hangup !== null)
      ? DialerQueue_CallRouting_Hangup.fromPartial(object.hangup)
      : undefined;
    message.skills = (object.skills !== undefined && object.skills !== null)
      ? DialerQueue_CallRouting_CallRoutingSkills.fromPartial(object.skills)
      : undefined;
    message.inverseQueuePriority = object.inverseQueuePriority ?? undefined;
    message.leadBlock = (object.leadBlock !== undefined && object.leadBlock !== null)
      ? DialerQueue_CallRouting_LeadBlock.fromPartial(object.leadBlock)
      : undefined;
    message.agentSelection = (object.agentSelection !== undefined && object.agentSelection !== null)
      ? DialerAgentSelection.fromPartial(object.agentSelection)
      : undefined;
    message.shareLeadData = object.shareLeadData ?? undefined;
    message.timeoutRedirectInS = object.timeoutRedirectInS ?? undefined;
    message.ignoreDelayInQueue = object.ignoreDelayInQueue ?? undefined;
    return message;
  },
};

function createBaseDialerQueue_CallRouting_AutoAccept(): DialerQueue_CallRouting_AutoAccept {
  return {
    enabled: undefined,
    timeoutInMs: undefined,
    textAfterTimeout: undefined,
    playMusic: undefined,
    musicSet: undefined,
  };
}

export const DialerQueue_CallRouting_AutoAccept = {
  encode(message: DialerQueue_CallRouting_AutoAccept, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.enabled !== undefined) {
      writer.uint32(8).bool(message.enabled);
    }
    if (message.timeoutInMs !== undefined) {
      writer.uint32(16).int32(message.timeoutInMs);
    }
    if (message.textAfterTimeout !== undefined) {
      writer.uint32(26).string(message.textAfterTimeout);
    }
    if (message.playMusic !== undefined) {
      writer.uint32(32).bool(message.playMusic);
    }
    if (message.musicSet !== undefined) {
      writer.uint32(42).string(message.musicSet);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerQueue_CallRouting_AutoAccept {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerQueue_CallRouting_AutoAccept();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.timeoutInMs = reader.int32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.textAfterTimeout = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.playMusic = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.musicSet = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerQueue_CallRouting_AutoAccept {
    return {
      enabled: isSet(object.enabled) ? Boolean(object.enabled) : undefined,
      timeoutInMs: isSet(object.timeoutInMs) ? Number(object.timeoutInMs) : undefined,
      textAfterTimeout: isSet(object.textAfterTimeout) ? String(object.textAfterTimeout) : undefined,
      playMusic: isSet(object.playMusic) ? Boolean(object.playMusic) : undefined,
      musicSet: isSet(object.musicSet) ? String(object.musicSet) : undefined,
    };
  },

  toJSON(message: DialerQueue_CallRouting_AutoAccept): unknown {
    const obj: any = {};
    message.enabled !== undefined && (obj.enabled = message.enabled);
    message.timeoutInMs !== undefined && (obj.timeoutInMs = Math.round(message.timeoutInMs));
    message.textAfterTimeout !== undefined && (obj.textAfterTimeout = message.textAfterTimeout);
    message.playMusic !== undefined && (obj.playMusic = message.playMusic);
    message.musicSet !== undefined && (obj.musicSet = message.musicSet);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerQueue_CallRouting_AutoAccept>, I>>(
    base?: I,
  ): DialerQueue_CallRouting_AutoAccept {
    return DialerQueue_CallRouting_AutoAccept.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerQueue_CallRouting_AutoAccept>, I>>(
    object: I,
  ): DialerQueue_CallRouting_AutoAccept {
    const message = createBaseDialerQueue_CallRouting_AutoAccept();
    message.enabled = object.enabled ?? undefined;
    message.timeoutInMs = object.timeoutInMs ?? undefined;
    message.textAfterTimeout = object.textAfterTimeout ?? undefined;
    message.playMusic = object.playMusic ?? undefined;
    message.musicSet = object.musicSet ?? undefined;
    return message;
  },
};

function createBaseDialerQueue_CallRouting_Hangup(): DialerQueue_CallRouting_Hangup {
  return { enabled: undefined, timeoutInS: undefined, textBeforeHangupTimeout: undefined };
}

export const DialerQueue_CallRouting_Hangup = {
  encode(message: DialerQueue_CallRouting_Hangup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.enabled !== undefined) {
      writer.uint32(8).bool(message.enabled);
    }
    if (message.timeoutInS !== undefined) {
      writer.uint32(16).int32(message.timeoutInS);
    }
    if (message.textBeforeHangupTimeout !== undefined) {
      writer.uint32(26).string(message.textBeforeHangupTimeout);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerQueue_CallRouting_Hangup {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerQueue_CallRouting_Hangup();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.timeoutInS = reader.int32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.textBeforeHangupTimeout = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerQueue_CallRouting_Hangup {
    return {
      enabled: isSet(object.enabled) ? Boolean(object.enabled) : undefined,
      timeoutInS: isSet(object.timeoutInS) ? Number(object.timeoutInS) : undefined,
      textBeforeHangupTimeout: isSet(object.textBeforeHangupTimeout)
        ? String(object.textBeforeHangupTimeout)
        : undefined,
    };
  },

  toJSON(message: DialerQueue_CallRouting_Hangup): unknown {
    const obj: any = {};
    message.enabled !== undefined && (obj.enabled = message.enabled);
    message.timeoutInS !== undefined && (obj.timeoutInS = Math.round(message.timeoutInS));
    message.textBeforeHangupTimeout !== undefined && (obj.textBeforeHangupTimeout = message.textBeforeHangupTimeout);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerQueue_CallRouting_Hangup>, I>>(base?: I): DialerQueue_CallRouting_Hangup {
    return DialerQueue_CallRouting_Hangup.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerQueue_CallRouting_Hangup>, I>>(
    object: I,
  ): DialerQueue_CallRouting_Hangup {
    const message = createBaseDialerQueue_CallRouting_Hangup();
    message.enabled = object.enabled ?? undefined;
    message.timeoutInS = object.timeoutInS ?? undefined;
    message.textBeforeHangupTimeout = object.textBeforeHangupTimeout ?? undefined;
    return message;
  },
};

function createBaseDialerQueue_CallRouting_CallRoutingSkills(): DialerQueue_CallRouting_CallRoutingSkills {
  return { enabled: undefined, strictMatching: undefined, skills: [], matchByType: undefined };
}

export const DialerQueue_CallRouting_CallRoutingSkills = {
  encode(message: DialerQueue_CallRouting_CallRoutingSkills, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.enabled !== undefined) {
      writer.uint32(8).bool(message.enabled);
    }
    if (message.strictMatching !== undefined) {
      writer.uint32(16).bool(message.strictMatching);
    }
    for (const v of message.skills) {
      Skill.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.matchByType !== undefined) {
      writer.uint32(32).bool(message.matchByType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerQueue_CallRouting_CallRoutingSkills {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerQueue_CallRouting_CallRoutingSkills();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.strictMatching = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.skills.push(Skill.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.matchByType = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerQueue_CallRouting_CallRoutingSkills {
    return {
      enabled: isSet(object.enabled) ? Boolean(object.enabled) : undefined,
      strictMatching: isSet(object.strictMatching) ? Boolean(object.strictMatching) : undefined,
      skills: Array.isArray(object?.skills) ? object.skills.map((e: any) => Skill.fromJSON(e)) : [],
      matchByType: isSet(object.matchByType) ? Boolean(object.matchByType) : undefined,
    };
  },

  toJSON(message: DialerQueue_CallRouting_CallRoutingSkills): unknown {
    const obj: any = {};
    message.enabled !== undefined && (obj.enabled = message.enabled);
    message.strictMatching !== undefined && (obj.strictMatching = message.strictMatching);
    if (message.skills) {
      obj.skills = message.skills.map((e) => e ? Skill.toJSON(e) : undefined);
    } else {
      obj.skills = [];
    }
    message.matchByType !== undefined && (obj.matchByType = message.matchByType);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerQueue_CallRouting_CallRoutingSkills>, I>>(
    base?: I,
  ): DialerQueue_CallRouting_CallRoutingSkills {
    return DialerQueue_CallRouting_CallRoutingSkills.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerQueue_CallRouting_CallRoutingSkills>, I>>(
    object: I,
  ): DialerQueue_CallRouting_CallRoutingSkills {
    const message = createBaseDialerQueue_CallRouting_CallRoutingSkills();
    message.enabled = object.enabled ?? undefined;
    message.strictMatching = object.strictMatching ?? undefined;
    message.skills = object.skills?.map((e) => Skill.fromPartial(e)) || [];
    message.matchByType = object.matchByType ?? undefined;
    return message;
  },
};

function createBaseDialerQueue_CallRouting_LeadBlock(): DialerQueue_CallRouting_LeadBlock {
  return { enabled: undefined, numberOfCalls: undefined };
}

export const DialerQueue_CallRouting_LeadBlock = {
  encode(message: DialerQueue_CallRouting_LeadBlock, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.enabled !== undefined) {
      writer.uint32(8).bool(message.enabled);
    }
    if (message.numberOfCalls !== undefined) {
      writer.uint32(16).int32(message.numberOfCalls);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerQueue_CallRouting_LeadBlock {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerQueue_CallRouting_LeadBlock();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.numberOfCalls = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerQueue_CallRouting_LeadBlock {
    return {
      enabled: isSet(object.enabled) ? Boolean(object.enabled) : undefined,
      numberOfCalls: isSet(object.numberOfCalls) ? Number(object.numberOfCalls) : undefined,
    };
  },

  toJSON(message: DialerQueue_CallRouting_LeadBlock): unknown {
    const obj: any = {};
    message.enabled !== undefined && (obj.enabled = message.enabled);
    message.numberOfCalls !== undefined && (obj.numberOfCalls = Math.round(message.numberOfCalls));
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerQueue_CallRouting_LeadBlock>, I>>(
    base?: I,
  ): DialerQueue_CallRouting_LeadBlock {
    return DialerQueue_CallRouting_LeadBlock.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerQueue_CallRouting_LeadBlock>, I>>(
    object: I,
  ): DialerQueue_CallRouting_LeadBlock {
    const message = createBaseDialerQueue_CallRouting_LeadBlock();
    message.enabled = object.enabled ?? undefined;
    message.numberOfCalls = object.numberOfCalls ?? undefined;
    return message;
  },
};

function createBaseDialerQueue_AfterHourSettings(): DialerQueue_AfterHourSettings {
  return { enableText: undefined, text: undefined };
}

export const DialerQueue_AfterHourSettings = {
  encode(message: DialerQueue_AfterHourSettings, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.enableText !== undefined) {
      writer.uint32(8).bool(message.enableText);
    }
    if (message.text !== undefined) {
      writer.uint32(18).string(message.text);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerQueue_AfterHourSettings {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerQueue_AfterHourSettings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.enableText = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.text = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerQueue_AfterHourSettings {
    return {
      enableText: isSet(object.enableText) ? Boolean(object.enableText) : undefined,
      text: isSet(object.text) ? String(object.text) : undefined,
    };
  },

  toJSON(message: DialerQueue_AfterHourSettings): unknown {
    const obj: any = {};
    message.enableText !== undefined && (obj.enableText = message.enableText);
    message.text !== undefined && (obj.text = message.text);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerQueue_AfterHourSettings>, I>>(base?: I): DialerQueue_AfterHourSettings {
    return DialerQueue_AfterHourSettings.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerQueue_AfterHourSettings>, I>>(
    object: I,
  ): DialerQueue_AfterHourSettings {
    const message = createBaseDialerQueue_AfterHourSettings();
    message.enableText = object.enableText ?? undefined;
    message.text = object.text ?? undefined;
    return message;
  },
};

function createBaseDialerQueue_Number(): DialerQueue_Number {
  return {};
}

export const DialerQueue_Number = {
  encode(_: DialerQueue_Number, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerQueue_Number {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerQueue_Number();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): DialerQueue_Number {
    return {};
  },

  toJSON(_: DialerQueue_Number): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerQueue_Number>, I>>(base?: I): DialerQueue_Number {
    return DialerQueue_Number.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerQueue_Number>, I>>(_: I): DialerQueue_Number {
    const message = createBaseDialerQueue_Number();
    return message;
  },
};

function createBaseDialerQueueSettingsContainer(): DialerQueueSettingsContainer {
  return { byReceiverAgent: {} };
}

export const DialerQueueSettingsContainer = {
  encode(message: DialerQueueSettingsContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.byReceiverAgent).forEach(([key, value]) => {
      DialerQueueSettingsContainer_ByReceiverAgentEntry.encode({ key: key as any, value }, writer.uint32(10).fork())
        .ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerQueueSettingsContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerQueueSettingsContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = DialerQueueSettingsContainer_ByReceiverAgentEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.byReceiverAgent[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerQueueSettingsContainer {
    return {
      byReceiverAgent: isObject(object.byReceiverAgent)
        ? Object.entries(object.byReceiverAgent).reduce<{ [key: string]: DialerQueueAgentSettings }>(
          (acc, [key, value]) => {
            acc[key] = DialerQueueAgentSettings.fromJSON(value);
            return acc;
          },
          {},
        )
        : {},
    };
  },

  toJSON(message: DialerQueueSettingsContainer): unknown {
    const obj: any = {};
    obj.byReceiverAgent = {};
    if (message.byReceiverAgent) {
      Object.entries(message.byReceiverAgent).forEach(([k, v]) => {
        obj.byReceiverAgent[k] = DialerQueueAgentSettings.toJSON(v);
      });
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerQueueSettingsContainer>, I>>(base?: I): DialerQueueSettingsContainer {
    return DialerQueueSettingsContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerQueueSettingsContainer>, I>>(object: I): DialerQueueSettingsContainer {
    const message = createBaseDialerQueueSettingsContainer();
    message.byReceiverAgent = Object.entries(object.byReceiverAgent ?? {}).reduce<
      { [key: string]: DialerQueueAgentSettings }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = DialerQueueAgentSettings.fromPartial(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseDialerQueueSettingsContainer_ByReceiverAgentEntry(): DialerQueueSettingsContainer_ByReceiverAgentEntry {
  return { key: "", value: undefined };
}

export const DialerQueueSettingsContainer_ByReceiverAgentEntry = {
  encode(
    message: DialerQueueSettingsContainer_ByReceiverAgentEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      DialerQueueAgentSettings.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerQueueSettingsContainer_ByReceiverAgentEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerQueueSettingsContainer_ByReceiverAgentEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = DialerQueueAgentSettings.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerQueueSettingsContainer_ByReceiverAgentEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? DialerQueueAgentSettings.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: DialerQueueSettingsContainer_ByReceiverAgentEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined &&
      (obj.value = message.value ? DialerQueueAgentSettings.toJSON(message.value) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerQueueSettingsContainer_ByReceiverAgentEntry>, I>>(
    base?: I,
  ): DialerQueueSettingsContainer_ByReceiverAgentEntry {
    return DialerQueueSettingsContainer_ByReceiverAgentEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerQueueSettingsContainer_ByReceiverAgentEntry>, I>>(
    object: I,
  ): DialerQueueSettingsContainer_ByReceiverAgentEntry {
    const message = createBaseDialerQueueSettingsContainer_ByReceiverAgentEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? DialerQueueAgentSettings.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseDialerQueueAgentSettings(): DialerQueueAgentSettings {
  return { callCap: undefined };
}

export const DialerQueueAgentSettings = {
  encode(message: DialerQueueAgentSettings, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.callCap !== undefined) {
      DialerCallCap.encode(message.callCap, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerQueueAgentSettings {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerQueueAgentSettings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.callCap = DialerCallCap.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerQueueAgentSettings {
    return { callCap: isSet(object.callCap) ? DialerCallCap.fromJSON(object.callCap) : undefined };
  },

  toJSON(message: DialerQueueAgentSettings): unknown {
    const obj: any = {};
    message.callCap !== undefined &&
      (obj.callCap = message.callCap ? DialerCallCap.toJSON(message.callCap) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerQueueAgentSettings>, I>>(base?: I): DialerQueueAgentSettings {
    return DialerQueueAgentSettings.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerQueueAgentSettings>, I>>(object: I): DialerQueueAgentSettings {
    const message = createBaseDialerQueueAgentSettings();
    message.callCap = (object.callCap !== undefined && object.callCap !== null)
      ? DialerCallCap.fromPartial(object.callCap)
      : undefined;
    return message;
  },
};

function createBaseDialerAgentSelection(): DialerAgentSelection {
  return { priority: [], availabilityDelayInS: undefined };
}

export const DialerAgentSelection = {
  encode(message: DialerAgentSelection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.priority) {
      DialerAgentSelectionPriority.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.availabilityDelayInS !== undefined) {
      writer.uint32(17).double(message.availabilityDelayInS);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerAgentSelection {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerAgentSelection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.priority.push(DialerAgentSelectionPriority.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.availabilityDelayInS = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerAgentSelection {
    return {
      priority: Array.isArray(object?.priority)
        ? object.priority.map((e: any) => DialerAgentSelectionPriority.fromJSON(e))
        : [],
      availabilityDelayInS: isSet(object.availabilityDelayInS) ? Number(object.availabilityDelayInS) : undefined,
    };
  },

  toJSON(message: DialerAgentSelection): unknown {
    const obj: any = {};
    if (message.priority) {
      obj.priority = message.priority.map((e) => e ? DialerAgentSelectionPriority.toJSON(e) : undefined);
    } else {
      obj.priority = [];
    }
    message.availabilityDelayInS !== undefined && (obj.availabilityDelayInS = message.availabilityDelayInS);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerAgentSelection>, I>>(base?: I): DialerAgentSelection {
    return DialerAgentSelection.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerAgentSelection>, I>>(object: I): DialerAgentSelection {
    const message = createBaseDialerAgentSelection();
    message.priority = object.priority?.map((e) => DialerAgentSelectionPriority.fromPartial(e)) || [];
    message.availabilityDelayInS = object.availabilityDelayInS ?? undefined;
    return message;
  },
};

function createBaseDialerAgentSelectionPriority(): DialerAgentSelectionPriority {
  return { type: 0, direction: 0 };
}

export const DialerAgentSelectionPriority = {
  encode(message: DialerAgentSelectionPriority, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.direction !== 0) {
      writer.uint32(16).int32(message.direction);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerAgentSelectionPriority {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerAgentSelectionPriority();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.direction = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerAgentSelectionPriority {
    return {
      type: isSet(object.type) ? dialerAgentSelectionPriorityTypeFromJSON(object.type) : 0,
      direction: isSet(object.direction) ? sortDirectionFromJSON(object.direction) : 0,
    };
  },

  toJSON(message: DialerAgentSelectionPriority): unknown {
    const obj: any = {};
    message.type !== undefined && (obj.type = dialerAgentSelectionPriorityTypeToJSON(message.type));
    message.direction !== undefined && (obj.direction = sortDirectionToJSON(message.direction));
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerAgentSelectionPriority>, I>>(base?: I): DialerAgentSelectionPriority {
    return DialerAgentSelectionPriority.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerAgentSelectionPriority>, I>>(object: I): DialerAgentSelectionPriority {
    const message = createBaseDialerAgentSelectionPriority();
    message.type = object.type ?? 0;
    message.direction = object.direction ?? 0;
    return message;
  },
};

function createBaseDialerCampaignHopper(): DialerCampaignHopper {
  return {
    queueId: undefined,
    agencyId: undefined,
    name: undefined,
    state: undefined,
    purgeTime: undefined,
    taskTtl: undefined,
    tombstoneTtl: undefined,
    stackdriverLoggingConfig: undefined,
    type: undefined,
    httpTarget: undefined,
    rateLimits: undefined,
    stats: undefined,
  };
}

export const DialerCampaignHopper = {
  encode(message: DialerCampaignHopper, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.queueId !== undefined) {
      writer.uint32(10).string(message.queueId);
    }
    if (message.agencyId !== undefined) {
      writer.uint32(18).string(message.agencyId);
    }
    if (message.name !== undefined) {
      writer.uint32(26).string(message.name);
    }
    if (message.state !== undefined) {
      writer.uint32(34).string(message.state);
    }
    if (message.purgeTime !== undefined) {
      writer.uint32(42).string(message.purgeTime);
    }
    if (message.taskTtl !== undefined) {
      writer.uint32(50).string(message.taskTtl);
    }
    if (message.tombstoneTtl !== undefined) {
      writer.uint32(58).string(message.tombstoneTtl);
    }
    if (message.stackdriverLoggingConfig !== undefined) {
      writer.uint32(66).string(message.stackdriverLoggingConfig);
    }
    if (message.type !== undefined) {
      writer.uint32(74).string(message.type);
    }
    if (message.httpTarget !== undefined) {
      writer.uint32(82).string(message.httpTarget);
    }
    if (message.rateLimits !== undefined) {
      DialerCampaignHopper_RateLimits.encode(message.rateLimits, writer.uint32(90).fork()).ldelim();
    }
    if (message.stats !== undefined) {
      DialerCampaignHopper_Stats.encode(message.stats, writer.uint32(98).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerCampaignHopper {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerCampaignHopper();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.queueId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.agencyId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.state = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.purgeTime = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.taskTtl = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.tombstoneTtl = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.stackdriverLoggingConfig = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.type = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.httpTarget = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.rateLimits = DialerCampaignHopper_RateLimits.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.stats = DialerCampaignHopper_Stats.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerCampaignHopper {
    return {
      queueId: isSet(object.queueId) ? String(object.queueId) : undefined,
      agencyId: isSet(object.agencyId) ? String(object.agencyId) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      state: isSet(object.state) ? String(object.state) : undefined,
      purgeTime: isSet(object.purgeTime) ? String(object.purgeTime) : undefined,
      taskTtl: isSet(object.taskTtl) ? String(object.taskTtl) : undefined,
      tombstoneTtl: isSet(object.tombstoneTtl) ? String(object.tombstoneTtl) : undefined,
      stackdriverLoggingConfig: isSet(object.stackdriverLoggingConfig)
        ? String(object.stackdriverLoggingConfig)
        : undefined,
      type: isSet(object.type) ? String(object.type) : undefined,
      httpTarget: isSet(object.httpTarget) ? String(object.httpTarget) : undefined,
      rateLimits: isSet(object.rateLimits) ? DialerCampaignHopper_RateLimits.fromJSON(object.rateLimits) : undefined,
      stats: isSet(object.stats) ? DialerCampaignHopper_Stats.fromJSON(object.stats) : undefined,
    };
  },

  toJSON(message: DialerCampaignHopper): unknown {
    const obj: any = {};
    message.queueId !== undefined && (obj.queueId = message.queueId);
    message.agencyId !== undefined && (obj.agencyId = message.agencyId);
    message.name !== undefined && (obj.name = message.name);
    message.state !== undefined && (obj.state = message.state);
    message.purgeTime !== undefined && (obj.purgeTime = message.purgeTime);
    message.taskTtl !== undefined && (obj.taskTtl = message.taskTtl);
    message.tombstoneTtl !== undefined && (obj.tombstoneTtl = message.tombstoneTtl);
    message.stackdriverLoggingConfig !== undefined && (obj.stackdriverLoggingConfig = message.stackdriverLoggingConfig);
    message.type !== undefined && (obj.type = message.type);
    message.httpTarget !== undefined && (obj.httpTarget = message.httpTarget);
    message.rateLimits !== undefined &&
      (obj.rateLimits = message.rateLimits ? DialerCampaignHopper_RateLimits.toJSON(message.rateLimits) : undefined);
    message.stats !== undefined &&
      (obj.stats = message.stats ? DialerCampaignHopper_Stats.toJSON(message.stats) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerCampaignHopper>, I>>(base?: I): DialerCampaignHopper {
    return DialerCampaignHopper.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerCampaignHopper>, I>>(object: I): DialerCampaignHopper {
    const message = createBaseDialerCampaignHopper();
    message.queueId = object.queueId ?? undefined;
    message.agencyId = object.agencyId ?? undefined;
    message.name = object.name ?? undefined;
    message.state = object.state ?? undefined;
    message.purgeTime = object.purgeTime ?? undefined;
    message.taskTtl = object.taskTtl ?? undefined;
    message.tombstoneTtl = object.tombstoneTtl ?? undefined;
    message.stackdriverLoggingConfig = object.stackdriverLoggingConfig ?? undefined;
    message.type = object.type ?? undefined;
    message.httpTarget = object.httpTarget ?? undefined;
    message.rateLimits = (object.rateLimits !== undefined && object.rateLimits !== null)
      ? DialerCampaignHopper_RateLimits.fromPartial(object.rateLimits)
      : undefined;
    message.stats = (object.stats !== undefined && object.stats !== null)
      ? DialerCampaignHopper_Stats.fromPartial(object.stats)
      : undefined;
    return message;
  },
};

function createBaseDialerCampaignHopper_RateLimits(): DialerCampaignHopper_RateLimits {
  return { maxDispatchesPerSecond: undefined, maxBurstSize: undefined, maxConcurrentDispatches: undefined };
}

export const DialerCampaignHopper_RateLimits = {
  encode(message: DialerCampaignHopper_RateLimits, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.maxDispatchesPerSecond !== undefined) {
      writer.uint32(8).int32(message.maxDispatchesPerSecond);
    }
    if (message.maxBurstSize !== undefined) {
      writer.uint32(16).int32(message.maxBurstSize);
    }
    if (message.maxConcurrentDispatches !== undefined) {
      writer.uint32(24).int32(message.maxConcurrentDispatches);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerCampaignHopper_RateLimits {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerCampaignHopper_RateLimits();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.maxDispatchesPerSecond = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.maxBurstSize = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.maxConcurrentDispatches = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerCampaignHopper_RateLimits {
    return {
      maxDispatchesPerSecond: isSet(object.maxDispatchesPerSecond) ? Number(object.maxDispatchesPerSecond) : undefined,
      maxBurstSize: isSet(object.maxBurstSize) ? Number(object.maxBurstSize) : undefined,
      maxConcurrentDispatches: isSet(object.maxConcurrentDispatches)
        ? Number(object.maxConcurrentDispatches)
        : undefined,
    };
  },

  toJSON(message: DialerCampaignHopper_RateLimits): unknown {
    const obj: any = {};
    message.maxDispatchesPerSecond !== undefined &&
      (obj.maxDispatchesPerSecond = Math.round(message.maxDispatchesPerSecond));
    message.maxBurstSize !== undefined && (obj.maxBurstSize = Math.round(message.maxBurstSize));
    message.maxConcurrentDispatches !== undefined &&
      (obj.maxConcurrentDispatches = Math.round(message.maxConcurrentDispatches));
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerCampaignHopper_RateLimits>, I>>(base?: I): DialerCampaignHopper_RateLimits {
    return DialerCampaignHopper_RateLimits.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerCampaignHopper_RateLimits>, I>>(
    object: I,
  ): DialerCampaignHopper_RateLimits {
    const message = createBaseDialerCampaignHopper_RateLimits();
    message.maxDispatchesPerSecond = object.maxDispatchesPerSecond ?? undefined;
    message.maxBurstSize = object.maxBurstSize ?? undefined;
    message.maxConcurrentDispatches = object.maxConcurrentDispatches ?? undefined;
    return message;
  },
};

function createBaseDialerCampaignHopper_Stats(): DialerCampaignHopper_Stats {
  return {
    tasksCount: undefined,
    oldestEstimatedArrivalTime: undefined,
    executedLastMinuteCount: undefined,
    concurrentDispatchesCount: undefined,
    effectiveExecutionRate: undefined,
  };
}

export const DialerCampaignHopper_Stats = {
  encode(message: DialerCampaignHopper_Stats, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tasksCount !== undefined) {
      writer.uint32(8).int32(message.tasksCount);
    }
    if (message.oldestEstimatedArrivalTime !== undefined) {
      DialerCampaignHopper_Stats_FirebaseTimestamp.encode(message.oldestEstimatedArrivalTime, writer.uint32(18).fork())
        .ldelim();
    }
    if (message.executedLastMinuteCount !== undefined) {
      writer.uint32(24).int32(message.executedLastMinuteCount);
    }
    if (message.concurrentDispatchesCount !== undefined) {
      writer.uint32(32).int32(message.concurrentDispatchesCount);
    }
    if (message.effectiveExecutionRate !== undefined) {
      writer.uint32(40).int32(message.effectiveExecutionRate);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerCampaignHopper_Stats {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerCampaignHopper_Stats();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.tasksCount = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.oldestEstimatedArrivalTime = DialerCampaignHopper_Stats_FirebaseTimestamp.decode(
            reader,
            reader.uint32(),
          );
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.executedLastMinuteCount = reader.int32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.concurrentDispatchesCount = reader.int32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.effectiveExecutionRate = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerCampaignHopper_Stats {
    return {
      tasksCount: isSet(object.tasksCount) ? Number(object.tasksCount) : undefined,
      oldestEstimatedArrivalTime: isSet(object.oldestEstimatedArrivalTime)
        ? DialerCampaignHopper_Stats_FirebaseTimestamp.fromJSON(object.oldestEstimatedArrivalTime)
        : undefined,
      executedLastMinuteCount: isSet(object.executedLastMinuteCount)
        ? Number(object.executedLastMinuteCount)
        : undefined,
      concurrentDispatchesCount: isSet(object.concurrentDispatchesCount)
        ? Number(object.concurrentDispatchesCount)
        : undefined,
      effectiveExecutionRate: isSet(object.effectiveExecutionRate) ? Number(object.effectiveExecutionRate) : undefined,
    };
  },

  toJSON(message: DialerCampaignHopper_Stats): unknown {
    const obj: any = {};
    message.tasksCount !== undefined && (obj.tasksCount = Math.round(message.tasksCount));
    message.oldestEstimatedArrivalTime !== undefined &&
      (obj.oldestEstimatedArrivalTime = message.oldestEstimatedArrivalTime
        ? DialerCampaignHopper_Stats_FirebaseTimestamp.toJSON(message.oldestEstimatedArrivalTime)
        : undefined);
    message.executedLastMinuteCount !== undefined &&
      (obj.executedLastMinuteCount = Math.round(message.executedLastMinuteCount));
    message.concurrentDispatchesCount !== undefined &&
      (obj.concurrentDispatchesCount = Math.round(message.concurrentDispatchesCount));
    message.effectiveExecutionRate !== undefined &&
      (obj.effectiveExecutionRate = Math.round(message.effectiveExecutionRate));
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerCampaignHopper_Stats>, I>>(base?: I): DialerCampaignHopper_Stats {
    return DialerCampaignHopper_Stats.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerCampaignHopper_Stats>, I>>(object: I): DialerCampaignHopper_Stats {
    const message = createBaseDialerCampaignHopper_Stats();
    message.tasksCount = object.tasksCount ?? undefined;
    message.oldestEstimatedArrivalTime =
      (object.oldestEstimatedArrivalTime !== undefined && object.oldestEstimatedArrivalTime !== null)
        ? DialerCampaignHopper_Stats_FirebaseTimestamp.fromPartial(object.oldestEstimatedArrivalTime)
        : undefined;
    message.executedLastMinuteCount = object.executedLastMinuteCount ?? undefined;
    message.concurrentDispatchesCount = object.concurrentDispatchesCount ?? undefined;
    message.effectiveExecutionRate = object.effectiveExecutionRate ?? undefined;
    return message;
  },
};

function createBaseDialerCampaignHopper_Stats_FirebaseTimestamp(): DialerCampaignHopper_Stats_FirebaseTimestamp {
  return { seconds: undefined, nanos: undefined };
}

export const DialerCampaignHopper_Stats_FirebaseTimestamp = {
  encode(message: DialerCampaignHopper_Stats_FirebaseTimestamp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.seconds !== undefined) {
      writer.uint32(8).int64(message.seconds);
    }
    if (message.nanos !== undefined) {
      writer.uint32(16).int32(message.nanos);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerCampaignHopper_Stats_FirebaseTimestamp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerCampaignHopper_Stats_FirebaseTimestamp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.seconds = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.nanos = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerCampaignHopper_Stats_FirebaseTimestamp {
    return {
      seconds: isSet(object.seconds) ? Number(object.seconds) : undefined,
      nanos: isSet(object.nanos) ? Number(object.nanos) : undefined,
    };
  },

  toJSON(message: DialerCampaignHopper_Stats_FirebaseTimestamp): unknown {
    const obj: any = {};
    message.seconds !== undefined && (obj.seconds = Math.round(message.seconds));
    message.nanos !== undefined && (obj.nanos = Math.round(message.nanos));
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerCampaignHopper_Stats_FirebaseTimestamp>, I>>(
    base?: I,
  ): DialerCampaignHopper_Stats_FirebaseTimestamp {
    return DialerCampaignHopper_Stats_FirebaseTimestamp.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerCampaignHopper_Stats_FirebaseTimestamp>, I>>(
    object: I,
  ): DialerCampaignHopper_Stats_FirebaseTimestamp {
    const message = createBaseDialerCampaignHopper_Stats_FirebaseTimestamp();
    message.seconds = object.seconds ?? undefined;
    message.nanos = object.nanos ?? undefined;
    return message;
  },
};

function createBaseNextAgent(): NextAgent {
  return {
    agentId: "",
    callControlId: "",
    agencyId: "",
    campaignId: undefined,
    isConference: undefined,
    conferenceId: undefined,
    score: undefined,
    skill: undefined,
  };
}

export const NextAgent = {
  encode(message: NextAgent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.agentId !== "") {
      writer.uint32(10).string(message.agentId);
    }
    if (message.callControlId !== "") {
      writer.uint32(18).string(message.callControlId);
    }
    if (message.agencyId !== "") {
      writer.uint32(26).string(message.agencyId);
    }
    if (message.campaignId !== undefined) {
      writer.uint32(34).string(message.campaignId);
    }
    if (message.isConference !== undefined) {
      writer.uint32(40).bool(message.isConference);
    }
    if (message.conferenceId !== undefined) {
      writer.uint32(50).string(message.conferenceId);
    }
    if (message.score !== undefined) {
      writer.uint32(56).int32(message.score);
    }
    if (message.skill !== undefined) {
      SkillListContainer.encode(message.skill, writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NextAgent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNextAgent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.agentId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.callControlId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.agencyId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.campaignId = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.isConference = reader.bool();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.conferenceId = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.score = reader.int32();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.skill = SkillListContainer.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NextAgent {
    return {
      agentId: isSet(object.agentId) ? String(object.agentId) : "",
      callControlId: isSet(object.callControlId) ? String(object.callControlId) : "",
      agencyId: isSet(object.agencyId) ? String(object.agencyId) : "",
      campaignId: isSet(object.campaignId) ? String(object.campaignId) : undefined,
      isConference: isSet(object.isConference) ? Boolean(object.isConference) : undefined,
      conferenceId: isSet(object.conferenceId) ? String(object.conferenceId) : undefined,
      score: isSet(object.score) ? Number(object.score) : undefined,
      skill: isSet(object.skill) ? SkillListContainer.fromJSON(object.skill) : undefined,
    };
  },

  toJSON(message: NextAgent): unknown {
    const obj: any = {};
    message.agentId !== undefined && (obj.agentId = message.agentId);
    message.callControlId !== undefined && (obj.callControlId = message.callControlId);
    message.agencyId !== undefined && (obj.agencyId = message.agencyId);
    message.campaignId !== undefined && (obj.campaignId = message.campaignId);
    message.isConference !== undefined && (obj.isConference = message.isConference);
    message.conferenceId !== undefined && (obj.conferenceId = message.conferenceId);
    message.score !== undefined && (obj.score = Math.round(message.score));
    message.skill !== undefined && (obj.skill = message.skill ? SkillListContainer.toJSON(message.skill) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<NextAgent>, I>>(base?: I): NextAgent {
    return NextAgent.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<NextAgent>, I>>(object: I): NextAgent {
    const message = createBaseNextAgent();
    message.agentId = object.agentId ?? "";
    message.callControlId = object.callControlId ?? "";
    message.agencyId = object.agencyId ?? "";
    message.campaignId = object.campaignId ?? undefined;
    message.isConference = object.isConference ?? undefined;
    message.conferenceId = object.conferenceId ?? undefined;
    message.score = object.score ?? undefined;
    message.skill = (object.skill !== undefined && object.skill !== null)
      ? SkillListContainer.fromPartial(object.skill)
      : undefined;
    return message;
  },
};

function createBaseScriptCallLaunch(): ScriptCallLaunch {
  return { useScript: undefined, scriptId: undefined };
}

export const ScriptCallLaunch = {
  encode(message: ScriptCallLaunch, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.useScript !== undefined) {
      writer.uint32(8).bool(message.useScript);
    }
    if (message.scriptId !== undefined) {
      writer.uint32(18).string(message.scriptId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ScriptCallLaunch {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScriptCallLaunch();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.useScript = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.scriptId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ScriptCallLaunch {
    return {
      useScript: isSet(object.useScript) ? Boolean(object.useScript) : undefined,
      scriptId: isSet(object.scriptId) ? String(object.scriptId) : undefined,
    };
  },

  toJSON(message: ScriptCallLaunch): unknown {
    const obj: any = {};
    message.useScript !== undefined && (obj.useScript = message.useScript);
    message.scriptId !== undefined && (obj.scriptId = message.scriptId);
    return obj;
  },

  create<I extends Exact<DeepPartial<ScriptCallLaunch>, I>>(base?: I): ScriptCallLaunch {
    return ScriptCallLaunch.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScriptCallLaunch>, I>>(object: I): ScriptCallLaunch {
    const message = createBaseScriptCallLaunch();
    message.useScript = object.useScript ?? undefined;
    message.scriptId = object.scriptId ?? undefined;
    return message;
  },
};

function createBaseDialerDispoStatusCallLaunch(): DialerDispoStatusCallLaunch {
  return { useDispoStatus: undefined, dispoStatusId: undefined };
}

export const DialerDispoStatusCallLaunch = {
  encode(message: DialerDispoStatusCallLaunch, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.useDispoStatus !== undefined) {
      writer.uint32(8).bool(message.useDispoStatus);
    }
    if (message.dispoStatusId !== undefined) {
      writer.uint32(18).string(message.dispoStatusId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerDispoStatusCallLaunch {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerDispoStatusCallLaunch();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.useDispoStatus = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.dispoStatusId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerDispoStatusCallLaunch {
    return {
      useDispoStatus: isSet(object.useDispoStatus) ? Boolean(object.useDispoStatus) : undefined,
      dispoStatusId: isSet(object.dispoStatusId) ? String(object.dispoStatusId) : undefined,
    };
  },

  toJSON(message: DialerDispoStatusCallLaunch): unknown {
    const obj: any = {};
    message.useDispoStatus !== undefined && (obj.useDispoStatus = message.useDispoStatus);
    message.dispoStatusId !== undefined && (obj.dispoStatusId = message.dispoStatusId);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerDispoStatusCallLaunch>, I>>(base?: I): DialerDispoStatusCallLaunch {
    return DialerDispoStatusCallLaunch.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerDispoStatusCallLaunch>, I>>(object: I): DialerDispoStatusCallLaunch {
    const message = createBaseDialerDispoStatusCallLaunch();
    message.useDispoStatus = object.useDispoStatus ?? undefined;
    message.dispoStatusId = object.dispoStatusId ?? undefined;
    return message;
  },
};

function createBaseDialerScript(): DialerScript {
  return { id: undefined, name: undefined, active: undefined, questionnaire: undefined, agencyId: undefined };
}

export const DialerScript = {
  encode(message: DialerScript, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.active !== undefined) {
      writer.uint32(24).bool(message.active);
    }
    if (message.questionnaire !== undefined) {
      Questionnaire.encode(message.questionnaire, writer.uint32(34).fork()).ldelim();
    }
    if (message.agencyId !== undefined) {
      writer.uint32(42).string(message.agencyId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerScript {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerScript();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.active = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.questionnaire = Questionnaire.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.agencyId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerScript {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      active: isSet(object.active) ? Boolean(object.active) : undefined,
      questionnaire: isSet(object.questionnaire) ? Questionnaire.fromJSON(object.questionnaire) : undefined,
      agencyId: isSet(object.agencyId) ? String(object.agencyId) : undefined,
    };
  },

  toJSON(message: DialerScript): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.active !== undefined && (obj.active = message.active);
    message.questionnaire !== undefined &&
      (obj.questionnaire = message.questionnaire ? Questionnaire.toJSON(message.questionnaire) : undefined);
    message.agencyId !== undefined && (obj.agencyId = message.agencyId);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerScript>, I>>(base?: I): DialerScript {
    return DialerScript.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerScript>, I>>(object: I): DialerScript {
    const message = createBaseDialerScript();
    message.id = object.id ?? undefined;
    message.name = object.name ?? undefined;
    message.active = object.active ?? undefined;
    message.questionnaire = (object.questionnaire !== undefined && object.questionnaire !== null)
      ? Questionnaire.fromPartial(object.questionnaire)
      : undefined;
    message.agencyId = object.agencyId ?? undefined;
    return message;
  },
};

function createBaseDialerDispoStatus(): DialerDispoStatus {
  return { id: undefined, name: undefined, active: undefined, agency: undefined, resultCategories: [] };
}

export const DialerDispoStatus = {
  encode(message: DialerDispoStatus, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.active !== undefined) {
      writer.uint32(24).bool(message.active);
    }
    if (message.agency !== undefined) {
      UserIdContainer.encode(message.agency, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.resultCategories) {
      DialerDispoStatus_ResultCategoryContainer.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerDispoStatus {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerDispoStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.active = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.agency = UserIdContainer.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.resultCategories.push(DialerDispoStatus_ResultCategoryContainer.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerDispoStatus {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      active: isSet(object.active) ? Boolean(object.active) : undefined,
      agency: isSet(object.agency) ? UserIdContainer.fromJSON(object.agency) : undefined,
      resultCategories: Array.isArray(object?.resultCategories)
        ? object.resultCategories.map((e: any) => DialerDispoStatus_ResultCategoryContainer.fromJSON(e))
        : [],
    };
  },

  toJSON(message: DialerDispoStatus): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.active !== undefined && (obj.active = message.active);
    message.agency !== undefined && (obj.agency = message.agency ? UserIdContainer.toJSON(message.agency) : undefined);
    if (message.resultCategories) {
      obj.resultCategories = message.resultCategories.map((e) =>
        e ? DialerDispoStatus_ResultCategoryContainer.toJSON(e) : undefined
      );
    } else {
      obj.resultCategories = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerDispoStatus>, I>>(base?: I): DialerDispoStatus {
    return DialerDispoStatus.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerDispoStatus>, I>>(object: I): DialerDispoStatus {
    const message = createBaseDialerDispoStatus();
    message.id = object.id ?? undefined;
    message.name = object.name ?? undefined;
    message.active = object.active ?? undefined;
    message.agency = (object.agency !== undefined && object.agency !== null)
      ? UserIdContainer.fromPartial(object.agency)
      : undefined;
    message.resultCategories =
      object.resultCategories?.map((e) => DialerDispoStatus_ResultCategoryContainer.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDialerDispoStatus_ResultCategoryContainer(): DialerDispoStatus_ResultCategoryContainer {
  return { resultCategory: 0, results: [] };
}

export const DialerDispoStatus_ResultCategoryContainer = {
  encode(message: DialerDispoStatus_ResultCategoryContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.resultCategory !== 0) {
      writer.uint32(8).int32(message.resultCategory);
    }
    for (const v of message.results) {
      DialerDispoStatus_ResultContainer.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerDispoStatus_ResultCategoryContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerDispoStatus_ResultCategoryContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.resultCategory = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.results.push(DialerDispoStatus_ResultContainer.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerDispoStatus_ResultCategoryContainer {
    return {
      resultCategory: isSet(object.resultCategory) ? consumer_Dialer_ResultCategoryFromJSON(object.resultCategory) : 0,
      results: Array.isArray(object?.results)
        ? object.results.map((e: any) => DialerDispoStatus_ResultContainer.fromJSON(e))
        : [],
    };
  },

  toJSON(message: DialerDispoStatus_ResultCategoryContainer): unknown {
    const obj: any = {};
    message.resultCategory !== undefined &&
      (obj.resultCategory = consumer_Dialer_ResultCategoryToJSON(message.resultCategory));
    if (message.results) {
      obj.results = message.results.map((e) => e ? DialerDispoStatus_ResultContainer.toJSON(e) : undefined);
    } else {
      obj.results = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerDispoStatus_ResultCategoryContainer>, I>>(
    base?: I,
  ): DialerDispoStatus_ResultCategoryContainer {
    return DialerDispoStatus_ResultCategoryContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerDispoStatus_ResultCategoryContainer>, I>>(
    object: I,
  ): DialerDispoStatus_ResultCategoryContainer {
    const message = createBaseDialerDispoStatus_ResultCategoryContainer();
    message.resultCategory = object.resultCategory ?? 0;
    message.results = object.results?.map((e) => DialerDispoStatus_ResultContainer.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDialerDispoStatus_ResultContainer(): DialerDispoStatus_ResultContainer {
  return { result: 0 };
}

export const DialerDispoStatus_ResultContainer = {
  encode(message: DialerDispoStatus_ResultContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.result !== 0) {
      writer.uint32(8).int32(message.result);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerDispoStatus_ResultContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerDispoStatus_ResultContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.result = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerDispoStatus_ResultContainer {
    return { result: isSet(object.result) ? consumer_Dialer_ResultFromJSON(object.result) : 0 };
  },

  toJSON(message: DialerDispoStatus_ResultContainer): unknown {
    const obj: any = {};
    message.result !== undefined && (obj.result = consumer_Dialer_ResultToJSON(message.result));
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerDispoStatus_ResultContainer>, I>>(
    base?: I,
  ): DialerDispoStatus_ResultContainer {
    return DialerDispoStatus_ResultContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerDispoStatus_ResultContainer>, I>>(
    object: I,
  ): DialerDispoStatus_ResultContainer {
    const message = createBaseDialerDispoStatus_ResultContainer();
    message.result = object.result ?? 0;
    return message;
  },
};

function createBaseDialerLiveStats(): DialerLiveStats {
  return { id: undefined, parentId: undefined, abandoned: undefined, answered: undefined, dropped: undefined };
}

export const DialerLiveStats = {
  encode(message: DialerLiveStats, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.parentId !== undefined) {
      writer.uint32(18).string(message.parentId);
    }
    if (message.abandoned !== undefined) {
      writer.uint32(24).int32(message.abandoned);
    }
    if (message.answered !== undefined) {
      writer.uint32(32).int32(message.answered);
    }
    if (message.dropped !== undefined) {
      writer.uint32(40).int32(message.dropped);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerLiveStats {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerLiveStats();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.parentId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.abandoned = reader.int32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.answered = reader.int32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.dropped = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerLiveStats {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      parentId: isSet(object.parentId) ? String(object.parentId) : undefined,
      abandoned: isSet(object.abandoned) ? Number(object.abandoned) : undefined,
      answered: isSet(object.answered) ? Number(object.answered) : undefined,
      dropped: isSet(object.dropped) ? Number(object.dropped) : undefined,
    };
  },

  toJSON(message: DialerLiveStats): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.parentId !== undefined && (obj.parentId = message.parentId);
    message.abandoned !== undefined && (obj.abandoned = Math.round(message.abandoned));
    message.answered !== undefined && (obj.answered = Math.round(message.answered));
    message.dropped !== undefined && (obj.dropped = Math.round(message.dropped));
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerLiveStats>, I>>(base?: I): DialerLiveStats {
    return DialerLiveStats.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerLiveStats>, I>>(object: I): DialerLiveStats {
    const message = createBaseDialerLiveStats();
    message.id = object.id ?? undefined;
    message.parentId = object.parentId ?? undefined;
    message.abandoned = object.abandoned ?? undefined;
    message.answered = object.answered ?? undefined;
    message.dropped = object.dropped ?? undefined;
    return message;
  },
};

function createBaseLeadList(): LeadList {
  return { id: undefined, name: undefined, agencyId: undefined, tags: [], percentage: undefined };
}

export const LeadList = {
  encode(message: LeadList, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.agencyId !== undefined) {
      writer.uint32(26).string(message.agencyId);
    }
    for (const v of message.tags) {
      writer.uint32(34).string(v!);
    }
    if (message.percentage !== undefined) {
      writer.uint32(40).int32(message.percentage);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LeadList {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLeadList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.agencyId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.tags.push(reader.string());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.percentage = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LeadList {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      agencyId: isSet(object.agencyId) ? String(object.agencyId) : undefined,
      tags: Array.isArray(object?.tags) ? object.tags.map((e: any) => String(e)) : [],
      percentage: isSet(object.percentage) ? Number(object.percentage) : undefined,
    };
  },

  toJSON(message: LeadList): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.agencyId !== undefined && (obj.agencyId = message.agencyId);
    if (message.tags) {
      obj.tags = message.tags.map((e) => e);
    } else {
      obj.tags = [];
    }
    message.percentage !== undefined && (obj.percentage = Math.round(message.percentage));
    return obj;
  },

  create<I extends Exact<DeepPartial<LeadList>, I>>(base?: I): LeadList {
    return LeadList.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<LeadList>, I>>(object: I): LeadList {
    const message = createBaseLeadList();
    message.id = object.id ?? undefined;
    message.name = object.name ?? undefined;
    message.agencyId = object.agencyId ?? undefined;
    message.tags = object.tags?.map((e) => e) || [];
    message.percentage = object.percentage ?? undefined;
    return message;
  },
};

function createBaseDialerSchedule(): DialerSchedule {
  return { enableSchedule: undefined, timeZone: undefined, weekdays: undefined };
}

export const DialerSchedule = {
  encode(message: DialerSchedule, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.enableSchedule !== undefined) {
      writer.uint32(8).bool(message.enableSchedule);
    }
    if (message.timeZone !== undefined) {
      writer.uint32(18).string(message.timeZone);
    }
    if (message.weekdays !== undefined) {
      DialerSchedule_WeekdayMap.encode(message.weekdays, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerSchedule {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerSchedule();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.enableSchedule = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.timeZone = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.weekdays = DialerSchedule_WeekdayMap.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerSchedule {
    return {
      enableSchedule: isSet(object.enableSchedule) ? Boolean(object.enableSchedule) : undefined,
      timeZone: isSet(object.timeZone) ? String(object.timeZone) : undefined,
      weekdays: isSet(object.weekdays) ? DialerSchedule_WeekdayMap.fromJSON(object.weekdays) : undefined,
    };
  },

  toJSON(message: DialerSchedule): unknown {
    const obj: any = {};
    message.enableSchedule !== undefined && (obj.enableSchedule = message.enableSchedule);
    message.timeZone !== undefined && (obj.timeZone = message.timeZone);
    message.weekdays !== undefined &&
      (obj.weekdays = message.weekdays ? DialerSchedule_WeekdayMap.toJSON(message.weekdays) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerSchedule>, I>>(base?: I): DialerSchedule {
    return DialerSchedule.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerSchedule>, I>>(object: I): DialerSchedule {
    const message = createBaseDialerSchedule();
    message.enableSchedule = object.enableSchedule ?? undefined;
    message.timeZone = object.timeZone ?? undefined;
    message.weekdays = (object.weekdays !== undefined && object.weekdays !== null)
      ? DialerSchedule_WeekdayMap.fromPartial(object.weekdays)
      : undefined;
    return message;
  },
};

function createBaseDialerSchedule_WeekdayMap(): DialerSchedule_WeekdayMap {
  return {
    sunday: undefined,
    monday: undefined,
    tuesday: undefined,
    wednesday: undefined,
    thursday: undefined,
    friday: undefined,
    saturday: undefined,
  };
}

export const DialerSchedule_WeekdayMap = {
  encode(message: DialerSchedule_WeekdayMap, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sunday !== undefined) {
      DialerSchedule_Weekday.encode(message.sunday, writer.uint32(10).fork()).ldelim();
    }
    if (message.monday !== undefined) {
      DialerSchedule_Weekday.encode(message.monday, writer.uint32(18).fork()).ldelim();
    }
    if (message.tuesday !== undefined) {
      DialerSchedule_Weekday.encode(message.tuesday, writer.uint32(26).fork()).ldelim();
    }
    if (message.wednesday !== undefined) {
      DialerSchedule_Weekday.encode(message.wednesday, writer.uint32(34).fork()).ldelim();
    }
    if (message.thursday !== undefined) {
      DialerSchedule_Weekday.encode(message.thursday, writer.uint32(42).fork()).ldelim();
    }
    if (message.friday !== undefined) {
      DialerSchedule_Weekday.encode(message.friday, writer.uint32(50).fork()).ldelim();
    }
    if (message.saturday !== undefined) {
      DialerSchedule_Weekday.encode(message.saturday, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerSchedule_WeekdayMap {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerSchedule_WeekdayMap();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.sunday = DialerSchedule_Weekday.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.monday = DialerSchedule_Weekday.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.tuesday = DialerSchedule_Weekday.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.wednesday = DialerSchedule_Weekday.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.thursday = DialerSchedule_Weekday.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.friday = DialerSchedule_Weekday.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.saturday = DialerSchedule_Weekday.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerSchedule_WeekdayMap {
    return {
      sunday: isSet(object.sunday) ? DialerSchedule_Weekday.fromJSON(object.sunday) : undefined,
      monday: isSet(object.monday) ? DialerSchedule_Weekday.fromJSON(object.monday) : undefined,
      tuesday: isSet(object.tuesday) ? DialerSchedule_Weekday.fromJSON(object.tuesday) : undefined,
      wednesday: isSet(object.wednesday) ? DialerSchedule_Weekday.fromJSON(object.wednesday) : undefined,
      thursday: isSet(object.thursday) ? DialerSchedule_Weekday.fromJSON(object.thursday) : undefined,
      friday: isSet(object.friday) ? DialerSchedule_Weekday.fromJSON(object.friday) : undefined,
      saturday: isSet(object.saturday) ? DialerSchedule_Weekday.fromJSON(object.saturday) : undefined,
    };
  },

  toJSON(message: DialerSchedule_WeekdayMap): unknown {
    const obj: any = {};
    message.sunday !== undefined &&
      (obj.sunday = message.sunday ? DialerSchedule_Weekday.toJSON(message.sunday) : undefined);
    message.monday !== undefined &&
      (obj.monday = message.monday ? DialerSchedule_Weekday.toJSON(message.monday) : undefined);
    message.tuesday !== undefined &&
      (obj.tuesday = message.tuesday ? DialerSchedule_Weekday.toJSON(message.tuesday) : undefined);
    message.wednesday !== undefined &&
      (obj.wednesday = message.wednesday ? DialerSchedule_Weekday.toJSON(message.wednesday) : undefined);
    message.thursday !== undefined &&
      (obj.thursday = message.thursday ? DialerSchedule_Weekday.toJSON(message.thursday) : undefined);
    message.friday !== undefined &&
      (obj.friday = message.friday ? DialerSchedule_Weekday.toJSON(message.friday) : undefined);
    message.saturday !== undefined &&
      (obj.saturday = message.saturday ? DialerSchedule_Weekday.toJSON(message.saturday) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerSchedule_WeekdayMap>, I>>(base?: I): DialerSchedule_WeekdayMap {
    return DialerSchedule_WeekdayMap.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerSchedule_WeekdayMap>, I>>(object: I): DialerSchedule_WeekdayMap {
    const message = createBaseDialerSchedule_WeekdayMap();
    message.sunday = (object.sunday !== undefined && object.sunday !== null)
      ? DialerSchedule_Weekday.fromPartial(object.sunday)
      : undefined;
    message.monday = (object.monday !== undefined && object.monday !== null)
      ? DialerSchedule_Weekday.fromPartial(object.monday)
      : undefined;
    message.tuesday = (object.tuesday !== undefined && object.tuesday !== null)
      ? DialerSchedule_Weekday.fromPartial(object.tuesday)
      : undefined;
    message.wednesday = (object.wednesday !== undefined && object.wednesday !== null)
      ? DialerSchedule_Weekday.fromPartial(object.wednesday)
      : undefined;
    message.thursday = (object.thursday !== undefined && object.thursday !== null)
      ? DialerSchedule_Weekday.fromPartial(object.thursday)
      : undefined;
    message.friday = (object.friday !== undefined && object.friday !== null)
      ? DialerSchedule_Weekday.fromPartial(object.friday)
      : undefined;
    message.saturday = (object.saturday !== undefined && object.saturday !== null)
      ? DialerSchedule_Weekday.fromPartial(object.saturday)
      : undefined;
    return message;
  },
};

function createBaseDialerSchedule_Weekday(): DialerSchedule_Weekday {
  return { enabled: undefined, startTime: undefined, endTime: undefined };
}

export const DialerSchedule_Weekday = {
  encode(message: DialerSchedule_Weekday, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.enabled !== undefined) {
      writer.uint32(8).bool(message.enabled);
    }
    if (message.startTime !== undefined) {
      writer.uint32(18).string(message.startTime);
    }
    if (message.endTime !== undefined) {
      writer.uint32(26).string(message.endTime);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerSchedule_Weekday {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerSchedule_Weekday();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.startTime = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.endTime = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerSchedule_Weekday {
    return {
      enabled: isSet(object.enabled) ? Boolean(object.enabled) : undefined,
      startTime: isSet(object.startTime) ? String(object.startTime) : undefined,
      endTime: isSet(object.endTime) ? String(object.endTime) : undefined,
    };
  },

  toJSON(message: DialerSchedule_Weekday): unknown {
    const obj: any = {};
    message.enabled !== undefined && (obj.enabled = message.enabled);
    message.startTime !== undefined && (obj.startTime = message.startTime);
    message.endTime !== undefined && (obj.endTime = message.endTime);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerSchedule_Weekday>, I>>(base?: I): DialerSchedule_Weekday {
    return DialerSchedule_Weekday.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerSchedule_Weekday>, I>>(object: I): DialerSchedule_Weekday {
    const message = createBaseDialerSchedule_Weekday();
    message.enabled = object.enabled ?? undefined;
    message.startTime = object.startTime ?? undefined;
    message.endTime = object.endTime ?? undefined;
    return message;
  },
};

function createBaseDialerQueueInbound(): DialerQueueInbound {
  return {
    id: undefined,
    lead: undefined,
    agency: undefined,
    skill: undefined,
    queue: undefined,
    uuid: undefined,
    createdAt: undefined,
    priority: undefined,
    bridgedAt: undefined,
    isConference: undefined,
    consumerCallControlId: undefined,
    directAgent: undefined,
    outgoingFlowRelated: undefined,
  };
}

export const DialerQueueInbound = {
  encode(message: DialerQueueInbound, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.lead !== undefined) {
      Consumer.encode(message.lead, writer.uint32(18).fork()).ldelim();
    }
    if (message.agency !== undefined) {
      UserIdContainer.encode(message.agency, writer.uint32(26).fork()).ldelim();
    }
    if (message.skill !== undefined) {
      SkillListContainer.encode(message.skill, writer.uint32(34).fork()).ldelim();
    }
    if (message.queue !== undefined) {
      DialerQueue.encode(message.queue, writer.uint32(42).fork()).ldelim();
    }
    if (message.uuid !== undefined) {
      writer.uint32(50).string(message.uuid);
    }
    if (message.createdAt !== undefined) {
      writer.uint32(56).int64(message.createdAt);
    }
    if (message.priority !== undefined) {
      writer.uint32(64).int32(message.priority);
    }
    if (message.bridgedAt !== undefined) {
      writer.uint32(72).int64(message.bridgedAt);
    }
    if (message.isConference !== undefined) {
      writer.uint32(80).bool(message.isConference);
    }
    if (message.consumerCallControlId !== undefined) {
      writer.uint32(90).string(message.consumerCallControlId);
    }
    if (message.directAgent !== undefined) {
      DialerAgent.encode(message.directAgent, writer.uint32(98).fork()).ldelim();
    }
    if (message.outgoingFlowRelated !== undefined) {
      RelatedContainer.encode(message.outgoingFlowRelated, writer.uint32(106).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerQueueInbound {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerQueueInbound();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.lead = Consumer.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.agency = UserIdContainer.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.skill = SkillListContainer.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.queue = DialerQueue.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.uuid = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.createdAt = longToNumber(reader.int64() as Long);
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.priority = reader.int32();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.bridgedAt = longToNumber(reader.int64() as Long);
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.isConference = reader.bool();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.consumerCallControlId = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.directAgent = DialerAgent.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.outgoingFlowRelated = RelatedContainer.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerQueueInbound {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      lead: isSet(object.lead) ? Consumer.fromJSON(object.lead) : undefined,
      agency: isSet(object.agency) ? UserIdContainer.fromJSON(object.agency) : undefined,
      skill: isSet(object.skill) ? SkillListContainer.fromJSON(object.skill) : undefined,
      queue: isSet(object.queue) ? DialerQueue.fromJSON(object.queue) : undefined,
      uuid: isSet(object.uuid) ? String(object.uuid) : undefined,
      createdAt: isSet(object.createdAt) ? Number(object.createdAt) : undefined,
      priority: isSet(object.priority) ? Number(object.priority) : undefined,
      bridgedAt: isSet(object.bridgedAt) ? Number(object.bridgedAt) : undefined,
      isConference: isSet(object.isConference) ? Boolean(object.isConference) : undefined,
      consumerCallControlId: isSet(object.consumerCallControlId) ? String(object.consumerCallControlId) : undefined,
      directAgent: isSet(object.directAgent) ? DialerAgent.fromJSON(object.directAgent) : undefined,
      outgoingFlowRelated: isSet(object.outgoingFlowRelated)
        ? RelatedContainer.fromJSON(object.outgoingFlowRelated)
        : undefined,
    };
  },

  toJSON(message: DialerQueueInbound): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.lead !== undefined && (obj.lead = message.lead ? Consumer.toJSON(message.lead) : undefined);
    message.agency !== undefined && (obj.agency = message.agency ? UserIdContainer.toJSON(message.agency) : undefined);
    message.skill !== undefined && (obj.skill = message.skill ? SkillListContainer.toJSON(message.skill) : undefined);
    message.queue !== undefined && (obj.queue = message.queue ? DialerQueue.toJSON(message.queue) : undefined);
    message.uuid !== undefined && (obj.uuid = message.uuid);
    message.createdAt !== undefined && (obj.createdAt = Math.round(message.createdAt));
    message.priority !== undefined && (obj.priority = Math.round(message.priority));
    message.bridgedAt !== undefined && (obj.bridgedAt = Math.round(message.bridgedAt));
    message.isConference !== undefined && (obj.isConference = message.isConference);
    message.consumerCallControlId !== undefined && (obj.consumerCallControlId = message.consumerCallControlId);
    message.directAgent !== undefined &&
      (obj.directAgent = message.directAgent ? DialerAgent.toJSON(message.directAgent) : undefined);
    message.outgoingFlowRelated !== undefined && (obj.outgoingFlowRelated = message.outgoingFlowRelated
      ? RelatedContainer.toJSON(message.outgoingFlowRelated)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerQueueInbound>, I>>(base?: I): DialerQueueInbound {
    return DialerQueueInbound.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerQueueInbound>, I>>(object: I): DialerQueueInbound {
    const message = createBaseDialerQueueInbound();
    message.id = object.id ?? undefined;
    message.lead = (object.lead !== undefined && object.lead !== null) ? Consumer.fromPartial(object.lead) : undefined;
    message.agency = (object.agency !== undefined && object.agency !== null)
      ? UserIdContainer.fromPartial(object.agency)
      : undefined;
    message.skill = (object.skill !== undefined && object.skill !== null)
      ? SkillListContainer.fromPartial(object.skill)
      : undefined;
    message.queue = (object.queue !== undefined && object.queue !== null)
      ? DialerQueue.fromPartial(object.queue)
      : undefined;
    message.uuid = object.uuid ?? undefined;
    message.createdAt = object.createdAt ?? undefined;
    message.priority = object.priority ?? undefined;
    message.bridgedAt = object.bridgedAt ?? undefined;
    message.isConference = object.isConference ?? undefined;
    message.consumerCallControlId = object.consumerCallControlId ?? undefined;
    message.directAgent = (object.directAgent !== undefined && object.directAgent !== null)
      ? DialerAgent.fromPartial(object.directAgent)
      : undefined;
    message.outgoingFlowRelated = (object.outgoingFlowRelated !== undefined && object.outgoingFlowRelated !== null)
      ? RelatedContainer.fromPartial(object.outgoingFlowRelated)
      : undefined;
    return message;
  },
};

function createBaseDialerQueueStatistics(): DialerQueueStatistics {
  return { position: undefined, waitTimeMs: undefined };
}

export const DialerQueueStatistics = {
  encode(message: DialerQueueStatistics, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.position !== undefined) {
      writer.uint32(8).int32(message.position);
    }
    if (message.waitTimeMs !== undefined) {
      writer.uint32(16).int32(message.waitTimeMs);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerQueueStatistics {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerQueueStatistics();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.position = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.waitTimeMs = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerQueueStatistics {
    return {
      position: isSet(object.position) ? Number(object.position) : undefined,
      waitTimeMs: isSet(object.waitTimeMs) ? Number(object.waitTimeMs) : undefined,
    };
  },

  toJSON(message: DialerQueueStatistics): unknown {
    const obj: any = {};
    message.position !== undefined && (obj.position = Math.round(message.position));
    message.waitTimeMs !== undefined && (obj.waitTimeMs = Math.round(message.waitTimeMs));
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerQueueStatistics>, I>>(base?: I): DialerQueueStatistics {
    return DialerQueueStatistics.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerQueueStatistics>, I>>(object: I): DialerQueueStatistics {
    const message = createBaseDialerQueueStatistics();
    message.position = object.position ?? undefined;
    message.waitTimeMs = object.waitTimeMs ?? undefined;
    return message;
  },
};

function createBaseDialerReferenceContainer(): DialerReferenceContainer {
  return { id: undefined, name: undefined, isCustom: undefined };
}

export const DialerReferenceContainer = {
  encode(message: DialerReferenceContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.isCustom !== undefined) {
      writer.uint32(24).bool(message.isCustom);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerReferenceContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerReferenceContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.isCustom = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerReferenceContainer {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      isCustom: isSet(object.isCustom) ? Boolean(object.isCustom) : undefined,
    };
  },

  toJSON(message: DialerReferenceContainer): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.isCustom !== undefined && (obj.isCustom = message.isCustom);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerReferenceContainer>, I>>(base?: I): DialerReferenceContainer {
    return DialerReferenceContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerReferenceContainer>, I>>(object: I): DialerReferenceContainer {
    const message = createBaseDialerReferenceContainer();
    message.id = object.id ?? undefined;
    message.name = object.name ?? undefined;
    message.isCustom = object.isCustom ?? undefined;
    return message;
  },
};

function createBaseDialerAgencySettings(): DialerAgencySettings {
  return {
    defaultDispoStatusId: undefined,
    defaultOutboundNumber: undefined,
    callCap: undefined,
    agentNumber: undefined,
  };
}

export const DialerAgencySettings = {
  encode(message: DialerAgencySettings, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.defaultDispoStatusId !== undefined) {
      writer.uint32(10).string(message.defaultDispoStatusId);
    }
    if (message.defaultOutboundNumber !== undefined) {
      writer.uint32(18).string(message.defaultOutboundNumber);
    }
    if (message.callCap !== undefined) {
      DialerCallCap.encode(message.callCap, writer.uint32(26).fork()).ldelim();
    }
    if (message.agentNumber !== undefined) {
      DialerAgencyAgentNumberSettings.encode(message.agentNumber, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerAgencySettings {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerAgencySettings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.defaultDispoStatusId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.defaultOutboundNumber = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.callCap = DialerCallCap.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.agentNumber = DialerAgencyAgentNumberSettings.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerAgencySettings {
    return {
      defaultDispoStatusId: isSet(object.defaultDispoStatusId) ? String(object.defaultDispoStatusId) : undefined,
      defaultOutboundNumber: isSet(object.defaultOutboundNumber) ? String(object.defaultOutboundNumber) : undefined,
      callCap: isSet(object.callCap) ? DialerCallCap.fromJSON(object.callCap) : undefined,
      agentNumber: isSet(object.agentNumber) ? DialerAgencyAgentNumberSettings.fromJSON(object.agentNumber) : undefined,
    };
  },

  toJSON(message: DialerAgencySettings): unknown {
    const obj: any = {};
    message.defaultDispoStatusId !== undefined && (obj.defaultDispoStatusId = message.defaultDispoStatusId);
    message.defaultOutboundNumber !== undefined && (obj.defaultOutboundNumber = message.defaultOutboundNumber);
    message.callCap !== undefined &&
      (obj.callCap = message.callCap ? DialerCallCap.toJSON(message.callCap) : undefined);
    message.agentNumber !== undefined &&
      (obj.agentNumber = message.agentNumber ? DialerAgencyAgentNumberSettings.toJSON(message.agentNumber) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerAgencySettings>, I>>(base?: I): DialerAgencySettings {
    return DialerAgencySettings.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerAgencySettings>, I>>(object: I): DialerAgencySettings {
    const message = createBaseDialerAgencySettings();
    message.defaultDispoStatusId = object.defaultDispoStatusId ?? undefined;
    message.defaultOutboundNumber = object.defaultOutboundNumber ?? undefined;
    message.callCap = (object.callCap !== undefined && object.callCap !== null)
      ? DialerCallCap.fromPartial(object.callCap)
      : undefined;
    message.agentNumber = (object.agentNumber !== undefined && object.agentNumber !== null)
      ? DialerAgencyAgentNumberSettings.fromPartial(object.agentNumber)
      : undefined;
    return message;
  },
};

function createBaseDialerAgencyAgentNumberSettings(): DialerAgencyAgentNumberSettings {
  return {
    useOwnNumber: undefined,
    canClaimNumber: undefined,
    autoClaimNewAgent: undefined,
    autoClaimRegion: undefined,
  };
}

export const DialerAgencyAgentNumberSettings = {
  encode(message: DialerAgencyAgentNumberSettings, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.useOwnNumber !== undefined) {
      writer.uint32(8).bool(message.useOwnNumber);
    }
    if (message.canClaimNumber !== undefined) {
      writer.uint32(16).bool(message.canClaimNumber);
    }
    if (message.autoClaimNewAgent !== undefined) {
      writer.uint32(24).bool(message.autoClaimNewAgent);
    }
    if (message.autoClaimRegion !== undefined) {
      writer.uint32(34).string(message.autoClaimRegion);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerAgencyAgentNumberSettings {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerAgencyAgentNumberSettings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.useOwnNumber = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.canClaimNumber = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.autoClaimNewAgent = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.autoClaimRegion = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerAgencyAgentNumberSettings {
    return {
      useOwnNumber: isSet(object.useOwnNumber) ? Boolean(object.useOwnNumber) : undefined,
      canClaimNumber: isSet(object.canClaimNumber) ? Boolean(object.canClaimNumber) : undefined,
      autoClaimNewAgent: isSet(object.autoClaimNewAgent) ? Boolean(object.autoClaimNewAgent) : undefined,
      autoClaimRegion: isSet(object.autoClaimRegion) ? String(object.autoClaimRegion) : undefined,
    };
  },

  toJSON(message: DialerAgencyAgentNumberSettings): unknown {
    const obj: any = {};
    message.useOwnNumber !== undefined && (obj.useOwnNumber = message.useOwnNumber);
    message.canClaimNumber !== undefined && (obj.canClaimNumber = message.canClaimNumber);
    message.autoClaimNewAgent !== undefined && (obj.autoClaimNewAgent = message.autoClaimNewAgent);
    message.autoClaimRegion !== undefined && (obj.autoClaimRegion = message.autoClaimRegion);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerAgencyAgentNumberSettings>, I>>(base?: I): DialerAgencyAgentNumberSettings {
    return DialerAgencyAgentNumberSettings.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerAgencyAgentNumberSettings>, I>>(
    object: I,
  ): DialerAgencyAgentNumberSettings {
    const message = createBaseDialerAgencyAgentNumberSettings();
    message.useOwnNumber = object.useOwnNumber ?? undefined;
    message.canClaimNumber = object.canClaimNumber ?? undefined;
    message.autoClaimNewAgent = object.autoClaimNewAgent ?? undefined;
    message.autoClaimRegion = object.autoClaimRegion ?? undefined;
    return message;
  },
};

function createBaseDialerStatusTimesDaily(): DialerStatusTimesDaily {
  return {
    date: undefined,
    totalWorkTime: undefined,
    statusDurationPercentages: undefined,
    statusDurationTotals: undefined,
    statusDurations: undefined,
    statusTimes: undefined,
    agency: undefined,
    agent: undefined,
    lastStatus: undefined,
    lastStatusSet: undefined,
    timestamp: undefined,
  };
}

export const DialerStatusTimesDaily = {
  encode(message: DialerStatusTimesDaily, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.date !== undefined) {
      writer.uint32(10).string(message.date);
    }
    if (message.totalWorkTime !== undefined) {
      writer.uint32(16).int32(message.totalWorkTime);
    }
    if (message.statusDurationPercentages !== undefined) {
      DialerStatusNumberContainer.encode(message.statusDurationPercentages, writer.uint32(26).fork()).ldelim();
    }
    if (message.statusDurationTotals !== undefined) {
      DialerStatusNumberContainer.encode(message.statusDurationTotals, writer.uint32(34).fork()).ldelim();
    }
    if (message.statusDurations !== undefined) {
      DialerStatusNumberContainer.encode(message.statusDurations, writer.uint32(42).fork()).ldelim();
    }
    if (message.statusTimes !== undefined) {
      DialerStatusNumberListContainer.encode(message.statusTimes, writer.uint32(50).fork()).ldelim();
    }
    if (message.agency !== undefined) {
      UserIdContainer.encode(message.agency, writer.uint32(58).fork()).ldelim();
    }
    if (message.agent !== undefined) {
      UserIdContainer.encode(message.agent, writer.uint32(66).fork()).ldelim();
    }
    if (message.lastStatus !== undefined) {
      writer.uint32(74).string(message.lastStatus);
    }
    if (message.lastStatusSet !== undefined) {
      writer.uint32(80).int64(message.lastStatusSet);
    }
    if (message.timestamp !== undefined) {
      writer.uint32(88).int64(message.timestamp);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerStatusTimesDaily {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerStatusTimesDaily();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.date = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.totalWorkTime = reader.int32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.statusDurationPercentages = DialerStatusNumberContainer.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.statusDurationTotals = DialerStatusNumberContainer.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.statusDurations = DialerStatusNumberContainer.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.statusTimes = DialerStatusNumberListContainer.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.agency = UserIdContainer.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.agent = UserIdContainer.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.lastStatus = reader.string();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.lastStatusSet = longToNumber(reader.int64() as Long);
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.timestamp = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerStatusTimesDaily {
    return {
      date: isSet(object.date) ? String(object.date) : undefined,
      totalWorkTime: isSet(object.totalWorkTime) ? Number(object.totalWorkTime) : undefined,
      statusDurationPercentages: isSet(object.statusDurationPercentages)
        ? DialerStatusNumberContainer.fromJSON(object.statusDurationPercentages)
        : undefined,
      statusDurationTotals: isSet(object.statusDurationTotals)
        ? DialerStatusNumberContainer.fromJSON(object.statusDurationTotals)
        : undefined,
      statusDurations: isSet(object.statusDurations)
        ? DialerStatusNumberContainer.fromJSON(object.statusDurations)
        : undefined,
      statusTimes: isSet(object.statusTimes) ? DialerStatusNumberListContainer.fromJSON(object.statusTimes) : undefined,
      agency: isSet(object.agency) ? UserIdContainer.fromJSON(object.agency) : undefined,
      agent: isSet(object.agent) ? UserIdContainer.fromJSON(object.agent) : undefined,
      lastStatus: isSet(object.lastStatus) ? String(object.lastStatus) : undefined,
      lastStatusSet: isSet(object.lastStatusSet) ? Number(object.lastStatusSet) : undefined,
      timestamp: isSet(object.timestamp) ? Number(object.timestamp) : undefined,
    };
  },

  toJSON(message: DialerStatusTimesDaily): unknown {
    const obj: any = {};
    message.date !== undefined && (obj.date = message.date);
    message.totalWorkTime !== undefined && (obj.totalWorkTime = Math.round(message.totalWorkTime));
    message.statusDurationPercentages !== undefined &&
      (obj.statusDurationPercentages = message.statusDurationPercentages
        ? DialerStatusNumberContainer.toJSON(message.statusDurationPercentages)
        : undefined);
    message.statusDurationTotals !== undefined && (obj.statusDurationTotals = message.statusDurationTotals
      ? DialerStatusNumberContainer.toJSON(message.statusDurationTotals)
      : undefined);
    message.statusDurations !== undefined && (obj.statusDurations = message.statusDurations
      ? DialerStatusNumberContainer.toJSON(message.statusDurations)
      : undefined);
    message.statusTimes !== undefined &&
      (obj.statusTimes = message.statusTimes ? DialerStatusNumberListContainer.toJSON(message.statusTimes) : undefined);
    message.agency !== undefined && (obj.agency = message.agency ? UserIdContainer.toJSON(message.agency) : undefined);
    message.agent !== undefined && (obj.agent = message.agent ? UserIdContainer.toJSON(message.agent) : undefined);
    message.lastStatus !== undefined && (obj.lastStatus = message.lastStatus);
    message.lastStatusSet !== undefined && (obj.lastStatusSet = Math.round(message.lastStatusSet));
    message.timestamp !== undefined && (obj.timestamp = Math.round(message.timestamp));
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerStatusTimesDaily>, I>>(base?: I): DialerStatusTimesDaily {
    return DialerStatusTimesDaily.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerStatusTimesDaily>, I>>(object: I): DialerStatusTimesDaily {
    const message = createBaseDialerStatusTimesDaily();
    message.date = object.date ?? undefined;
    message.totalWorkTime = object.totalWorkTime ?? undefined;
    message.statusDurationPercentages =
      (object.statusDurationPercentages !== undefined && object.statusDurationPercentages !== null)
        ? DialerStatusNumberContainer.fromPartial(object.statusDurationPercentages)
        : undefined;
    message.statusDurationTotals = (object.statusDurationTotals !== undefined && object.statusDurationTotals !== null)
      ? DialerStatusNumberContainer.fromPartial(object.statusDurationTotals)
      : undefined;
    message.statusDurations = (object.statusDurations !== undefined && object.statusDurations !== null)
      ? DialerStatusNumberContainer.fromPartial(object.statusDurations)
      : undefined;
    message.statusTimes = (object.statusTimes !== undefined && object.statusTimes !== null)
      ? DialerStatusNumberListContainer.fromPartial(object.statusTimes)
      : undefined;
    message.agency = (object.agency !== undefined && object.agency !== null)
      ? UserIdContainer.fromPartial(object.agency)
      : undefined;
    message.agent = (object.agent !== undefined && object.agent !== null)
      ? UserIdContainer.fromPartial(object.agent)
      : undefined;
    message.lastStatus = object.lastStatus ?? undefined;
    message.lastStatusSet = object.lastStatusSet ?? undefined;
    message.timestamp = object.timestamp ?? undefined;
    return message;
  },
};

function createBaseDialerStatusNumberContainer(): DialerStatusNumberContainer {
  return {
    AVAILABLE: undefined,
    CALLING: undefined,
    DISPO: undefined,
    OFFLINE: undefined,
    PAUSED: undefined,
    RINGING: undefined,
    ringingOutgoing: undefined,
  };
}

export const DialerStatusNumberContainer = {
  encode(message: DialerStatusNumberContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.AVAILABLE !== undefined) {
      writer.uint32(8).int32(message.AVAILABLE);
    }
    if (message.CALLING !== undefined) {
      writer.uint32(16).int32(message.CALLING);
    }
    if (message.DISPO !== undefined) {
      writer.uint32(24).int32(message.DISPO);
    }
    if (message.OFFLINE !== undefined) {
      writer.uint32(32).int32(message.OFFLINE);
    }
    if (message.PAUSED !== undefined) {
      writer.uint32(40).int32(message.PAUSED);
    }
    if (message.RINGING !== undefined) {
      writer.uint32(48).int32(message.RINGING);
    }
    if (message.ringingOutgoing !== undefined) {
      writer.uint32(56).int32(message.ringingOutgoing);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerStatusNumberContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerStatusNumberContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.AVAILABLE = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.CALLING = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.DISPO = reader.int32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.OFFLINE = reader.int32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.PAUSED = reader.int32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.RINGING = reader.int32();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.ringingOutgoing = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerStatusNumberContainer {
    return {
      AVAILABLE: isSet(object.AVAILABLE) ? Number(object.AVAILABLE) : undefined,
      CALLING: isSet(object.CALLING) ? Number(object.CALLING) : undefined,
      DISPO: isSet(object.DISPO) ? Number(object.DISPO) : undefined,
      OFFLINE: isSet(object.OFFLINE) ? Number(object.OFFLINE) : undefined,
      PAUSED: isSet(object.PAUSED) ? Number(object.PAUSED) : undefined,
      RINGING: isSet(object.RINGING) ? Number(object.RINGING) : undefined,
      ringingOutgoing: isSet(object.RINGINGOUTGOING) ? Number(object.RINGINGOUTGOING) : undefined,
    };
  },

  toJSON(message: DialerStatusNumberContainer): unknown {
    const obj: any = {};
    message.AVAILABLE !== undefined && (obj.AVAILABLE = Math.round(message.AVAILABLE));
    message.CALLING !== undefined && (obj.CALLING = Math.round(message.CALLING));
    message.DISPO !== undefined && (obj.DISPO = Math.round(message.DISPO));
    message.OFFLINE !== undefined && (obj.OFFLINE = Math.round(message.OFFLINE));
    message.PAUSED !== undefined && (obj.PAUSED = Math.round(message.PAUSED));
    message.RINGING !== undefined && (obj.RINGING = Math.round(message.RINGING));
    message.ringingOutgoing !== undefined && (obj.RINGINGOUTGOING = Math.round(message.ringingOutgoing));
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerStatusNumberContainer>, I>>(base?: I): DialerStatusNumberContainer {
    return DialerStatusNumberContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerStatusNumberContainer>, I>>(object: I): DialerStatusNumberContainer {
    const message = createBaseDialerStatusNumberContainer();
    message.AVAILABLE = object.AVAILABLE ?? undefined;
    message.CALLING = object.CALLING ?? undefined;
    message.DISPO = object.DISPO ?? undefined;
    message.OFFLINE = object.OFFLINE ?? undefined;
    message.PAUSED = object.PAUSED ?? undefined;
    message.RINGING = object.RINGING ?? undefined;
    message.ringingOutgoing = object.ringingOutgoing ?? undefined;
    return message;
  },
};

function createBaseDialerStatusNumberListContainer(): DialerStatusNumberListContainer {
  return { AVAILABLE: [], CALLING: [], DISPO: [], OFFLINE: [], PAUSED: [], RINGING: [], ringingOutgoing: [] };
}

export const DialerStatusNumberListContainer = {
  encode(message: DialerStatusNumberListContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.AVAILABLE) {
      writer.int32(v);
    }
    writer.ldelim();
    writer.uint32(18).fork();
    for (const v of message.CALLING) {
      writer.int32(v);
    }
    writer.ldelim();
    writer.uint32(26).fork();
    for (const v of message.DISPO) {
      writer.int32(v);
    }
    writer.ldelim();
    writer.uint32(34).fork();
    for (const v of message.OFFLINE) {
      writer.int32(v);
    }
    writer.ldelim();
    writer.uint32(42).fork();
    for (const v of message.PAUSED) {
      writer.int32(v);
    }
    writer.ldelim();
    writer.uint32(50).fork();
    for (const v of message.RINGING) {
      writer.int32(v);
    }
    writer.ldelim();
    writer.uint32(58).fork();
    for (const v of message.ringingOutgoing) {
      writer.int32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerStatusNumberListContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerStatusNumberListContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.AVAILABLE.push(reader.int32());

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.AVAILABLE.push(reader.int32());
            }

            continue;
          }

          break;
        case 2:
          if (tag === 16) {
            message.CALLING.push(reader.int32());

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.CALLING.push(reader.int32());
            }

            continue;
          }

          break;
        case 3:
          if (tag === 24) {
            message.DISPO.push(reader.int32());

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.DISPO.push(reader.int32());
            }

            continue;
          }

          break;
        case 4:
          if (tag === 32) {
            message.OFFLINE.push(reader.int32());

            continue;
          }

          if (tag === 34) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.OFFLINE.push(reader.int32());
            }

            continue;
          }

          break;
        case 5:
          if (tag === 40) {
            message.PAUSED.push(reader.int32());

            continue;
          }

          if (tag === 42) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.PAUSED.push(reader.int32());
            }

            continue;
          }

          break;
        case 6:
          if (tag === 48) {
            message.RINGING.push(reader.int32());

            continue;
          }

          if (tag === 50) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.RINGING.push(reader.int32());
            }

            continue;
          }

          break;
        case 7:
          if (tag === 56) {
            message.ringingOutgoing.push(reader.int32());

            continue;
          }

          if (tag === 58) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.ringingOutgoing.push(reader.int32());
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerStatusNumberListContainer {
    return {
      AVAILABLE: Array.isArray(object?.AVAILABLE) ? object.AVAILABLE.map((e: any) => Number(e)) : [],
      CALLING: Array.isArray(object?.CALLING) ? object.CALLING.map((e: any) => Number(e)) : [],
      DISPO: Array.isArray(object?.DISPO) ? object.DISPO.map((e: any) => Number(e)) : [],
      OFFLINE: Array.isArray(object?.OFFLINE) ? object.OFFLINE.map((e: any) => Number(e)) : [],
      PAUSED: Array.isArray(object?.PAUSED) ? object.PAUSED.map((e: any) => Number(e)) : [],
      RINGING: Array.isArray(object?.RINGING) ? object.RINGING.map((e: any) => Number(e)) : [],
      ringingOutgoing: Array.isArray(object?.RINGINGOUTGOING) ? object.RINGINGOUTGOING.map((e: any) => Number(e)) : [],
    };
  },

  toJSON(message: DialerStatusNumberListContainer): unknown {
    const obj: any = {};
    if (message.AVAILABLE) {
      obj.AVAILABLE = message.AVAILABLE.map((e) => Math.round(e));
    } else {
      obj.AVAILABLE = [];
    }
    if (message.CALLING) {
      obj.CALLING = message.CALLING.map((e) => Math.round(e));
    } else {
      obj.CALLING = [];
    }
    if (message.DISPO) {
      obj.DISPO = message.DISPO.map((e) => Math.round(e));
    } else {
      obj.DISPO = [];
    }
    if (message.OFFLINE) {
      obj.OFFLINE = message.OFFLINE.map((e) => Math.round(e));
    } else {
      obj.OFFLINE = [];
    }
    if (message.PAUSED) {
      obj.PAUSED = message.PAUSED.map((e) => Math.round(e));
    } else {
      obj.PAUSED = [];
    }
    if (message.RINGING) {
      obj.RINGING = message.RINGING.map((e) => Math.round(e));
    } else {
      obj.RINGING = [];
    }
    if (message.ringingOutgoing) {
      obj.RINGINGOUTGOING = message.ringingOutgoing.map((e) => Math.round(e));
    } else {
      obj.RINGINGOUTGOING = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerStatusNumberListContainer>, I>>(base?: I): DialerStatusNumberListContainer {
    return DialerStatusNumberListContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerStatusNumberListContainer>, I>>(
    object: I,
  ): DialerStatusNumberListContainer {
    const message = createBaseDialerStatusNumberListContainer();
    message.AVAILABLE = object.AVAILABLE?.map((e) => e) || [];
    message.CALLING = object.CALLING?.map((e) => e) || [];
    message.DISPO = object.DISPO?.map((e) => e) || [];
    message.OFFLINE = object.OFFLINE?.map((e) => e) || [];
    message.PAUSED = object.PAUSED?.map((e) => e) || [];
    message.RINGING = object.RINGING?.map((e) => e) || [];
    message.ringingOutgoing = object.ringingOutgoing?.map((e) => e) || [];
    return message;
  },
};

function createBaseDialerBillableSettings(): DialerBillableSettings {
  return {
    isBillable: undefined,
    billableAfterSeconds: undefined,
    countingType: undefined,
    billableAfterAnswer: undefined,
    costPerCall: undefined,
    costPerMinute: undefined,
  };
}

export const DialerBillableSettings = {
  encode(message: DialerBillableSettings, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.isBillable !== undefined) {
      writer.uint32(8).bool(message.isBillable);
    }
    if (message.billableAfterSeconds !== undefined) {
      writer.uint32(16).int32(message.billableAfterSeconds);
    }
    if (message.countingType !== undefined) {
      writer.uint32(24).int32(message.countingType);
    }
    if (message.billableAfterAnswer !== undefined) {
      writer.uint32(32).bool(message.billableAfterAnswer);
    }
    if (message.costPerCall !== undefined) {
      writer.uint32(41).double(message.costPerCall);
    }
    if (message.costPerMinute !== undefined) {
      writer.uint32(49).double(message.costPerMinute);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerBillableSettings {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerBillableSettings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.isBillable = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.billableAfterSeconds = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.countingType = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.billableAfterAnswer = reader.bool();
          continue;
        case 5:
          if (tag !== 41) {
            break;
          }

          message.costPerCall = reader.double();
          continue;
        case 6:
          if (tag !== 49) {
            break;
          }

          message.costPerMinute = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerBillableSettings {
    return {
      isBillable: isSet(object.isBillable) ? Boolean(object.isBillable) : undefined,
      billableAfterSeconds: isSet(object.billableAfterSeconds) ? Number(object.billableAfterSeconds) : undefined,
      countingType: isSet(object.countingType)
        ? dialerBillableSettingsCountingTypeFromJSON(object.countingType)
        : undefined,
      billableAfterAnswer: isSet(object.billableAfterAnswer) ? Boolean(object.billableAfterAnswer) : undefined,
      costPerCall: isSet(object.costPerCall) ? Number(object.costPerCall) : undefined,
      costPerMinute: isSet(object.costPerMinute) ? Number(object.costPerMinute) : undefined,
    };
  },

  toJSON(message: DialerBillableSettings): unknown {
    const obj: any = {};
    message.isBillable !== undefined && (obj.isBillable = message.isBillable);
    message.billableAfterSeconds !== undefined && (obj.billableAfterSeconds = Math.round(message.billableAfterSeconds));
    message.countingType !== undefined && (obj.countingType = message.countingType !== undefined
      ? dialerBillableSettingsCountingTypeToJSON(message.countingType)
      : undefined);
    message.billableAfterAnswer !== undefined && (obj.billableAfterAnswer = message.billableAfterAnswer);
    message.costPerCall !== undefined && (obj.costPerCall = message.costPerCall);
    message.costPerMinute !== undefined && (obj.costPerMinute = message.costPerMinute);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerBillableSettings>, I>>(base?: I): DialerBillableSettings {
    return DialerBillableSettings.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerBillableSettings>, I>>(object: I): DialerBillableSettings {
    const message = createBaseDialerBillableSettings();
    message.isBillable = object.isBillable ?? undefined;
    message.billableAfterSeconds = object.billableAfterSeconds ?? undefined;
    message.countingType = object.countingType ?? undefined;
    message.billableAfterAnswer = object.billableAfterAnswer ?? undefined;
    message.costPerCall = object.costPerCall ?? undefined;
    message.costPerMinute = object.costPerMinute ?? undefined;
    return message;
  },
};

function createBaseAdvanceSettings(): AdvanceSettings {
  return { advance: undefined, advanceInS: undefined };
}

export const AdvanceSettings = {
  encode(message: AdvanceSettings, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.advance !== undefined) {
      writer.uint32(8).bool(message.advance);
    }
    if (message.advanceInS !== undefined) {
      writer.uint32(16).int32(message.advanceInS);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdvanceSettings {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdvanceSettings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.advance = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.advanceInS = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdvanceSettings {
    return {
      advance: isSet(object.advance) ? Boolean(object.advance) : undefined,
      advanceInS: isSet(object.advanceInS) ? Number(object.advanceInS) : undefined,
    };
  },

  toJSON(message: AdvanceSettings): unknown {
    const obj: any = {};
    message.advance !== undefined && (obj.advance = message.advance);
    message.advanceInS !== undefined && (obj.advanceInS = Math.round(message.advanceInS));
    return obj;
  },

  create<I extends Exact<DeepPartial<AdvanceSettings>, I>>(base?: I): AdvanceSettings {
    return AdvanceSettings.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AdvanceSettings>, I>>(object: I): AdvanceSettings {
    const message = createBaseAdvanceSettings();
    message.advance = object.advance ?? undefined;
    message.advanceInS = object.advanceInS ?? undefined;
    return message;
  },
};

function createBaseDialerCallDeclineReasonContainer(): DialerCallDeclineReasonContainer {
  return { status: undefined, setAt: undefined };
}

export const DialerCallDeclineReasonContainer = {
  encode(message: DialerCallDeclineReasonContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== undefined) {
      writer.uint32(8).int32(message.status);
    }
    if (message.setAt !== undefined) {
      writer.uint32(16).int64(message.setAt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerCallDeclineReasonContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerCallDeclineReasonContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.setAt = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerCallDeclineReasonContainer {
    return {
      status: isSet(object.status) ? dialerCallDeclineReasonFromJSON(object.status) : undefined,
      setAt: isSet(object.setAt) ? Number(object.setAt) : undefined,
    };
  },

  toJSON(message: DialerCallDeclineReasonContainer): unknown {
    const obj: any = {};
    message.status !== undefined &&
      (obj.status = message.status !== undefined ? dialerCallDeclineReasonToJSON(message.status) : undefined);
    message.setAt !== undefined && (obj.setAt = Math.round(message.setAt));
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerCallDeclineReasonContainer>, I>>(
    base?: I,
  ): DialerCallDeclineReasonContainer {
    return DialerCallDeclineReasonContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerCallDeclineReasonContainer>, I>>(
    object: I,
  ): DialerCallDeclineReasonContainer {
    const message = createBaseDialerCallDeclineReasonContainer();
    message.status = object.status ?? undefined;
    message.setAt = object.setAt ?? undefined;
    return message;
  },
};

function createBaseDialerConcurrentCallCap(): DialerConcurrentCallCap {
  return { enabled: undefined, maxConcurrentCalls: undefined };
}

export const DialerConcurrentCallCap = {
  encode(message: DialerConcurrentCallCap, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.enabled !== undefined) {
      writer.uint32(8).bool(message.enabled);
    }
    if (message.maxConcurrentCalls !== undefined) {
      writer.uint32(17).double(message.maxConcurrentCalls);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerConcurrentCallCap {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerConcurrentCallCap();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.maxConcurrentCalls = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerConcurrentCallCap {
    return {
      enabled: isSet(object.enabled) ? Boolean(object.enabled) : undefined,
      maxConcurrentCalls: isSet(object.maxConcurrentCalls) ? Number(object.maxConcurrentCalls) : undefined,
    };
  },

  toJSON(message: DialerConcurrentCallCap): unknown {
    const obj: any = {};
    message.enabled !== undefined && (obj.enabled = message.enabled);
    message.maxConcurrentCalls !== undefined && (obj.maxConcurrentCalls = message.maxConcurrentCalls);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerConcurrentCallCap>, I>>(base?: I): DialerConcurrentCallCap {
    return DialerConcurrentCallCap.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerConcurrentCallCap>, I>>(object: I): DialerConcurrentCallCap {
    const message = createBaseDialerConcurrentCallCap();
    message.enabled = object.enabled ?? undefined;
    message.maxConcurrentCalls = object.maxConcurrentCalls ?? undefined;
    return message;
  },
};

function createBaseRelatedContainer(): RelatedContainer {
  return { calls: [] };
}

export const RelatedContainer = {
  encode(message: RelatedContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.calls) {
      RelatedCall.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RelatedContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRelatedContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.calls.push(RelatedCall.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RelatedContainer {
    return { calls: Array.isArray(object?.calls) ? object.calls.map((e: any) => RelatedCall.fromJSON(e)) : [] };
  },

  toJSON(message: RelatedContainer): unknown {
    const obj: any = {};
    if (message.calls) {
      obj.calls = message.calls.map((e) => e ? RelatedCall.toJSON(e) : undefined);
    } else {
      obj.calls = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RelatedContainer>, I>>(base?: I): RelatedContainer {
    return RelatedContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RelatedContainer>, I>>(object: I): RelatedContainer {
    const message = createBaseRelatedContainer();
    message.calls = object.calls?.map((e) => RelatedCall.fromPartial(e)) || [];
    return message;
  },
};

function createBaseRelatedCall(): RelatedCall {
  return {
    uuid: undefined,
    lead: undefined,
    agency: undefined,
    queue: undefined,
    isParent: undefined,
    isChild: undefined,
  };
}

export const RelatedCall = {
  encode(message: RelatedCall, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.uuid !== undefined) {
      writer.uint32(10).string(message.uuid);
    }
    if (message.lead !== undefined) {
      UserIdContainer.encode(message.lead, writer.uint32(18).fork()).ldelim();
    }
    if (message.agency !== undefined) {
      UserIdContainer.encode(message.agency, writer.uint32(26).fork()).ldelim();
    }
    if (message.queue !== undefined) {
      UserIdContainer.encode(message.queue, writer.uint32(34).fork()).ldelim();
    }
    if (message.isParent !== undefined) {
      writer.uint32(40).bool(message.isParent);
    }
    if (message.isChild !== undefined) {
      writer.uint32(48).bool(message.isChild);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RelatedCall {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRelatedCall();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.uuid = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.lead = UserIdContainer.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.agency = UserIdContainer.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.queue = UserIdContainer.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.isParent = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.isChild = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RelatedCall {
    return {
      uuid: isSet(object.uuid) ? String(object.uuid) : undefined,
      lead: isSet(object.lead) ? UserIdContainer.fromJSON(object.lead) : undefined,
      agency: isSet(object.agency) ? UserIdContainer.fromJSON(object.agency) : undefined,
      queue: isSet(object.queue) ? UserIdContainer.fromJSON(object.queue) : undefined,
      isParent: isSet(object.isParent) ? Boolean(object.isParent) : undefined,
      isChild: isSet(object.isChild) ? Boolean(object.isChild) : undefined,
    };
  },

  toJSON(message: RelatedCall): unknown {
    const obj: any = {};
    message.uuid !== undefined && (obj.uuid = message.uuid);
    message.lead !== undefined && (obj.lead = message.lead ? UserIdContainer.toJSON(message.lead) : undefined);
    message.agency !== undefined && (obj.agency = message.agency ? UserIdContainer.toJSON(message.agency) : undefined);
    message.queue !== undefined && (obj.queue = message.queue ? UserIdContainer.toJSON(message.queue) : undefined);
    message.isParent !== undefined && (obj.isParent = message.isParent);
    message.isChild !== undefined && (obj.isChild = message.isChild);
    return obj;
  },

  create<I extends Exact<DeepPartial<RelatedCall>, I>>(base?: I): RelatedCall {
    return RelatedCall.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RelatedCall>, I>>(object: I): RelatedCall {
    const message = createBaseRelatedCall();
    message.uuid = object.uuid ?? undefined;
    message.lead = (object.lead !== undefined && object.lead !== null)
      ? UserIdContainer.fromPartial(object.lead)
      : undefined;
    message.agency = (object.agency !== undefined && object.agency !== null)
      ? UserIdContainer.fromPartial(object.agency)
      : undefined;
    message.queue = (object.queue !== undefined && object.queue !== null)
      ? UserIdContainer.fromPartial(object.queue)
      : undefined;
    message.isParent = object.isParent ?? undefined;
    message.isChild = object.isChild ?? undefined;
    return message;
  },
};

function createBaseDialerAgentNumber(): DialerAgentNumber {
  return { settings: [], number: undefined, defaultOutbound: undefined };
}

export const DialerAgentNumber = {
  encode(message: DialerAgentNumber, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.settings) {
      DialerAgentNumberItem.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.number !== undefined) {
      DialerAgentNumberListContainer.encode(message.number, writer.uint32(18).fork()).ldelim();
    }
    if (message.defaultOutbound !== undefined) {
      writer.uint32(26).string(message.defaultOutbound);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerAgentNumber {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerAgentNumber();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.settings.push(DialerAgentNumberItem.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.number = DialerAgentNumberListContainer.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.defaultOutbound = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerAgentNumber {
    return {
      settings: Array.isArray(object?.settings)
        ? object.settings.map((e: any) => DialerAgentNumberItem.fromJSON(e))
        : [],
      number: isSet(object.number) ? DialerAgentNumberListContainer.fromJSON(object.number) : undefined,
      defaultOutbound: isSet(object.defaultOutbound) ? String(object.defaultOutbound) : undefined,
    };
  },

  toJSON(message: DialerAgentNumber): unknown {
    const obj: any = {};
    if (message.settings) {
      obj.settings = message.settings.map((e) => e ? DialerAgentNumberItem.toJSON(e) : undefined);
    } else {
      obj.settings = [];
    }
    message.number !== undefined &&
      (obj.number = message.number ? DialerAgentNumberListContainer.toJSON(message.number) : undefined);
    message.defaultOutbound !== undefined && (obj.defaultOutbound = message.defaultOutbound);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerAgentNumber>, I>>(base?: I): DialerAgentNumber {
    return DialerAgentNumber.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerAgentNumber>, I>>(object: I): DialerAgentNumber {
    const message = createBaseDialerAgentNumber();
    message.settings = object.settings?.map((e) => DialerAgentNumberItem.fromPartial(e)) || [];
    message.number = (object.number !== undefined && object.number !== null)
      ? DialerAgentNumberListContainer.fromPartial(object.number)
      : undefined;
    message.defaultOutbound = object.defaultOutbound ?? undefined;
    return message;
  },
};

function createBaseDialerAgentNumberListContainer(): DialerAgentNumberListContainer {
  return { list: [] };
}

export const DialerAgentNumberListContainer = {
  encode(message: DialerAgentNumberListContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.list) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerAgentNumberListContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerAgentNumberListContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.list.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerAgentNumberListContainer {
    return { list: Array.isArray(object?.list) ? object.list.map((e: any) => String(e)) : [] };
  },

  toJSON(message: DialerAgentNumberListContainer): unknown {
    const obj: any = {};
    if (message.list) {
      obj.list = message.list.map((e) => e);
    } else {
      obj.list = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerAgentNumberListContainer>, I>>(base?: I): DialerAgentNumberListContainer {
    return DialerAgentNumberListContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerAgentNumberListContainer>, I>>(
    object: I,
  ): DialerAgentNumberListContainer {
    const message = createBaseDialerAgentNumberListContainer();
    message.list = object.list?.map((e) => e) || [];
    return message;
  },
};

function createBaseDialerAgentNumberItem(): DialerAgentNumberItem {
  return {
    enabled: undefined,
    number: undefined,
    handler: undefined,
    delayInS: undefined,
    voicemailText: undefined,
    voicemailAudio: undefined,
    transferToNumber: undefined,
    boughtAt: undefined,
  };
}

export const DialerAgentNumberItem = {
  encode(message: DialerAgentNumberItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.enabled !== undefined) {
      writer.uint32(8).bool(message.enabled);
    }
    if (message.number !== undefined) {
      writer.uint32(18).string(message.number);
    }
    if (message.handler !== undefined) {
      writer.uint32(24).int32(message.handler);
    }
    if (message.delayInS !== undefined) {
      writer.uint32(32).int32(message.delayInS);
    }
    if (message.voicemailText !== undefined) {
      writer.uint32(42).string(message.voicemailText);
    }
    if (message.voicemailAudio !== undefined) {
      writer.uint32(50).string(message.voicemailAudio);
    }
    if (message.transferToNumber !== undefined) {
      writer.uint32(58).string(message.transferToNumber);
    }
    if (message.boughtAt !== undefined) {
      writer.uint32(64).int64(message.boughtAt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerAgentNumberItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerAgentNumberItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.number = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.handler = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.delayInS = reader.int32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.voicemailText = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.voicemailAudio = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.transferToNumber = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.boughtAt = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerAgentNumberItem {
    return {
      enabled: isSet(object.enabled) ? Boolean(object.enabled) : undefined,
      number: isSet(object.number) ? String(object.number) : undefined,
      handler: isSet(object.handler) ? dialerAgentNumberHandlerFromJSON(object.handler) : undefined,
      delayInS: isSet(object.delayInS) ? Number(object.delayInS) : undefined,
      voicemailText: isSet(object.voicemailText) ? String(object.voicemailText) : undefined,
      voicemailAudio: isSet(object.voicemailAudio) ? String(object.voicemailAudio) : undefined,
      transferToNumber: isSet(object.transferToNumber) ? String(object.transferToNumber) : undefined,
      boughtAt: isSet(object.boughtAt) ? Number(object.boughtAt) : undefined,
    };
  },

  toJSON(message: DialerAgentNumberItem): unknown {
    const obj: any = {};
    message.enabled !== undefined && (obj.enabled = message.enabled);
    message.number !== undefined && (obj.number = message.number);
    message.handler !== undefined &&
      (obj.handler = message.handler !== undefined ? dialerAgentNumberHandlerToJSON(message.handler) : undefined);
    message.delayInS !== undefined && (obj.delayInS = Math.round(message.delayInS));
    message.voicemailText !== undefined && (obj.voicemailText = message.voicemailText);
    message.voicemailAudio !== undefined && (obj.voicemailAudio = message.voicemailAudio);
    message.transferToNumber !== undefined && (obj.transferToNumber = message.transferToNumber);
    message.boughtAt !== undefined && (obj.boughtAt = Math.round(message.boughtAt));
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerAgentNumberItem>, I>>(base?: I): DialerAgentNumberItem {
    return DialerAgentNumberItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerAgentNumberItem>, I>>(object: I): DialerAgentNumberItem {
    const message = createBaseDialerAgentNumberItem();
    message.enabled = object.enabled ?? undefined;
    message.number = object.number ?? undefined;
    message.handler = object.handler ?? undefined;
    message.delayInS = object.delayInS ?? undefined;
    message.voicemailText = object.voicemailText ?? undefined;
    message.voicemailAudio = object.voicemailAudio ?? undefined;
    message.transferToNumber = object.transferToNumber ?? undefined;
    message.boughtAt = object.boughtAt ?? undefined;
    return message;
  },
};

function createBaseDialerCallFlow(): DialerCallFlow {
  return {
    isInitial: undefined,
    initialId: undefined,
    isPassthrough: undefined,
    passthroughId: undefined,
    isFinal: undefined,
    finalId: undefined,
    list: [],
  };
}

export const DialerCallFlow = {
  encode(message: DialerCallFlow, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.isInitial !== undefined) {
      writer.uint32(8).bool(message.isInitial);
    }
    if (message.initialId !== undefined) {
      writer.uint32(18).string(message.initialId);
    }
    if (message.isPassthrough !== undefined) {
      writer.uint32(24).bool(message.isPassthrough);
    }
    if (message.passthroughId !== undefined) {
      writer.uint32(34).string(message.passthroughId);
    }
    if (message.isFinal !== undefined) {
      writer.uint32(40).bool(message.isFinal);
    }
    if (message.finalId !== undefined) {
      writer.uint32(50).string(message.finalId);
    }
    for (const v of message.list) {
      DialerFlowStep.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerCallFlow {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerCallFlow();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.isInitial = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.initialId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.isPassthrough = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.passthroughId = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.isFinal = reader.bool();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.finalId = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.list.push(DialerFlowStep.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerCallFlow {
    return {
      isInitial: isSet(object.isInitial) ? Boolean(object.isInitial) : undefined,
      initialId: isSet(object.initialId) ? String(object.initialId) : undefined,
      isPassthrough: isSet(object.isPassthrough) ? Boolean(object.isPassthrough) : undefined,
      passthroughId: isSet(object.passthroughId) ? String(object.passthroughId) : undefined,
      isFinal: isSet(object.isFinal) ? Boolean(object.isFinal) : undefined,
      finalId: isSet(object.finalId) ? String(object.finalId) : undefined,
      list: Array.isArray(object?.list) ? object.list.map((e: any) => DialerFlowStep.fromJSON(e)) : [],
    };
  },

  toJSON(message: DialerCallFlow): unknown {
    const obj: any = {};
    message.isInitial !== undefined && (obj.isInitial = message.isInitial);
    message.initialId !== undefined && (obj.initialId = message.initialId);
    message.isPassthrough !== undefined && (obj.isPassthrough = message.isPassthrough);
    message.passthroughId !== undefined && (obj.passthroughId = message.passthroughId);
    message.isFinal !== undefined && (obj.isFinal = message.isFinal);
    message.finalId !== undefined && (obj.finalId = message.finalId);
    if (message.list) {
      obj.list = message.list.map((e) => e ? DialerFlowStep.toJSON(e) : undefined);
    } else {
      obj.list = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerCallFlow>, I>>(base?: I): DialerCallFlow {
    return DialerCallFlow.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerCallFlow>, I>>(object: I): DialerCallFlow {
    const message = createBaseDialerCallFlow();
    message.isInitial = object.isInitial ?? undefined;
    message.initialId = object.initialId ?? undefined;
    message.isPassthrough = object.isPassthrough ?? undefined;
    message.passthroughId = object.passthroughId ?? undefined;
    message.isFinal = object.isFinal ?? undefined;
    message.finalId = object.finalId ?? undefined;
    message.list = object.list?.map((e) => DialerFlowStep.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDialerFlowStep(): DialerFlowStep {
  return { queue: undefined, agency: undefined, type: undefined, crossAgencyUser: undefined };
}

export const DialerFlowStep = {
  encode(message: DialerFlowStep, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.queue !== undefined) {
      UserIdContainer.encode(message.queue, writer.uint32(10).fork()).ldelim();
    }
    if (message.agency !== undefined) {
      UserIdContainer.encode(message.agency, writer.uint32(18).fork()).ldelim();
    }
    if (message.type !== undefined) {
      writer.uint32(24).int32(message.type);
    }
    if (message.crossAgencyUser !== undefined) {
      writer.uint32(32).bool(message.crossAgencyUser);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerFlowStep {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerFlowStep();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.queue = UserIdContainer.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.agency = UserIdContainer.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.crossAgencyUser = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerFlowStep {
    return {
      queue: isSet(object.queue) ? UserIdContainer.fromJSON(object.queue) : undefined,
      agency: isSet(object.agency) ? UserIdContainer.fromJSON(object.agency) : undefined,
      type: isSet(object.type) ? dialerClientState_ClientStateTarget_TargetTypeFromJSON(object.type) : undefined,
      crossAgencyUser: isSet(object.crossAgencyUser) ? Boolean(object.crossAgencyUser) : undefined,
    };
  },

  toJSON(message: DialerFlowStep): unknown {
    const obj: any = {};
    message.queue !== undefined && (obj.queue = message.queue ? UserIdContainer.toJSON(message.queue) : undefined);
    message.agency !== undefined && (obj.agency = message.agency ? UserIdContainer.toJSON(message.agency) : undefined);
    message.type !== undefined && (obj.type = message.type !== undefined
      ? dialerClientState_ClientStateTarget_TargetTypeToJSON(message.type)
      : undefined);
    message.crossAgencyUser !== undefined && (obj.crossAgencyUser = message.crossAgencyUser);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerFlowStep>, I>>(base?: I): DialerFlowStep {
    return DialerFlowStep.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerFlowStep>, I>>(object: I): DialerFlowStep {
    const message = createBaseDialerFlowStep();
    message.queue = (object.queue !== undefined && object.queue !== null)
      ? UserIdContainer.fromPartial(object.queue)
      : undefined;
    message.agency = (object.agency !== undefined && object.agency !== null)
      ? UserIdContainer.fromPartial(object.agency)
      : undefined;
    message.type = object.type ?? undefined;
    message.crossAgencyUser = object.crossAgencyUser ?? undefined;
    return message;
  },
};

function createBaseDialerQueuePrioritizationContainer(): DialerQueuePrioritizationContainer {
  return { list: [] };
}

export const DialerQueuePrioritizationContainer = {
  encode(message: DialerQueuePrioritizationContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.list) {
      DialerQueuePrioritizationItem.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerQueuePrioritizationContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerQueuePrioritizationContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.list.push(DialerQueuePrioritizationItem.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerQueuePrioritizationContainer {
    return {
      list: Array.isArray(object?.list) ? object.list.map((e: any) => DialerQueuePrioritizationItem.fromJSON(e)) : [],
    };
  },

  toJSON(message: DialerQueuePrioritizationContainer): unknown {
    const obj: any = {};
    if (message.list) {
      obj.list = message.list.map((e) => e ? DialerQueuePrioritizationItem.toJSON(e) : undefined);
    } else {
      obj.list = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerQueuePrioritizationContainer>, I>>(
    base?: I,
  ): DialerQueuePrioritizationContainer {
    return DialerQueuePrioritizationContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerQueuePrioritizationContainer>, I>>(
    object: I,
  ): DialerQueuePrioritizationContainer {
    const message = createBaseDialerQueuePrioritizationContainer();
    message.list = object.list?.map((e) => DialerQueuePrioritizationItem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDialerQueuePrioritizationItem(): DialerQueuePrioritizationItem {
  return { queue: undefined, agency: undefined, delayBeforeNextInS: undefined, soldSettings: undefined };
}

export const DialerQueuePrioritizationItem = {
  encode(message: DialerQueuePrioritizationItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.queue !== undefined) {
      ReferenceContainer.encode(message.queue, writer.uint32(10).fork()).ldelim();
    }
    if (message.agency !== undefined) {
      UserIdContainer.encode(message.agency, writer.uint32(18).fork()).ldelim();
    }
    if (message.delayBeforeNextInS !== undefined) {
      writer.uint32(24).int32(message.delayBeforeNextInS);
    }
    if (message.soldSettings !== undefined) {
      DialerBillableSettings.encode(message.soldSettings, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerQueuePrioritizationItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerQueuePrioritizationItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.queue = ReferenceContainer.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.agency = UserIdContainer.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.delayBeforeNextInS = reader.int32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.soldSettings = DialerBillableSettings.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerQueuePrioritizationItem {
    return {
      queue: isSet(object.queue) ? ReferenceContainer.fromJSON(object.queue) : undefined,
      agency: isSet(object.agency) ? UserIdContainer.fromJSON(object.agency) : undefined,
      delayBeforeNextInS: isSet(object.delayBeforeNextInS) ? Number(object.delayBeforeNextInS) : undefined,
      soldSettings: isSet(object.soldSettings) ? DialerBillableSettings.fromJSON(object.soldSettings) : undefined,
    };
  },

  toJSON(message: DialerQueuePrioritizationItem): unknown {
    const obj: any = {};
    message.queue !== undefined && (obj.queue = message.queue ? ReferenceContainer.toJSON(message.queue) : undefined);
    message.agency !== undefined && (obj.agency = message.agency ? UserIdContainer.toJSON(message.agency) : undefined);
    message.delayBeforeNextInS !== undefined && (obj.delayBeforeNextInS = Math.round(message.delayBeforeNextInS));
    message.soldSettings !== undefined &&
      (obj.soldSettings = message.soldSettings ? DialerBillableSettings.toJSON(message.soldSettings) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerQueuePrioritizationItem>, I>>(base?: I): DialerQueuePrioritizationItem {
    return DialerQueuePrioritizationItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerQueuePrioritizationItem>, I>>(
    object: I,
  ): DialerQueuePrioritizationItem {
    const message = createBaseDialerQueuePrioritizationItem();
    message.queue = (object.queue !== undefined && object.queue !== null)
      ? ReferenceContainer.fromPartial(object.queue)
      : undefined;
    message.agency = (object.agency !== undefined && object.agency !== null)
      ? UserIdContainer.fromPartial(object.agency)
      : undefined;
    message.delayBeforeNextInS = object.delayBeforeNextInS ?? undefined;
    message.soldSettings = (object.soldSettings !== undefined && object.soldSettings !== null)
      ? DialerBillableSettings.fromPartial(object.soldSettings)
      : undefined;
    return message;
  },
};

function createBaseDialerInternalBilling(): DialerInternalBilling {
  return {
    baseDuration: undefined,
    byParticipantCall: {},
    countingType: undefined,
    paying: undefined,
    payingUserId: undefined,
  };
}

export const DialerInternalBilling = {
  encode(message: DialerInternalBilling, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.baseDuration !== undefined) {
      writer.uint32(8).int64(message.baseDuration);
    }
    Object.entries(message.byParticipantCall).forEach(([key, value]) => {
      DialerInternalBilling_ByParticipantCallEntry.encode({ key: key as any, value }, writer.uint32(18).fork())
        .ldelim();
    });
    if (message.countingType !== undefined) {
      DialerInternalCountingType.encode(message.countingType, writer.uint32(26).fork()).ldelim();
    }
    if (message.paying !== undefined) {
      writer.uint32(32).bool(message.paying);
    }
    if (message.payingUserId !== undefined) {
      writer.uint32(42).string(message.payingUserId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerInternalBilling {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerInternalBilling();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.baseDuration = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          const entry2 = DialerInternalBilling_ByParticipantCallEntry.decode(reader, reader.uint32());
          if (entry2.value !== undefined) {
            message.byParticipantCall[entry2.key] = entry2.value;
          }
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.countingType = DialerInternalCountingType.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.paying = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.payingUserId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerInternalBilling {
    return {
      baseDuration: isSet(object.baseDuration) ? Number(object.baseDuration) : undefined,
      byParticipantCall: isObject(object.byParticipantCall)
        ? Object.entries(object.byParticipantCall).reduce<{ [key: string]: number }>((acc, [key, value]) => {
          acc[key] = Number(value);
          return acc;
        }, {})
        : {},
      countingType: isSet(object.countingType) ? DialerInternalCountingType.fromJSON(object.countingType) : undefined,
      paying: isSet(object.paying) ? Boolean(object.paying) : undefined,
      payingUserId: isSet(object.payingUserId) ? String(object.payingUserId) : undefined,
    };
  },

  toJSON(message: DialerInternalBilling): unknown {
    const obj: any = {};
    message.baseDuration !== undefined && (obj.baseDuration = Math.round(message.baseDuration));
    obj.byParticipantCall = {};
    if (message.byParticipantCall) {
      Object.entries(message.byParticipantCall).forEach(([k, v]) => {
        obj.byParticipantCall[k] = Math.round(v);
      });
    }
    message.countingType !== undefined &&
      (obj.countingType = message.countingType ? DialerInternalCountingType.toJSON(message.countingType) : undefined);
    message.paying !== undefined && (obj.paying = message.paying);
    message.payingUserId !== undefined && (obj.payingUserId = message.payingUserId);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerInternalBilling>, I>>(base?: I): DialerInternalBilling {
    return DialerInternalBilling.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerInternalBilling>, I>>(object: I): DialerInternalBilling {
    const message = createBaseDialerInternalBilling();
    message.baseDuration = object.baseDuration ?? undefined;
    message.byParticipantCall = Object.entries(object.byParticipantCall ?? {}).reduce<{ [key: string]: number }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = Number(value);
        }
        return acc;
      },
      {},
    );
    message.countingType = (object.countingType !== undefined && object.countingType !== null)
      ? DialerInternalCountingType.fromPartial(object.countingType)
      : undefined;
    message.paying = object.paying ?? undefined;
    message.payingUserId = object.payingUserId ?? undefined;
    return message;
  },
};

function createBaseDialerInternalBilling_ByParticipantCallEntry(): DialerInternalBilling_ByParticipantCallEntry {
  return { key: "", value: 0 };
}

export const DialerInternalBilling_ByParticipantCallEntry = {
  encode(message: DialerInternalBilling_ByParticipantCallEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== 0) {
      writer.uint32(16).int64(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerInternalBilling_ByParticipantCallEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerInternalBilling_ByParticipantCallEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.value = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerInternalBilling_ByParticipantCallEntry {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? Number(object.value) : 0 };
  },

  toJSON(message: DialerInternalBilling_ByParticipantCallEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = Math.round(message.value));
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerInternalBilling_ByParticipantCallEntry>, I>>(
    base?: I,
  ): DialerInternalBilling_ByParticipantCallEntry {
    return DialerInternalBilling_ByParticipantCallEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerInternalBilling_ByParticipantCallEntry>, I>>(
    object: I,
  ): DialerInternalBilling_ByParticipantCallEntry {
    const message = createBaseDialerInternalBilling_ByParticipantCallEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseDialerInternalCountingType(): DialerInternalCountingType {
  return { byBillableForm: undefined };
}

export const DialerInternalCountingType = {
  encode(message: DialerInternalCountingType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.byBillableForm !== undefined) {
      BillableFormList.encode(message.byBillableForm, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerInternalCountingType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerInternalCountingType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.byBillableForm = BillableFormList.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerInternalCountingType {
    return {
      byBillableForm: isSet(object.byBillableForm) ? BillableFormList.fromJSON(object.byBillableForm) : undefined,
    };
  },

  toJSON(message: DialerInternalCountingType): unknown {
    const obj: any = {};
    message.byBillableForm !== undefined &&
      (obj.byBillableForm = message.byBillableForm ? BillableFormList.toJSON(message.byBillableForm) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerInternalCountingType>, I>>(base?: I): DialerInternalCountingType {
    return DialerInternalCountingType.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerInternalCountingType>, I>>(object: I): DialerInternalCountingType {
    const message = createBaseDialerInternalCountingType();
    message.byBillableForm = (object.byBillableForm !== undefined && object.byBillableForm !== null)
      ? BillableFormList.fromPartial(object.byBillableForm)
      : undefined;
    return message;
  },
};

function createBaseBillableFormList(): BillableFormList {
  return { b6060: undefined, b606: undefined, b66: undefined, b3030: undefined, b660: undefined };
}

export const BillableFormList = {
  encode(message: BillableFormList, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.b6060 !== undefined) {
      writer.uint32(8).int64(message.b6060);
    }
    if (message.b606 !== undefined) {
      writer.uint32(16).int64(message.b606);
    }
    if (message.b66 !== undefined) {
      writer.uint32(24).int64(message.b66);
    }
    if (message.b3030 !== undefined) {
      writer.uint32(32).int64(message.b3030);
    }
    if (message.b660 !== undefined) {
      writer.uint32(40).int64(message.b660);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BillableFormList {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBillableFormList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.b6060 = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.b606 = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.b66 = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.b3030 = longToNumber(reader.int64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.b660 = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BillableFormList {
    return {
      b6060: isSet(object.b6060) ? Number(object.b6060) : undefined,
      b606: isSet(object.b606) ? Number(object.b606) : undefined,
      b66: isSet(object.b66) ? Number(object.b66) : undefined,
      b3030: isSet(object.b3030) ? Number(object.b3030) : undefined,
      b660: isSet(object.b660) ? Number(object.b660) : undefined,
    };
  },

  toJSON(message: BillableFormList): unknown {
    const obj: any = {};
    message.b6060 !== undefined && (obj.b6060 = Math.round(message.b6060));
    message.b606 !== undefined && (obj.b606 = Math.round(message.b606));
    message.b66 !== undefined && (obj.b66 = Math.round(message.b66));
    message.b3030 !== undefined && (obj.b3030 = Math.round(message.b3030));
    message.b660 !== undefined && (obj.b660 = Math.round(message.b660));
    return obj;
  },

  create<I extends Exact<DeepPartial<BillableFormList>, I>>(base?: I): BillableFormList {
    return BillableFormList.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<BillableFormList>, I>>(object: I): BillableFormList {
    const message = createBaseBillableFormList();
    message.b6060 = object.b6060 ?? undefined;
    message.b606 = object.b606 ?? undefined;
    message.b66 = object.b66 ?? undefined;
    message.b3030 = object.b3030 ?? undefined;
    message.b660 = object.b660 ?? undefined;
    return message;
  },
};

function createBaseDialerQueueEnrollmentCodeSettings(): DialerQueueEnrollmentCodeSettings {
  return { enabled: undefined, allowedSources: [], defaultSource: undefined, enforceSource: undefined };
}

export const DialerQueueEnrollmentCodeSettings = {
  encode(message: DialerQueueEnrollmentCodeSettings, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.enabled !== undefined) {
      writer.uint32(8).bool(message.enabled);
    }
    writer.uint32(18).fork();
    for (const v of message.allowedSources) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.defaultSource !== undefined) {
      writer.uint32(24).int32(message.defaultSource);
    }
    if (message.enforceSource !== undefined) {
      writer.uint32(32).bool(message.enforceSource);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DialerQueueEnrollmentCodeSettings {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDialerQueueEnrollmentCodeSettings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 2:
          if (tag === 16) {
            message.allowedSources.push(reader.int32() as any);

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.allowedSources.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.defaultSource = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.enforceSource = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DialerQueueEnrollmentCodeSettings {
    return {
      enabled: isSet(object.enabled) ? Boolean(object.enabled) : undefined,
      allowedSources: Array.isArray(object?.allowedSources)
        ? object.allowedSources.map((e: any) => enrollmentSourceFromJSON(e))
        : [],
      defaultSource: isSet(object.defaultSource) ? enrollmentSourceFromJSON(object.defaultSource) : undefined,
      enforceSource: isSet(object.enforceSource) ? Boolean(object.enforceSource) : undefined,
    };
  },

  toJSON(message: DialerQueueEnrollmentCodeSettings): unknown {
    const obj: any = {};
    message.enabled !== undefined && (obj.enabled = message.enabled);
    if (message.allowedSources) {
      obj.allowedSources = message.allowedSources.map((e) => enrollmentSourceToJSON(e));
    } else {
      obj.allowedSources = [];
    }
    message.defaultSource !== undefined && (obj.defaultSource = message.defaultSource !== undefined
      ? enrollmentSourceToJSON(message.defaultSource)
      : undefined);
    message.enforceSource !== undefined && (obj.enforceSource = message.enforceSource);
    return obj;
  },

  create<I extends Exact<DeepPartial<DialerQueueEnrollmentCodeSettings>, I>>(
    base?: I,
  ): DialerQueueEnrollmentCodeSettings {
    return DialerQueueEnrollmentCodeSettings.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DialerQueueEnrollmentCodeSettings>, I>>(
    object: I,
  ): DialerQueueEnrollmentCodeSettings {
    const message = createBaseDialerQueueEnrollmentCodeSettings();
    message.enabled = object.enabled ?? undefined;
    message.allowedSources = object.allowedSources?.map((e) => e) || [];
    message.defaultSource = object.defaultSource ?? undefined;
    message.enforceSource = object.enforceSource ?? undefined;
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

// If you get a compile-error about 'Constructor<Long> and ... have no overlap',
// add '--ts_proto_opt=esModuleInterop=true' as a flag when calling 'protoc'.
if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
