import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Publisher } from 'src/app/shared/models';

export const PublisherActions = createActionGroup({
  source: 'Publisher',
  events: {
    'Load Publishers': emptyProps(),
    'Load Publishers Success': props<{ publishers: Publisher[] }>(),
    'Load Publishers Failure': props<{ error: Error }>(),
    'Load Publisher': props<{ publisherId: string }>(),
    'Load Publisher Success': props<{ publisher: Publisher }>(),
    'Load Publisher Failure': props<{ error: Error }>(),
    'Add Publisher': props<{ publisher: Publisher }>(),
    'Add Publisher Success': props<{ publisher: Publisher }>(),
    'Add Publisher Failure': props<{ error: Error }>(),
    'Remove Publisher': props<{ publisherId: string }>(),
    'Remove Publisher Success': props<{ publisherId: string }>(),
    'Remove Publisher Failure': props<{ error: Error }>(),
    'Update Publisher': props<{
      publisherId: string;
      publisher: Partial<Publisher>;
    }>(),
    'Update Publisher Success': props<{
      publisherId: string;
      publisher: Partial<Publisher>;
    }>(),
    'Update Publisher Failure': props<{ error: Error }>(),
  },
});
