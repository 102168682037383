import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromEmployee from './employee.reducer';
import { BaseUser } from 'src/app/shared/models';

export const selectEmployeeState =
  createFeatureSelector<fromEmployee.EmployeeState>(
    fromEmployee.employeeFeatureKey
  );

export const selectEmployees = createSelector(
  selectEmployeeState,
  state => state.employees
);

export const selectEmployeeById = (id: string) =>
  createSelector(selectEmployeeState, state => ({
    ...state,
    employee: state?.employees?.find(employee => employee.id === id) ?? null,
  }));

export const selectEmployeeByIdBaseUser = (id: string) =>
  createSelector<object, fromEmployee.EmployeeState, BaseUser | undefined>(
    selectEmployeeState,
    state => state.employees.find(employee => employee.id === id)
  );

export const selectSelectedEmployee = createSelector(
  selectEmployeeState,
  state => state.employee
);
