/* eslint-disable */
// @ts-ignore
import * as Long from "long";
import * as _m0 from "protobufjs/minimal";
import { Agent } from "./agent";
import { Carrier } from "./carrier";
import { Consumer } from "./consumer";
import { AggregatedExecutiveRule } from "./executive";
import { Struct } from "./google/protobuf/struct";
import { Timestamp } from "./google/protobuf/timestamp";
import {
  Address,
  CommissionCycle,
  DateContainer,
  FieldUpdateContainer,
  IdentifierContainer,
  PolicyUpdateContainer,
  TagsContainer,
  TierFilterListElement,
} from "./misc";

export const protobufPackage = "";

export enum SignaturePeriod {
  SP_UNKNOWN = 0,
  AEP = 1,
  OEP = 2,
  ROY = 3,
  UNRECOGNIZED = -1,
}

export function signaturePeriodFromJSON(object: any): SignaturePeriod {
  switch (object) {
    case 0:
    case "SP_UNKNOWN":
      return SignaturePeriod.SP_UNKNOWN;
    case 1:
    case "AEP":
      return SignaturePeriod.AEP;
    case 2:
    case "OEP":
      return SignaturePeriod.OEP;
    case 3:
    case "ROY":
      return SignaturePeriod.ROY;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SignaturePeriod.UNRECOGNIZED;
  }
}

export function signaturePeriodToJSON(object: SignaturePeriod): string {
  switch (object) {
    case SignaturePeriod.SP_UNKNOWN:
      return "SP_UNKNOWN";
    case SignaturePeriod.AEP:
      return "AEP";
    case SignaturePeriod.OEP:
      return "OEP";
    case SignaturePeriod.ROY:
      return "ROY";
    case SignaturePeriod.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum PaymentType {
  NO_PAYMENT_TYPE = 0,
  UNRECOGNIZED = -1,
}

export function paymentTypeFromJSON(object: any): PaymentType {
  switch (object) {
    case 0:
    case "NO_PAYMENT_TYPE":
      return PaymentType.NO_PAYMENT_TYPE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PaymentType.UNRECOGNIZED;
  }
}

export function paymentTypeToJSON(object: PaymentType): string {
  switch (object) {
    case PaymentType.NO_PAYMENT_TYPE:
      return "NO_PAYMENT_TYPE";
    case PaymentType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum PolicyStatusCategory {
  PSCAT_UNKNOWN = 0,
  PSCAT_ACTIVE = 1,
  PSCAT_INACTIVE = 2,
  PSCAT_DRAFT = 3,
  UNRECOGNIZED = -1,
}

export function policyStatusCategoryFromJSON(object: any): PolicyStatusCategory {
  switch (object) {
    case 0:
    case "PSCAT_UNKNOWN":
      return PolicyStatusCategory.PSCAT_UNKNOWN;
    case 1:
    case "PSCAT_ACTIVE":
      return PolicyStatusCategory.PSCAT_ACTIVE;
    case 2:
    case "PSCAT_INACTIVE":
      return PolicyStatusCategory.PSCAT_INACTIVE;
    case 3:
    case "PSCAT_DRAFT":
      return PolicyStatusCategory.PSCAT_DRAFT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PolicyStatusCategory.UNRECOGNIZED;
  }
}

export function policyStatusCategoryToJSON(object: PolicyStatusCategory): string {
  switch (object) {
    case PolicyStatusCategory.PSCAT_UNKNOWN:
      return "PSCAT_UNKNOWN";
    case PolicyStatusCategory.PSCAT_ACTIVE:
      return "PSCAT_ACTIVE";
    case PolicyStatusCategory.PSCAT_INACTIVE:
      return "PSCAT_INACTIVE";
    case PolicyStatusCategory.PSCAT_DRAFT:
      return "PSCAT_DRAFT";
    case PolicyStatusCategory.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum PolicyStatus {
  POL_UNKNOWN = 0,
  POL_PENDING = 1,
  POL_SUBMITTED = 2,
  POL_PAID = 21,
  POL_APPROVED = 22,
  POL_INFORCE = 23,
  POL_LAPSED = 41,
  POL_TERMINATED = 42,
  POL_DECEASED = 43,
  POL_LOST = 61,
  POL_REVERSED = 62,
  POL_DECLINED = 63,
  UNRECOGNIZED = -1,
}

export function policyStatusFromJSON(object: any): PolicyStatus {
  switch (object) {
    case 0:
    case "POL_UNKNOWN":
      return PolicyStatus.POL_UNKNOWN;
    case 1:
    case "POL_PENDING":
      return PolicyStatus.POL_PENDING;
    case 2:
    case "POL_SUBMITTED":
      return PolicyStatus.POL_SUBMITTED;
    case 21:
    case "POL_PAID":
      return PolicyStatus.POL_PAID;
    case 22:
    case "POL_APPROVED":
      return PolicyStatus.POL_APPROVED;
    case 23:
    case "POL_INFORCE":
      return PolicyStatus.POL_INFORCE;
    case 41:
    case "POL_LAPSED":
      return PolicyStatus.POL_LAPSED;
    case 42:
    case "POL_TERMINATED":
      return PolicyStatus.POL_TERMINATED;
    case 43:
    case "POL_DECEASED":
      return PolicyStatus.POL_DECEASED;
    case 61:
    case "POL_LOST":
      return PolicyStatus.POL_LOST;
    case 62:
    case "POL_REVERSED":
      return PolicyStatus.POL_REVERSED;
    case 63:
    case "POL_DECLINED":
      return PolicyStatus.POL_DECLINED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PolicyStatus.UNRECOGNIZED;
  }
}

export function policyStatusToJSON(object: PolicyStatus): string {
  switch (object) {
    case PolicyStatus.POL_UNKNOWN:
      return "POL_UNKNOWN";
    case PolicyStatus.POL_PENDING:
      return "POL_PENDING";
    case PolicyStatus.POL_SUBMITTED:
      return "POL_SUBMITTED";
    case PolicyStatus.POL_PAID:
      return "POL_PAID";
    case PolicyStatus.POL_APPROVED:
      return "POL_APPROVED";
    case PolicyStatus.POL_INFORCE:
      return "POL_INFORCE";
    case PolicyStatus.POL_LAPSED:
      return "POL_LAPSED";
    case PolicyStatus.POL_TERMINATED:
      return "POL_TERMINATED";
    case PolicyStatus.POL_DECEASED:
      return "POL_DECEASED";
    case PolicyStatus.POL_LOST:
      return "POL_LOST";
    case PolicyStatus.POL_REVERSED:
      return "POL_REVERSED";
    case PolicyStatus.POL_DECLINED:
      return "POL_DECLINED";
    case PolicyStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum PlanCategory {
  PCAT_UNKNOWN = 0,
  PCAT_ACCIDENT = 1,
  PCAT_ACCIDENTAL_DEATH = 2,
  PCAT_ACA = 3,
  PCAT_CANCER = 4,
  PCAT_CANCER_HEART_STROKE = 5,
  PCAT_DENTAL = 6,
  PCAT_FIXED_INDEXED = 7,
  PCAT_FIXED = 8,
  PCAT_GUARANTEED_UNIVERSAL = 9,
  PCAT_HOSPITAL_INDEMNITY = 10,
  PCAT_INDEXED_UNIVERSAL = 11,
  PCAT_TERM = 12,
  PCAT_UNIVERSAL = 13,
  PCAT_VISION = 14,
  PCAT_WHOLE = 15,
  UNRECOGNIZED = -1,
}

export function planCategoryFromJSON(object: any): PlanCategory {
  switch (object) {
    case 0:
    case "PCAT_UNKNOWN":
      return PlanCategory.PCAT_UNKNOWN;
    case 1:
    case "PCAT_ACCIDENT":
      return PlanCategory.PCAT_ACCIDENT;
    case 2:
    case "PCAT_ACCIDENTAL_DEATH":
      return PlanCategory.PCAT_ACCIDENTAL_DEATH;
    case 3:
    case "PCAT_ACA":
      return PlanCategory.PCAT_ACA;
    case 4:
    case "PCAT_CANCER":
      return PlanCategory.PCAT_CANCER;
    case 5:
    case "PCAT_CANCER_HEART_STROKE":
      return PlanCategory.PCAT_CANCER_HEART_STROKE;
    case 6:
    case "PCAT_DENTAL":
      return PlanCategory.PCAT_DENTAL;
    case 7:
    case "PCAT_FIXED_INDEXED":
      return PlanCategory.PCAT_FIXED_INDEXED;
    case 8:
    case "PCAT_FIXED":
      return PlanCategory.PCAT_FIXED;
    case 9:
    case "PCAT_GUARANTEED_UNIVERSAL":
      return PlanCategory.PCAT_GUARANTEED_UNIVERSAL;
    case 10:
    case "PCAT_HOSPITAL_INDEMNITY":
      return PlanCategory.PCAT_HOSPITAL_INDEMNITY;
    case 11:
    case "PCAT_INDEXED_UNIVERSAL":
      return PlanCategory.PCAT_INDEXED_UNIVERSAL;
    case 12:
    case "PCAT_TERM":
      return PlanCategory.PCAT_TERM;
    case 13:
    case "PCAT_UNIVERSAL":
      return PlanCategory.PCAT_UNIVERSAL;
    case 14:
    case "PCAT_VISION":
      return PlanCategory.PCAT_VISION;
    case 15:
    case "PCAT_WHOLE":
      return PlanCategory.PCAT_WHOLE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PlanCategory.UNRECOGNIZED;
  }
}

export function planCategoryToJSON(object: PlanCategory): string {
  switch (object) {
    case PlanCategory.PCAT_UNKNOWN:
      return "PCAT_UNKNOWN";
    case PlanCategory.PCAT_ACCIDENT:
      return "PCAT_ACCIDENT";
    case PlanCategory.PCAT_ACCIDENTAL_DEATH:
      return "PCAT_ACCIDENTAL_DEATH";
    case PlanCategory.PCAT_ACA:
      return "PCAT_ACA";
    case PlanCategory.PCAT_CANCER:
      return "PCAT_CANCER";
    case PlanCategory.PCAT_CANCER_HEART_STROKE:
      return "PCAT_CANCER_HEART_STROKE";
    case PlanCategory.PCAT_DENTAL:
      return "PCAT_DENTAL";
    case PlanCategory.PCAT_FIXED_INDEXED:
      return "PCAT_FIXED_INDEXED";
    case PlanCategory.PCAT_FIXED:
      return "PCAT_FIXED";
    case PlanCategory.PCAT_GUARANTEED_UNIVERSAL:
      return "PCAT_GUARANTEED_UNIVERSAL";
    case PlanCategory.PCAT_HOSPITAL_INDEMNITY:
      return "PCAT_HOSPITAL_INDEMNITY";
    case PlanCategory.PCAT_INDEXED_UNIVERSAL:
      return "PCAT_INDEXED_UNIVERSAL";
    case PlanCategory.PCAT_TERM:
      return "PCAT_TERM";
    case PlanCategory.PCAT_UNIVERSAL:
      return "PCAT_UNIVERSAL";
    case PlanCategory.PCAT_VISION:
      return "PCAT_VISION";
    case PlanCategory.PCAT_WHOLE:
      return "PCAT_WHOLE";
    case PlanCategory.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Enrollment {
  id: string;
  agency?: Enrollment_Agency | undefined;
  agent?: Agent | undefined;
  consumer?: Consumer | undefined;
  carrier?: Carrier | undefined;
  marketingVendor?: Enrollment_MarketingVendor | undefined;
  plan?: MedicarePlan | undefined;
  planTable?: Enrollment_PlanTable | undefined;
  medicareInfo?: Enrollment_MedicareInfo | undefined;
  medicaidInfo?: Enrollment_MedicaidInfo | undefined;
  reviewSubmit?: Enrollment_ReviewSubmit | undefined;
  completed?: Enrollment_CompletedSteps | undefined;
  premium?: Enrollment_Premium | undefined;
  benefitsConfirmed?: boolean | undefined;
  signatureOption?: string | undefined;
  tpmoId?: string | undefined;
  leadId?: string | undefined;
  details?: Enrollment_Details | undefined;
  executive?: Enrollment_EnrollmentExecutive | undefined;
  managingAgent?:
    | Agent
    | undefined;
  /** compat */
  planInfo?: Enrollment_PlanInfo | undefined;
  fieldUpdates?: FieldUpdateContainer | undefined;
  identifiers?: IdentifierContainer | undefined;
  policyUpdates?: PolicyUpdateContainer | undefined;
  tags?: TagsContainer | undefined;
  cycle?:
    | CommissionCycle
    | undefined;
  /** PLATFORMS */
  allegation?: Allegation | undefined;
  commissions?: Commissions | undefined;
  baseCategory?: Enrollment_BaseCategory | undefined;
  metadata?: Enrollment_Metadata | undefined;
}

export enum Enrollment_BaseCategory {
  BC_UNKNOWN = 0,
  BC_MEDICARE = 1,
  BC_LIFE = 2,
  BC_HEALTH = 3,
  BC_ANNUITY = 4,
  UNRECOGNIZED = -1,
}

export function enrollment_BaseCategoryFromJSON(object: any): Enrollment_BaseCategory {
  switch (object) {
    case 0:
    case "BC_UNKNOWN":
      return Enrollment_BaseCategory.BC_UNKNOWN;
    case 1:
    case "BC_MEDICARE":
      return Enrollment_BaseCategory.BC_MEDICARE;
    case 2:
    case "BC_LIFE":
      return Enrollment_BaseCategory.BC_LIFE;
    case 3:
    case "BC_HEALTH":
      return Enrollment_BaseCategory.BC_HEALTH;
    case 4:
    case "BC_ANNUITY":
      return Enrollment_BaseCategory.BC_ANNUITY;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Enrollment_BaseCategory.UNRECOGNIZED;
  }
}

export function enrollment_BaseCategoryToJSON(object: Enrollment_BaseCategory): string {
  switch (object) {
    case Enrollment_BaseCategory.BC_UNKNOWN:
      return "BC_UNKNOWN";
    case Enrollment_BaseCategory.BC_MEDICARE:
      return "BC_MEDICARE";
    case Enrollment_BaseCategory.BC_LIFE:
      return "BC_LIFE";
    case Enrollment_BaseCategory.BC_HEALTH:
      return "BC_HEALTH";
    case Enrollment_BaseCategory.BC_ANNUITY:
      return "BC_ANNUITY";
    case Enrollment_BaseCategory.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum Enrollment_EnrollmentSignatureType {
  PHONE = 0,
  MAIL = 1,
  DIGITAL = 2,
  DIGITAL_WRITTEN = 3,
  UNRECOGNIZED = -1,
}

export function enrollment_EnrollmentSignatureTypeFromJSON(object: any): Enrollment_EnrollmentSignatureType {
  switch (object) {
    case 0:
    case "PHONE":
      return Enrollment_EnrollmentSignatureType.PHONE;
    case 1:
    case "MAIL":
      return Enrollment_EnrollmentSignatureType.MAIL;
    case 2:
    case "DIGITAL":
      return Enrollment_EnrollmentSignatureType.DIGITAL;
    case 3:
    case "DIGITAL_WRITTEN":
      return Enrollment_EnrollmentSignatureType.DIGITAL_WRITTEN;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Enrollment_EnrollmentSignatureType.UNRECOGNIZED;
  }
}

export function enrollment_EnrollmentSignatureTypeToJSON(object: Enrollment_EnrollmentSignatureType): string {
  switch (object) {
    case Enrollment_EnrollmentSignatureType.PHONE:
      return "PHONE";
    case Enrollment_EnrollmentSignatureType.MAIL:
      return "MAIL";
    case Enrollment_EnrollmentSignatureType.DIGITAL:
      return "DIGITAL";
    case Enrollment_EnrollmentSignatureType.DIGITAL_WRITTEN:
      return "DIGITAL_WRITTEN";
    case Enrollment_EnrollmentSignatureType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Enrollment_EnrollmentExecutive {
  aggregatedExecutiveRule?: AggregatedExecutiveRule | undefined;
  tierMap?: { [key: string]: any } | undefined;
  tierFilterList: TierFilterListElement[];
}

export interface Enrollment_PlanInfo {
  id?: string | undefined;
  planDetailsConfirmed?: boolean | undefined;
  isNotMedicareSupplementPlan?: boolean | undefined;
  doctorSpecifyNow?: boolean | undefined;
  doctors: Doctor[];
  isCurrentlyPatient?: boolean | undefined;
  paymentOption?: string | undefined;
}

export interface Enrollment_Details {
  partAEffectiveDate?: DateContainer | undefined;
  partBEffectiveDate?: DateContainer | undefined;
  effectiveDate?: DateContainer | undefined;
  signatureDate?: DateContainer | undefined;
  signaturePeriod?: SignaturePeriod | undefined;
  confirmationNumber?: string | undefined;
  confirmationStatus?: Enrollment_Details_ConfirmationStatus | undefined;
  enrollmentCode?: string | undefined;
  policyStatusCategory?: PolicyStatusCategory | undefined;
  policyStatus?: PolicyStatus | undefined;
  paymentType?: PaymentType | undefined;
  policyStatusContainer?: PolicyStatusContainer | undefined;
}

export enum Enrollment_Details_ConfirmationStatus {
  CS_UNKNOWN = 0,
  CS_CONFIRMED = 1,
  CS_PENDING = 2,
  CS_REJECTED = 3,
  CS_DRAFT = 4,
  UNRECOGNIZED = -1,
}

export function enrollment_Details_ConfirmationStatusFromJSON(object: any): Enrollment_Details_ConfirmationStatus {
  switch (object) {
    case 0:
    case "CS_UNKNOWN":
      return Enrollment_Details_ConfirmationStatus.CS_UNKNOWN;
    case 1:
    case "CS_CONFIRMED":
      return Enrollment_Details_ConfirmationStatus.CS_CONFIRMED;
    case 2:
    case "CS_PENDING":
      return Enrollment_Details_ConfirmationStatus.CS_PENDING;
    case 3:
    case "CS_REJECTED":
      return Enrollment_Details_ConfirmationStatus.CS_REJECTED;
    case 4:
    case "CS_DRAFT":
      return Enrollment_Details_ConfirmationStatus.CS_DRAFT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Enrollment_Details_ConfirmationStatus.UNRECOGNIZED;
  }
}

export function enrollment_Details_ConfirmationStatusToJSON(object: Enrollment_Details_ConfirmationStatus): string {
  switch (object) {
    case Enrollment_Details_ConfirmationStatus.CS_UNKNOWN:
      return "CS_UNKNOWN";
    case Enrollment_Details_ConfirmationStatus.CS_CONFIRMED:
      return "CS_CONFIRMED";
    case Enrollment_Details_ConfirmationStatus.CS_PENDING:
      return "CS_PENDING";
    case Enrollment_Details_ConfirmationStatus.CS_REJECTED:
      return "CS_REJECTED";
    case Enrollment_Details_ConfirmationStatus.CS_DRAFT:
      return "CS_DRAFT";
    case Enrollment_Details_ConfirmationStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Enrollment_Premium {
  /** nicht sicher was es hier gibt */
  monthly?:
    | number
    | undefined;
  /**
   * optional double default = 1;
   * optional double deducted = 2;
   * optional double withhold = 3;
   * optional double annualPremium = 4;
   * optional double faceAmount = 5;
   * optional double modalPremium = 6;
   */
  annual?: number | undefined;
}

export interface Enrollment_Agency {
  id?: string | undefined;
}

export interface Enrollment_MarketingVendor {
  id?: string | undefined;
}

export interface Enrollment_PlanTable {
  id: string;
  planBenefit: { [key: string]: string };
  summary: Enrollment_PlanTable_SummaryElement[];
  inpatientCare: Enrollment_PlanTable_TableElement[];
  outpatientCare: Enrollment_PlanTable_TableElement[];
  coveragePhase: Enrollment_PlanTable_CoverageElement[];
  additional: Enrollment_PlanTable_TableElement[];
}

export interface Enrollment_PlanTable_PlanBenefitEntry {
  key: string;
  value: string;
}

export interface Enrollment_PlanTable_SummaryElement {
  name?: string | undefined;
  iconClass?: string | undefined;
  value?: string | undefined;
}

export interface Enrollment_PlanTable_TableElement {
  name?: string | undefined;
  iconClass?: string | undefined;
  services: Enrollment_PlanTable_TableElement_TableService[];
  groups: Enrollment_PlanTable_TableElement_TableGroup[];
  copayment?: string | undefined;
  authorizationNeeded?: string | undefined;
  value?: string | undefined;
  limit?: string | undefined;
}

export interface Enrollment_PlanTable_TableElement_TableGroup {
  name?: string | undefined;
  iconClass?: string | undefined;
  services: Enrollment_PlanTable_TableElement_TableService[];
  value?: string | undefined;
  authorizationNeeded?: string | undefined;
  limit?: string | undefined;
}

export interface Enrollment_PlanTable_TableElement_TableService {
  name?: string | undefined;
  value?: string | undefined;
  authorizationNeeded?: string | undefined;
  limit?: string | undefined;
  network?: string | undefined;
}

export interface Enrollment_PlanTable_CoverageElement {
  name?: string | undefined;
  iconClass?: string | undefined;
  order?:
    | string
    | undefined;
  /**
   * repeated TableService services = 3;
   * repeated TableGroup groups = 4;
   * optional string copayment = 5;
   * optional string authorizationNeeded = 6;
   * optional string value = 7
   * ;
   * optional string limit = 8;
   */
  tiers: Enrollment_PlanTable_CoverageElement_CoverageTier[];
}

export interface Enrollment_PlanTable_CoverageElement_CoverageTier {
  name?: string | undefined;
  order?: string | undefined;
  costs: Enrollment_PlanTable_CoverageElement_CoverageTierCosts[];
}

export interface Enrollment_PlanTable_CoverageElement_CoverageTierCosts {
  name?: string | undefined;
  iconClass?: string | undefined;
  value1M?: string | undefined;
  value2M?: string | undefined;
  value3M?: string | undefined;
}

export interface Enrollment_MedicareInfo {
  number?: string | undefined;
  chf?: Enrollment_MedicareInfo_CHF | undefined;
  copd?: Enrollment_MedicareInfo_COPD | undefined;
  cvd?: Enrollment_MedicareInfo_CVD | undefined;
  diabetes?: Enrollment_MedicareInfo_Diabetes | undefined;
  pcp?: Enrollment_MedicareInfo_PCP | undefined;
  otherDrugCoverage?: Enrollment_MedicareInfo_OtherDrugCoverage | undefined;
  otherGroupCoverage?: Enrollment_MedicareInfo_OtherGroupCoverage | undefined;
  otherMedicareCoverage?: Enrollment_MedicareInfo_OtherMedicareCoverage | undefined;
}

export interface Enrollment_MedicareInfo_CHF {
  has?: boolean | undefined;
  hasBodyFluid?: boolean | undefined;
  hasLungFluid?: boolean | undefined;
}

export interface Enrollment_MedicareInfo_COPD {
  has?: boolean | undefined;
  hasAsthma?: boolean | undefined;
  hasDifficultyBreathing?: boolean | undefined;
  hasEmphysema?: boolean | undefined;
  needsOxygenSupport?: boolean | undefined;
}

export interface Enrollment_MedicareInfo_CVD {
  has?: boolean | undefined;
  hadHeartAttack?: boolean | undefined;
  hasChestLegPain?: boolean | undefined;
  needsDrugsHeart?: boolean | undefined;
}

export interface Enrollment_MedicareInfo_Diabetes {
  has?: boolean | undefined;
  checksBloodSugar?: boolean | undefined;
  hasHighBloodSugar?: boolean | undefined;
  drugsBloodSugar?: boolean | undefined;
}

export interface Enrollment_MedicareInfo_PCP {
  wishDesignatePCP?: boolean | undefined;
  isEstablishedPatient?: boolean | undefined;
  id?: string | undefined;
  name?: string | undefined;
}

export interface Enrollment_MedicareInfo_OtherDrugCoverage {
  has?: boolean | undefined;
  memberId?: string | undefined;
  memberName?: string | undefined;
  policyId?: string | undefined;
}

export interface Enrollment_MedicareInfo_OtherGroupCoverage {
  /**
   * optional string policyId = 3;
   * optional string groupPolicyId = 5;
   * optional Address address = 6;
   * optional Carrier carrier = 7;
   * optional bool coverageIncludesPrescriptionDrugCoverage = 8;
   */
  has?: boolean | undefined;
}

export interface Enrollment_MedicareInfo_OtherMedicareCoverage {
  has?: boolean | undefined;
  startDate?: DateContainer | undefined;
  endDate?: DateContainer | undefined;
  policyId?: string | undefined;
}

export interface Enrollment_MedicaidInfo {
  has?: boolean | undefined;
  number?: string | undefined;
}

export interface Enrollment_ReviewSubmit {
  id: string;
  signatureType: Enrollment_EnrollmentSignatureType;
}

export interface Enrollment_CompletedSteps {
  prerequisites?: boolean | undefined;
  applicantInfo?: boolean | undefined;
  medicareInfo?: boolean | undefined;
  planInfo?: boolean | undefined;
  reviewSubmit?: boolean | undefined;
}

export interface Enrollment_Metadata {
  webhookReceived?: DateContainer | undefined;
  webhookSource?: string | undefined;
}

export interface Doctor {
  id: string;
  npi?: string | undefined;
  pacId?: string | undefined;
  clinicianEnrollmentId?: string | undefined;
  lastName?: string | undefined;
  firstName?: string | undefined;
  middleName?: string | undefined;
  suffix?: string | undefined;
  gender?: string | undefined;
  credential?: string | undefined;
  medSchool?: string | undefined;
  graduationYear?: string | undefined;
  organisations: Doctor_Organisation[];
  zip: string[];
  loading?: boolean | undefined;
}

export interface Doctor_Organisation {
  primarySpecialty?: string | undefined;
  secondarySpecialties: string[];
  secondarySpecialtiesAll?: string | undefined;
  telehealth?: boolean | undefined;
  name?: string | undefined;
  pacId?: string | undefined;
  numOrgMem?: string | undefined;
  markerAddressLine2Suppression?: string | undefined;
  zip9?: string | undefined;
  phone?: string | undefined;
  clinicianAcceptsMedicareAssignment?: string | undefined;
  groupAcceptsMedicareAssignment?: string | undefined;
  addressId?: string | undefined;
  address?: Address | undefined;
}

export interface MedicarePlan {
  id?: string | undefined;
  name?: string | undefined;
  carrier?: Carrier | undefined;
  policyType?: MedicarePlan_PolicyType | undefined;
  planType?: MedicarePlan_PlanType | undefined;
  snp?: MedicarePlan_SNP | undefined;
  category?: PlanCategory | undefined;
  year?: string | undefined;
}

export enum MedicarePlan_PolicyType {
  NO_POLICY_TYPE = 0,
  P_HMO = 1,
  P_LPPO = 2,
  P_PDP = 3,
  P_PPO = 4,
  P_PFFS = 5,
  UNRECOGNIZED = -1,
}

export function medicarePlan_PolicyTypeFromJSON(object: any): MedicarePlan_PolicyType {
  switch (object) {
    case 0:
    case "NO_POLICY_TYPE":
      return MedicarePlan_PolicyType.NO_POLICY_TYPE;
    case 1:
    case "P_HMO":
      return MedicarePlan_PolicyType.P_HMO;
    case 2:
    case "P_LPPO":
      return MedicarePlan_PolicyType.P_LPPO;
    case 3:
    case "P_PDP":
      return MedicarePlan_PolicyType.P_PDP;
    case 4:
    case "P_PPO":
      return MedicarePlan_PolicyType.P_PPO;
    case 5:
    case "P_PFFS":
      return MedicarePlan_PolicyType.P_PFFS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return MedicarePlan_PolicyType.UNRECOGNIZED;
  }
}

export function medicarePlan_PolicyTypeToJSON(object: MedicarePlan_PolicyType): string {
  switch (object) {
    case MedicarePlan_PolicyType.NO_POLICY_TYPE:
      return "NO_POLICY_TYPE";
    case MedicarePlan_PolicyType.P_HMO:
      return "P_HMO";
    case MedicarePlan_PolicyType.P_LPPO:
      return "P_LPPO";
    case MedicarePlan_PolicyType.P_PDP:
      return "P_PDP";
    case MedicarePlan_PolicyType.P_PPO:
      return "P_PPO";
    case MedicarePlan_PolicyType.P_PFFS:
      return "P_PFFS";
    case MedicarePlan_PolicyType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum MedicarePlan_PlanType {
  NO_PLAN_TYPE = 0,
  PT_MA = 1,
  PT_MAPD = 2,
  PT_PDP = 3,
  PT_MS = 4,
  UNRECOGNIZED = -1,
}

export function medicarePlan_PlanTypeFromJSON(object: any): MedicarePlan_PlanType {
  switch (object) {
    case 0:
    case "NO_PLAN_TYPE":
      return MedicarePlan_PlanType.NO_PLAN_TYPE;
    case 1:
    case "PT_MA":
      return MedicarePlan_PlanType.PT_MA;
    case 2:
    case "PT_MAPD":
      return MedicarePlan_PlanType.PT_MAPD;
    case 3:
    case "PT_PDP":
      return MedicarePlan_PlanType.PT_PDP;
    case 4:
    case "PT_MS":
      return MedicarePlan_PlanType.PT_MS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return MedicarePlan_PlanType.UNRECOGNIZED;
  }
}

export function medicarePlan_PlanTypeToJSON(object: MedicarePlan_PlanType): string {
  switch (object) {
    case MedicarePlan_PlanType.NO_PLAN_TYPE:
      return "NO_PLAN_TYPE";
    case MedicarePlan_PlanType.PT_MA:
      return "PT_MA";
    case MedicarePlan_PlanType.PT_MAPD:
      return "PT_MAPD";
    case MedicarePlan_PlanType.PT_PDP:
      return "PT_PDP";
    case MedicarePlan_PlanType.PT_MS:
      return "PT_MS";
    case MedicarePlan_PlanType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum MedicarePlan_SNP {
  NO_SNP = 0,
  CSNP = 1,
  DSNP = 2,
  UNRECOGNIZED = -1,
}

export function medicarePlan_SNPFromJSON(object: any): MedicarePlan_SNP {
  switch (object) {
    case 0:
    case "NO_SNP":
      return MedicarePlan_SNP.NO_SNP;
    case 1:
    case "CSNP":
      return MedicarePlan_SNP.CSNP;
    case 2:
    case "DSNP":
      return MedicarePlan_SNP.DSNP;
    case -1:
    case "UNRECOGNIZED":
    default:
      return MedicarePlan_SNP.UNRECOGNIZED;
  }
}

export function medicarePlan_SNPToJSON(object: MedicarePlan_SNP): string {
  switch (object) {
    case MedicarePlan_SNP.NO_SNP:
      return "NO_SNP";
    case MedicarePlan_SNP.CSNP:
      return "CSNP";
    case MedicarePlan_SNP.DSNP:
      return "DSNP";
    case MedicarePlan_SNP.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface PolicyStatusContainer {
  /** status by policy id */
  statusByUpdater: { [key: string]: string };
  /** policyUpdateId */
  lastUpdater?: string | undefined;
  currentStatuses: string[];
  historicStatuses: string[];
}

export interface PolicyStatusContainer_StatusByUpdaterEntry {
  key: string;
  value: string;
}

/** to be removed */
export interface Commissions {
  paymentDescription?: string | undefined;
  amount?: number | undefined;
  statementDate?: Date | undefined;
  statementTimestamp?: number | undefined;
  statementDateDay?: number | undefined;
  statementDateMonth?: number | undefined;
  statementDateYear?: number | undefined;
  amountAgency?: number | undefined;
  amountAgent?: number | undefined;
}

export interface Allegation {
  id?: string | undefined;
  active?: boolean | undefined;
  note?: string | undefined;
  result?: string | undefined;
  complaint?: string | undefined;
  date?: Date | undefined;
  dateDay?: number | undefined;
  dateMonth?: number | undefined;
  dateYear?: number | undefined;
  dateTimestamp?: number | undefined;
  dueDate?: Date | undefined;
  dueDateDay?: number | undefined;
  dueDateMonth?: number | undefined;
  dueDateYear?: number | undefined;
  dueDateTimestamp?: number | undefined;
}

function createBaseEnrollment(): Enrollment {
  return {
    id: "",
    agency: undefined,
    agent: undefined,
    consumer: undefined,
    carrier: undefined,
    marketingVendor: undefined,
    plan: undefined,
    planTable: undefined,
    medicareInfo: undefined,
    medicaidInfo: undefined,
    reviewSubmit: undefined,
    completed: undefined,
    premium: undefined,
    benefitsConfirmed: undefined,
    signatureOption: undefined,
    tpmoId: undefined,
    leadId: undefined,
    details: undefined,
    executive: undefined,
    managingAgent: undefined,
    planInfo: undefined,
    fieldUpdates: undefined,
    identifiers: undefined,
    policyUpdates: undefined,
    tags: undefined,
    cycle: undefined,
    allegation: undefined,
    commissions: undefined,
    baseCategory: undefined,
    metadata: undefined,
  };
}

export const Enrollment = {
  encode(message: Enrollment, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.agency !== undefined) {
      Enrollment_Agency.encode(message.agency, writer.uint32(26).fork()).ldelim();
    }
    if (message.agent !== undefined) {
      Agent.encode(message.agent, writer.uint32(34).fork()).ldelim();
    }
    if (message.consumer !== undefined) {
      Consumer.encode(message.consumer, writer.uint32(42).fork()).ldelim();
    }
    if (message.carrier !== undefined) {
      Carrier.encode(message.carrier, writer.uint32(50).fork()).ldelim();
    }
    if (message.marketingVendor !== undefined) {
      Enrollment_MarketingVendor.encode(message.marketingVendor, writer.uint32(58).fork()).ldelim();
    }
    if (message.plan !== undefined) {
      MedicarePlan.encode(message.plan, writer.uint32(66).fork()).ldelim();
    }
    if (message.planTable !== undefined) {
      Enrollment_PlanTable.encode(message.planTable, writer.uint32(74).fork()).ldelim();
    }
    if (message.medicareInfo !== undefined) {
      Enrollment_MedicareInfo.encode(message.medicareInfo, writer.uint32(82).fork()).ldelim();
    }
    if (message.medicaidInfo !== undefined) {
      Enrollment_MedicaidInfo.encode(message.medicaidInfo, writer.uint32(90).fork()).ldelim();
    }
    if (message.reviewSubmit !== undefined) {
      Enrollment_ReviewSubmit.encode(message.reviewSubmit, writer.uint32(98).fork()).ldelim();
    }
    if (message.completed !== undefined) {
      Enrollment_CompletedSteps.encode(message.completed, writer.uint32(106).fork()).ldelim();
    }
    if (message.premium !== undefined) {
      Enrollment_Premium.encode(message.premium, writer.uint32(114).fork()).ldelim();
    }
    if (message.benefitsConfirmed !== undefined) {
      writer.uint32(120).bool(message.benefitsConfirmed);
    }
    if (message.signatureOption !== undefined) {
      writer.uint32(130).string(message.signatureOption);
    }
    if (message.tpmoId !== undefined) {
      writer.uint32(138).string(message.tpmoId);
    }
    if (message.leadId !== undefined) {
      writer.uint32(146).string(message.leadId);
    }
    if (message.details !== undefined) {
      Enrollment_Details.encode(message.details, writer.uint32(154).fork()).ldelim();
    }
    if (message.executive !== undefined) {
      Enrollment_EnrollmentExecutive.encode(message.executive, writer.uint32(162).fork()).ldelim();
    }
    if (message.managingAgent !== undefined) {
      Agent.encode(message.managingAgent, writer.uint32(170).fork()).ldelim();
    }
    if (message.planInfo !== undefined) {
      Enrollment_PlanInfo.encode(message.planInfo, writer.uint32(178).fork()).ldelim();
    }
    if (message.fieldUpdates !== undefined) {
      FieldUpdateContainer.encode(message.fieldUpdates, writer.uint32(186).fork()).ldelim();
    }
    if (message.identifiers !== undefined) {
      IdentifierContainer.encode(message.identifiers, writer.uint32(194).fork()).ldelim();
    }
    if (message.policyUpdates !== undefined) {
      PolicyUpdateContainer.encode(message.policyUpdates, writer.uint32(202).fork()).ldelim();
    }
    if (message.tags !== undefined) {
      TagsContainer.encode(message.tags, writer.uint32(210).fork()).ldelim();
    }
    if (message.cycle !== undefined) {
      CommissionCycle.encode(message.cycle, writer.uint32(218).fork()).ldelim();
    }
    if (message.allegation !== undefined) {
      Allegation.encode(message.allegation, writer.uint32(410).fork()).ldelim();
    }
    if (message.commissions !== undefined) {
      Commissions.encode(message.commissions, writer.uint32(418).fork()).ldelim();
    }
    if (message.baseCategory !== undefined) {
      writer.uint32(784).int32(message.baseCategory);
    }
    if (message.metadata !== undefined) {
      Enrollment_Metadata.encode(message.metadata, writer.uint32(794).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Enrollment {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollment();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.agency = Enrollment_Agency.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.agent = Agent.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.consumer = Consumer.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.carrier = Carrier.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.marketingVendor = Enrollment_MarketingVendor.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.plan = MedicarePlan.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.planTable = Enrollment_PlanTable.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.medicareInfo = Enrollment_MedicareInfo.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.medicaidInfo = Enrollment_MedicaidInfo.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.reviewSubmit = Enrollment_ReviewSubmit.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.completed = Enrollment_CompletedSteps.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.premium = Enrollment_Premium.decode(reader, reader.uint32());
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.benefitsConfirmed = reader.bool();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.signatureOption = reader.string();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.tpmoId = reader.string();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.leadId = reader.string();
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.details = Enrollment_Details.decode(reader, reader.uint32());
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.executive = Enrollment_EnrollmentExecutive.decode(reader, reader.uint32());
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.managingAgent = Agent.decode(reader, reader.uint32());
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.planInfo = Enrollment_PlanInfo.decode(reader, reader.uint32());
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.fieldUpdates = FieldUpdateContainer.decode(reader, reader.uint32());
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.identifiers = IdentifierContainer.decode(reader, reader.uint32());
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.policyUpdates = PolicyUpdateContainer.decode(reader, reader.uint32());
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          message.tags = TagsContainer.decode(reader, reader.uint32());
          continue;
        case 27:
          if (tag !== 218) {
            break;
          }

          message.cycle = CommissionCycle.decode(reader, reader.uint32());
          continue;
        case 51:
          if (tag !== 410) {
            break;
          }

          message.allegation = Allegation.decode(reader, reader.uint32());
          continue;
        case 52:
          if (tag !== 418) {
            break;
          }

          message.commissions = Commissions.decode(reader, reader.uint32());
          continue;
        case 98:
          if (tag !== 784) {
            break;
          }

          message.baseCategory = reader.int32() as any;
          continue;
        case 99:
          if (tag !== 794) {
            break;
          }

          message.metadata = Enrollment_Metadata.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Enrollment {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      agency: isSet(object.agency) ? Enrollment_Agency.fromJSON(object.agency) : undefined,
      agent: isSet(object.agent) ? Agent.fromJSON(object.agent) : undefined,
      consumer: isSet(object.consumer) ? Consumer.fromJSON(object.consumer) : undefined,
      carrier: isSet(object.carrier) ? Carrier.fromJSON(object.carrier) : undefined,
      marketingVendor: isSet(object.marketingVendor)
        ? Enrollment_MarketingVendor.fromJSON(object.marketingVendor)
        : undefined,
      plan: isSet(object.plan) ? MedicarePlan.fromJSON(object.plan) : undefined,
      planTable: isSet(object.planTable) ? Enrollment_PlanTable.fromJSON(object.planTable) : undefined,
      medicareInfo: isSet(object.medicareInfo) ? Enrollment_MedicareInfo.fromJSON(object.medicareInfo) : undefined,
      medicaidInfo: isSet(object.medicaidInfo) ? Enrollment_MedicaidInfo.fromJSON(object.medicaidInfo) : undefined,
      reviewSubmit: isSet(object.reviewSubmit) ? Enrollment_ReviewSubmit.fromJSON(object.reviewSubmit) : undefined,
      completed: isSet(object.completed) ? Enrollment_CompletedSteps.fromJSON(object.completed) : undefined,
      premium: isSet(object.premium) ? Enrollment_Premium.fromJSON(object.premium) : undefined,
      benefitsConfirmed: isSet(object.benefitsConfirmed) ? Boolean(object.benefitsConfirmed) : undefined,
      signatureOption: isSet(object.signatureOption) ? String(object.signatureOption) : undefined,
      tpmoId: isSet(object.tpmoId) ? String(object.tpmoId) : undefined,
      leadId: isSet(object.leadId) ? String(object.leadId) : undefined,
      details: isSet(object.details) ? Enrollment_Details.fromJSON(object.details) : undefined,
      executive: isSet(object.executive) ? Enrollment_EnrollmentExecutive.fromJSON(object.executive) : undefined,
      managingAgent: isSet(object.managingAgent) ? Agent.fromJSON(object.managingAgent) : undefined,
      planInfo: isSet(object.planInfo) ? Enrollment_PlanInfo.fromJSON(object.planInfo) : undefined,
      fieldUpdates: isSet(object.fieldUpdates) ? FieldUpdateContainer.fromJSON(object.fieldUpdates) : undefined,
      identifiers: isSet(object.identifiers) ? IdentifierContainer.fromJSON(object.identifiers) : undefined,
      policyUpdates: isSet(object.policyUpdates) ? PolicyUpdateContainer.fromJSON(object.policyUpdates) : undefined,
      tags: isSet(object.tags) ? TagsContainer.fromJSON(object.tags) : undefined,
      cycle: isSet(object.cycle) ? CommissionCycle.fromJSON(object.cycle) : undefined,
      allegation: isSet(object.allegation) ? Allegation.fromJSON(object.allegation) : undefined,
      commissions: isSet(object.commissions) ? Commissions.fromJSON(object.commissions) : undefined,
      baseCategory: isSet(object.baseCategory) ? enrollment_BaseCategoryFromJSON(object.baseCategory) : undefined,
      metadata: isSet(object.metadata) ? Enrollment_Metadata.fromJSON(object.metadata) : undefined,
    };
  },

  toJSON(message: Enrollment): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.agency !== undefined &&
      (obj.agency = message.agency ? Enrollment_Agency.toJSON(message.agency) : undefined);
    message.agent !== undefined && (obj.agent = message.agent ? Agent.toJSON(message.agent) : undefined);
    message.consumer !== undefined && (obj.consumer = message.consumer ? Consumer.toJSON(message.consumer) : undefined);
    message.carrier !== undefined && (obj.carrier = message.carrier ? Carrier.toJSON(message.carrier) : undefined);
    message.marketingVendor !== undefined && (obj.marketingVendor = message.marketingVendor
      ? Enrollment_MarketingVendor.toJSON(message.marketingVendor)
      : undefined);
    message.plan !== undefined && (obj.plan = message.plan ? MedicarePlan.toJSON(message.plan) : undefined);
    message.planTable !== undefined &&
      (obj.planTable = message.planTable ? Enrollment_PlanTable.toJSON(message.planTable) : undefined);
    message.medicareInfo !== undefined &&
      (obj.medicareInfo = message.medicareInfo ? Enrollment_MedicareInfo.toJSON(message.medicareInfo) : undefined);
    message.medicaidInfo !== undefined &&
      (obj.medicaidInfo = message.medicaidInfo ? Enrollment_MedicaidInfo.toJSON(message.medicaidInfo) : undefined);
    message.reviewSubmit !== undefined &&
      (obj.reviewSubmit = message.reviewSubmit ? Enrollment_ReviewSubmit.toJSON(message.reviewSubmit) : undefined);
    message.completed !== undefined &&
      (obj.completed = message.completed ? Enrollment_CompletedSteps.toJSON(message.completed) : undefined);
    message.premium !== undefined &&
      (obj.premium = message.premium ? Enrollment_Premium.toJSON(message.premium) : undefined);
    message.benefitsConfirmed !== undefined && (obj.benefitsConfirmed = message.benefitsConfirmed);
    message.signatureOption !== undefined && (obj.signatureOption = message.signatureOption);
    message.tpmoId !== undefined && (obj.tpmoId = message.tpmoId);
    message.leadId !== undefined && (obj.leadId = message.leadId);
    message.details !== undefined &&
      (obj.details = message.details ? Enrollment_Details.toJSON(message.details) : undefined);
    message.executive !== undefined &&
      (obj.executive = message.executive ? Enrollment_EnrollmentExecutive.toJSON(message.executive) : undefined);
    message.managingAgent !== undefined &&
      (obj.managingAgent = message.managingAgent ? Agent.toJSON(message.managingAgent) : undefined);
    message.planInfo !== undefined &&
      (obj.planInfo = message.planInfo ? Enrollment_PlanInfo.toJSON(message.planInfo) : undefined);
    message.fieldUpdates !== undefined &&
      (obj.fieldUpdates = message.fieldUpdates ? FieldUpdateContainer.toJSON(message.fieldUpdates) : undefined);
    message.identifiers !== undefined &&
      (obj.identifiers = message.identifiers ? IdentifierContainer.toJSON(message.identifiers) : undefined);
    message.policyUpdates !== undefined &&
      (obj.policyUpdates = message.policyUpdates ? PolicyUpdateContainer.toJSON(message.policyUpdates) : undefined);
    message.tags !== undefined && (obj.tags = message.tags ? TagsContainer.toJSON(message.tags) : undefined);
    message.cycle !== undefined && (obj.cycle = message.cycle ? CommissionCycle.toJSON(message.cycle) : undefined);
    message.allegation !== undefined &&
      (obj.allegation = message.allegation ? Allegation.toJSON(message.allegation) : undefined);
    message.commissions !== undefined &&
      (obj.commissions = message.commissions ? Commissions.toJSON(message.commissions) : undefined);
    message.baseCategory !== undefined && (obj.baseCategory = message.baseCategory !== undefined
      ? enrollment_BaseCategoryToJSON(message.baseCategory)
      : undefined);
    message.metadata !== undefined &&
      (obj.metadata = message.metadata ? Enrollment_Metadata.toJSON(message.metadata) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<Enrollment>, I>>(base?: I): Enrollment {
    return Enrollment.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Enrollment>, I>>(object: I): Enrollment {
    const message = createBaseEnrollment();
    message.id = object.id ?? "";
    message.agency = (object.agency !== undefined && object.agency !== null)
      ? Enrollment_Agency.fromPartial(object.agency)
      : undefined;
    message.agent = (object.agent !== undefined && object.agent !== null) ? Agent.fromPartial(object.agent) : undefined;
    message.consumer = (object.consumer !== undefined && object.consumer !== null)
      ? Consumer.fromPartial(object.consumer)
      : undefined;
    message.carrier = (object.carrier !== undefined && object.carrier !== null)
      ? Carrier.fromPartial(object.carrier)
      : undefined;
    message.marketingVendor = (object.marketingVendor !== undefined && object.marketingVendor !== null)
      ? Enrollment_MarketingVendor.fromPartial(object.marketingVendor)
      : undefined;
    message.plan = (object.plan !== undefined && object.plan !== null)
      ? MedicarePlan.fromPartial(object.plan)
      : undefined;
    message.planTable = (object.planTable !== undefined && object.planTable !== null)
      ? Enrollment_PlanTable.fromPartial(object.planTable)
      : undefined;
    message.medicareInfo = (object.medicareInfo !== undefined && object.medicareInfo !== null)
      ? Enrollment_MedicareInfo.fromPartial(object.medicareInfo)
      : undefined;
    message.medicaidInfo = (object.medicaidInfo !== undefined && object.medicaidInfo !== null)
      ? Enrollment_MedicaidInfo.fromPartial(object.medicaidInfo)
      : undefined;
    message.reviewSubmit = (object.reviewSubmit !== undefined && object.reviewSubmit !== null)
      ? Enrollment_ReviewSubmit.fromPartial(object.reviewSubmit)
      : undefined;
    message.completed = (object.completed !== undefined && object.completed !== null)
      ? Enrollment_CompletedSteps.fromPartial(object.completed)
      : undefined;
    message.premium = (object.premium !== undefined && object.premium !== null)
      ? Enrollment_Premium.fromPartial(object.premium)
      : undefined;
    message.benefitsConfirmed = object.benefitsConfirmed ?? undefined;
    message.signatureOption = object.signatureOption ?? undefined;
    message.tpmoId = object.tpmoId ?? undefined;
    message.leadId = object.leadId ?? undefined;
    message.details = (object.details !== undefined && object.details !== null)
      ? Enrollment_Details.fromPartial(object.details)
      : undefined;
    message.executive = (object.executive !== undefined && object.executive !== null)
      ? Enrollment_EnrollmentExecutive.fromPartial(object.executive)
      : undefined;
    message.managingAgent = (object.managingAgent !== undefined && object.managingAgent !== null)
      ? Agent.fromPartial(object.managingAgent)
      : undefined;
    message.planInfo = (object.planInfo !== undefined && object.planInfo !== null)
      ? Enrollment_PlanInfo.fromPartial(object.planInfo)
      : undefined;
    message.fieldUpdates = (object.fieldUpdates !== undefined && object.fieldUpdates !== null)
      ? FieldUpdateContainer.fromPartial(object.fieldUpdates)
      : undefined;
    message.identifiers = (object.identifiers !== undefined && object.identifiers !== null)
      ? IdentifierContainer.fromPartial(object.identifiers)
      : undefined;
    message.policyUpdates = (object.policyUpdates !== undefined && object.policyUpdates !== null)
      ? PolicyUpdateContainer.fromPartial(object.policyUpdates)
      : undefined;
    message.tags = (object.tags !== undefined && object.tags !== null)
      ? TagsContainer.fromPartial(object.tags)
      : undefined;
    message.cycle = (object.cycle !== undefined && object.cycle !== null)
      ? CommissionCycle.fromPartial(object.cycle)
      : undefined;
    message.allegation = (object.allegation !== undefined && object.allegation !== null)
      ? Allegation.fromPartial(object.allegation)
      : undefined;
    message.commissions = (object.commissions !== undefined && object.commissions !== null)
      ? Commissions.fromPartial(object.commissions)
      : undefined;
    message.baseCategory = object.baseCategory ?? undefined;
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? Enrollment_Metadata.fromPartial(object.metadata)
      : undefined;
    return message;
  },
};

function createBaseEnrollment_EnrollmentExecutive(): Enrollment_EnrollmentExecutive {
  return { aggregatedExecutiveRule: undefined, tierMap: undefined, tierFilterList: [] };
}

export const Enrollment_EnrollmentExecutive = {
  encode(message: Enrollment_EnrollmentExecutive, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.aggregatedExecutiveRule !== undefined) {
      AggregatedExecutiveRule.encode(message.aggregatedExecutiveRule, writer.uint32(10).fork()).ldelim();
    }
    if (message.tierMap !== undefined) {
      Struct.encode(Struct.wrap(message.tierMap), writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.tierFilterList) {
      TierFilterListElement.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Enrollment_EnrollmentExecutive {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollment_EnrollmentExecutive();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.aggregatedExecutiveRule = AggregatedExecutiveRule.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.tierMap = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.tierFilterList.push(TierFilterListElement.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Enrollment_EnrollmentExecutive {
    return {
      aggregatedExecutiveRule: isSet(object.aggregatedExecutiveRule)
        ? AggregatedExecutiveRule.fromJSON(object.aggregatedExecutiveRule)
        : undefined,
      tierMap: isObject(object.tierMap) ? object.tierMap : undefined,
      tierFilterList: Array.isArray(object?.tierFilterList)
        ? object.tierFilterList.map((e: any) => TierFilterListElement.fromJSON(e))
        : [],
    };
  },

  toJSON(message: Enrollment_EnrollmentExecutive): unknown {
    const obj: any = {};
    message.aggregatedExecutiveRule !== undefined && (obj.aggregatedExecutiveRule = message.aggregatedExecutiveRule
      ? AggregatedExecutiveRule.toJSON(message.aggregatedExecutiveRule)
      : undefined);
    message.tierMap !== undefined && (obj.tierMap = message.tierMap);
    if (message.tierFilterList) {
      obj.tierFilterList = message.tierFilterList.map((e) => e ? TierFilterListElement.toJSON(e) : undefined);
    } else {
      obj.tierFilterList = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Enrollment_EnrollmentExecutive>, I>>(base?: I): Enrollment_EnrollmentExecutive {
    return Enrollment_EnrollmentExecutive.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Enrollment_EnrollmentExecutive>, I>>(
    object: I,
  ): Enrollment_EnrollmentExecutive {
    const message = createBaseEnrollment_EnrollmentExecutive();
    message.aggregatedExecutiveRule =
      (object.aggregatedExecutiveRule !== undefined && object.aggregatedExecutiveRule !== null)
        ? AggregatedExecutiveRule.fromPartial(object.aggregatedExecutiveRule)
        : undefined;
    message.tierMap = object.tierMap ?? undefined;
    message.tierFilterList = object.tierFilterList?.map((e) => TierFilterListElement.fromPartial(e)) || [];
    return message;
  },
};

function createBaseEnrollment_PlanInfo(): Enrollment_PlanInfo {
  return {
    id: undefined,
    planDetailsConfirmed: undefined,
    isNotMedicareSupplementPlan: undefined,
    doctorSpecifyNow: undefined,
    doctors: [],
    isCurrentlyPatient: undefined,
    paymentOption: undefined,
  };
}

export const Enrollment_PlanInfo = {
  encode(message: Enrollment_PlanInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.planDetailsConfirmed !== undefined) {
      writer.uint32(16).bool(message.planDetailsConfirmed);
    }
    if (message.isNotMedicareSupplementPlan !== undefined) {
      writer.uint32(24).bool(message.isNotMedicareSupplementPlan);
    }
    if (message.doctorSpecifyNow !== undefined) {
      writer.uint32(32).bool(message.doctorSpecifyNow);
    }
    for (const v of message.doctors) {
      Doctor.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.isCurrentlyPatient !== undefined) {
      writer.uint32(48).bool(message.isCurrentlyPatient);
    }
    if (message.paymentOption !== undefined) {
      writer.uint32(58).string(message.paymentOption);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Enrollment_PlanInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollment_PlanInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.planDetailsConfirmed = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.isNotMedicareSupplementPlan = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.doctorSpecifyNow = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.doctors.push(Doctor.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.isCurrentlyPatient = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.paymentOption = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Enrollment_PlanInfo {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      planDetailsConfirmed: isSet(object.planDetailsConfirmed) ? Boolean(object.planDetailsConfirmed) : undefined,
      isNotMedicareSupplementPlan: isSet(object.isNotMedicareSupplementPlan)
        ? Boolean(object.isNotMedicareSupplementPlan)
        : undefined,
      doctorSpecifyNow: isSet(object.doctorSpecifyNow) ? Boolean(object.doctorSpecifyNow) : undefined,
      doctors: Array.isArray(object?.doctors) ? object.doctors.map((e: any) => Doctor.fromJSON(e)) : [],
      isCurrentlyPatient: isSet(object.isCurrentlyPatient) ? Boolean(object.isCurrentlyPatient) : undefined,
      paymentOption: isSet(object.paymentOption) ? String(object.paymentOption) : undefined,
    };
  },

  toJSON(message: Enrollment_PlanInfo): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.planDetailsConfirmed !== undefined && (obj.planDetailsConfirmed = message.planDetailsConfirmed);
    message.isNotMedicareSupplementPlan !== undefined &&
      (obj.isNotMedicareSupplementPlan = message.isNotMedicareSupplementPlan);
    message.doctorSpecifyNow !== undefined && (obj.doctorSpecifyNow = message.doctorSpecifyNow);
    if (message.doctors) {
      obj.doctors = message.doctors.map((e) => e ? Doctor.toJSON(e) : undefined);
    } else {
      obj.doctors = [];
    }
    message.isCurrentlyPatient !== undefined && (obj.isCurrentlyPatient = message.isCurrentlyPatient);
    message.paymentOption !== undefined && (obj.paymentOption = message.paymentOption);
    return obj;
  },

  create<I extends Exact<DeepPartial<Enrollment_PlanInfo>, I>>(base?: I): Enrollment_PlanInfo {
    return Enrollment_PlanInfo.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Enrollment_PlanInfo>, I>>(object: I): Enrollment_PlanInfo {
    const message = createBaseEnrollment_PlanInfo();
    message.id = object.id ?? undefined;
    message.planDetailsConfirmed = object.planDetailsConfirmed ?? undefined;
    message.isNotMedicareSupplementPlan = object.isNotMedicareSupplementPlan ?? undefined;
    message.doctorSpecifyNow = object.doctorSpecifyNow ?? undefined;
    message.doctors = object.doctors?.map((e) => Doctor.fromPartial(e)) || [];
    message.isCurrentlyPatient = object.isCurrentlyPatient ?? undefined;
    message.paymentOption = object.paymentOption ?? undefined;
    return message;
  },
};

function createBaseEnrollment_Details(): Enrollment_Details {
  return {
    partAEffectiveDate: undefined,
    partBEffectiveDate: undefined,
    effectiveDate: undefined,
    signatureDate: undefined,
    signaturePeriod: undefined,
    confirmationNumber: undefined,
    confirmationStatus: undefined,
    enrollmentCode: undefined,
    policyStatusCategory: undefined,
    policyStatus: undefined,
    paymentType: undefined,
    policyStatusContainer: undefined,
  };
}

export const Enrollment_Details = {
  encode(message: Enrollment_Details, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.partAEffectiveDate !== undefined) {
      DateContainer.encode(message.partAEffectiveDate, writer.uint32(10).fork()).ldelim();
    }
    if (message.partBEffectiveDate !== undefined) {
      DateContainer.encode(message.partBEffectiveDate, writer.uint32(18).fork()).ldelim();
    }
    if (message.effectiveDate !== undefined) {
      DateContainer.encode(message.effectiveDate, writer.uint32(26).fork()).ldelim();
    }
    if (message.signatureDate !== undefined) {
      DateContainer.encode(message.signatureDate, writer.uint32(34).fork()).ldelim();
    }
    if (message.signaturePeriod !== undefined) {
      writer.uint32(40).int32(message.signaturePeriod);
    }
    if (message.confirmationNumber !== undefined) {
      writer.uint32(50).string(message.confirmationNumber);
    }
    if (message.confirmationStatus !== undefined) {
      writer.uint32(56).int32(message.confirmationStatus);
    }
    if (message.enrollmentCode !== undefined) {
      writer.uint32(66).string(message.enrollmentCode);
    }
    if (message.policyStatusCategory !== undefined) {
      writer.uint32(72).int32(message.policyStatusCategory);
    }
    if (message.policyStatus !== undefined) {
      writer.uint32(80).int32(message.policyStatus);
    }
    if (message.paymentType !== undefined) {
      writer.uint32(88).int32(message.paymentType);
    }
    if (message.policyStatusContainer !== undefined) {
      PolicyStatusContainer.encode(message.policyStatusContainer, writer.uint32(98).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Enrollment_Details {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollment_Details();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.partAEffectiveDate = DateContainer.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.partBEffectiveDate = DateContainer.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.effectiveDate = DateContainer.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.signatureDate = DateContainer.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.signaturePeriod = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.confirmationNumber = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.confirmationStatus = reader.int32() as any;
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.enrollmentCode = reader.string();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.policyStatusCategory = reader.int32() as any;
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.policyStatus = reader.int32() as any;
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.paymentType = reader.int32() as any;
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.policyStatusContainer = PolicyStatusContainer.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Enrollment_Details {
    return {
      partAEffectiveDate: isSet(object.partAEffectiveDate)
        ? DateContainer.fromJSON(object.partAEffectiveDate)
        : undefined,
      partBEffectiveDate: isSet(object.partBEffectiveDate)
        ? DateContainer.fromJSON(object.partBEffectiveDate)
        : undefined,
      effectiveDate: isSet(object.effectiveDate) ? DateContainer.fromJSON(object.effectiveDate) : undefined,
      signatureDate: isSet(object.signatureDate) ? DateContainer.fromJSON(object.signatureDate) : undefined,
      signaturePeriod: isSet(object.signaturePeriod) ? signaturePeriodFromJSON(object.signaturePeriod) : undefined,
      confirmationNumber: isSet(object.confirmationNumber) ? String(object.confirmationNumber) : undefined,
      confirmationStatus: isSet(object.confirmationStatus)
        ? enrollment_Details_ConfirmationStatusFromJSON(object.confirmationStatus)
        : undefined,
      enrollmentCode: isSet(object.enrollmentCode) ? String(object.enrollmentCode) : undefined,
      policyStatusCategory: isSet(object.policyStatusCategory)
        ? policyStatusCategoryFromJSON(object.policyStatusCategory)
        : undefined,
      policyStatus: isSet(object.policyStatus) ? policyStatusFromJSON(object.policyStatus) : undefined,
      paymentType: isSet(object.paymentType) ? paymentTypeFromJSON(object.paymentType) : undefined,
      policyStatusContainer: isSet(object.policyStatusContainer)
        ? PolicyStatusContainer.fromJSON(object.policyStatusContainer)
        : undefined,
    };
  },

  toJSON(message: Enrollment_Details): unknown {
    const obj: any = {};
    message.partAEffectiveDate !== undefined && (obj.partAEffectiveDate = message.partAEffectiveDate
      ? DateContainer.toJSON(message.partAEffectiveDate)
      : undefined);
    message.partBEffectiveDate !== undefined && (obj.partBEffectiveDate = message.partBEffectiveDate
      ? DateContainer.toJSON(message.partBEffectiveDate)
      : undefined);
    message.effectiveDate !== undefined &&
      (obj.effectiveDate = message.effectiveDate ? DateContainer.toJSON(message.effectiveDate) : undefined);
    message.signatureDate !== undefined &&
      (obj.signatureDate = message.signatureDate ? DateContainer.toJSON(message.signatureDate) : undefined);
    message.signaturePeriod !== undefined && (obj.signaturePeriod = message.signaturePeriod !== undefined
      ? signaturePeriodToJSON(message.signaturePeriod)
      : undefined);
    message.confirmationNumber !== undefined && (obj.confirmationNumber = message.confirmationNumber);
    message.confirmationStatus !== undefined && (obj.confirmationStatus = message.confirmationStatus !== undefined
      ? enrollment_Details_ConfirmationStatusToJSON(message.confirmationStatus)
      : undefined);
    message.enrollmentCode !== undefined && (obj.enrollmentCode = message.enrollmentCode);
    message.policyStatusCategory !== undefined && (obj.policyStatusCategory = message.policyStatusCategory !== undefined
      ? policyStatusCategoryToJSON(message.policyStatusCategory)
      : undefined);
    message.policyStatus !== undefined &&
      (obj.policyStatus = message.policyStatus !== undefined ? policyStatusToJSON(message.policyStatus) : undefined);
    message.paymentType !== undefined &&
      (obj.paymentType = message.paymentType !== undefined ? paymentTypeToJSON(message.paymentType) : undefined);
    message.policyStatusContainer !== undefined && (obj.policyStatusContainer = message.policyStatusContainer
      ? PolicyStatusContainer.toJSON(message.policyStatusContainer)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<Enrollment_Details>, I>>(base?: I): Enrollment_Details {
    return Enrollment_Details.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Enrollment_Details>, I>>(object: I): Enrollment_Details {
    const message = createBaseEnrollment_Details();
    message.partAEffectiveDate = (object.partAEffectiveDate !== undefined && object.partAEffectiveDate !== null)
      ? DateContainer.fromPartial(object.partAEffectiveDate)
      : undefined;
    message.partBEffectiveDate = (object.partBEffectiveDate !== undefined && object.partBEffectiveDate !== null)
      ? DateContainer.fromPartial(object.partBEffectiveDate)
      : undefined;
    message.effectiveDate = (object.effectiveDate !== undefined && object.effectiveDate !== null)
      ? DateContainer.fromPartial(object.effectiveDate)
      : undefined;
    message.signatureDate = (object.signatureDate !== undefined && object.signatureDate !== null)
      ? DateContainer.fromPartial(object.signatureDate)
      : undefined;
    message.signaturePeriod = object.signaturePeriod ?? undefined;
    message.confirmationNumber = object.confirmationNumber ?? undefined;
    message.confirmationStatus = object.confirmationStatus ?? undefined;
    message.enrollmentCode = object.enrollmentCode ?? undefined;
    message.policyStatusCategory = object.policyStatusCategory ?? undefined;
    message.policyStatus = object.policyStatus ?? undefined;
    message.paymentType = object.paymentType ?? undefined;
    message.policyStatusContainer =
      (object.policyStatusContainer !== undefined && object.policyStatusContainer !== null)
        ? PolicyStatusContainer.fromPartial(object.policyStatusContainer)
        : undefined;
    return message;
  },
};

function createBaseEnrollment_Premium(): Enrollment_Premium {
  return { monthly: undefined, annual: undefined };
}

export const Enrollment_Premium = {
  encode(message: Enrollment_Premium, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.monthly !== undefined) {
      writer.uint32(9).double(message.monthly);
    }
    if (message.annual !== undefined) {
      writer.uint32(17).double(message.annual);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Enrollment_Premium {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollment_Premium();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.monthly = reader.double();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.annual = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Enrollment_Premium {
    return {
      monthly: isSet(object.monthly) ? Number(object.monthly) : undefined,
      annual: isSet(object.annual) ? Number(object.annual) : undefined,
    };
  },

  toJSON(message: Enrollment_Premium): unknown {
    const obj: any = {};
    message.monthly !== undefined && (obj.monthly = message.monthly);
    message.annual !== undefined && (obj.annual = message.annual);
    return obj;
  },

  create<I extends Exact<DeepPartial<Enrollment_Premium>, I>>(base?: I): Enrollment_Premium {
    return Enrollment_Premium.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Enrollment_Premium>, I>>(object: I): Enrollment_Premium {
    const message = createBaseEnrollment_Premium();
    message.monthly = object.monthly ?? undefined;
    message.annual = object.annual ?? undefined;
    return message;
  },
};

function createBaseEnrollment_Agency(): Enrollment_Agency {
  return { id: undefined };
}

export const Enrollment_Agency = {
  encode(message: Enrollment_Agency, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Enrollment_Agency {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollment_Agency();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Enrollment_Agency {
    return { id: isSet(object.id) ? String(object.id) : undefined };
  },

  toJSON(message: Enrollment_Agency): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  create<I extends Exact<DeepPartial<Enrollment_Agency>, I>>(base?: I): Enrollment_Agency {
    return Enrollment_Agency.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Enrollment_Agency>, I>>(object: I): Enrollment_Agency {
    const message = createBaseEnrollment_Agency();
    message.id = object.id ?? undefined;
    return message;
  },
};

function createBaseEnrollment_MarketingVendor(): Enrollment_MarketingVendor {
  return { id: undefined };
}

export const Enrollment_MarketingVendor = {
  encode(message: Enrollment_MarketingVendor, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Enrollment_MarketingVendor {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollment_MarketingVendor();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Enrollment_MarketingVendor {
    return { id: isSet(object.id) ? String(object.id) : undefined };
  },

  toJSON(message: Enrollment_MarketingVendor): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  create<I extends Exact<DeepPartial<Enrollment_MarketingVendor>, I>>(base?: I): Enrollment_MarketingVendor {
    return Enrollment_MarketingVendor.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Enrollment_MarketingVendor>, I>>(object: I): Enrollment_MarketingVendor {
    const message = createBaseEnrollment_MarketingVendor();
    message.id = object.id ?? undefined;
    return message;
  },
};

function createBaseEnrollment_PlanTable(): Enrollment_PlanTable {
  return {
    id: "",
    planBenefit: {},
    summary: [],
    inpatientCare: [],
    outpatientCare: [],
    coveragePhase: [],
    additional: [],
  };
}

export const Enrollment_PlanTable = {
  encode(message: Enrollment_PlanTable, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    Object.entries(message.planBenefit).forEach(([key, value]) => {
      Enrollment_PlanTable_PlanBenefitEntry.encode({ key: key as any, value }, writer.uint32(18).fork()).ldelim();
    });
    for (const v of message.summary) {
      Enrollment_PlanTable_SummaryElement.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.inpatientCare) {
      Enrollment_PlanTable_TableElement.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.outpatientCare) {
      Enrollment_PlanTable_TableElement.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    for (const v of message.coveragePhase) {
      Enrollment_PlanTable_CoverageElement.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.additional) {
      Enrollment_PlanTable_TableElement.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Enrollment_PlanTable {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollment_PlanTable();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          const entry2 = Enrollment_PlanTable_PlanBenefitEntry.decode(reader, reader.uint32());
          if (entry2.value !== undefined) {
            message.planBenefit[entry2.key] = entry2.value;
          }
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.summary.push(Enrollment_PlanTable_SummaryElement.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.inpatientCare.push(Enrollment_PlanTable_TableElement.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.outpatientCare.push(Enrollment_PlanTable_TableElement.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.coveragePhase.push(Enrollment_PlanTable_CoverageElement.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.additional.push(Enrollment_PlanTable_TableElement.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Enrollment_PlanTable {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      planBenefit: isObject(object.planBenefit)
        ? Object.entries(object.planBenefit).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
      summary: Array.isArray(object?.summary)
        ? object.summary.map((e: any) => Enrollment_PlanTable_SummaryElement.fromJSON(e))
        : [],
      inpatientCare: Array.isArray(object?.inpatientCare)
        ? object.inpatientCare.map((e: any) => Enrollment_PlanTable_TableElement.fromJSON(e))
        : [],
      outpatientCare: Array.isArray(object?.outpatientCare)
        ? object.outpatientCare.map((e: any) => Enrollment_PlanTable_TableElement.fromJSON(e))
        : [],
      coveragePhase: Array.isArray(object?.coveragePhase)
        ? object.coveragePhase.map((e: any) => Enrollment_PlanTable_CoverageElement.fromJSON(e))
        : [],
      additional: Array.isArray(object?.additional)
        ? object.additional.map((e: any) => Enrollment_PlanTable_TableElement.fromJSON(e))
        : [],
    };
  },

  toJSON(message: Enrollment_PlanTable): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    obj.planBenefit = {};
    if (message.planBenefit) {
      Object.entries(message.planBenefit).forEach(([k, v]) => {
        obj.planBenefit[k] = v;
      });
    }
    if (message.summary) {
      obj.summary = message.summary.map((e) => e ? Enrollment_PlanTable_SummaryElement.toJSON(e) : undefined);
    } else {
      obj.summary = [];
    }
    if (message.inpatientCare) {
      obj.inpatientCare = message.inpatientCare.map((e) => e ? Enrollment_PlanTable_TableElement.toJSON(e) : undefined);
    } else {
      obj.inpatientCare = [];
    }
    if (message.outpatientCare) {
      obj.outpatientCare = message.outpatientCare.map((e) =>
        e ? Enrollment_PlanTable_TableElement.toJSON(e) : undefined
      );
    } else {
      obj.outpatientCare = [];
    }
    if (message.coveragePhase) {
      obj.coveragePhase = message.coveragePhase.map((e) =>
        e ? Enrollment_PlanTable_CoverageElement.toJSON(e) : undefined
      );
    } else {
      obj.coveragePhase = [];
    }
    if (message.additional) {
      obj.additional = message.additional.map((e) => e ? Enrollment_PlanTable_TableElement.toJSON(e) : undefined);
    } else {
      obj.additional = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Enrollment_PlanTable>, I>>(base?: I): Enrollment_PlanTable {
    return Enrollment_PlanTable.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Enrollment_PlanTable>, I>>(object: I): Enrollment_PlanTable {
    const message = createBaseEnrollment_PlanTable();
    message.id = object.id ?? "";
    message.planBenefit = Object.entries(object.planBenefit ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = String(value);
        }
        return acc;
      },
      {},
    );
    message.summary = object.summary?.map((e) => Enrollment_PlanTable_SummaryElement.fromPartial(e)) || [];
    message.inpatientCare = object.inpatientCare?.map((e) => Enrollment_PlanTable_TableElement.fromPartial(e)) || [];
    message.outpatientCare = object.outpatientCare?.map((e) => Enrollment_PlanTable_TableElement.fromPartial(e)) || [];
    message.coveragePhase = object.coveragePhase?.map((e) => Enrollment_PlanTable_CoverageElement.fromPartial(e)) || [];
    message.additional = object.additional?.map((e) => Enrollment_PlanTable_TableElement.fromPartial(e)) || [];
    return message;
  },
};

function createBaseEnrollment_PlanTable_PlanBenefitEntry(): Enrollment_PlanTable_PlanBenefitEntry {
  return { key: "", value: "" };
}

export const Enrollment_PlanTable_PlanBenefitEntry = {
  encode(message: Enrollment_PlanTable_PlanBenefitEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Enrollment_PlanTable_PlanBenefitEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollment_PlanTable_PlanBenefitEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Enrollment_PlanTable_PlanBenefitEntry {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? String(object.value) : "" };
  },

  toJSON(message: Enrollment_PlanTable_PlanBenefitEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create<I extends Exact<DeepPartial<Enrollment_PlanTable_PlanBenefitEntry>, I>>(
    base?: I,
  ): Enrollment_PlanTable_PlanBenefitEntry {
    return Enrollment_PlanTable_PlanBenefitEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Enrollment_PlanTable_PlanBenefitEntry>, I>>(
    object: I,
  ): Enrollment_PlanTable_PlanBenefitEntry {
    const message = createBaseEnrollment_PlanTable_PlanBenefitEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseEnrollment_PlanTable_SummaryElement(): Enrollment_PlanTable_SummaryElement {
  return { name: undefined, iconClass: undefined, value: undefined };
}

export const Enrollment_PlanTable_SummaryElement = {
  encode(message: Enrollment_PlanTable_SummaryElement, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== undefined) {
      writer.uint32(10).string(message.name);
    }
    if (message.iconClass !== undefined) {
      writer.uint32(18).string(message.iconClass);
    }
    if (message.value !== undefined) {
      writer.uint32(26).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Enrollment_PlanTable_SummaryElement {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollment_PlanTable_SummaryElement();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.iconClass = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Enrollment_PlanTable_SummaryElement {
    return {
      name: isSet(object.name) ? String(object.name) : undefined,
      iconClass: isSet(object.iconClass) ? String(object.iconClass) : undefined,
      value: isSet(object.value) ? String(object.value) : undefined,
    };
  },

  toJSON(message: Enrollment_PlanTable_SummaryElement): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.iconClass !== undefined && (obj.iconClass = message.iconClass);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create<I extends Exact<DeepPartial<Enrollment_PlanTable_SummaryElement>, I>>(
    base?: I,
  ): Enrollment_PlanTable_SummaryElement {
    return Enrollment_PlanTable_SummaryElement.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Enrollment_PlanTable_SummaryElement>, I>>(
    object: I,
  ): Enrollment_PlanTable_SummaryElement {
    const message = createBaseEnrollment_PlanTable_SummaryElement();
    message.name = object.name ?? undefined;
    message.iconClass = object.iconClass ?? undefined;
    message.value = object.value ?? undefined;
    return message;
  },
};

function createBaseEnrollment_PlanTable_TableElement(): Enrollment_PlanTable_TableElement {
  return {
    name: undefined,
    iconClass: undefined,
    services: [],
    groups: [],
    copayment: undefined,
    authorizationNeeded: undefined,
    value: undefined,
    limit: undefined,
  };
}

export const Enrollment_PlanTable_TableElement = {
  encode(message: Enrollment_PlanTable_TableElement, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== undefined) {
      writer.uint32(10).string(message.name);
    }
    if (message.iconClass !== undefined) {
      writer.uint32(18).string(message.iconClass);
    }
    for (const v of message.services) {
      Enrollment_PlanTable_TableElement_TableService.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.groups) {
      Enrollment_PlanTable_TableElement_TableGroup.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    if (message.copayment !== undefined) {
      writer.uint32(42).string(message.copayment);
    }
    if (message.authorizationNeeded !== undefined) {
      writer.uint32(50).string(message.authorizationNeeded);
    }
    if (message.value !== undefined) {
      writer.uint32(58).string(message.value);
    }
    if (message.limit !== undefined) {
      writer.uint32(66).string(message.limit);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Enrollment_PlanTable_TableElement {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollment_PlanTable_TableElement();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.iconClass = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.services.push(Enrollment_PlanTable_TableElement_TableService.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.groups.push(Enrollment_PlanTable_TableElement_TableGroup.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.copayment = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.authorizationNeeded = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.value = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.limit = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Enrollment_PlanTable_TableElement {
    return {
      name: isSet(object.name) ? String(object.name) : undefined,
      iconClass: isSet(object.iconClass) ? String(object.iconClass) : undefined,
      services: Array.isArray(object?.services)
        ? object.services.map((e: any) => Enrollment_PlanTable_TableElement_TableService.fromJSON(e))
        : [],
      groups: Array.isArray(object?.groups)
        ? object.groups.map((e: any) => Enrollment_PlanTable_TableElement_TableGroup.fromJSON(e))
        : [],
      copayment: isSet(object.copayment) ? String(object.copayment) : undefined,
      authorizationNeeded: isSet(object.authorizationNeeded) ? String(object.authorizationNeeded) : undefined,
      value: isSet(object.value) ? String(object.value) : undefined,
      limit: isSet(object.limit) ? String(object.limit) : undefined,
    };
  },

  toJSON(message: Enrollment_PlanTable_TableElement): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.iconClass !== undefined && (obj.iconClass = message.iconClass);
    if (message.services) {
      obj.services = message.services.map((e) =>
        e ? Enrollment_PlanTable_TableElement_TableService.toJSON(e) : undefined
      );
    } else {
      obj.services = [];
    }
    if (message.groups) {
      obj.groups = message.groups.map((e) => e ? Enrollment_PlanTable_TableElement_TableGroup.toJSON(e) : undefined);
    } else {
      obj.groups = [];
    }
    message.copayment !== undefined && (obj.copayment = message.copayment);
    message.authorizationNeeded !== undefined && (obj.authorizationNeeded = message.authorizationNeeded);
    message.value !== undefined && (obj.value = message.value);
    message.limit !== undefined && (obj.limit = message.limit);
    return obj;
  },

  create<I extends Exact<DeepPartial<Enrollment_PlanTable_TableElement>, I>>(
    base?: I,
  ): Enrollment_PlanTable_TableElement {
    return Enrollment_PlanTable_TableElement.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Enrollment_PlanTable_TableElement>, I>>(
    object: I,
  ): Enrollment_PlanTable_TableElement {
    const message = createBaseEnrollment_PlanTable_TableElement();
    message.name = object.name ?? undefined;
    message.iconClass = object.iconClass ?? undefined;
    message.services = object.services?.map((e) => Enrollment_PlanTable_TableElement_TableService.fromPartial(e)) || [];
    message.groups = object.groups?.map((e) => Enrollment_PlanTable_TableElement_TableGroup.fromPartial(e)) || [];
    message.copayment = object.copayment ?? undefined;
    message.authorizationNeeded = object.authorizationNeeded ?? undefined;
    message.value = object.value ?? undefined;
    message.limit = object.limit ?? undefined;
    return message;
  },
};

function createBaseEnrollment_PlanTable_TableElement_TableGroup(): Enrollment_PlanTable_TableElement_TableGroup {
  return {
    name: undefined,
    iconClass: undefined,
    services: [],
    value: undefined,
    authorizationNeeded: undefined,
    limit: undefined,
  };
}

export const Enrollment_PlanTable_TableElement_TableGroup = {
  encode(message: Enrollment_PlanTable_TableElement_TableGroup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== undefined) {
      writer.uint32(10).string(message.name);
    }
    if (message.iconClass !== undefined) {
      writer.uint32(18).string(message.iconClass);
    }
    for (const v of message.services) {
      Enrollment_PlanTable_TableElement_TableService.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.value !== undefined) {
      writer.uint32(34).string(message.value);
    }
    if (message.authorizationNeeded !== undefined) {
      writer.uint32(42).string(message.authorizationNeeded);
    }
    if (message.limit !== undefined) {
      writer.uint32(50).string(message.limit);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Enrollment_PlanTable_TableElement_TableGroup {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollment_PlanTable_TableElement_TableGroup();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.iconClass = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.services.push(Enrollment_PlanTable_TableElement_TableService.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.value = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.authorizationNeeded = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.limit = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Enrollment_PlanTable_TableElement_TableGroup {
    return {
      name: isSet(object.name) ? String(object.name) : undefined,
      iconClass: isSet(object.iconClass) ? String(object.iconClass) : undefined,
      services: Array.isArray(object?.services)
        ? object.services.map((e: any) => Enrollment_PlanTable_TableElement_TableService.fromJSON(e))
        : [],
      value: isSet(object.value) ? String(object.value) : undefined,
      authorizationNeeded: isSet(object.authorizationNeeded) ? String(object.authorizationNeeded) : undefined,
      limit: isSet(object.limit) ? String(object.limit) : undefined,
    };
  },

  toJSON(message: Enrollment_PlanTable_TableElement_TableGroup): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.iconClass !== undefined && (obj.iconClass = message.iconClass);
    if (message.services) {
      obj.services = message.services.map((e) =>
        e ? Enrollment_PlanTable_TableElement_TableService.toJSON(e) : undefined
      );
    } else {
      obj.services = [];
    }
    message.value !== undefined && (obj.value = message.value);
    message.authorizationNeeded !== undefined && (obj.authorizationNeeded = message.authorizationNeeded);
    message.limit !== undefined && (obj.limit = message.limit);
    return obj;
  },

  create<I extends Exact<DeepPartial<Enrollment_PlanTable_TableElement_TableGroup>, I>>(
    base?: I,
  ): Enrollment_PlanTable_TableElement_TableGroup {
    return Enrollment_PlanTable_TableElement_TableGroup.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Enrollment_PlanTable_TableElement_TableGroup>, I>>(
    object: I,
  ): Enrollment_PlanTable_TableElement_TableGroup {
    const message = createBaseEnrollment_PlanTable_TableElement_TableGroup();
    message.name = object.name ?? undefined;
    message.iconClass = object.iconClass ?? undefined;
    message.services = object.services?.map((e) => Enrollment_PlanTable_TableElement_TableService.fromPartial(e)) || [];
    message.value = object.value ?? undefined;
    message.authorizationNeeded = object.authorizationNeeded ?? undefined;
    message.limit = object.limit ?? undefined;
    return message;
  },
};

function createBaseEnrollment_PlanTable_TableElement_TableService(): Enrollment_PlanTable_TableElement_TableService {
  return { name: undefined, value: undefined, authorizationNeeded: undefined, limit: undefined, network: undefined };
}

export const Enrollment_PlanTable_TableElement_TableService = {
  encode(
    message: Enrollment_PlanTable_TableElement_TableService,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.name !== undefined) {
      writer.uint32(10).string(message.name);
    }
    if (message.value !== undefined) {
      writer.uint32(18).string(message.value);
    }
    if (message.authorizationNeeded !== undefined) {
      writer.uint32(26).string(message.authorizationNeeded);
    }
    if (message.limit !== undefined) {
      writer.uint32(34).string(message.limit);
    }
    if (message.network !== undefined) {
      writer.uint32(42).string(message.network);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Enrollment_PlanTable_TableElement_TableService {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollment_PlanTable_TableElement_TableService();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.authorizationNeeded = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.limit = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.network = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Enrollment_PlanTable_TableElement_TableService {
    return {
      name: isSet(object.name) ? String(object.name) : undefined,
      value: isSet(object.value) ? String(object.value) : undefined,
      authorizationNeeded: isSet(object.authorizationNeeded) ? String(object.authorizationNeeded) : undefined,
      limit: isSet(object.limit) ? String(object.limit) : undefined,
      network: isSet(object.network) ? String(object.network) : undefined,
    };
  },

  toJSON(message: Enrollment_PlanTable_TableElement_TableService): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.value !== undefined && (obj.value = message.value);
    message.authorizationNeeded !== undefined && (obj.authorizationNeeded = message.authorizationNeeded);
    message.limit !== undefined && (obj.limit = message.limit);
    message.network !== undefined && (obj.network = message.network);
    return obj;
  },

  create<I extends Exact<DeepPartial<Enrollment_PlanTable_TableElement_TableService>, I>>(
    base?: I,
  ): Enrollment_PlanTable_TableElement_TableService {
    return Enrollment_PlanTable_TableElement_TableService.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Enrollment_PlanTable_TableElement_TableService>, I>>(
    object: I,
  ): Enrollment_PlanTable_TableElement_TableService {
    const message = createBaseEnrollment_PlanTable_TableElement_TableService();
    message.name = object.name ?? undefined;
    message.value = object.value ?? undefined;
    message.authorizationNeeded = object.authorizationNeeded ?? undefined;
    message.limit = object.limit ?? undefined;
    message.network = object.network ?? undefined;
    return message;
  },
};

function createBaseEnrollment_PlanTable_CoverageElement(): Enrollment_PlanTable_CoverageElement {
  return { name: undefined, iconClass: undefined, order: undefined, tiers: [] };
}

export const Enrollment_PlanTable_CoverageElement = {
  encode(message: Enrollment_PlanTable_CoverageElement, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== undefined) {
      writer.uint32(10).string(message.name);
    }
    if (message.iconClass !== undefined) {
      writer.uint32(18).string(message.iconClass);
    }
    if (message.order !== undefined) {
      writer.uint32(26).string(message.order);
    }
    for (const v of message.tiers) {
      Enrollment_PlanTable_CoverageElement_CoverageTier.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Enrollment_PlanTable_CoverageElement {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollment_PlanTable_CoverageElement();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.iconClass = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.order = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.tiers.push(Enrollment_PlanTable_CoverageElement_CoverageTier.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Enrollment_PlanTable_CoverageElement {
    return {
      name: isSet(object.name) ? String(object.name) : undefined,
      iconClass: isSet(object.iconClass) ? String(object.iconClass) : undefined,
      order: isSet(object.order) ? String(object.order) : undefined,
      tiers: Array.isArray(object?.tiers)
        ? object.tiers.map((e: any) => Enrollment_PlanTable_CoverageElement_CoverageTier.fromJSON(e))
        : [],
    };
  },

  toJSON(message: Enrollment_PlanTable_CoverageElement): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.iconClass !== undefined && (obj.iconClass = message.iconClass);
    message.order !== undefined && (obj.order = message.order);
    if (message.tiers) {
      obj.tiers = message.tiers.map((e) => e ? Enrollment_PlanTable_CoverageElement_CoverageTier.toJSON(e) : undefined);
    } else {
      obj.tiers = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Enrollment_PlanTable_CoverageElement>, I>>(
    base?: I,
  ): Enrollment_PlanTable_CoverageElement {
    return Enrollment_PlanTable_CoverageElement.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Enrollment_PlanTable_CoverageElement>, I>>(
    object: I,
  ): Enrollment_PlanTable_CoverageElement {
    const message = createBaseEnrollment_PlanTable_CoverageElement();
    message.name = object.name ?? undefined;
    message.iconClass = object.iconClass ?? undefined;
    message.order = object.order ?? undefined;
    message.tiers = object.tiers?.map((e) => Enrollment_PlanTable_CoverageElement_CoverageTier.fromPartial(e)) || [];
    return message;
  },
};

function createBaseEnrollment_PlanTable_CoverageElement_CoverageTier(): Enrollment_PlanTable_CoverageElement_CoverageTier {
  return { name: undefined, order: undefined, costs: [] };
}

export const Enrollment_PlanTable_CoverageElement_CoverageTier = {
  encode(
    message: Enrollment_PlanTable_CoverageElement_CoverageTier,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.name !== undefined) {
      writer.uint32(10).string(message.name);
    }
    if (message.order !== undefined) {
      writer.uint32(18).string(message.order);
    }
    for (const v of message.costs) {
      Enrollment_PlanTable_CoverageElement_CoverageTierCosts.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Enrollment_PlanTable_CoverageElement_CoverageTier {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollment_PlanTable_CoverageElement_CoverageTier();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.order = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.costs.push(Enrollment_PlanTable_CoverageElement_CoverageTierCosts.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Enrollment_PlanTable_CoverageElement_CoverageTier {
    return {
      name: isSet(object.name) ? String(object.name) : undefined,
      order: isSet(object.order) ? String(object.order) : undefined,
      costs: Array.isArray(object?.costs)
        ? object.costs.map((e: any) => Enrollment_PlanTable_CoverageElement_CoverageTierCosts.fromJSON(e))
        : [],
    };
  },

  toJSON(message: Enrollment_PlanTable_CoverageElement_CoverageTier): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.order !== undefined && (obj.order = message.order);
    if (message.costs) {
      obj.costs = message.costs.map((e) =>
        e ? Enrollment_PlanTable_CoverageElement_CoverageTierCosts.toJSON(e) : undefined
      );
    } else {
      obj.costs = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Enrollment_PlanTable_CoverageElement_CoverageTier>, I>>(
    base?: I,
  ): Enrollment_PlanTable_CoverageElement_CoverageTier {
    return Enrollment_PlanTable_CoverageElement_CoverageTier.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Enrollment_PlanTable_CoverageElement_CoverageTier>, I>>(
    object: I,
  ): Enrollment_PlanTable_CoverageElement_CoverageTier {
    const message = createBaseEnrollment_PlanTable_CoverageElement_CoverageTier();
    message.name = object.name ?? undefined;
    message.order = object.order ?? undefined;
    message.costs = object.costs?.map((e) => Enrollment_PlanTable_CoverageElement_CoverageTierCosts.fromPartial(e)) ||
      [];
    return message;
  },
};

function createBaseEnrollment_PlanTable_CoverageElement_CoverageTierCosts(): Enrollment_PlanTable_CoverageElement_CoverageTierCosts {
  return { name: undefined, iconClass: undefined, value1M: undefined, value2M: undefined, value3M: undefined };
}

export const Enrollment_PlanTable_CoverageElement_CoverageTierCosts = {
  encode(
    message: Enrollment_PlanTable_CoverageElement_CoverageTierCosts,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.name !== undefined) {
      writer.uint32(10).string(message.name);
    }
    if (message.iconClass !== undefined) {
      writer.uint32(18).string(message.iconClass);
    }
    if (message.value1M !== undefined) {
      writer.uint32(26).string(message.value1M);
    }
    if (message.value2M !== undefined) {
      writer.uint32(34).string(message.value2M);
    }
    if (message.value3M !== undefined) {
      writer.uint32(42).string(message.value3M);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Enrollment_PlanTable_CoverageElement_CoverageTierCosts {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollment_PlanTable_CoverageElement_CoverageTierCosts();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.iconClass = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.value1M = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.value2M = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.value3M = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Enrollment_PlanTable_CoverageElement_CoverageTierCosts {
    return {
      name: isSet(object.name) ? String(object.name) : undefined,
      iconClass: isSet(object.iconClass) ? String(object.iconClass) : undefined,
      value1M: isSet(object.value1M) ? String(object.value1M) : undefined,
      value2M: isSet(object.value2M) ? String(object.value2M) : undefined,
      value3M: isSet(object.value3M) ? String(object.value3M) : undefined,
    };
  },

  toJSON(message: Enrollment_PlanTable_CoverageElement_CoverageTierCosts): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.iconClass !== undefined && (obj.iconClass = message.iconClass);
    message.value1M !== undefined && (obj.value1M = message.value1M);
    message.value2M !== undefined && (obj.value2M = message.value2M);
    message.value3M !== undefined && (obj.value3M = message.value3M);
    return obj;
  },

  create<I extends Exact<DeepPartial<Enrollment_PlanTable_CoverageElement_CoverageTierCosts>, I>>(
    base?: I,
  ): Enrollment_PlanTable_CoverageElement_CoverageTierCosts {
    return Enrollment_PlanTable_CoverageElement_CoverageTierCosts.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Enrollment_PlanTable_CoverageElement_CoverageTierCosts>, I>>(
    object: I,
  ): Enrollment_PlanTable_CoverageElement_CoverageTierCosts {
    const message = createBaseEnrollment_PlanTable_CoverageElement_CoverageTierCosts();
    message.name = object.name ?? undefined;
    message.iconClass = object.iconClass ?? undefined;
    message.value1M = object.value1M ?? undefined;
    message.value2M = object.value2M ?? undefined;
    message.value3M = object.value3M ?? undefined;
    return message;
  },
};

function createBaseEnrollment_MedicareInfo(): Enrollment_MedicareInfo {
  return {
    number: undefined,
    chf: undefined,
    copd: undefined,
    cvd: undefined,
    diabetes: undefined,
    pcp: undefined,
    otherDrugCoverage: undefined,
    otherGroupCoverage: undefined,
    otherMedicareCoverage: undefined,
  };
}

export const Enrollment_MedicareInfo = {
  encode(message: Enrollment_MedicareInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.number !== undefined) {
      writer.uint32(10).string(message.number);
    }
    if (message.chf !== undefined) {
      Enrollment_MedicareInfo_CHF.encode(message.chf, writer.uint32(18).fork()).ldelim();
    }
    if (message.copd !== undefined) {
      Enrollment_MedicareInfo_COPD.encode(message.copd, writer.uint32(26).fork()).ldelim();
    }
    if (message.cvd !== undefined) {
      Enrollment_MedicareInfo_CVD.encode(message.cvd, writer.uint32(34).fork()).ldelim();
    }
    if (message.diabetes !== undefined) {
      Enrollment_MedicareInfo_Diabetes.encode(message.diabetes, writer.uint32(42).fork()).ldelim();
    }
    if (message.pcp !== undefined) {
      Enrollment_MedicareInfo_PCP.encode(message.pcp, writer.uint32(50).fork()).ldelim();
    }
    if (message.otherDrugCoverage !== undefined) {
      Enrollment_MedicareInfo_OtherDrugCoverage.encode(message.otherDrugCoverage, writer.uint32(58).fork()).ldelim();
    }
    if (message.otherGroupCoverage !== undefined) {
      Enrollment_MedicareInfo_OtherGroupCoverage.encode(message.otherGroupCoverage, writer.uint32(66).fork()).ldelim();
    }
    if (message.otherMedicareCoverage !== undefined) {
      Enrollment_MedicareInfo_OtherMedicareCoverage.encode(message.otherMedicareCoverage, writer.uint32(74).fork())
        .ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Enrollment_MedicareInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollment_MedicareInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.number = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.chf = Enrollment_MedicareInfo_CHF.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.copd = Enrollment_MedicareInfo_COPD.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.cvd = Enrollment_MedicareInfo_CVD.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.diabetes = Enrollment_MedicareInfo_Diabetes.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.pcp = Enrollment_MedicareInfo_PCP.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.otherDrugCoverage = Enrollment_MedicareInfo_OtherDrugCoverage.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.otherGroupCoverage = Enrollment_MedicareInfo_OtherGroupCoverage.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.otherMedicareCoverage = Enrollment_MedicareInfo_OtherMedicareCoverage.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Enrollment_MedicareInfo {
    return {
      number: isSet(object.number) ? String(object.number) : undefined,
      chf: isSet(object.chf) ? Enrollment_MedicareInfo_CHF.fromJSON(object.chf) : undefined,
      copd: isSet(object.copd) ? Enrollment_MedicareInfo_COPD.fromJSON(object.copd) : undefined,
      cvd: isSet(object.cvd) ? Enrollment_MedicareInfo_CVD.fromJSON(object.cvd) : undefined,
      diabetes: isSet(object.diabetes) ? Enrollment_MedicareInfo_Diabetes.fromJSON(object.diabetes) : undefined,
      pcp: isSet(object.pcp) ? Enrollment_MedicareInfo_PCP.fromJSON(object.pcp) : undefined,
      otherDrugCoverage: isSet(object.otherDrugCoverage)
        ? Enrollment_MedicareInfo_OtherDrugCoverage.fromJSON(object.otherDrugCoverage)
        : undefined,
      otherGroupCoverage: isSet(object.otherGroupCoverage)
        ? Enrollment_MedicareInfo_OtherGroupCoverage.fromJSON(object.otherGroupCoverage)
        : undefined,
      otherMedicareCoverage: isSet(object.otherMedicareCoverage)
        ? Enrollment_MedicareInfo_OtherMedicareCoverage.fromJSON(object.otherMedicareCoverage)
        : undefined,
    };
  },

  toJSON(message: Enrollment_MedicareInfo): unknown {
    const obj: any = {};
    message.number !== undefined && (obj.number = message.number);
    message.chf !== undefined && (obj.chf = message.chf ? Enrollment_MedicareInfo_CHF.toJSON(message.chf) : undefined);
    message.copd !== undefined &&
      (obj.copd = message.copd ? Enrollment_MedicareInfo_COPD.toJSON(message.copd) : undefined);
    message.cvd !== undefined && (obj.cvd = message.cvd ? Enrollment_MedicareInfo_CVD.toJSON(message.cvd) : undefined);
    message.diabetes !== undefined &&
      (obj.diabetes = message.diabetes ? Enrollment_MedicareInfo_Diabetes.toJSON(message.diabetes) : undefined);
    message.pcp !== undefined && (obj.pcp = message.pcp ? Enrollment_MedicareInfo_PCP.toJSON(message.pcp) : undefined);
    message.otherDrugCoverage !== undefined && (obj.otherDrugCoverage = message.otherDrugCoverage
      ? Enrollment_MedicareInfo_OtherDrugCoverage.toJSON(message.otherDrugCoverage)
      : undefined);
    message.otherGroupCoverage !== undefined && (obj.otherGroupCoverage = message.otherGroupCoverage
      ? Enrollment_MedicareInfo_OtherGroupCoverage.toJSON(message.otherGroupCoverage)
      : undefined);
    message.otherMedicareCoverage !== undefined && (obj.otherMedicareCoverage = message.otherMedicareCoverage
      ? Enrollment_MedicareInfo_OtherMedicareCoverage.toJSON(message.otherMedicareCoverage)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<Enrollment_MedicareInfo>, I>>(base?: I): Enrollment_MedicareInfo {
    return Enrollment_MedicareInfo.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Enrollment_MedicareInfo>, I>>(object: I): Enrollment_MedicareInfo {
    const message = createBaseEnrollment_MedicareInfo();
    message.number = object.number ?? undefined;
    message.chf = (object.chf !== undefined && object.chf !== null)
      ? Enrollment_MedicareInfo_CHF.fromPartial(object.chf)
      : undefined;
    message.copd = (object.copd !== undefined && object.copd !== null)
      ? Enrollment_MedicareInfo_COPD.fromPartial(object.copd)
      : undefined;
    message.cvd = (object.cvd !== undefined && object.cvd !== null)
      ? Enrollment_MedicareInfo_CVD.fromPartial(object.cvd)
      : undefined;
    message.diabetes = (object.diabetes !== undefined && object.diabetes !== null)
      ? Enrollment_MedicareInfo_Diabetes.fromPartial(object.diabetes)
      : undefined;
    message.pcp = (object.pcp !== undefined && object.pcp !== null)
      ? Enrollment_MedicareInfo_PCP.fromPartial(object.pcp)
      : undefined;
    message.otherDrugCoverage = (object.otherDrugCoverage !== undefined && object.otherDrugCoverage !== null)
      ? Enrollment_MedicareInfo_OtherDrugCoverage.fromPartial(object.otherDrugCoverage)
      : undefined;
    message.otherGroupCoverage = (object.otherGroupCoverage !== undefined && object.otherGroupCoverage !== null)
      ? Enrollment_MedicareInfo_OtherGroupCoverage.fromPartial(object.otherGroupCoverage)
      : undefined;
    message.otherMedicareCoverage =
      (object.otherMedicareCoverage !== undefined && object.otherMedicareCoverage !== null)
        ? Enrollment_MedicareInfo_OtherMedicareCoverage.fromPartial(object.otherMedicareCoverage)
        : undefined;
    return message;
  },
};

function createBaseEnrollment_MedicareInfo_CHF(): Enrollment_MedicareInfo_CHF {
  return { has: undefined, hasBodyFluid: undefined, hasLungFluid: undefined };
}

export const Enrollment_MedicareInfo_CHF = {
  encode(message: Enrollment_MedicareInfo_CHF, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.has !== undefined) {
      writer.uint32(8).bool(message.has);
    }
    if (message.hasBodyFluid !== undefined) {
      writer.uint32(16).bool(message.hasBodyFluid);
    }
    if (message.hasLungFluid !== undefined) {
      writer.uint32(24).bool(message.hasLungFluid);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Enrollment_MedicareInfo_CHF {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollment_MedicareInfo_CHF();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.has = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.hasBodyFluid = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.hasLungFluid = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Enrollment_MedicareInfo_CHF {
    return {
      has: isSet(object.has) ? Boolean(object.has) : undefined,
      hasBodyFluid: isSet(object.hasBodyFluid) ? Boolean(object.hasBodyFluid) : undefined,
      hasLungFluid: isSet(object.hasLungFluid) ? Boolean(object.hasLungFluid) : undefined,
    };
  },

  toJSON(message: Enrollment_MedicareInfo_CHF): unknown {
    const obj: any = {};
    message.has !== undefined && (obj.has = message.has);
    message.hasBodyFluid !== undefined && (obj.hasBodyFluid = message.hasBodyFluid);
    message.hasLungFluid !== undefined && (obj.hasLungFluid = message.hasLungFluid);
    return obj;
  },

  create<I extends Exact<DeepPartial<Enrollment_MedicareInfo_CHF>, I>>(base?: I): Enrollment_MedicareInfo_CHF {
    return Enrollment_MedicareInfo_CHF.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Enrollment_MedicareInfo_CHF>, I>>(object: I): Enrollment_MedicareInfo_CHF {
    const message = createBaseEnrollment_MedicareInfo_CHF();
    message.has = object.has ?? undefined;
    message.hasBodyFluid = object.hasBodyFluid ?? undefined;
    message.hasLungFluid = object.hasLungFluid ?? undefined;
    return message;
  },
};

function createBaseEnrollment_MedicareInfo_COPD(): Enrollment_MedicareInfo_COPD {
  return {
    has: undefined,
    hasAsthma: undefined,
    hasDifficultyBreathing: undefined,
    hasEmphysema: undefined,
    needsOxygenSupport: undefined,
  };
}

export const Enrollment_MedicareInfo_COPD = {
  encode(message: Enrollment_MedicareInfo_COPD, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.has !== undefined) {
      writer.uint32(8).bool(message.has);
    }
    if (message.hasAsthma !== undefined) {
      writer.uint32(16).bool(message.hasAsthma);
    }
    if (message.hasDifficultyBreathing !== undefined) {
      writer.uint32(24).bool(message.hasDifficultyBreathing);
    }
    if (message.hasEmphysema !== undefined) {
      writer.uint32(32).bool(message.hasEmphysema);
    }
    if (message.needsOxygenSupport !== undefined) {
      writer.uint32(40).bool(message.needsOxygenSupport);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Enrollment_MedicareInfo_COPD {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollment_MedicareInfo_COPD();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.has = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.hasAsthma = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.hasDifficultyBreathing = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.hasEmphysema = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.needsOxygenSupport = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Enrollment_MedicareInfo_COPD {
    return {
      has: isSet(object.has) ? Boolean(object.has) : undefined,
      hasAsthma: isSet(object.hasAsthma) ? Boolean(object.hasAsthma) : undefined,
      hasDifficultyBreathing: isSet(object.hasDifficultyBreathing) ? Boolean(object.hasDifficultyBreathing) : undefined,
      hasEmphysema: isSet(object.hasEmphysema) ? Boolean(object.hasEmphysema) : undefined,
      needsOxygenSupport: isSet(object.needsOxygenSupport) ? Boolean(object.needsOxygenSupport) : undefined,
    };
  },

  toJSON(message: Enrollment_MedicareInfo_COPD): unknown {
    const obj: any = {};
    message.has !== undefined && (obj.has = message.has);
    message.hasAsthma !== undefined && (obj.hasAsthma = message.hasAsthma);
    message.hasDifficultyBreathing !== undefined && (obj.hasDifficultyBreathing = message.hasDifficultyBreathing);
    message.hasEmphysema !== undefined && (obj.hasEmphysema = message.hasEmphysema);
    message.needsOxygenSupport !== undefined && (obj.needsOxygenSupport = message.needsOxygenSupport);
    return obj;
  },

  create<I extends Exact<DeepPartial<Enrollment_MedicareInfo_COPD>, I>>(base?: I): Enrollment_MedicareInfo_COPD {
    return Enrollment_MedicareInfo_COPD.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Enrollment_MedicareInfo_COPD>, I>>(object: I): Enrollment_MedicareInfo_COPD {
    const message = createBaseEnrollment_MedicareInfo_COPD();
    message.has = object.has ?? undefined;
    message.hasAsthma = object.hasAsthma ?? undefined;
    message.hasDifficultyBreathing = object.hasDifficultyBreathing ?? undefined;
    message.hasEmphysema = object.hasEmphysema ?? undefined;
    message.needsOxygenSupport = object.needsOxygenSupport ?? undefined;
    return message;
  },
};

function createBaseEnrollment_MedicareInfo_CVD(): Enrollment_MedicareInfo_CVD {
  return { has: undefined, hadHeartAttack: undefined, hasChestLegPain: undefined, needsDrugsHeart: undefined };
}

export const Enrollment_MedicareInfo_CVD = {
  encode(message: Enrollment_MedicareInfo_CVD, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.has !== undefined) {
      writer.uint32(8).bool(message.has);
    }
    if (message.hadHeartAttack !== undefined) {
      writer.uint32(16).bool(message.hadHeartAttack);
    }
    if (message.hasChestLegPain !== undefined) {
      writer.uint32(24).bool(message.hasChestLegPain);
    }
    if (message.needsDrugsHeart !== undefined) {
      writer.uint32(32).bool(message.needsDrugsHeart);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Enrollment_MedicareInfo_CVD {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollment_MedicareInfo_CVD();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.has = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.hadHeartAttack = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.hasChestLegPain = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.needsDrugsHeart = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Enrollment_MedicareInfo_CVD {
    return {
      has: isSet(object.has) ? Boolean(object.has) : undefined,
      hadHeartAttack: isSet(object.hadHeartAttack) ? Boolean(object.hadHeartAttack) : undefined,
      hasChestLegPain: isSet(object.hasChestLegPain) ? Boolean(object.hasChestLegPain) : undefined,
      needsDrugsHeart: isSet(object.needsDrugsHeart) ? Boolean(object.needsDrugsHeart) : undefined,
    };
  },

  toJSON(message: Enrollment_MedicareInfo_CVD): unknown {
    const obj: any = {};
    message.has !== undefined && (obj.has = message.has);
    message.hadHeartAttack !== undefined && (obj.hadHeartAttack = message.hadHeartAttack);
    message.hasChestLegPain !== undefined && (obj.hasChestLegPain = message.hasChestLegPain);
    message.needsDrugsHeart !== undefined && (obj.needsDrugsHeart = message.needsDrugsHeart);
    return obj;
  },

  create<I extends Exact<DeepPartial<Enrollment_MedicareInfo_CVD>, I>>(base?: I): Enrollment_MedicareInfo_CVD {
    return Enrollment_MedicareInfo_CVD.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Enrollment_MedicareInfo_CVD>, I>>(object: I): Enrollment_MedicareInfo_CVD {
    const message = createBaseEnrollment_MedicareInfo_CVD();
    message.has = object.has ?? undefined;
    message.hadHeartAttack = object.hadHeartAttack ?? undefined;
    message.hasChestLegPain = object.hasChestLegPain ?? undefined;
    message.needsDrugsHeart = object.needsDrugsHeart ?? undefined;
    return message;
  },
};

function createBaseEnrollment_MedicareInfo_Diabetes(): Enrollment_MedicareInfo_Diabetes {
  return { has: undefined, checksBloodSugar: undefined, hasHighBloodSugar: undefined, drugsBloodSugar: undefined };
}

export const Enrollment_MedicareInfo_Diabetes = {
  encode(message: Enrollment_MedicareInfo_Diabetes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.has !== undefined) {
      writer.uint32(8).bool(message.has);
    }
    if (message.checksBloodSugar !== undefined) {
      writer.uint32(16).bool(message.checksBloodSugar);
    }
    if (message.hasHighBloodSugar !== undefined) {
      writer.uint32(24).bool(message.hasHighBloodSugar);
    }
    if (message.drugsBloodSugar !== undefined) {
      writer.uint32(32).bool(message.drugsBloodSugar);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Enrollment_MedicareInfo_Diabetes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollment_MedicareInfo_Diabetes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.has = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.checksBloodSugar = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.hasHighBloodSugar = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.drugsBloodSugar = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Enrollment_MedicareInfo_Diabetes {
    return {
      has: isSet(object.has) ? Boolean(object.has) : undefined,
      checksBloodSugar: isSet(object.checksBloodSugar) ? Boolean(object.checksBloodSugar) : undefined,
      hasHighBloodSugar: isSet(object.hasHighBloodSugar) ? Boolean(object.hasHighBloodSugar) : undefined,
      drugsBloodSugar: isSet(object.drugsBloodSugar) ? Boolean(object.drugsBloodSugar) : undefined,
    };
  },

  toJSON(message: Enrollment_MedicareInfo_Diabetes): unknown {
    const obj: any = {};
    message.has !== undefined && (obj.has = message.has);
    message.checksBloodSugar !== undefined && (obj.checksBloodSugar = message.checksBloodSugar);
    message.hasHighBloodSugar !== undefined && (obj.hasHighBloodSugar = message.hasHighBloodSugar);
    message.drugsBloodSugar !== undefined && (obj.drugsBloodSugar = message.drugsBloodSugar);
    return obj;
  },

  create<I extends Exact<DeepPartial<Enrollment_MedicareInfo_Diabetes>, I>>(
    base?: I,
  ): Enrollment_MedicareInfo_Diabetes {
    return Enrollment_MedicareInfo_Diabetes.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Enrollment_MedicareInfo_Diabetes>, I>>(
    object: I,
  ): Enrollment_MedicareInfo_Diabetes {
    const message = createBaseEnrollment_MedicareInfo_Diabetes();
    message.has = object.has ?? undefined;
    message.checksBloodSugar = object.checksBloodSugar ?? undefined;
    message.hasHighBloodSugar = object.hasHighBloodSugar ?? undefined;
    message.drugsBloodSugar = object.drugsBloodSugar ?? undefined;
    return message;
  },
};

function createBaseEnrollment_MedicareInfo_PCP(): Enrollment_MedicareInfo_PCP {
  return { wishDesignatePCP: undefined, isEstablishedPatient: undefined, id: undefined, name: undefined };
}

export const Enrollment_MedicareInfo_PCP = {
  encode(message: Enrollment_MedicareInfo_PCP, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.wishDesignatePCP !== undefined) {
      writer.uint32(8).bool(message.wishDesignatePCP);
    }
    if (message.isEstablishedPatient !== undefined) {
      writer.uint32(16).bool(message.isEstablishedPatient);
    }
    if (message.id !== undefined) {
      writer.uint32(26).string(message.id);
    }
    if (message.name !== undefined) {
      writer.uint32(34).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Enrollment_MedicareInfo_PCP {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollment_MedicareInfo_PCP();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.wishDesignatePCP = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isEstablishedPatient = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.id = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Enrollment_MedicareInfo_PCP {
    return {
      wishDesignatePCP: isSet(object.wishDesignatePCP) ? Boolean(object.wishDesignatePCP) : undefined,
      isEstablishedPatient: isSet(object.isEstablishedPatient) ? Boolean(object.isEstablishedPatient) : undefined,
      id: isSet(object.id) ? String(object.id) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
    };
  },

  toJSON(message: Enrollment_MedicareInfo_PCP): unknown {
    const obj: any = {};
    message.wishDesignatePCP !== undefined && (obj.wishDesignatePCP = message.wishDesignatePCP);
    message.isEstablishedPatient !== undefined && (obj.isEstablishedPatient = message.isEstablishedPatient);
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  create<I extends Exact<DeepPartial<Enrollment_MedicareInfo_PCP>, I>>(base?: I): Enrollment_MedicareInfo_PCP {
    return Enrollment_MedicareInfo_PCP.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Enrollment_MedicareInfo_PCP>, I>>(object: I): Enrollment_MedicareInfo_PCP {
    const message = createBaseEnrollment_MedicareInfo_PCP();
    message.wishDesignatePCP = object.wishDesignatePCP ?? undefined;
    message.isEstablishedPatient = object.isEstablishedPatient ?? undefined;
    message.id = object.id ?? undefined;
    message.name = object.name ?? undefined;
    return message;
  },
};

function createBaseEnrollment_MedicareInfo_OtherDrugCoverage(): Enrollment_MedicareInfo_OtherDrugCoverage {
  return { has: undefined, memberId: undefined, memberName: undefined, policyId: undefined };
}

export const Enrollment_MedicareInfo_OtherDrugCoverage = {
  encode(message: Enrollment_MedicareInfo_OtherDrugCoverage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.has !== undefined) {
      writer.uint32(8).bool(message.has);
    }
    if (message.memberId !== undefined) {
      writer.uint32(18).string(message.memberId);
    }
    if (message.memberName !== undefined) {
      writer.uint32(26).string(message.memberName);
    }
    if (message.policyId !== undefined) {
      writer.uint32(34).string(message.policyId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Enrollment_MedicareInfo_OtherDrugCoverage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollment_MedicareInfo_OtherDrugCoverage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.has = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.memberId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.memberName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.policyId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Enrollment_MedicareInfo_OtherDrugCoverage {
    return {
      has: isSet(object.has) ? Boolean(object.has) : undefined,
      memberId: isSet(object.memberId) ? String(object.memberId) : undefined,
      memberName: isSet(object.memberName) ? String(object.memberName) : undefined,
      policyId: isSet(object.policyId) ? String(object.policyId) : undefined,
    };
  },

  toJSON(message: Enrollment_MedicareInfo_OtherDrugCoverage): unknown {
    const obj: any = {};
    message.has !== undefined && (obj.has = message.has);
    message.memberId !== undefined && (obj.memberId = message.memberId);
    message.memberName !== undefined && (obj.memberName = message.memberName);
    message.policyId !== undefined && (obj.policyId = message.policyId);
    return obj;
  },

  create<I extends Exact<DeepPartial<Enrollment_MedicareInfo_OtherDrugCoverage>, I>>(
    base?: I,
  ): Enrollment_MedicareInfo_OtherDrugCoverage {
    return Enrollment_MedicareInfo_OtherDrugCoverage.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Enrollment_MedicareInfo_OtherDrugCoverage>, I>>(
    object: I,
  ): Enrollment_MedicareInfo_OtherDrugCoverage {
    const message = createBaseEnrollment_MedicareInfo_OtherDrugCoverage();
    message.has = object.has ?? undefined;
    message.memberId = object.memberId ?? undefined;
    message.memberName = object.memberName ?? undefined;
    message.policyId = object.policyId ?? undefined;
    return message;
  },
};

function createBaseEnrollment_MedicareInfo_OtherGroupCoverage(): Enrollment_MedicareInfo_OtherGroupCoverage {
  return { has: undefined };
}

export const Enrollment_MedicareInfo_OtherGroupCoverage = {
  encode(message: Enrollment_MedicareInfo_OtherGroupCoverage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.has !== undefined) {
      writer.uint32(8).bool(message.has);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Enrollment_MedicareInfo_OtherGroupCoverage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollment_MedicareInfo_OtherGroupCoverage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.has = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Enrollment_MedicareInfo_OtherGroupCoverage {
    return { has: isSet(object.has) ? Boolean(object.has) : undefined };
  },

  toJSON(message: Enrollment_MedicareInfo_OtherGroupCoverage): unknown {
    const obj: any = {};
    message.has !== undefined && (obj.has = message.has);
    return obj;
  },

  create<I extends Exact<DeepPartial<Enrollment_MedicareInfo_OtherGroupCoverage>, I>>(
    base?: I,
  ): Enrollment_MedicareInfo_OtherGroupCoverage {
    return Enrollment_MedicareInfo_OtherGroupCoverage.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Enrollment_MedicareInfo_OtherGroupCoverage>, I>>(
    object: I,
  ): Enrollment_MedicareInfo_OtherGroupCoverage {
    const message = createBaseEnrollment_MedicareInfo_OtherGroupCoverage();
    message.has = object.has ?? undefined;
    return message;
  },
};

function createBaseEnrollment_MedicareInfo_OtherMedicareCoverage(): Enrollment_MedicareInfo_OtherMedicareCoverage {
  return { has: undefined, startDate: undefined, endDate: undefined, policyId: undefined };
}

export const Enrollment_MedicareInfo_OtherMedicareCoverage = {
  encode(message: Enrollment_MedicareInfo_OtherMedicareCoverage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.has !== undefined) {
      writer.uint32(8).bool(message.has);
    }
    if (message.startDate !== undefined) {
      DateContainer.encode(message.startDate, writer.uint32(18).fork()).ldelim();
    }
    if (message.endDate !== undefined) {
      DateContainer.encode(message.endDate, writer.uint32(26).fork()).ldelim();
    }
    if (message.policyId !== undefined) {
      writer.uint32(58).string(message.policyId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Enrollment_MedicareInfo_OtherMedicareCoverage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollment_MedicareInfo_OtherMedicareCoverage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.has = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.startDate = DateContainer.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.endDate = DateContainer.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.policyId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Enrollment_MedicareInfo_OtherMedicareCoverage {
    return {
      has: isSet(object.has) ? Boolean(object.has) : undefined,
      startDate: isSet(object.startDate) ? DateContainer.fromJSON(object.startDate) : undefined,
      endDate: isSet(object.endDate) ? DateContainer.fromJSON(object.endDate) : undefined,
      policyId: isSet(object.policyId) ? String(object.policyId) : undefined,
    };
  },

  toJSON(message: Enrollment_MedicareInfo_OtherMedicareCoverage): unknown {
    const obj: any = {};
    message.has !== undefined && (obj.has = message.has);
    message.startDate !== undefined &&
      (obj.startDate = message.startDate ? DateContainer.toJSON(message.startDate) : undefined);
    message.endDate !== undefined &&
      (obj.endDate = message.endDate ? DateContainer.toJSON(message.endDate) : undefined);
    message.policyId !== undefined && (obj.policyId = message.policyId);
    return obj;
  },

  create<I extends Exact<DeepPartial<Enrollment_MedicareInfo_OtherMedicareCoverage>, I>>(
    base?: I,
  ): Enrollment_MedicareInfo_OtherMedicareCoverage {
    return Enrollment_MedicareInfo_OtherMedicareCoverage.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Enrollment_MedicareInfo_OtherMedicareCoverage>, I>>(
    object: I,
  ): Enrollment_MedicareInfo_OtherMedicareCoverage {
    const message = createBaseEnrollment_MedicareInfo_OtherMedicareCoverage();
    message.has = object.has ?? undefined;
    message.startDate = (object.startDate !== undefined && object.startDate !== null)
      ? DateContainer.fromPartial(object.startDate)
      : undefined;
    message.endDate = (object.endDate !== undefined && object.endDate !== null)
      ? DateContainer.fromPartial(object.endDate)
      : undefined;
    message.policyId = object.policyId ?? undefined;
    return message;
  },
};

function createBaseEnrollment_MedicaidInfo(): Enrollment_MedicaidInfo {
  return { has: undefined, number: undefined };
}

export const Enrollment_MedicaidInfo = {
  encode(message: Enrollment_MedicaidInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.has !== undefined) {
      writer.uint32(8).bool(message.has);
    }
    if (message.number !== undefined) {
      writer.uint32(18).string(message.number);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Enrollment_MedicaidInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollment_MedicaidInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.has = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.number = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Enrollment_MedicaidInfo {
    return {
      has: isSet(object.has) ? Boolean(object.has) : undefined,
      number: isSet(object.number) ? String(object.number) : undefined,
    };
  },

  toJSON(message: Enrollment_MedicaidInfo): unknown {
    const obj: any = {};
    message.has !== undefined && (obj.has = message.has);
    message.number !== undefined && (obj.number = message.number);
    return obj;
  },

  create<I extends Exact<DeepPartial<Enrollment_MedicaidInfo>, I>>(base?: I): Enrollment_MedicaidInfo {
    return Enrollment_MedicaidInfo.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Enrollment_MedicaidInfo>, I>>(object: I): Enrollment_MedicaidInfo {
    const message = createBaseEnrollment_MedicaidInfo();
    message.has = object.has ?? undefined;
    message.number = object.number ?? undefined;
    return message;
  },
};

function createBaseEnrollment_ReviewSubmit(): Enrollment_ReviewSubmit {
  return { id: "", signatureType: 0 };
}

export const Enrollment_ReviewSubmit = {
  encode(message: Enrollment_ReviewSubmit, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.signatureType !== 0) {
      writer.uint32(16).int32(message.signatureType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Enrollment_ReviewSubmit {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollment_ReviewSubmit();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.signatureType = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Enrollment_ReviewSubmit {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      signatureType: isSet(object.signatureType) ? enrollment_EnrollmentSignatureTypeFromJSON(object.signatureType) : 0,
    };
  },

  toJSON(message: Enrollment_ReviewSubmit): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.signatureType !== undefined &&
      (obj.signatureType = enrollment_EnrollmentSignatureTypeToJSON(message.signatureType));
    return obj;
  },

  create<I extends Exact<DeepPartial<Enrollment_ReviewSubmit>, I>>(base?: I): Enrollment_ReviewSubmit {
    return Enrollment_ReviewSubmit.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Enrollment_ReviewSubmit>, I>>(object: I): Enrollment_ReviewSubmit {
    const message = createBaseEnrollment_ReviewSubmit();
    message.id = object.id ?? "";
    message.signatureType = object.signatureType ?? 0;
    return message;
  },
};

function createBaseEnrollment_CompletedSteps(): Enrollment_CompletedSteps {
  return {
    prerequisites: undefined,
    applicantInfo: undefined,
    medicareInfo: undefined,
    planInfo: undefined,
    reviewSubmit: undefined,
  };
}

export const Enrollment_CompletedSteps = {
  encode(message: Enrollment_CompletedSteps, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.prerequisites !== undefined) {
      writer.uint32(8).bool(message.prerequisites);
    }
    if (message.applicantInfo !== undefined) {
      writer.uint32(16).bool(message.applicantInfo);
    }
    if (message.medicareInfo !== undefined) {
      writer.uint32(24).bool(message.medicareInfo);
    }
    if (message.planInfo !== undefined) {
      writer.uint32(32).bool(message.planInfo);
    }
    if (message.reviewSubmit !== undefined) {
      writer.uint32(40).bool(message.reviewSubmit);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Enrollment_CompletedSteps {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollment_CompletedSteps();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.prerequisites = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.applicantInfo = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.medicareInfo = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.planInfo = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.reviewSubmit = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Enrollment_CompletedSteps {
    return {
      prerequisites: isSet(object.prerequisites) ? Boolean(object.prerequisites) : undefined,
      applicantInfo: isSet(object.applicantInfo) ? Boolean(object.applicantInfo) : undefined,
      medicareInfo: isSet(object.medicareInfo) ? Boolean(object.medicareInfo) : undefined,
      planInfo: isSet(object.planInfo) ? Boolean(object.planInfo) : undefined,
      reviewSubmit: isSet(object.reviewSubmit) ? Boolean(object.reviewSubmit) : undefined,
    };
  },

  toJSON(message: Enrollment_CompletedSteps): unknown {
    const obj: any = {};
    message.prerequisites !== undefined && (obj.prerequisites = message.prerequisites);
    message.applicantInfo !== undefined && (obj.applicantInfo = message.applicantInfo);
    message.medicareInfo !== undefined && (obj.medicareInfo = message.medicareInfo);
    message.planInfo !== undefined && (obj.planInfo = message.planInfo);
    message.reviewSubmit !== undefined && (obj.reviewSubmit = message.reviewSubmit);
    return obj;
  },

  create<I extends Exact<DeepPartial<Enrollment_CompletedSteps>, I>>(base?: I): Enrollment_CompletedSteps {
    return Enrollment_CompletedSteps.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Enrollment_CompletedSteps>, I>>(object: I): Enrollment_CompletedSteps {
    const message = createBaseEnrollment_CompletedSteps();
    message.prerequisites = object.prerequisites ?? undefined;
    message.applicantInfo = object.applicantInfo ?? undefined;
    message.medicareInfo = object.medicareInfo ?? undefined;
    message.planInfo = object.planInfo ?? undefined;
    message.reviewSubmit = object.reviewSubmit ?? undefined;
    return message;
  },
};

function createBaseEnrollment_Metadata(): Enrollment_Metadata {
  return { webhookReceived: undefined, webhookSource: undefined };
}

export const Enrollment_Metadata = {
  encode(message: Enrollment_Metadata, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.webhookReceived !== undefined) {
      DateContainer.encode(message.webhookReceived, writer.uint32(10).fork()).ldelim();
    }
    if (message.webhookSource !== undefined) {
      writer.uint32(18).string(message.webhookSource);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Enrollment_Metadata {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEnrollment_Metadata();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.webhookReceived = DateContainer.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.webhookSource = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Enrollment_Metadata {
    return {
      webhookReceived: isSet(object.webhookReceived) ? DateContainer.fromJSON(object.webhookReceived) : undefined,
      webhookSource: isSet(object.webhookSource) ? String(object.webhookSource) : undefined,
    };
  },

  toJSON(message: Enrollment_Metadata): unknown {
    const obj: any = {};
    message.webhookReceived !== undefined &&
      (obj.webhookReceived = message.webhookReceived ? DateContainer.toJSON(message.webhookReceived) : undefined);
    message.webhookSource !== undefined && (obj.webhookSource = message.webhookSource);
    return obj;
  },

  create<I extends Exact<DeepPartial<Enrollment_Metadata>, I>>(base?: I): Enrollment_Metadata {
    return Enrollment_Metadata.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Enrollment_Metadata>, I>>(object: I): Enrollment_Metadata {
    const message = createBaseEnrollment_Metadata();
    message.webhookReceived = (object.webhookReceived !== undefined && object.webhookReceived !== null)
      ? DateContainer.fromPartial(object.webhookReceived)
      : undefined;
    message.webhookSource = object.webhookSource ?? undefined;
    return message;
  },
};

function createBaseDoctor(): Doctor {
  return {
    id: "",
    npi: undefined,
    pacId: undefined,
    clinicianEnrollmentId: undefined,
    lastName: undefined,
    firstName: undefined,
    middleName: undefined,
    suffix: undefined,
    gender: undefined,
    credential: undefined,
    medSchool: undefined,
    graduationYear: undefined,
    organisations: [],
    zip: [],
    loading: undefined,
  };
}

export const Doctor = {
  encode(message: Doctor, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.npi !== undefined) {
      writer.uint32(18).string(message.npi);
    }
    if (message.pacId !== undefined) {
      writer.uint32(26).string(message.pacId);
    }
    if (message.clinicianEnrollmentId !== undefined) {
      writer.uint32(34).string(message.clinicianEnrollmentId);
    }
    if (message.lastName !== undefined) {
      writer.uint32(42).string(message.lastName);
    }
    if (message.firstName !== undefined) {
      writer.uint32(50).string(message.firstName);
    }
    if (message.middleName !== undefined) {
      writer.uint32(58).string(message.middleName);
    }
    if (message.suffix !== undefined) {
      writer.uint32(66).string(message.suffix);
    }
    if (message.gender !== undefined) {
      writer.uint32(74).string(message.gender);
    }
    if (message.credential !== undefined) {
      writer.uint32(82).string(message.credential);
    }
    if (message.medSchool !== undefined) {
      writer.uint32(90).string(message.medSchool);
    }
    if (message.graduationYear !== undefined) {
      writer.uint32(98).string(message.graduationYear);
    }
    for (const v of message.organisations) {
      Doctor_Organisation.encode(v!, writer.uint32(106).fork()).ldelim();
    }
    for (const v of message.zip) {
      writer.uint32(114).string(v!);
    }
    if (message.loading !== undefined) {
      writer.uint32(120).bool(message.loading);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Doctor {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDoctor();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.npi = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pacId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.clinicianEnrollmentId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.lastName = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.middleName = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.suffix = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.gender = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.credential = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.medSchool = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.graduationYear = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.organisations.push(Doctor_Organisation.decode(reader, reader.uint32()));
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.zip.push(reader.string());
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.loading = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Doctor {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      npi: isSet(object.npi) ? String(object.npi) : undefined,
      pacId: isSet(object.pacId) ? String(object.pacId) : undefined,
      clinicianEnrollmentId: isSet(object.clinicianEnrollmentId) ? String(object.clinicianEnrollmentId) : undefined,
      lastName: isSet(object.lastName) ? String(object.lastName) : undefined,
      firstName: isSet(object.firstName) ? String(object.firstName) : undefined,
      middleName: isSet(object.middleName) ? String(object.middleName) : undefined,
      suffix: isSet(object.suffix) ? String(object.suffix) : undefined,
      gender: isSet(object.gender) ? String(object.gender) : undefined,
      credential: isSet(object.credential) ? String(object.credential) : undefined,
      medSchool: isSet(object.medSchool) ? String(object.medSchool) : undefined,
      graduationYear: isSet(object.graduationYear) ? String(object.graduationYear) : undefined,
      organisations: Array.isArray(object?.organisations)
        ? object.organisations.map((e: any) => Doctor_Organisation.fromJSON(e))
        : [],
      zip: Array.isArray(object?.zip) ? object.zip.map((e: any) => String(e)) : [],
      loading: isSet(object.loading) ? Boolean(object.loading) : undefined,
    };
  },

  toJSON(message: Doctor): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.npi !== undefined && (obj.npi = message.npi);
    message.pacId !== undefined && (obj.pacId = message.pacId);
    message.clinicianEnrollmentId !== undefined && (obj.clinicianEnrollmentId = message.clinicianEnrollmentId);
    message.lastName !== undefined && (obj.lastName = message.lastName);
    message.firstName !== undefined && (obj.firstName = message.firstName);
    message.middleName !== undefined && (obj.middleName = message.middleName);
    message.suffix !== undefined && (obj.suffix = message.suffix);
    message.gender !== undefined && (obj.gender = message.gender);
    message.credential !== undefined && (obj.credential = message.credential);
    message.medSchool !== undefined && (obj.medSchool = message.medSchool);
    message.graduationYear !== undefined && (obj.graduationYear = message.graduationYear);
    if (message.organisations) {
      obj.organisations = message.organisations.map((e) => e ? Doctor_Organisation.toJSON(e) : undefined);
    } else {
      obj.organisations = [];
    }
    if (message.zip) {
      obj.zip = message.zip.map((e) => e);
    } else {
      obj.zip = [];
    }
    message.loading !== undefined && (obj.loading = message.loading);
    return obj;
  },

  create<I extends Exact<DeepPartial<Doctor>, I>>(base?: I): Doctor {
    return Doctor.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Doctor>, I>>(object: I): Doctor {
    const message = createBaseDoctor();
    message.id = object.id ?? "";
    message.npi = object.npi ?? undefined;
    message.pacId = object.pacId ?? undefined;
    message.clinicianEnrollmentId = object.clinicianEnrollmentId ?? undefined;
    message.lastName = object.lastName ?? undefined;
    message.firstName = object.firstName ?? undefined;
    message.middleName = object.middleName ?? undefined;
    message.suffix = object.suffix ?? undefined;
    message.gender = object.gender ?? undefined;
    message.credential = object.credential ?? undefined;
    message.medSchool = object.medSchool ?? undefined;
    message.graduationYear = object.graduationYear ?? undefined;
    message.organisations = object.organisations?.map((e) => Doctor_Organisation.fromPartial(e)) || [];
    message.zip = object.zip?.map((e) => e) || [];
    message.loading = object.loading ?? undefined;
    return message;
  },
};

function createBaseDoctor_Organisation(): Doctor_Organisation {
  return {
    primarySpecialty: undefined,
    secondarySpecialties: [],
    secondarySpecialtiesAll: undefined,
    telehealth: undefined,
    name: undefined,
    pacId: undefined,
    numOrgMem: undefined,
    markerAddressLine2Suppression: undefined,
    zip9: undefined,
    phone: undefined,
    clinicianAcceptsMedicareAssignment: undefined,
    groupAcceptsMedicareAssignment: undefined,
    addressId: undefined,
    address: undefined,
  };
}

export const Doctor_Organisation = {
  encode(message: Doctor_Organisation, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.primarySpecialty !== undefined) {
      writer.uint32(10).string(message.primarySpecialty);
    }
    for (const v of message.secondarySpecialties) {
      writer.uint32(18).string(v!);
    }
    if (message.secondarySpecialtiesAll !== undefined) {
      writer.uint32(26).string(message.secondarySpecialtiesAll);
    }
    if (message.telehealth !== undefined) {
      writer.uint32(32).bool(message.telehealth);
    }
    if (message.name !== undefined) {
      writer.uint32(42).string(message.name);
    }
    if (message.pacId !== undefined) {
      writer.uint32(50).string(message.pacId);
    }
    if (message.numOrgMem !== undefined) {
      writer.uint32(58).string(message.numOrgMem);
    }
    if (message.markerAddressLine2Suppression !== undefined) {
      writer.uint32(66).string(message.markerAddressLine2Suppression);
    }
    if (message.zip9 !== undefined) {
      writer.uint32(74).string(message.zip9);
    }
    if (message.phone !== undefined) {
      writer.uint32(82).string(message.phone);
    }
    if (message.clinicianAcceptsMedicareAssignment !== undefined) {
      writer.uint32(90).string(message.clinicianAcceptsMedicareAssignment);
    }
    if (message.groupAcceptsMedicareAssignment !== undefined) {
      writer.uint32(98).string(message.groupAcceptsMedicareAssignment);
    }
    if (message.addressId !== undefined) {
      writer.uint32(106).string(message.addressId);
    }
    if (message.address !== undefined) {
      Address.encode(message.address, writer.uint32(114).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Doctor_Organisation {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDoctor_Organisation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.primarySpecialty = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.secondarySpecialties.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.secondarySpecialtiesAll = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.telehealth = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.name = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.pacId = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.numOrgMem = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.markerAddressLine2Suppression = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.zip9 = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.phone = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.clinicianAcceptsMedicareAssignment = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.groupAcceptsMedicareAssignment = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.addressId = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.address = Address.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Doctor_Organisation {
    return {
      primarySpecialty: isSet(object.primarySpecialty) ? String(object.primarySpecialty) : undefined,
      secondarySpecialties: Array.isArray(object?.secondarySpecialties)
        ? object.secondarySpecialties.map((e: any) => String(e))
        : [],
      secondarySpecialtiesAll: isSet(object.secondarySpecialtiesAll)
        ? String(object.secondarySpecialtiesAll)
        : undefined,
      telehealth: isSet(object.telehealth) ? Boolean(object.telehealth) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      pacId: isSet(object.pacId) ? String(object.pacId) : undefined,
      numOrgMem: isSet(object.numOrgMem) ? String(object.numOrgMem) : undefined,
      markerAddressLine2Suppression: isSet(object.markerAddressLine2Suppression)
        ? String(object.markerAddressLine2Suppression)
        : undefined,
      zip9: isSet(object.zip9) ? String(object.zip9) : undefined,
      phone: isSet(object.phone) ? String(object.phone) : undefined,
      clinicianAcceptsMedicareAssignment: isSet(object.clinicianAcceptsMedicareAssignment)
        ? String(object.clinicianAcceptsMedicareAssignment)
        : undefined,
      groupAcceptsMedicareAssignment: isSet(object.groupAcceptsMedicareAssignment)
        ? String(object.groupAcceptsMedicareAssignment)
        : undefined,
      addressId: isSet(object.addressId) ? String(object.addressId) : undefined,
      address: isSet(object.address) ? Address.fromJSON(object.address) : undefined,
    };
  },

  toJSON(message: Doctor_Organisation): unknown {
    const obj: any = {};
    message.primarySpecialty !== undefined && (obj.primarySpecialty = message.primarySpecialty);
    if (message.secondarySpecialties) {
      obj.secondarySpecialties = message.secondarySpecialties.map((e) => e);
    } else {
      obj.secondarySpecialties = [];
    }
    message.secondarySpecialtiesAll !== undefined && (obj.secondarySpecialtiesAll = message.secondarySpecialtiesAll);
    message.telehealth !== undefined && (obj.telehealth = message.telehealth);
    message.name !== undefined && (obj.name = message.name);
    message.pacId !== undefined && (obj.pacId = message.pacId);
    message.numOrgMem !== undefined && (obj.numOrgMem = message.numOrgMem);
    message.markerAddressLine2Suppression !== undefined &&
      (obj.markerAddressLine2Suppression = message.markerAddressLine2Suppression);
    message.zip9 !== undefined && (obj.zip9 = message.zip9);
    message.phone !== undefined && (obj.phone = message.phone);
    message.clinicianAcceptsMedicareAssignment !== undefined &&
      (obj.clinicianAcceptsMedicareAssignment = message.clinicianAcceptsMedicareAssignment);
    message.groupAcceptsMedicareAssignment !== undefined &&
      (obj.groupAcceptsMedicareAssignment = message.groupAcceptsMedicareAssignment);
    message.addressId !== undefined && (obj.addressId = message.addressId);
    message.address !== undefined && (obj.address = message.address ? Address.toJSON(message.address) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<Doctor_Organisation>, I>>(base?: I): Doctor_Organisation {
    return Doctor_Organisation.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Doctor_Organisation>, I>>(object: I): Doctor_Organisation {
    const message = createBaseDoctor_Organisation();
    message.primarySpecialty = object.primarySpecialty ?? undefined;
    message.secondarySpecialties = object.secondarySpecialties?.map((e) => e) || [];
    message.secondarySpecialtiesAll = object.secondarySpecialtiesAll ?? undefined;
    message.telehealth = object.telehealth ?? undefined;
    message.name = object.name ?? undefined;
    message.pacId = object.pacId ?? undefined;
    message.numOrgMem = object.numOrgMem ?? undefined;
    message.markerAddressLine2Suppression = object.markerAddressLine2Suppression ?? undefined;
    message.zip9 = object.zip9 ?? undefined;
    message.phone = object.phone ?? undefined;
    message.clinicianAcceptsMedicareAssignment = object.clinicianAcceptsMedicareAssignment ?? undefined;
    message.groupAcceptsMedicareAssignment = object.groupAcceptsMedicareAssignment ?? undefined;
    message.addressId = object.addressId ?? undefined;
    message.address = (object.address !== undefined && object.address !== null)
      ? Address.fromPartial(object.address)
      : undefined;
    return message;
  },
};

function createBaseMedicarePlan(): MedicarePlan {
  return {
    id: undefined,
    name: undefined,
    carrier: undefined,
    policyType: undefined,
    planType: undefined,
    snp: undefined,
    category: undefined,
    year: undefined,
  };
}

export const MedicarePlan = {
  encode(message: MedicarePlan, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.carrier !== undefined) {
      Carrier.encode(message.carrier, writer.uint32(26).fork()).ldelim();
    }
    if (message.policyType !== undefined) {
      writer.uint32(32).int32(message.policyType);
    }
    if (message.planType !== undefined) {
      writer.uint32(40).int32(message.planType);
    }
    if (message.snp !== undefined) {
      writer.uint32(48).int32(message.snp);
    }
    if (message.category !== undefined) {
      writer.uint32(56).int32(message.category);
    }
    if (message.year !== undefined) {
      writer.uint32(66).string(message.year);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MedicarePlan {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMedicarePlan();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.carrier = Carrier.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.policyType = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.planType = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.snp = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.category = reader.int32() as any;
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.year = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MedicarePlan {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      carrier: isSet(object.carrier) ? Carrier.fromJSON(object.carrier) : undefined,
      policyType: isSet(object.policyType) ? medicarePlan_PolicyTypeFromJSON(object.policyType) : undefined,
      planType: isSet(object.planType) ? medicarePlan_PlanTypeFromJSON(object.planType) : undefined,
      snp: isSet(object.snp) ? medicarePlan_SNPFromJSON(object.snp) : undefined,
      category: isSet(object.category) ? planCategoryFromJSON(object.category) : undefined,
      year: isSet(object.year) ? String(object.year) : undefined,
    };
  },

  toJSON(message: MedicarePlan): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.carrier !== undefined && (obj.carrier = message.carrier ? Carrier.toJSON(message.carrier) : undefined);
    message.policyType !== undefined &&
      (obj.policyType = message.policyType !== undefined
        ? medicarePlan_PolicyTypeToJSON(message.policyType)
        : undefined);
    message.planType !== undefined &&
      (obj.planType = message.planType !== undefined ? medicarePlan_PlanTypeToJSON(message.planType) : undefined);
    message.snp !== undefined &&
      (obj.snp = message.snp !== undefined ? medicarePlan_SNPToJSON(message.snp) : undefined);
    message.category !== undefined &&
      (obj.category = message.category !== undefined ? planCategoryToJSON(message.category) : undefined);
    message.year !== undefined && (obj.year = message.year);
    return obj;
  },

  create<I extends Exact<DeepPartial<MedicarePlan>, I>>(base?: I): MedicarePlan {
    return MedicarePlan.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MedicarePlan>, I>>(object: I): MedicarePlan {
    const message = createBaseMedicarePlan();
    message.id = object.id ?? undefined;
    message.name = object.name ?? undefined;
    message.carrier = (object.carrier !== undefined && object.carrier !== null)
      ? Carrier.fromPartial(object.carrier)
      : undefined;
    message.policyType = object.policyType ?? undefined;
    message.planType = object.planType ?? undefined;
    message.snp = object.snp ?? undefined;
    message.category = object.category ?? undefined;
    message.year = object.year ?? undefined;
    return message;
  },
};

function createBasePolicyStatusContainer(): PolicyStatusContainer {
  return { statusByUpdater: {}, lastUpdater: undefined, currentStatuses: [], historicStatuses: [] };
}

export const PolicyStatusContainer = {
  encode(message: PolicyStatusContainer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.statusByUpdater).forEach(([key, value]) => {
      PolicyStatusContainer_StatusByUpdaterEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    if (message.lastUpdater !== undefined) {
      writer.uint32(18).string(message.lastUpdater);
    }
    for (const v of message.currentStatuses) {
      writer.uint32(26).string(v!);
    }
    for (const v of message.historicStatuses) {
      writer.uint32(34).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PolicyStatusContainer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePolicyStatusContainer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = PolicyStatusContainer_StatusByUpdaterEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.statusByUpdater[entry1.key] = entry1.value;
          }
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.lastUpdater = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.currentStatuses.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.historicStatuses.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PolicyStatusContainer {
    return {
      statusByUpdater: isObject(object.statusByUpdater)
        ? Object.entries(object.statusByUpdater).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
      lastUpdater: isSet(object.lastUpdater) ? String(object.lastUpdater) : undefined,
      currentStatuses: Array.isArray(object?.currentStatuses) ? object.currentStatuses.map((e: any) => String(e)) : [],
      historicStatuses: Array.isArray(object?.historicStatuses)
        ? object.historicStatuses.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: PolicyStatusContainer): unknown {
    const obj: any = {};
    obj.statusByUpdater = {};
    if (message.statusByUpdater) {
      Object.entries(message.statusByUpdater).forEach(([k, v]) => {
        obj.statusByUpdater[k] = v;
      });
    }
    message.lastUpdater !== undefined && (obj.lastUpdater = message.lastUpdater);
    if (message.currentStatuses) {
      obj.currentStatuses = message.currentStatuses.map((e) => e);
    } else {
      obj.currentStatuses = [];
    }
    if (message.historicStatuses) {
      obj.historicStatuses = message.historicStatuses.map((e) => e);
    } else {
      obj.historicStatuses = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PolicyStatusContainer>, I>>(base?: I): PolicyStatusContainer {
    return PolicyStatusContainer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PolicyStatusContainer>, I>>(object: I): PolicyStatusContainer {
    const message = createBasePolicyStatusContainer();
    message.statusByUpdater = Object.entries(object.statusByUpdater ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = String(value);
        }
        return acc;
      },
      {},
    );
    message.lastUpdater = object.lastUpdater ?? undefined;
    message.currentStatuses = object.currentStatuses?.map((e) => e) || [];
    message.historicStatuses = object.historicStatuses?.map((e) => e) || [];
    return message;
  },
};

function createBasePolicyStatusContainer_StatusByUpdaterEntry(): PolicyStatusContainer_StatusByUpdaterEntry {
  return { key: "", value: "" };
}

export const PolicyStatusContainer_StatusByUpdaterEntry = {
  encode(message: PolicyStatusContainer_StatusByUpdaterEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PolicyStatusContainer_StatusByUpdaterEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePolicyStatusContainer_StatusByUpdaterEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PolicyStatusContainer_StatusByUpdaterEntry {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? String(object.value) : "" };
  },

  toJSON(message: PolicyStatusContainer_StatusByUpdaterEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create<I extends Exact<DeepPartial<PolicyStatusContainer_StatusByUpdaterEntry>, I>>(
    base?: I,
  ): PolicyStatusContainer_StatusByUpdaterEntry {
    return PolicyStatusContainer_StatusByUpdaterEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PolicyStatusContainer_StatusByUpdaterEntry>, I>>(
    object: I,
  ): PolicyStatusContainer_StatusByUpdaterEntry {
    const message = createBasePolicyStatusContainer_StatusByUpdaterEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseCommissions(): Commissions {
  return {
    paymentDescription: undefined,
    amount: undefined,
    statementDate: undefined,
    statementTimestamp: undefined,
    statementDateDay: undefined,
    statementDateMonth: undefined,
    statementDateYear: undefined,
    amountAgency: undefined,
    amountAgent: undefined,
  };
}

export const Commissions = {
  encode(message: Commissions, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.paymentDescription !== undefined) {
      writer.uint32(10).string(message.paymentDescription);
    }
    if (message.amount !== undefined) {
      writer.uint32(17).double(message.amount);
    }
    if (message.statementDate !== undefined) {
      Timestamp.encode(toTimestamp(message.statementDate), writer.uint32(26).fork()).ldelim();
    }
    if (message.statementTimestamp !== undefined) {
      writer.uint32(32).int64(message.statementTimestamp);
    }
    if (message.statementDateDay !== undefined) {
      writer.uint32(40).int32(message.statementDateDay);
    }
    if (message.statementDateMonth !== undefined) {
      writer.uint32(48).int32(message.statementDateMonth);
    }
    if (message.statementDateYear !== undefined) {
      writer.uint32(56).int32(message.statementDateYear);
    }
    if (message.amountAgency !== undefined) {
      writer.uint32(65).double(message.amountAgency);
    }
    if (message.amountAgent !== undefined) {
      writer.uint32(73).double(message.amountAgent);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Commissions {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCommissions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.paymentDescription = reader.string();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.amount = reader.double();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.statementDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.statementTimestamp = longToNumber(reader.int64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.statementDateDay = reader.int32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.statementDateMonth = reader.int32();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.statementDateYear = reader.int32();
          continue;
        case 8:
          if (tag !== 65) {
            break;
          }

          message.amountAgency = reader.double();
          continue;
        case 9:
          if (tag !== 73) {
            break;
          }

          message.amountAgent = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Commissions {
    return {
      paymentDescription: isSet(object.paymentDescription) ? String(object.paymentDescription) : undefined,
      amount: isSet(object.amount) ? Number(object.amount) : undefined,
      statementDate: isSet(object.statementDate) ? fromJsonTimestamp(object.statementDate) : undefined,
      statementTimestamp: isSet(object.statementTimestamp) ? Number(object.statementTimestamp) : undefined,
      statementDateDay: isSet(object.statementDateDay) ? Number(object.statementDateDay) : undefined,
      statementDateMonth: isSet(object.statementDateMonth) ? Number(object.statementDateMonth) : undefined,
      statementDateYear: isSet(object.statementDateYear) ? Number(object.statementDateYear) : undefined,
      amountAgency: isSet(object.amountAgency) ? Number(object.amountAgency) : undefined,
      amountAgent: isSet(object.amountAgent) ? Number(object.amountAgent) : undefined,
    };
  },

  toJSON(message: Commissions): unknown {
    const obj: any = {};
    message.paymentDescription !== undefined && (obj.paymentDescription = message.paymentDescription);
    message.amount !== undefined && (obj.amount = message.amount);
    message.statementDate !== undefined && (obj.statementDate = message.statementDate.toISOString());
    message.statementTimestamp !== undefined && (obj.statementTimestamp = Math.round(message.statementTimestamp));
    message.statementDateDay !== undefined && (obj.statementDateDay = Math.round(message.statementDateDay));
    message.statementDateMonth !== undefined && (obj.statementDateMonth = Math.round(message.statementDateMonth));
    message.statementDateYear !== undefined && (obj.statementDateYear = Math.round(message.statementDateYear));
    message.amountAgency !== undefined && (obj.amountAgency = message.amountAgency);
    message.amountAgent !== undefined && (obj.amountAgent = message.amountAgent);
    return obj;
  },

  create<I extends Exact<DeepPartial<Commissions>, I>>(base?: I): Commissions {
    return Commissions.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Commissions>, I>>(object: I): Commissions {
    const message = createBaseCommissions();
    message.paymentDescription = object.paymentDescription ?? undefined;
    message.amount = object.amount ?? undefined;
    message.statementDate = object.statementDate ?? undefined;
    message.statementTimestamp = object.statementTimestamp ?? undefined;
    message.statementDateDay = object.statementDateDay ?? undefined;
    message.statementDateMonth = object.statementDateMonth ?? undefined;
    message.statementDateYear = object.statementDateYear ?? undefined;
    message.amountAgency = object.amountAgency ?? undefined;
    message.amountAgent = object.amountAgent ?? undefined;
    return message;
  },
};

function createBaseAllegation(): Allegation {
  return {
    id: undefined,
    active: undefined,
    note: undefined,
    result: undefined,
    complaint: undefined,
    date: undefined,
    dateDay: undefined,
    dateMonth: undefined,
    dateYear: undefined,
    dateTimestamp: undefined,
    dueDate: undefined,
    dueDateDay: undefined,
    dueDateMonth: undefined,
    dueDateYear: undefined,
    dueDateTimestamp: undefined,
  };
}

export const Allegation = {
  encode(message: Allegation, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.active !== undefined) {
      writer.uint32(16).bool(message.active);
    }
    if (message.note !== undefined) {
      writer.uint32(26).string(message.note);
    }
    if (message.result !== undefined) {
      writer.uint32(34).string(message.result);
    }
    if (message.complaint !== undefined) {
      writer.uint32(42).string(message.complaint);
    }
    if (message.date !== undefined) {
      Timestamp.encode(toTimestamp(message.date), writer.uint32(50).fork()).ldelim();
    }
    if (message.dateDay !== undefined) {
      writer.uint32(56).int32(message.dateDay);
    }
    if (message.dateMonth !== undefined) {
      writer.uint32(64).int32(message.dateMonth);
    }
    if (message.dateYear !== undefined) {
      writer.uint32(72).int32(message.dateYear);
    }
    if (message.dateTimestamp !== undefined) {
      writer.uint32(80).int64(message.dateTimestamp);
    }
    if (message.dueDate !== undefined) {
      Timestamp.encode(toTimestamp(message.dueDate), writer.uint32(90).fork()).ldelim();
    }
    if (message.dueDateDay !== undefined) {
      writer.uint32(96).int32(message.dueDateDay);
    }
    if (message.dueDateMonth !== undefined) {
      writer.uint32(104).int32(message.dueDateMonth);
    }
    if (message.dueDateYear !== undefined) {
      writer.uint32(112).int32(message.dueDateYear);
    }
    if (message.dueDateTimestamp !== undefined) {
      writer.uint32(120).int64(message.dueDateTimestamp);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Allegation {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAllegation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.active = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.note = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.result = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.complaint = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.date = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.dateDay = reader.int32();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.dateMonth = reader.int32();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.dateYear = reader.int32();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.dateTimestamp = longToNumber(reader.int64() as Long);
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.dueDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.dueDateDay = reader.int32();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.dueDateMonth = reader.int32();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.dueDateYear = reader.int32();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.dueDateTimestamp = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Allegation {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      active: isSet(object.active) ? Boolean(object.active) : undefined,
      note: isSet(object.note) ? String(object.note) : undefined,
      result: isSet(object.result) ? String(object.result) : undefined,
      complaint: isSet(object.complaint) ? String(object.complaint) : undefined,
      date: isSet(object.date) ? fromJsonTimestamp(object.date) : undefined,
      dateDay: isSet(object.dateDay) ? Number(object.dateDay) : undefined,
      dateMonth: isSet(object.dateMonth) ? Number(object.dateMonth) : undefined,
      dateYear: isSet(object.dateYear) ? Number(object.dateYear) : undefined,
      dateTimestamp: isSet(object.dateTimestamp) ? Number(object.dateTimestamp) : undefined,
      dueDate: isSet(object.dueDate) ? fromJsonTimestamp(object.dueDate) : undefined,
      dueDateDay: isSet(object.dueDateDay) ? Number(object.dueDateDay) : undefined,
      dueDateMonth: isSet(object.dueDateMonth) ? Number(object.dueDateMonth) : undefined,
      dueDateYear: isSet(object.dueDateYear) ? Number(object.dueDateYear) : undefined,
      dueDateTimestamp: isSet(object.dueDateTimestamp) ? Number(object.dueDateTimestamp) : undefined,
    };
  },

  toJSON(message: Allegation): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.active !== undefined && (obj.active = message.active);
    message.note !== undefined && (obj.note = message.note);
    message.result !== undefined && (obj.result = message.result);
    message.complaint !== undefined && (obj.complaint = message.complaint);
    message.date !== undefined && (obj.date = message.date.toISOString());
    message.dateDay !== undefined && (obj.dateDay = Math.round(message.dateDay));
    message.dateMonth !== undefined && (obj.dateMonth = Math.round(message.dateMonth));
    message.dateYear !== undefined && (obj.dateYear = Math.round(message.dateYear));
    message.dateTimestamp !== undefined && (obj.dateTimestamp = Math.round(message.dateTimestamp));
    message.dueDate !== undefined && (obj.dueDate = message.dueDate.toISOString());
    message.dueDateDay !== undefined && (obj.dueDateDay = Math.round(message.dueDateDay));
    message.dueDateMonth !== undefined && (obj.dueDateMonth = Math.round(message.dueDateMonth));
    message.dueDateYear !== undefined && (obj.dueDateYear = Math.round(message.dueDateYear));
    message.dueDateTimestamp !== undefined && (obj.dueDateTimestamp = Math.round(message.dueDateTimestamp));
    return obj;
  },

  create<I extends Exact<DeepPartial<Allegation>, I>>(base?: I): Allegation {
    return Allegation.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Allegation>, I>>(object: I): Allegation {
    const message = createBaseAllegation();
    message.id = object.id ?? undefined;
    message.active = object.active ?? undefined;
    message.note = object.note ?? undefined;
    message.result = object.result ?? undefined;
    message.complaint = object.complaint ?? undefined;
    message.date = object.date ?? undefined;
    message.dateDay = object.dateDay ?? undefined;
    message.dateMonth = object.dateMonth ?? undefined;
    message.dateYear = object.dateYear ?? undefined;
    message.dateTimestamp = object.dateTimestamp ?? undefined;
    message.dueDate = object.dueDate ?? undefined;
    message.dueDateDay = object.dueDateDay ?? undefined;
    message.dueDateMonth = object.dueDateMonth ?? undefined;
    message.dueDateYear = object.dueDateYear ?? undefined;
    message.dueDateTimestamp = object.dueDateTimestamp ?? undefined;
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

// If you get a compile-error about 'Constructor<Long> and ... have no overlap',
// add '--ts_proto_opt=esModuleInterop=true' as a flag when calling 'protoc'.
if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
