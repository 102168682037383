import { createFeature, createReducer, on } from '@ngrx/store';
import { Publisher } from 'src/app/shared/models';
import { PublisherActions } from './publisher.actions';

export const publisherFeatureKey = 'publisher';

export interface PublisherState {
  publishers: Publisher[];
  publisher: Publisher | null;
  loading: boolean;
  error: Error | null;
}

export const initialState: PublisherState = {
  publishers: [],
  publisher: null,
  loading: false,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(
    PublisherActions.loadPublishers,
    (state): PublisherState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    PublisherActions.loadPublishersSuccess,
    (state, { publishers }): PublisherState => ({
      ...state,
      publishers,
      loading: false,
      error: null,
    })
  ),
  on(
    PublisherActions.loadPublishersFailure,
    (state, { error }): PublisherState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    PublisherActions.loadPublisher,
    (state): PublisherState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    PublisherActions.loadPublisherSuccess,
    (state, { publisher }): PublisherState => ({
      ...state,
      publishers: [publisher],
      publisher: publisher,
      loading: false,
      error: null,
    })
  ),
  on(
    PublisherActions.loadPublisherFailure,
    (state, { error }): PublisherState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    PublisherActions.addPublisher,
    (state): PublisherState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    PublisherActions.addPublisherSuccess,
    (state, { publisher }): PublisherState => ({
      ...state,

      publishers: [...state.publishers, publisher],

      loading: false,

      error: null,
    })
  ),
  on(
    PublisherActions.addPublisherFailure,
    (state, { error }): PublisherState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    PublisherActions.removePublisher,
    (state): PublisherState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    PublisherActions.removePublisherSuccess,
    (state, { publisherId }): PublisherState => ({
      ...state,
      publishers: state.publishers.filter(
        publisher => publisher.id !== publisherId
      ),
      loading: false,
      error: null,
    })
  ),
  on(
    PublisherActions.removePublisherFailure,
    (state, { error }): PublisherState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    PublisherActions.updatePublisher,
    (state): PublisherState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    PublisherActions.updatePublisherSuccess,
    (state, { publisher }): PublisherState => ({
      ...state,
      publishers: state.publishers.map(item =>
        // item.id === publisher.id ? publisher : item
        item.id === publisher.id ? { ...item, ...publisher } : item
      ),
      loading: false,
      error: null,
    })
  ),
  on(
    PublisherActions.updatePublisherFailure,
    (state, { error }): PublisherState => ({
      ...state,
      loading: false,
      error,
    })
  )
);

export const publisherFeature = createFeature({
  name: publisherFeatureKey,
  reducer,
});
